define('common/config/templates',['require','angular'],function(require) { require("angular"); (function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/accounts/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<h1 class="pageHead">Accounts</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<tabset>\n' +
    '			<tab heading="2 Year View" ng-if="uiState.twoYearViewAvailable">\n' +
    '				<div class="two-year-view" two-year-view></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Accounts" ng-if="user.hasComponentPermissions(\'advancedAccountSearch\')" active="uiState.isAccountsTabDefault">\n' +
    '				<div accounts-search search-data-results="searchData"></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Submissions" ng-if="user.hasComponentPermissions(\'advancedSubmissionSearch\')">\n' +
    '				<div submissions-search search-data-results="searchData"></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Policies" ng-if="user.hasComponentPermissions(\'advancedPolicySearch\')">\n' +
    '				<div policies-search search-data-results="searchData"></div>\n' +
    '			</tab>\n' +
    '		</tabset>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/accountSummary/template/insuredTemplate.html',
    '<div class="account-summary">\n' +
    '\n' +
    '	<div class="row-fluid">\n' +
    '		<div id="mainWrapper" class="span12">\n' +
    '			<tabset>\n' +
    '				<tab heading="Account Summary" ng-repeat="tab in tabs.summary" active="tab.active">\n' +
    '\n' +
    '\n' +
    '					<div loading-bar ng-if="!account && !status.notFound && !status.noAccessiblePolicies && !status.serverDown"></div>\n' +
    '					<div class="row-fluid" ng-if="status.notFound">\n' +
    '						<div class="span12">\n' +
    '							<div class="alert alert-warning">Account not found.</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<div class="row-fluid" ng-if="status.noAccessiblePolicies">\n' +
    '						<div class="span12">\n' +
    '							<div class="alert alert-warning">There is not an active policy on record for your account. Please contact Customer Service for any necessary assistance.</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<div class="row-fluid" ng-if="status.serverDown">\n' +
    '						<div class="span12">\n' +
    '							<div class="alert alert-warning">We’re sorry, the information you are looking for is temporarily unavailable. Please try again later.</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					\n' +
    '					\n' +
    '					<div ng-if="account">\n' +
    '						<div class="row-fluid" ng-if="account.missingData">\n' +
    '							<div class="span12">\n' +
    '								<div class="alert missing-data">\n' +
    '									Some or all of the data for this account is not currently available. Please try again later.\n' +
    '								</div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '						<div class="row-fluid">\n' +
    '							<div class="span2">\n' +
    '								<div insured-info account="account" account-id-list="accountIds"></div>\n' +
    '								<div add-account on-success="onAddAccountSuccess"></div>\n' +
    '								<div insured-claims claims="claims" open-claim-detail="openClaimDetail"></div>\n' +
    '							</div>\n' +
    '							<div class="span10">\n' +
    '								<div ng-if="accountIds.length > 1" class="alert">\n' +
    '                                    When making a payment, please be aware each billing account needs to be paid separately to ensure accurate posting of your payment.\n' +
    '								</div>\n' +
    '								<div ng-if="isFilteredClaimMessageVisible" class="alert" style="font-weight: bold"> \n' +
    '									Alaska, Idaho, Montana, and Oregon Workers\' Compensation claims data is currently unavailable. Please contact Customer Service at 888-500-3344 for accurate claims details and/or loss runs.\n' +
    '								</div>\n' +
    '								<div payment-system account="account" accounts="accounts" class="contentBlock"></div>\n' +
    '								\n' +
    '								<div account-list accounts="accounts" open-tab="openPolicyTab" set-message-view="setMessageView" user="user"></div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '\n' +
    '\n' +
    '				</tab>\n' +
    '				<tab ng-repeat="tab in tabs.message" active="tab.active" ng-show="messageVisible">\n' +
    '					<tab-heading>Messages</tab-heading>\n' +
    '					<message-form account="account" accounts="accounts"></message-form>\n' +
    '				</tab>\n' +
    '				<tab ng-repeat="tab in tabs.policies" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						<span product-label="tab.model.program" auto-width="true">{{:: tab.model.program }}</span>\n' +
    '						Policy - {{:: tab.model.effectiveDate | date:"MM/dd/yyyy" }}\n' +
    '						<span ng-click="close( $event, tab.model.policyNumber )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<policy-detail account="tab.model.account" policy="tab.model" all-policies="policies" open-policy-tab="openPolicyTab" submission="tab.model" all-submissions="submissions" open-submission-tab="openSubmissionTab" set-message-view="setMessageView" user="user"></policy-detail>\n' +
    '				</tab>\n' +
    '				<tab ng-repeat="tab in tabs.submissions" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						<span product-label="tab.model.program" auto-width="true">{{:: tab.model.program }}</span>\n' +
    '						Submission - {{:: tab.model.effectiveDate | date:"MM/dd/yyyy" }}\n' +
    '						<span ng-click="close( $event, tab.model.submissionId )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<submission-detail account="tab.model.account" submission="tab.model" all-submissions="submissions" open-submission-tab="openSubmissionTab"></submission-detail>\n' +
    '				</tab>\n' +
    '				<tab ng-repeat="tab in tabs.claims" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						<span ng-bind-html="tab.model.tab_title"></span>\n' +
    '						<span ng-click="closeClaim( $event,\'claims\',tab.model.claim.policyNumber )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<insured-claim-info claim="tab.model" open-claim-detail="openClaimDetail"></insured-claim-info>\n' +
    '				</tab>\n' +
    '				<tab ng-repeat="tab in tabs.occurrence" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						Occurrence - {{:: tab.model.occurrenceDate | date:"MM/dd/yyyy" }}\n' +
    '						<span ng-click="closeClaim( $event,\'occurrence\',tab.model.occurrenceNumber )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<claim-detail policy-number="tab.model.policyNumber" occurrence-number="tab.model.occurrenceNumber" claim="tab.model" open-claim-detail="openClaimDetail"></claim-detail>\n' +
    '				</tab>\n' +
    '			</tabset>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/accountSummary/template/template.html',
    '<div class="account-summary">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h1 class="pageHead">{{:: account.accountName }}</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div class="row-fluid">\n' +
    '		<div id="mainWrapper" class="span12">\n' +
    '			<tabset>\n' +
    '				<tab heading="Account Summary" ng-repeat="tab in tabs.summary" active="tab.active">\n' +
    '					<div loading-bar ng-if="!account && !status.notFound && !status.serverDown"></div>\n' +
    '					<div class="row-fluid" ng-if="status.serverDown">\n' +
    '						<div class="span12">\n' +
    '							<div class="alert alert-warning">We’re sorry, the information you are looking for is temporarily unavailable.</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<div class="row-fluid" ng-if="status.notFound">\n' +
    '						<div class="span12">\n' +
    '							<div class="alert alert-warning" ng-if="!status.claimNotFound">Account not found.</div>\n' +
    '							<div class="alert alert-warning" ng-if="status.claimNotFound">Occurrence detail not available at this time. Please contact your adjuster for more information.</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<div ng-if="account">\n' +
    '						<div class="row-fluid" ng-if="account.missingData">\n' +
    '							<div class="span12">\n' +
    '								<div class="alert missing-data">\n' +
    '									Some or all of the data for this account is not currently available. Please try again later.\n' +
    '								</div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '						<div class="row-fluid">\n' +
    '							<div class="span2">\n' +
    '								<div insured-info account="account"></div>\n' +
    '								<div class="actions well-action">\n' +
    '									<ul class="nav nav-list">\n' +
    '										<li class="nav-header">I want to</li>\n' +
    '                                        <li class="detail">\n' +
    '                                            <span>\n' +
    '                                                <a href ng-click="setMessageView(\'send\', {typeId : 1})">\n' +
    '                                                    <span>Send A Message</span>\n' +
    '                                                </a>\n' +
    '                                            </span>\n' +
    '                                        </li>\n' +
    '										<li class="detail" ng-if="isLossRunLinkVisible()">\n' +
    '                                            <span loss-runs account-id="account.piNumber" user="user"></span>\n' +
    '										</li>\n' +
    '									</ul>\n' +
    '								</div>\n' +
    '							</div>\n' +
    '							<div class="span10">\n' +
    '								<div payment-system account="account" accounts="accounts"></div>\n' +
    '								<div quote-summary-section ng-if="upcomingRenewals.length > 0" quotes="upcomingRenewals" quote-type="Upcoming Renewals" open-tab="openSubmissionTab" set-message-view="setMessageView" user="user" open-application-tab="openApplicationTab"></div>\n' +
    '								<div quote-summary-section quotes="newSubmission" account="account" quote-type="Submissions" open-tab="openSubmissionTab" set-message-view="setMessageView" user="user" open-application-tab="openApplicationTab"></div>\n' +
    '								<div quote-list quotes="account.policies" account="account" quote-type="Policies" open-tab="openPolicyTab" set-message-view="setMessageView" user="user" open-application-tab="openApplicationTab"></div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '						<div class="row-fluid">\n' +
    '							<div class="span2">&nbsp;</div>\n' +
    '							<div class="span10">\n' +
    '								<div insured-account-activity activity-items="activity"></div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</tab>\n' +
    '                <tab ng-repeat="tab in tabs.message" active="tab.active">\n' +
    '                	<tab-heading>Messages</tab-heading>\n' +
    '                	<message-container account="account" accounts="accounts"></message-container>\n' +
    '                </tab>\n' +
    '				<tab ng-repeat="tab in tabs.policies" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						<span product-label="tab.model.program" auto-width="true">{{:: tab.model.program }}</span>\n' +
    '						Policy - {{:: tab.model.effectiveDate | date:"MM/dd/yyyy" }}\n' +
    '						<span ng-click="close( $event, \'policies\', tab.model.policyNumber )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<policy-detail account="tab.model.account" policy="tab.model" all-policies="policies" set-message-view="setMessageView" open-policy-tab="openPolicyTab" open-claim-tab="openClaimTab" open-submission-tab="openSubmissionTab" user="user"></policy-detail>\n' +
    '				</tab>\n' +
    '				\n' +
    '				<tab ng-if="user.hasComponentPermissions( \'claimsData\' ) && showLossRuns()" ng-repeat="tab in tabs.claims" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						<span product-label="tab.model.policy.program" auto-width="true">{{:: tab.model.policy.program }}</span>\n' +
    '						Occurrence - {{:: tab.model.claim.occurrenceDate | date:"MM/dd/yyyy" }}\n' +
    '						<span ng-click="close( $event, \'claims\', tab.model.occurrenceNumber )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<claim-detail policy-number="tab.model.policy.policyNumber" occurrence-number="tab.model.occurrenceNumber" claim="tab.model.claim" load-claim="tab.model.loadClaim"></claim-detail>\n' +
    '				</tab>\n' +
    '				<tab ng-repeat="tab in tabs.applications" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						<span product-label="tab.model.program" auto-width="true">{{:: tab.model.program }}</span>\n' +
    '						Application - {{:: tab.model.effectiveDate | date:"MM/dd/yyyy" }}\n' +
    '						<span ng-click="close( $event, \'applications\', tab.model.applicationId )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<application-data application="tab.model"></application-data>\n' +
    '				</tab>\n' +
    '				<tab ng-repeat="tab in tabs.submissions" active="tab.active">\n' +
    '					<tab-heading>\n' +
    '						<span product-label="tab.model.program" auto-width="true">{{:: tab.model.program }}</span>\n' +
    '						Submission - {{:: tab.model.effectiveDate | date:"MM/dd/yyyy" }}\n' +
    '						<span ng-click="close( $event, \'submissions\', tab.model.submissionId )">&times;</span>\n' +
    '					</tab-heading>\n' +
    '					<submission-detail account="tab.model.account" set-message-view="setMessageView" submission="tab.model" all-submissions="submissions" open-submission-tab="openSubmissionTab"></submission-detail>\n' +
    '				</tab>\n' +
    '			</tabset>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/bundlePayment/template/template.html',
    '<div id="bundlePaymentWrapper">\n' +
    '<div ng-show="uiInit">\n' +
    '    <div class="row-fluid">\n' +
    '        <br>\n' +
    '        <div class="progress progress-striped progress-success active">\n' +
    '            <div class="bar" style="width: 100%;"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="false">\n' +
    '	<!-- iframes for each of up to three payment systems to talk to - todo-bc: set ng-show above true for development only -->\n' +
    '    <iframe style="border: 1px solid black;" width="1000" height="60" id="ePayMpolicyIframe" name="ePayIframeMpolicy" ng-src="{{ ePayMpolicyIframeSrc }}"></iframe>\n' +
    '    <iframe width="1000" height="60" id="ePayIconIframe" name="ePayIframeIcon" ng-src="{{ ePayIconIframeSrc }}"></iframe>\n' +
    '    <iframe width="1000" height="60" id="iscIframe" name="iscIframe" ng-src="{{ iscIframeSrc }}"></iframe>\n' +
    '    <iframe width="1000" height="60" id="ePayBC9Iframe" name="ePayBC9Iframe" ng-src="{{ ePayBC9IframeSrc }}"></iframe>\n' +
    '	<form action="{{ iscFormAction }}" method="post" id="iscForm" target="iscIframe">\n' +
    '		<input type="text" name="ticketId" ng-model="iscTicket.id">\n' +
    '		<input type="text" name="ticket" ng-model="iscTicket.ticketString">\n' +
    '		<input type="text" name="insuredenterpriseid" ng-model="iscTicket.enterpriseId">\n' +
    '		<input type="text" name="noheaders" value="true">\n' +
    '		<input type="text" name="nextEvent" value="page.payment">\n' +
    '	</form>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="uiPayment" id="insidePayment" class="row-fluid">\n' +
    '	<form name="pmtForm" class="form" autocomplete="off" ng-submit="eventPaymentFormSubmitted()" novalidate>\n' +
    '		<div class="alert alert-error help-inline error" ng-show="submitted && paymentError">{{ paymentError }}</div>\n' +
    '        <fieldset>\n' +
    '            <legend>{{ uiPaymentLabels.accountHeader }}</legend>{{paymentSystemBreakdown}}test\n' +
    '        </fieldset>\n' +
    '        <table class="table table-striped" ng-show="true">\n' +
    '            <thead>\n' +
    '            <tr>\n' +
    '\n' +
    '                <th>{{ uiPaymentLabels.policyNumber }}</th>\n' +
    '                <th>{{ uiPaymentLabels.effectiveDate }}</th>\n' +
    '                <th>{{ uiPaymentLabels.currentDue }}</th>\n' +
    '                <th>Balance</th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '            <tr ng-repeat="policy in data.itemsDue | filter:{\'policyNumber\':\'!BILLING FEES\'} | orderBy: [\'policyNumber\', \'-isRenewalDownPayment\']: true">\n' +
    '                <td>\n' +
    '                    <span ng-if="policy.isRenewalDownPayment">{{ uiPaymentLabels.renewalDeposit }}</span>\n' +
    '                    {{ policy.policyNumber }}\n' +
    '                </td>\n' +
    '                <td ng-show="true || policy.acceptPaymentsOnPolicy">{{ policy.effectiveDate | date:"MM/dd/yyyy" }}</td>\n' +
    '                <td ng-show="true || policy.acceptPaymentsOnPolicy">{{ policy.amountDue | formatMoney }}</td>\n' +
    '                <td ng-show="true || policy.acceptPaymentsOnPolicy">{{ policy.balance | formatMoney }}</td>\n' +
    '            </tr>\n' +
    '            <tr ng-repeat="policy in data.itemsDue | filter:{\'policyNumber\':\'BILLING FEES\'}">\n' +
    '	            <td colspan="2">Fees</td>\n' +
    '	            <td>{{ policy.amountDue | formatMoney }}</td>\n' +
    '	            <td>{{ policy.balance | formatMoney }}</td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '        <div ng-show="true || account.acceptPayment">\n' +
    '            <fieldset ng-show="true || !account.config.delayPayment">\n' +
    '                <legend>{{ uiPaymentLabels.paymentOptions }}</legend>\n' +
    '                <div class="alert {{info}}">Payments made within the last 24 hours may not be reflected in your current balance.</div>\n' +
    '                <span class="alert alert-error help-inline error" ng-show="submitted && pmtForm.paymentOption.$error.required">{{ error.poRequired }}</span>\n' +
    '                <div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.paymentOption.$error.required]">\n' +
    '                    <label class="radio">\n' +
    '                        <input ng-model="paymentForm.paymentOption" type="radio" class="piiSafe" name="paymentOption" value="currentDue" ng-required="!account.config.delayPayment" ng-change="changeAmount()">\n' +
    '                        {{ uiPaymentLabels.poCurrent }} ({{data.totalCurrentDue | formatMoney}})<br>\n' +
    '                    </label>\n' +
    '                    <label class="radio">\n' +
    '                        <input ng-model="paymentForm.paymentOption" type="radio" class="piiSafe" name="paymentOption" value="payBalance" ng-required="!account.config.delayPayment" ng-change="changeAmount()">\n' +
    '                        {{ uiPaymentLabels.poBalance }} ({{ data.totalBalance | formatMoney }})\n' +
    '                    </label>\n' +
    '                    <label class="radio">\n' +
    '                        <input ng-model="paymentForm.paymentOption" type="radio" class="piiSafe" name="paymentOption" value="specifyAmount" ng-required="!account.config.delayPayment" ng-change="changeSpecifiedAmount()">\n' +
    '                        {{ uiPaymentLabels.poSpecifyAmount }}\n' +
    '                    </label>\n' +
    '                    <label class="radio" ng-show="config.allowRecurringSetup || account.config.delayPayment">\n' +
    '                        <input ng-model="paymentForm.paymentOption" type="radio" class="piiSafe" name="paymentOption" value="setupRecurring" ng-required="!account.config.delayPayment" id="paymentOptionDiv">\n' +
    '                        <span ng-show="!config.recurring">{{ uiPaymentLabels.poSetupRecurring }}</span>\n' +
    '                        <!-- todo: need to add in display of existing payment account card number, etc... (not returned currently) -->\n' +
    '                        <span ng-show="config.recurring">{{ uiPaymentLabels.poEditRecurring }}</span>\n' +
    '                    </label>\n' +
    '                    <div class="well" ng-show="paymentForm.paymentOption==\'currentDue\' && false">\n' +
    '                        <div id="currentDue">\n' +
    '                            <table class="table">\n' +
    '                                <thead>\n' +
    '                                <tr>\n' +
    '                                    <th>{{ global.policy }}</th>\n' +
    '                                    <th>{{ uiPaymentLabels.currentDue }}</th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody>\n' +
    '                                <tr ng-repeat="policy in data.itemsDue  | orderBy: [\' policyNumber\', \'-isRenewalDownPayment\' ] : true">\n' +
    '                                    <td><span ng-if="policy.isRenewalDownPayment">{{ uiPaymentLabels.renewalDeposit }}</span> {{policy.policyNumber}}</td>\n' +
    '                                    <td>{{policy.due | formatMoney}}</td>\n' +
    '                                </tr>\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div ng-show="paymentForm.paymentOption==\'specifyAmount\'">\n' +
    '                        <div id="specifyAmount" class="well" ng-show="!showCalculator">\n' +
    '                            <table class="table">\n' +
    '                                <thead>\n' +
    '                                <tr>\n' +
    '                                    <th>{{ global.account }}/{{ global.policy }}</th>\n' +
    '                                    <th>{{ uiPaymentLabels.currentDue }}</th>\n' +
    '                                    <th>{{ global.balance }}</th>\n' +
    '                                    <th>{{ global.amount }}</th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody>\n' +
    '\n' +
    '                                <tr ng-repeat="policy in data.itemsDue | filter:{\'policyNumber\':\'!BILLING FEES\'}| orderBy: [ \'policyNumber\', \'-isRenewalDownPayment\' ] : true">\n' +
    '                                    <td>\n' +
    '                                        <i class="icon-file"></i>\n' +
    '                                        <span ng-if="policy.isRenewalDownPayment">{{ uiPaymentLabels.renewalDeposit }}</span>\n' +
    '                                        <span>{{ policy.policyNumber }}</span>\n' +
    '                                    </td>\n' +
    '                                    <td>{{ policy.amountDue | formatMoney}}</td>\n' +
    '                                    <td>{{ policy.balance | formatMoney}}</td>\n' +
    '                                    <td width="30%">\n' +
    '                                        <input ng-blur="validateSpecifyAmountPayment()" ng-change="changeSpecifiedAmount()" ng-model="policy.payment" name="amount" type="text" class="input-small piiSafe">\n' +
    '                                        <span class="alert alert-error help-inline error" ng-show="policy.payment && !policy.isRenewalDownPayment && paymentForm.paymentOption == \'specifyAmount\' && policy.payment > Math.max(policy.balance, policy.amountDue) && policy.payment != 0">Maximum Payment on this policy is: {{Math.max(policy.balance, policy.amountDue) | formatMoney}}</span>\n' +
    '                                        <span class="alert alert-error help-inline error" ng-show="policy.payment && policy.isRenewalDownPayment && paymentForm.paymentOption == \'specifyAmount\' && policy.payment < Math.max(policy.balance, policy.amountDue) && policy.payment != 0">Renewal deposit on this policy must be greater than or equal to {{Math.max(policy.balance, policy.amountDue) | formatMoney}}</span>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '                                <tr ng-repeat="policy in data.itemsDue | filter:{\'policyNumber\':\'BILLING FEES\'}">\n' +
    '	                                <td>\n' +
    '		                                <div>Fees</div>\n' +
    '		                                <sub>This amount is required to be paid in full.</sub>\n' +
    '	                                </td>\n' +
    '	                                <td>{{ policy.amountDue | formatMoney}}</td>\n' +
    '	                                <td>{{ policy.balance | formatMoney}}</td>\n' +
    '	                                <td>{{policy.balance | formatMoney}}</td>\n' +
    '                                </tr>\n' +
    '                                <tr>\n' +
    '                                    <td ng-bind-html-unsafe="account.insuredName"><strong></strong></td>\n' +
    '                                    <td>{{ data.totalCurrentDue | formatMoney }}</td>\n' +
    '                                    <td>{{ data.totalBalance | formatMoney }}</td>\n' +
    '                                    <td width="30%">\n' +
    '                                        <input type="text" name="totalSpecifiedAmount" ng-model="totalSpecifiedAmount" class="input-small piiSafe" value="{{ totalSpecifiedAmount | formatMoney }}" disabled="disabled">\n' +
    '	                                    <div class="alert alert-error help-inline error" ng-show="submitted && paymentForm.paymentOption==\'specifyAmount\' && totalSpecifiedAmount <= 0">{{ error.noAmountZero }}</div>\n' +
    '                                        <!--<div class="alert alert-error help-inline error" ng-show="submitted && paymentForm.paymentOption==\'specifyAmount\' && (!totalSpecifiedAmount || account.policies.length > 1) && (!totalSpecifiedAmount || totalSpecifiedAmount < account.config.minimumPayment ) ">{{ \'error.noAmountMin\':{ minimum: account.config.minimumPayment } }}</div>-->\n' +
    '                                        <!--<div class="alert alert-error help-inline error" ng-show="submitted && paymentForm.paymentOption==\'specifyAmount\' && account.policies.length > 1 && (totalSpecifiedAmount > account.config.maximumPayment ) ">{{ error.noAmountMax :{ maximum: 1234 } }}</div>-->\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                            <button type="button" class="btn btn-primary" ng-click="showCalculator = !showCalculator" ng-init="showCalculator=false" ng-show="account.flatPolicyInstallments.length > 0">{{ \'uiPaymentLabels.poScheduleCalculator\' }}</button>\n' +
    '                        </div>\n' +
    '                        <div id="scheduleCalculator" class="well" ng-show="showCalculator">\n' +
    '                            <table class="table">\n' +
    '                                <thead>\n' +
    '                                <tr>\n' +
    '                                    <th>{{ global.account }}/{{ global.policy }}</th>\n' +
    '                                    <th>{{ global.amount }}</th>\n' +
    '                                    <th>{{ uiPaymentLabels.poDueDate }}</th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody>\n' +
    '                                <tr ng-repeat="record in account.flatPolicyInstallments" ng-show="record.acceptPaymentsOnPolicy" ng-click="payThrough(record)" ng-class="{true: \'alert alert-info\'}[this.$index <= selectedIndex]">\n' +
    '                                    <td><i class="icon-file"></i>&nbsp{{record.policyNumber}}</td>\n' +
    '                                    <!--<td>{{record.amount | currency2Filter}}</td>-->\n' +
    '                                    <td>{{record.amount}}</td>\n' +
    '                                    <td>{{record.paymentDate | date}}</td>\n' +
    '                                </tr>\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                            <div class="alert alert-info small-alerts">{{ uiPaymentLabels.scInstructions }}\n' +
    '                                {{ uiPaymentLabels.scAmount }} <strong>{{selectedPayment}}</strong></div>\n' +
    '                            <button type="button" class="btn btn-primary" ng-click="payThroughBtnHandler(true)" ng-init="showCalculator=false">{{ global.done }}</button>\n' +
    '                            <button type="button" class="btn btn-link" ng-click="payThroughBtnHandler(false)" ng-init="showCalculator=false">{{ global.cancel }}</button>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </fieldset>\n' +
    '            <!--<fieldset ng-show="account.config.delayPayment || paymentForm.edit_paymentInfo || paymentForm.edit_contactInfo || ((paymentForm.profile.accountType!=\'card\' || account.profiles) && paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">\n' +
    '                <legend>{{ \'uiPaymentLabels.contactInformation\' }}</legend>\n' +
    '                <div class="control-group">\n' +
    '                    <div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.bill_to_email.$invalid]">\n' +
    '                        <label>{{ \'global.email\' }} {{ \'global.emailAddendum\' }}</label>\n' +
    '                        <input type="email" id="bill_to_email" name="bill_to_email" placeholder="Enter email to notify" class="piiSafe" ng-model="paymentForm.bill_to_email" ng-required="account.config.delayPayment || paymentForm.edit_paymentInfo || paymentForm.edit_contactInfo || ((paymentForm.profile.accountType!=\'card\' || account.profiles) && paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">\n' +
    '                        <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_email.$error.required">{{ \'error.emailRequired\' }}</span>\n' +
    '                        <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_email.$error.email">{{ \'error.emailInvalid\' }}</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </fieldset>-->\n' +
    '            <fieldset ng-show="account.config.delayPayment || paymentForm.edit_paymentInfo || (paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">\n' +
    '                <legend>{{ uiPaymentLabels.paymentInformation }}</legend>\n' +
    '                <div class="control-group">\n' +
    '                    <label>{{ uiPaymentLabels.paymentMethod }}</label>\n' +
    '                    <select ng-model="paymentForm.paymentMethod" ng-change="paymentMethodChanged()" id="paymentMethod" name="paymentMethod" class="piiSafe" ng-init="paymentForm.paymentMethod=\'echeck\'" ng-disabled="paymentForm.useProfile==true">\n' +
    '\n' +
    '                        <option value="card">{{ global.creditCard }}</option>\n' +
    '                        <option value="echeck">{{ global.eCheck }}</option>\n' +
    '                    </select>\n' +
    '                    <label class="checkbox inline" ng-show="account.profiles && account.profiles.length != 0 && account.config.showPreviousPaymentMethods">\n' +
    '                        <!--<input type="checkbox" name="paymentForm.useProfile" ng-model="paymentForm.useProfile" ng-change="usePreviousChanged()">-->\n' +
    '                        <input type="checkbox" name="useProfile" ng-model="paymentForm.useProfile" ng-change="usePreviousChanged()" class="piiSafe">\n' +
    '                        {{ uiPaymentLabels.previousMethod }}\n' +
    '                    </label>\n' +
    '                    <span class="alert alert-error" ng-show="submitted && (paymentForm.useProfile==false || paymentForm.useProfile==\'\') && paymentForm.paymentMethod==\'\'">{{ \'Payment Method is required.\' }}</span>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="well" ng-show="(paymentForm.useProfile==false || paymentForm.useProfile==\'\') && paymentForm.paymentMethod!=\'\'">\n' +
    '                    <div id="eCheckDetail" ng-show="paymentForm.paymentMethod==\'echeck\'">\n' +
    '                        <div class="control-group">\n' +
    '                            <label class="checkbox inline">\n' +
    '                                <input type="checkbox" name="chk_corporateChecking" ng-model="paymentForm.chk_corporateChecking" class="piiSafe">\n' +
    '                                Corporate Checking Account\n' +
    '                            </label>\n' +
    '                        </div>\n' +
    '                        <div class="control-group" ng-show="paymentForm.paymentMethod==\'echeck\'" ng-class="{true: \'error\'}[submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.bill_to_phone.$invalid]">\n' +
    '                            <label>{{ uiPaymentLabels.phoneNumber }}</label>\n' +
    '                            <input ng-model="paymentForm.bill_to_phone" type="text" placeholder="Enter phone number" name="bill_to_phone" class="piiSafe" ng-pattern="phoneNumberFormat" ng-required="paymentForm.paymentMethod==\'echeck\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                            <!-- TODO: Make the validation friendly. Just numbers makes it unreadable. Check for min 10 digits. Checks real number and allows for extensions -->\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.bill_to_phone.$error.required">{{ error.phoneRequired }}</span>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.bill_to_phone.$error.pattern">{{ error.phoneInvalid }}</span>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div class="control-group" ng-class="{true: \'error\'}[submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.echeck_routing_number.$invalid]">\n' +
    '                            <label>{{ uiPaymentLabels.routingNumber }}</label>\n' +
    '                            <input maxlength="9" ng-model="paymentForm.echeck_routing_number" type="text" placeholder="Enter routing number" name="echeck_routing_number" id="echeck_routing_number" class="tlRepress piiMask" ng-pattern="routingNumberFormat" ng-required="paymentForm.paymentMethod==\'echeck\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                            <i ng-click="showRoutingHelp = !showRoutingHelp" ng-init="showRoutingHelp=false" class="icon-question-sign-medium" title="Toggle help"></i>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.echeck_routing_number.$error.required">{{ error.routingRequired }}</span>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.echeck_routing_number.$error.pattern">{{ error.routingInvalid }}</span>\n' +
    '                        </div>\n' +
    '                        <div ng-show="showRoutingHelp" class="help-block alert alert-info">\n' +
    '                            {{ uiPaymentLabels.routingHelp }}\n' +
    '                            <div width="100%" align="center"><img src="assets/images/Checking.jpg"> </div>\n' +
    '                        </div>\n' +
    '                        <div class="control-group" ng-class="{true: \'error\'}[submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.echeck_account_number.$invalid]">\n' +
    '                            <label>{{ uiPaymentLabels.accountNumber }}</label>\n' +
    '                            <input ng-model="paymentForm.echeck_account_number" type="text" placeholder="Enter account number" name="echeck_account_number" id="echeck_account_number" class="tlRepress piiMask" ng-pattern="numberFormat" ng-required="paymentForm.paymentMethod==\'echeck\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.echeck_account_number.$error.required">{{ error.accountRequired }}</span>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'echeck\' && pmtForm.echeck_account_number.$error.pattern">{{ error.accountInvalid }}</span>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <!-- use div below for card or debit card -->\n' +
    '                    <div ng-show="paymentForm.paymentMethod==\'card\'">\n' +
    '                        <div class="control-group" ng-class="{true: \'error\'}[submitted && paymentForm.paymentMethod==\'card\' && pmtForm.card_type.$invalid]">\n' +
    '                            <label>{{ uiPaymentLabels.cardType }}</label>\n' +
    '                            <select ng-model="paymentForm.card_type" name="card_type" class="piiSafe" ng-init="paymentForm.card_type=\'\'" ng-required="!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && paymentForm.paymentMethod==\'card\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                                <option value="">-Select Card Type-</option>\n' +
    '                                <option ng-repeat="card in data.cardTypes" value="{{card.code}}">{{card.label}}</option>\n' +
    '                            </select>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'card\' && pmtForm.card_type.$error.required">{{ error.cardTypeRequired }}</span>\n' +
    '                        </div>\n' +
    '                        <div class="control-group" ng-class="{true: \'error\'}[submitted && paymentForm.paymentMethod==\'card\' && pmtForm.card_number.$invalid]">\n' +
    '                            <label>{{ uiPaymentLabels.cardNumber }}</label>\n' +
    '                            <input luhn-valid class="tlRepress piiMask" name="card_number" id="card_number" ng-model="paymentForm.card_number\n' +
    '                            " type="text" maxlength="16" placeholder="Enter card number" ng-pattern="ccFormat" ng-required="!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && paymentForm.paymentMethod==\'card\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'card\' && pmtForm.card_number.$error.required">{{ error.cardRequired }}</span>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'card\' && (pmtForm.card_number.$error.pattern ||  pmtForm.card_number.$error.luhnValid)">Invalid card number. Please try again.</span>\n' +
    '                        </div>\n' +
    '                        <div class="control-group" ng-class="{true: \'error\'}[submitted && paymentForm.paymentMethod==\'card\' && (pmtForm.card_expirationMonth.$error.expiryValid || pmtForm.card_expirationYear.$error.expiryValid)]">\n' +
    '                            <label>{{ uiPaymentLabels.cardExpiration }}</label>\n' +
    '                            <!--  expiry-valid -->\n' +
    '                            <select expiry-valid name="card_expirationMonth" ng-model="paymentForm.card_expirationMonth" ng-init="paymentForm.card_expirationMonth=\'01\'" class="input-medium" ng-required="!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && paymentForm.paymentMethod==\'card\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                                <option value="01">{{ global.jan }}</option>\n' +
    '                                <option value="02">{{ global.feb }}</option>\n' +
    '                                <option value="03">{{ global.mar }}</option>\n' +
    '                                <option value="04">{{ global.apr }}</option>\n' +
    '                                <option value="05">{{ global.may }}</option>\n' +
    '                                <option value="06">{{ global.jun }}</option>\n' +
    '                                <option value="07">{{ global.jul }}</option>\n' +
    '                                <option value="08">{{ global.aug }}</option>\n' +
    '                                <option value="09">{{ global.sep }}</option>\n' +
    '                                <option value="10">{{ global.oct }}</option>\n' +
    '                                <option value="11">{{ global.nov }}</option>\n' +
    '                                <option value="12">{{ global.dec }}</option>\n' +
    '                            </select>\n' +
    '                            <!-- expiry-valid -->\n' +
    '                            <select expiry-valid name="card_expirationYear" ng-model="paymentForm.card_expirationYear" class="input-small" ng-required="!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && paymentForm.paymentMethod==\'card\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                                <option ng-selected="year == expiryYears[0]" ng-repeat="year in data.expiryYears" value="{{year}}">{{ year }}</option>\n' +
    '                            </select>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'card\' && (pmtForm.card_expirationMonth.$error.expiryValid || pmtForm.card_expirationYear.$error.expiryValid)">{{ error.cardExpiration }}</span>\n' +
    '                        </div>\n' +
    '                        <div class="control-group" ng-class="{true: \'error\'}[submitted && paymentForm.paymentMethod==\'card\' && pmtForm.card_cvn.$invalid]">\n' +
    '                            <label>{{ uiPaymentLabels.ccv }}</label>\n' +
    '                            <input ng-model="paymentForm.card_cvn" type="text" name="card_cvn" id="card_cvn" ng-pattern="paymentForm.card_type == \'003\' ? cvnFormat : ccvFormat" placeholder="{{ paymentForm.card_type == \'003\' ? \'CVN Number\' : \'CVV Number\' }}" class="input-mini tlRepress piiMask" ng-attr-maxlength="{{ paymentForm.card_type == \'003\' ? 4 : 3 }}" ng-required="!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && paymentForm.paymentMethod==\'card\' && (paymentForm.useProfile==false || paymentForm.useProfile==\'\')">\n' +
    '                            <i ng-click="showCcvHelp = !showCcvHelp" ng-init="showCcvHelp=false" class="icon-question-sign" title="Toggle help"></i>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'card\' && pmtForm.card_cvn.$error.required">{{ error.ccvRequired }}</span>\n' +
    '                            <span class="help-inline error" ng-show="submitted && paymentForm.paymentMethod==\'card\' && pmtForm.card_cvn.$error.pattern">{{ error.ccvInvalid }}</span>\n' +
    '                        </div>\n' +
    '                        <div ng-show="showCcvHelp" class="help-block alert alert-info">\n' +
    '                            <div ng-bind-html="uiPaymentLabels.ccvHelp" ng-show="paymentForm.card_type != \'003\'"></div>\n' +
    '                            <div ng-bind-html="uiPaymentLabels.cvnHelp" ng-show="paymentForm.card_type == \'003\'"></div>\n' +
    '                            <div width="100%" align="center" ng-show="paymentForm.card_type != \'003\'"><img src="assets/images/Credit-card-image.jpg"> </div>\n' +
    '                            <div width="100%" align="center" ng-show="paymentForm.card_type == \'003\'"><img src="assets/images/AMEX-CID-number.png"> </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div id="previousDetail" ng-show="paymentForm.useProfile" ng-init="paymentForm.useProfile=false">\n' +
    '                    <div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.previousCard.$invalid]">\n' +
    '                        <div ng-repeat="profile in account.profiles">\n' +
    '                            <div ng-switch on="profile.accountType">\n' +
    '                               <label class="radio" ng-switch-when="card">\n' +
    '                                   <input ng-model="paymentForm.profile" type="radio" name="previousCard" class="prevCard piiSafe" ng-value="profile" ng-required="paymentForm.useProfile"> {{ global.creditCard }} {{profile.accountNumberMask}} {{profile.cardExpirationDate}}\n' +
    '                               </label>\n' +
    '                               <label class="radio" ng-switch-when="echeck">\n' +
    '                                   <input ng-model="paymentForm.profile" type="radio" name="previousCard" class="prevEcheck piiSafe" ng-value="profile" ng-required="paymentForm.useProfile"> {{ global.bankAccount }} {{profile.accountNumberMask}}\n' +
    '                               </label>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <span class="help-inline error" ng-show="submitted && pmtForm.previousCard.$invalid">{{ error.previousMethod }}</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </fieldset>\n' +
    '\n' +
    '            <fieldset id="addressBlock" ng-show="(paymentForm.paymentMethod && account.config.delayPayment) || (paymentForm.paymentMethod && paymentForm.edit_paymentInfo) || (paymentForm.paymentMethod && !paymentForm.useProfile && paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">\n' +
    '                <div class="control-group" ng-class="{true: \'error\'}[(submitted && pmtForm.bill_to_forename.$invalid) || (submitted && pmtForm.bill_to_surname.$invalid)]">\n' +
    '                    <label ng-show="paymentForm.paymentMethod==\'card\'">Cardholder {{ global.name }}</label>\n' +
    '                    <label ng-show="paymentForm.paymentMethod!=\'card\' && !paymentForm.chk_corporateChecking">Checking Account First and Last {{ global.name }}</label>\n' +
    '                    <label ng-show="paymentForm.paymentMethod!=\'card\' && paymentForm.chk_corporateChecking">Checking Account {{ global.name }}</label>\n' +
    '                    <input type="text" name="bill_to_forename" placeholder="Enter first name" class="piiSafe" ng-model="paymentForm.bill_to_forename" ng-show="!paymentForm.chk_corporateChecking" ng-required="!paymentForm.chk_corporateChecking && !(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile">\n' +
    '                    <input type="text" name="bill_to_surname" placeholder="Enter last name" class="piiSafe" ng-model="paymentForm.bill_to_surname" ng-show="!paymentForm.chk_corporateChecking" ng-required="!paymentForm.chk_corporateChecking && !(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile">\n' +
    '                    <input type="text" name="bill_to_surname_corp" placeholder="Enter corporation name" class="piiSafe" ng-model="paymentForm.bill_to_surname_corp" ng-show="paymentForm.chk_corporateChecking" ng-required="paymentForm.chk_corporateChecking && !(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile">\n' +
    '                    <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_forename.$invalid">{{ error.firstNameRequired }}</span>\n' +
    '                    <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_surname.$invalid">{{ error.lastNameRequired }}</span>\n' +
    '                    <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_surname_corp.$invalid">{{ error.corpNameRequired }}</span>\n' +
    '                </div>\n' +
    '                <div class="control-group" ng-show="account.account.address">\n' +
    '                    <label class="checkbox inline">\n' +
    '                        <input type="checkbox" name="chk_useShippingAddress" class="piiSafe" ng-model="paymentForm.chk_useShippingAddress" ng-click="toggleUseExistingAddress()">\n' +
    '                        {{ uiPaymentLabels.useExistingAddress }}\n' +
    '                    </label>\n' +
    '                </div>\n' +
    '                <!--<div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.bill_to_address_line1.$invalid]">-->\n' +
    '                <div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.bill_to_address_line1.$invalid]">\n' +
    '                    <label>{{ uiPaymentLabels.address1 }}</label>\n' +
    '                    <input type="text" name="bill_to_address_line1" placeholder="Enter address" class="piiSafe" ng-model="paymentForm.bill_to_address_line1" ng-required="!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile">\n' +
    '                    <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_address_line1.$invalid">{{ error.address1 }}</span>\n' +
    '                </div>\n' +
    '                <div class="control-group">\n' +
    '                    <label>{{ uiPaymentLabels.address2 }}</label>\n' +
    '                    <input type="text" name="bill_to_address_line2" placeholder="Enter additional address" class="piiSafe" ng-model="paymentForm.bill_to_address_line2">\n' +
    '                </div>\n' +
    '                <div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.bill_to_address_city.$invalid]">\n' +
    '                    <label>{{ uiPaymentLabels.city }}</label>\n' +
    '                    <input type="text" name="bill_to_address_city" placeholder="Enter city" class="piiSafe" ng-model="paymentForm.bill_to_address_city" ng-required="!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile">\n' +
    '                    <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_address_city.$invalid">{{ error.cityRequired }}</span>\n' +
    '                </div>\n' +
    '                <div class="control-group" ng-class="{true: \'error\'}[submitted && (pmtForm.bill_to_address_state.$invalid || pmtForm.bill_to_address_state_ca.$invalid)]">\n' +
    '                    <label ng-show="!paymentForm.chk_outsideUS">{{ uiPaymentLabels.state }}</label>\n' +
    '                    <label ng-show="paymentForm.chk_outsideUS && paymentForm.bill_to_address_country != \'CA\'">{{ uiPaymentLabels.country }}</label>\n' +
    '                    <label ng-show="paymentForm.chk_outsideUS && paymentForm.bill_to_address_country == \'CA\'">{{ uiPaymentLabels.country }} / {{ uiPaymentLabels.state }}</label>\n' +
    '                    <div class="controls error">\n' +
    '                        <select name="bill_to_address_state" size="1" class="stateDD input-medium" class="piiSafe" ng-show="!paymentForm.chk_outsideUS" ng-model="paymentForm.bill_to_address_state" ng-required="!paymentForm.chk_outsideUS && (!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile)">\n' +
    '                            <option value="AL">Alabama</option><option value="AK">Alaska</option><option value="AZ">Arizona</option><option value="AR">Arkansas</option><option value="CA">California</option><option value="CO">Colorado</option><option value="CT">Connecticut</option><option value="DE">Delaware</option><option value="DC">District Of Columbia</option><option value="FL">Florida</option><option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option><option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option><option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option><option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option><option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option><option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option><option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option><option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option><option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option><option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option><option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option><option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option><option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option><option value="WI">Wisconsin</option><option value="WY">Wyoming</option>\n' +
    '                        </select>\n' +
    '\n' +
    '                        <select name="bill_to_address_country" ng-change="changeCountryCode()" class="piiSafe" ng-model="paymentForm.bill_to_address_country" ng-show="paymentForm.chk_outsideUS" ng-required="paymentForm.chk_outsideUS && (!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile)">\n' +
    '                            <option value="AS">American Samoa</option>\n' +
    '                            <option value="AR">Argentina</option>\n' +
    '                            <option value="AU">Australia</option>\n' +
    '                            <option value="AT">Austria</option>\n' +
    '                            <option value="BE">Belgium</option>\n' +
    '                            <option value="BR">Brazil</option>\n' +
    '                            <option value="CA">Canada</option>\n' +
    '                            <option value="CL">Chile</option>\n' +
    '                            <option value="CN">China</option>\n' +
    '                            <option value="EG">Egypt</option>\n' +
    '                            <option value="FR">France</option>\n' +
    '                            <option value="DE">Germany</option>\n' +
    '                            <option value="GR">Greece</option>\n' +
    '                            <option value="GT">Guatemala</option>\n' +
    '                            <option value="IT">Italy</option>\n' +
    '                            <option value="JP">Japan</option>\n' +
    '                            <option value="MX">Mexico</option>\n' +
    '                            <option value="NL">Netherlands</option>\n' +
    '                            <option value="NZ">New Zealand</option>\n' +
    '                            <option value="PR">Puerto Rico</option>\n' +
    '                            <option value="GB">United Kingdom</option>\n' +
    '                            <option value="VI">Virgin Islands, U.S.</option>\n' +
    '\n' +
    '                        </select>\n' +
    '                        <select name="bill_to_address_state_ca" size="1" class="stateDD input-medium" class="piiSafe" ng-show="paymentForm.chk_outsideUS && paymentForm.bill_to_address_country == \'CA\'" ng-model="paymentForm.bill_to_address_state_ca" ng-required="paymentForm.chk_outsideUS && paymentForm.bill_to_address_country == \'CA\' && (!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile)">\n' +
    '                            <option value="AB">Alberta</option>\n' +
    '                            <option value="BC">British Columbia</option>\n' +
    '                            <option value="MB">Manitoba</option>\n' +
    '                            <option value="NB">New Brunswick</option>\n' +
    '                            <option value="NL">Newfoundland</option>\n' +
    '                            <option value="NT">Northwest Territories</option>\n' +
    '                            <option value="NS">Nova Scotia</option>\n' +
    '                            <option value="NU">Nunavut</option>\n' +
    '                            <option value="ON">Ontario</option>\n' +
    '                            <option value="PE">Prince Edward Island</option>\n' +
    '                            <option value="QC">Quebec</option>\n' +
    '                            <option value="SK">Saskatchewan</option>\n' +
    '                            <option value="YT">Yukon</option>\n' +
    '                        </select>\n' +
    '\n' +
    '                        <label class="checkbox inline">\n' +
    '                            <input type="checkbox" name="chk_outsideUS" class="piiSafe" ng-model="paymentForm.chk_outsideUS">\n' +
    '                            Outside US\n' +
    '                        </label>\n' +
    '                        <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_address_state.$invalid">{{ error.stateRequired }}</span>\n' +
    '                        <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_address_state_ca.$invalid">{{ error.stateRequired }}</span>\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '                <div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.bill_to_address_postal_code.$invalid]" ng-show="!paymentForm.chk_outsideUS || paymentForm.bill_to_address_country == \'CA\'">\n' +
    '                    <label>{{ uiPaymentLabels.zip }}</label>\n' +
    '                    <input type="text" name="bill_to_address_postal_code" ng-pattern="zipFormat" placeholder="Enter zip" class="piiSafe" ng-model="paymentForm.bill_to_address_postal_code" ng-required="!paymentForm.chk_outsideUS && (!(!paymentForm.edit_paymentInfo && config.recurring && paymentForm.paymentOption==\'setupRecurring\') && !paymentForm.useProfile)">\n' +
    '                    <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_address_postal_code.$error.required">{{ error.zipRequired }}</span>\n' +
    '                    <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_address_postal_code.$error.pattern">{{ error.zipInvalid }}</span>\n' +
    '                </div>\n' +
    '                <!--<div ng-show="account.config.delayPayment || paymentForm.edit_contactInfo || ((paymentForm.profile.accountType!=\'card\' || account.profiles) && paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">-->\n' +
    '               <!-- <div ng-show="true || account.config.delayPayment || paymentForm.edit_contactInfo || ((paymentForm.profile.accountType!=\'card\' || account.profiles) && paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">\n' +
    '                    <div class="control-group">\n' +
    '                        <span class="strong span3">{{ global.email }}</span>\n' +
    '                        <span>{{ paymentForm.bill_to_email }}</span>\n' +
    '                    </div>\n' +
    '                </div>-->\n' +
    '                <legend>{{ uiPaymentLabels.contactInformation }}</legend>\n' +
    '                <div class="control-group">\n' +
    '                    <div class="control-group" ng-class="{true: \'error\'}[submitted && pmtForm.bill_to_email.$invalid]">\n' +
    '                        <label>{{ global.email }} {{ global.emailAddendum }}</label>\n' +
    '                        <input type="email" id="bill_to_email" name="bill_to_email" placeholder="Enter email to notify" class="piiSafe" ng-model="paymentForm.bill_to_email" ng-required="true || account.config.delayPayment || paymentForm.edit_paymentInfo || paymentForm.edit_contactInfo || ((paymentForm.profile.accountType!=\'card\' || account.profiles) && paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">\n' +
    '                        <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_email.$error.required">{{ error.emailRequired }}</span>\n' +
    '                        <span class="help-inline error" ng-show="submitted && pmtForm.bill_to_email.$error.email">{{ error.emailInvalid }}</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </fieldset>\n' +
    '        </div>\n' +
    '        <div class="control-group">\n' +
    '            <!--<button type="submit" id="submitPayment" name="submit" class="btn btn-success" ng-click="submitted=true" ng-show="account.acceptPayment && (paymentForm.paymentOption!=\'setupRecurring\' && !account.config.delayPayment)">{{ \'uiPaymentLabels.paymentBtnText\' }}</button>-->\n' +
    '            <button type="submit" id="submitPayment" name="submit" class="btn btn-success" ng-click="submitted=true">{{ uiPaymentLabels.paymentBtnText }}</button>\n' +
    '            <!--<button type="submit" id="submitContinue" name="submit" class="btn btn-success" ng-click="submitted=true" ng-show="account.acceptPayment && (paymentForm.paymentOption==\'setupRecurring\' || account.config.delayPayment)">{{ \'uiPaymentLabels.continueBtnText\' }}</button>-->\n' +
    '            <!--<button ng-click="disableCancel=true; eventCloseClick();" ng-disabled="disableCancel" ng-init="disableCancel=false" type="button" class="btn-link" ng-show="!config.cancelButtonText && config.returnUrl">{{ \'uiPaymentLabels.cancelBtnText\' }}</button>-->\n' +
    '            <!--<button ng-click="disableCancel=true; eventCloseClick();" ng-disabled="disableCancel" ng-init="disableCancel=false" type="button" class="btn-link" ng-show="config.cancelButtonText && config.returnUrl">{{ config.cancelButtonText }}</button>-->\n' +
    '            <!--<button id="cancelRecurring" ng-click="cancelRecurringPayments();" ng-show="paymentForm.paymentOption == \'setupRecurring\' && config.recurring" type="button" class="btn-link">Stop Automatic Payments</button>-->\n' +
    '        </div>\n' +
    '\n' +
    '	</form>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="uiConfirm">\n' +
    '    <br>\n' +
    '    <div class="alert" ng-show="confirmNotificationMessages.length">\n' +
    '        <table>\n' +
    '            <tr ng-repeat="message in confirmNotificationMessages">\n' +
    '                <td>{{ message }}</td>\n' +
    '            </tr>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '	<div class="row-fluid">\n' +
    '		<form class="form form-horizontal" ng-submit="eventConfirmSubmitted()" autocomplete="off">\n' +
    '			<!-- TODO: Fix messaging so non-payment process has good messaging. See use of ng-show on amount. -->\n' +
    '			<fieldset>\n' +
    '				<legend id="uiConfirmHeader">{{ uiConfirmLabels.header }}</legend>\n' +
    '				<br>\n' +
    '				<div class="alert alert-info" ng-show="account.config.confirmationButtonText && !paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo && paymentForm.paymentOption!=\'setupRecurring\' && !paymentFails20Minute">\n' +
    '					<!--{{ uiConfirmLabels.customInstructions : { submit: account.config.confirmationButtonText } }}-->\n' +
    '				</div>\n' +
    '                <div class="alert alert-info" ng-show="!account.config.confirmationButtonText && !paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo && paymentForm.paymentOption!=\'setupRecurring\' && !paymentFails20Minute">\n' +
    '                    {{ uiConfirmLabels.instructions }}\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="alert alert-info" ng-show="account.config.confirmationButtonText && !paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo && paymentForm.paymentOption==\'setupRecurring\'">\n' +
    '                    <!--{{ uiConfirmLabels.customSetupInstructions:{ submit: account.config.confirmationButtonText } }}-->\n' +
    '                </div>\n' +
    '                <div class="alert alert-info" ng-show="!account.config.confirmationButtonText && !paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo && paymentForm.paymentOption==\'setupRecurring\'">\n' +
    '                    {{ uiConfirmLabels.setupInstructions }}\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="alert alert-info" ng-show="account.config.confirmationButtonText && paymentForm.edit_paymentInfo">\n' +
    '                    <!--{{ uiConfirmLabels.customUpdateInstructions:{ submit: account.config.confirmationButtonText } }}-->\n' +
    '                </div>\n' +
    '                <div class="alert alert-info" ng-show="!account.config.confirmationButtonText && paymentForm.edit_paymentInfo">\n' +
    '                    {{ uiConfirmLabels.updateInstructions }}\n' +
    '                </div>\n' +
    '                <div class="alert alert-info" ng-show="account.config.confirmationButtonText && paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo">\n' +
    '                    <!--{{ uiConfirmLabels.customContactInstructions:{ submit: account.config.confirmationButtonText } }}-->\n' +
    '                </div>\n' +
    '                <div class="alert alert-info" ng-show="!account.config.confirmationButtonText && paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo">\n' +
    '                    {{ uiConfirmLabels.contactInstructions }}\n' +
    '                </div>\n' +
    '\n' +
    '                <div ng-show="account.config.delayPayment || paymentForm.edit_contactInfo || ((paymentForm.profile.accountType!=\'card\' || account.profiles) && paymentForm.paymentOption && (paymentForm.paymentOption != \'setupRecurring\' || (!config.recurring && paymentForm.chk_termsAndCond)))">\n' +
    '                    <div class="control-group">\n' +
    '                        <span class="strong span3">{{ global.email }}</span>\n' +
    '                        <span>{{ paymentForm.bill_to_email }}</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div>\n' +
    '                    <div class="control-group" ng-show="paymentForm.useProfile || (paymentForm.paymentMethod==\'echeck\' && !paymentForm.useProfile) || (paymentForm.paymentMethod==\'card\' && !paymentForm.useProfile)">\n' +
    '                        <span class="strong span3">{{ uiConfirmLabels.paymentType }}</span>\n' +
    '                        <span ng-show="paymentForm.paymentMethod==\'echeck\' && !paymentForm.useProfile">{{ global.eCheck }}</span>\n' +
    '                        <span ng-show="paymentForm.paymentMethod==\'card\' && !paymentForm.useProfile">{{ global.creditCard }}</span>\n' +
    '                        <span ng-show="paymentForm.useProfile">Previous Payment Method &mdash; {{paymentForm.profile.accountNumberMask}} {{paymentForm.profile.cardExpirationDate}}</span>\n' +
    '                    </div>\n' +
    '                    <div class="control-group" ng-show="!paymentForm.useProfile && paymentForm.bill_to_forename && !paymentForm.chk_corporateChecking">\n' +
    '                        <span class="strong span3">{{ uiConfirmLabels.payerName }}</span>\n' +
    '                        <span>{{paymentForm.bill_to_forename + \' \' + paymentForm.bill_to_surname}}</span>\n' +
    '                    </div>\n' +
    '                    <div class="control-group" ng-show="!paymentForm.useProfile && paymentForm.bill_to_surname_corp && paymentForm.chk_corporateChecking">\n' +
    '                        <span class="strong span3">{{ uiConfirmLabels.payerName }}</span>\n' +
    '                        <span>{{paymentForm.bill_to_surname_corp}}</span>\n' +
    '                    </div>\n' +
    '                    <div ng-show="paymentForm.paymentMethod==\'echeck\'">\n' +
    '                        <div class="control-group">\n' +
    '                            <span class="strong span3">{{ uiPaymentLabels.routingNumber }}</span>\n' +
    '                            <span>{{paymentForm.echeck_routing_number}}</span>\n' +
    '                        </div>\n' +
    '                        <div class="control-group">\n' +
    '                            <span class="strong span3">{{ uiPaymentLabels.accountNumber }}</span>\n' +
    '                            <span>{{paymentForm.echeck_account_number | MaskAccountFilter}}</span>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="cardDetail" ng-show="paymentForm.paymentMethod==\'card\'">\n' +
    '                        <div class="control-group">\n' +
    '                            <span class="strong span3">{{ global.creditCard }}</span>\n' +
    '                            <span>{{paymentForm.card_number | MaskAccountFilter}}</span>\n' +
    '                            <span ng-show="paymentForm.card_type==\'001\'">Visa</span>\n' +
    '                            <span ng-show="paymentForm.card_type==\'002\'">Mastercard</span>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="billingAddress" ng-show="!paymentForm.useProfile && paymentForm.bill_to_address_line1">\n' +
    '                        <div class="control-group" ng-show="!paymentForm.chk_outsideUS">\n' +
    '                            <div class="strong span3">{{ uiConfirmLabels.billingAddress }}</div>\n' +
    '                            <div class="span8" style="margin-left: 0px">\n' +
    '                                    {{paymentForm.bill_to_address_line1}}<br>\n' +
    '                                    {{paymentForm.bill_to_address_line2}}<span ng-show="paymentForm.bill_to_address_line2"><br></span>\n' +
    '                                    {{paymentForm.bill_to_address_city}}, {{paymentForm.bill_to_address_state}} {{paymentForm.bill_to_address_postal_code}}\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="control-group" ng-show="paymentForm.chk_outsideUS">\n' +
    '                            <div class="strong span3">{{ uiConfirmLabels.billingAddress }}</div>\n' +
    '                            <div class="span8" style="margin-left: 0px">\n' +
    '                                {{paymentForm.bill_to_address_line1}}<br>\n' +
    '                                {{paymentForm.bill_to_address_line2}}<span ng-show="paymentForm.bill_to_address_line2"><br></span>\n' +
    '                                {{paymentForm.bill_to_address_city}}<span ng-show="paymentForm.bill_to_address_country == \'CA\'">, {{paymentForm.bill_to_address_state_ca}}</span><br>\n' +
    '                                <span ng-show="paymentForm.bill_to_address_country == \'CA\'">{{paymentForm.bill_to_address_postal_code}}<br></span>\n' +
    '                                {{paymentForm.bill_to_address_country}}\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="control-group" ng-show="paymentForm.amount>0">\n' +
    '                        <span class="strong span3">{{ global.payment }}&nbsp;{{ global.amount }}</span>\n' +
    '                        <span>{{paymentForm.amount | formatMoney }}</span>\n' +
    '                    </div>\n' +
    '	                <div data-ng-repeat="policy in policyBreakdown">\n' +
    '		                <span class="strong span3">{{ global.policy }}&nbsp;{{ global.amount }}</span>\n' +
    '		                <span><span ng-if="policy.isRenewal">{{ uiPaymentLabels.renewalDeposit }} </span>{{ policy.policyNumber }} - {{ policy.amount | formatMoney }}</span>\n' +
    '	                </div>\n' +
    '                </div>\n' +
    '				<br>\n' +
    '                <div class="control-group">\n' +
    '					<button ng-click="eventConfirmFormSubmitted( paymentSystemBreakdown )" id="submitConfirm" type="submit" class="btn btn-success" ng-show="!config.confirmationButtonText && !paymentFails20Minute">{{ uiConfirmLabels.authorize }}</button>\n' +
    '                    <button id="submitConfirm2" type="submit" class="btn btn-success" ng-show="config.confirmationButtonText && !paymentFails20Minute">{{ config.confirmationButtonText }}</button>\n' +
    '                    <button ng-click="eventEditPaymentClicked()" type="button" class="btn-link" ng-show="!(paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo)">{{ uiConfirmLabels.edit }}</button>\n' +
    '                    <button ng-click="eventEditPaymentClicked()" type="button" class="btn-link" ng-show="paymentForm.edit_contactInfo && !paymentForm.edit_paymentInfo">{{ uiConfirmLabels.editContact }}</button>\n' +
    '                </div>\n' +
    '			</fieldset>\n' +
    '		</form>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="uiProcessing" class="row-fluid">\n' +
    '	<div class="processingMsg">\n' +
    '		<h2 ng-show="paymentPolicyDisplayText">{{ uiProcessingLabels.procPayment }}</h2>\n' +
    '		<h2 ng-show="!paymentPolicyDisplayText">{{ uiProcessingLabels.proc }}</h2>\n' +
    '		<p ng-show="paymentPolicyDisplayText">{{ uiProcessingLabels.message1 }} {{paymentPolicyDisplayText}} {{ uiProcessingLabels.message2 }} ${{paymentForm.amount}}.</p>\n' +
    '        <p ng-show="IE11Detected && config.skipReceiptPage && (account.registratrionSource == \'MAGIC Consumer\' || account.registratrionSource == \'MAGIC Agent\')">When prompted by the browser to close this window, please click "Yes" to continue.</p>\n' +
    '\n' +
    '        <p>{{ uiProcessingLabels.please }}</p>\n' +
    '	</div>\n' +
    '	<div class="progress progress-striped progress-success active">\n' +
    '		<div class="bar" progressbar2 load="paymentProgress"></div>\n' +
    '\n' +
    '		<!--<div class="bar"  style="width: {{processingWidth}}%;"></div>-->\n' +
    '		<!--<div class="bar" ng-attr-width="{{processingWidth}}%"></div>-->\n' +
    '\n' +
    '		<!--<div class="bar" ng-style="{ width: this[$scope.processingWidth] + \'%\' || \'0%\' }"></div>-->\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-show="uiReceipt" class="row-fluid">\n' +
    '	<form class="form form-horizontal" action="" autocomplete="off">\n' +
    '		<fieldset>\n' +
    '			<legend ng-show="true">{{ uiReceiptLabels.receiptHeader }}<span class="pull-right"><button ng-click="printReceipt()" class="btn"><i title="Print Receipt" class="icon-print"></i></button></span></legend>\n' +
    '			<br>\n' +
    '			<div ng-repeat="receipt in paymentMessages | orderBy: \'transactionDateTime\' : true">\n' +
    '				<div class="control-group" ng-show="receipt.status" style="margin-bottom: 20px;">\n' +
    '					<!--<span class="strong span5">{{ global.amount }}</span>-->\n' +
    '					<div class="alert span8" ng-class="{true: \'alert-success\'}[receipt.status === \'Success\']">{{receipt.status + \' - \' + receipt.message}}</div>\n' +
    '				</div>\n' +
    '				<div class="control-group" ng-show="receipt.amount">\n' +
    '					<span class="strong span5">{{ global.amount }}</span>\n' +
    '					<span>{{receipt.amount | formatMoney}}</span>\n' +
    '				</div>\n' +
    '				<div class="control-group" ng-show="receipt.transactionDateTime">\n' +
    '					<span class="strong span5">{{ uiReceiptLabels.transactionDateTime }}</span>\n' +
    '					<span>{{receipt.transactionDateTime | date:\'short\'}}</span>\n' +
    '				</div>\n' +
    '				<div class="control-group" ng-show="receipt.transactionID">\n' +
    '					<span class="strong span5">{{ uiReceiptLabels.confirmationNumber }}</span>\n' +
    '					<span>{{ receipt.transactionID }}</span>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div class="control-group">\n' +
    '				<button ng-click="disableClose=true; closeReceipt();" name="closeBtn" ng-model="closeBtn" ng-disabled="disableClose" ng-init="disableClose=false" type="button" class="btn">Close</button>\n' +
    '			</div>\n' +
    '		</fieldset>\n' +
    '	</form>\n' +
    '</div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/contact/template/template.html',
    '<div class="container-fluid contact">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h1>Contact Us</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row-fluid" ng-if="insuredType !== \'Direct\'">\n' +
    '		<div class="span12">\n' +
    '			<p ng-if="insuredType === \'Agent\'">Please contact your agent for policy change needs.</p>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<table class="table contact-information">\n' +
    '				<tr>\n' +
    '					<th>Phone</th>\n' +
    '					<td>1-888-500-3344, Monday through Friday, 7:00 a.m. to 7:00 p.m. CST.</td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<th>Email</th>\n' +
    '					<td><a href="mailto:customerservice2@markelcorp.com">customerservice2@markelcorp.com</a></td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<th>Chat</th>\n' +
    '					<td>\n' +
    '						<a href="https://api-engagement-us-east.velaro.com/20009/Chat/4656" target="_blank" onclick="this.newWindow = window.open(\'https://api-engagement-us-east.velaro.com/20009/Chat/4656\', \'LiveChatSoftware\', \'toolbar=no,location=no,directories=no,menubar=no,status=no,scrollbars=no,resizable=yes,replace=no\');this.newWindow.focus();this.newWindow.opener=window;return false;">\n' +
    '							Click to Chat (Monday - Friday, 8AM - 5PM CST)\n' +
    '						</a>\n' +
    '					</td>\n' +
    '				</tr>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/error/template/404.html',
    '<div class="error-page">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h1>Page Not Found</h1>\n' +
    '			<div class="error-well">\n' +
    '				<p>The page may have been removed, had its name changed, or is temporarily unavailable. We apologize for any inconvenience.</p>\n' +
    '				<p>Click <a href="#/application">here</a> to return to the home page.</p>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/error/template/agent404.html',
    '<div class="error-page">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h1>Page Not Found</h1>\n' +
    '			<div class="error-well">\n' +
    '				<p>The page may have been removed, had its name changed, or is temporarily unavailable. We apologize for any inconvenience.</p>\n' +
    '				<p>Click <a href="#/">here</a> to return to the home page.</p>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/error/template/invalid-token.html',
    '<div class="error-page">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h1>Login error : {{ token }}</h1>\n' +
    '			<div class="error-well">\n' +
    '				<p>There was an error logging you in. We apologize for any inconvenience.</p>\n' +
    '				<p>To resolve this issue please call Customer Service: 888-500-3344 </p>\n' +
    '				<p>Click <a href="#/">here</a> to return to the login page.</p>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/faq/template/template.html',
    '<style>\n' +
    '.markel-grey {\n' +
    '  color: #424242 !important;\n' +
    '}\n' +
    '</style>\n' +
    '\n' +
    '<div class="container-fluid faq">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h1>Frequently Asked Questions</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<div>\n' +
    '				<h3 class="markel-grey">mPortal account questions for policyholders</h3>\n' +
    '				<div>\n' +
    '					<b>What if I forgot my mPortal password?</b> \n' +
    '					<br>\n' +
    '					On the login screen, click on “Forgot your password?”  Input the email address Markel has on file for you and select “Send.” A password reset link will be emailed to the address you provided.  If additional assistance is needed, please contact customer service at 888-500-3344.\n' +
    '				</div>\n' +
    '\n' +
    '				<br>\n' +
    '				<div>\n' +
    '					<b>How can I update my profile and/or change my mPortal password?</b> \n' +
    '					<br>\n' +
    '					Once you have logged in to your mPortal account, you can change your profile by choosing “Account” at the top right hand corner of the screen.  The “Account Settings” screen will allow you change your phone number, email address, password, and password security questions.\n' +
    '				</div>\n' +
    '\n' +
    '\n' +
    '				<br>\n' +
    '				<div>\n' +
    '					<b>How do I add another Markel account after I have logged in?</b> \n' +
    '					<br>\n' +
    '					On the left your screen you will see a box that says “Are you not seeing one of your Markel policies?” Click on the link provided. You will need your Markel account number or policy number and mailing zip code. If you are unable to add the additional account, please contact customer service.\n' +
    '				</div>\n' +
    '\n' +
    '				<br>\n' +
    '				<div>\n' +
    '					<b>Why does Markel ask you to change your password so often?</b> \n' +
    '					<br>\n' +
    '					Your information is valuable! To ensure the safety of your data, Markel requires all users change their password every 180 days.\n' +
    '				</div>\n' +
    '\n' +
    '				<br>\n' +
    '				<br>\n' +
    '				\n' +
    '				<h3 class="markel-grey">Customer service questions</h3>\n' +
    '				<div>\n' +
    '					<b>What if I receive an error message or need further assistance? </b> \n' +
    '					<br>\n' +
    '					Please call 888-500-3344 or email <a href="mailto:customerservice2@markelcorp.com">customerservice2@markelcorp.com</a>.  Please have your Markel policy number, policyholder\'s name, and/or payment reference number (provided on the payment confirmation screen) available.\n' +
    '				</div>\n' +
    '\n' +
    '				<br>\n' +
    '				<br>\n' +
    '				\n' +
    '				<h3 class="markel-grey">Markel appointed agent questions</h3>\n' +
    '				<div>\n' +
    '					<b>How do I contact my Markel underwriter?</b> \n' +
    '					<br>\n' +
    '					Contact your workers compensation or businessowners policy underwriter by using the Message feature on an account, e-mail him/her directly, or call 1-888-500-3344. <br><br>\n' +
    '					For all other Markel Specialty Commercial products, please click <a href="https://www.markelinsurance.com/contacts-search">here</a> to find your underwriter, or sales representative, by selecting state, and then product.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<div>\n' +
    '					<b>How do I access mPortal agent training documents and videos?</b> \n' +
    '					<br>\n' +
    '					Select the “Training” tab in the red navigation bar and you will be directed to a page with loaded with various training materials.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<div>\n' +
    '					<b>What should I do if I receive “This account locked for editing” message while in a submission?</b> \n' +
    '					<br>\n' +
    '					Contact your Markel underwriter as a Markel associate is currently working that submission.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<div>\n' +
    '					<b>How do I add/delete mPortal users?</b> \n' +
    '					<br>\n' +
    '					The agency principal can contact Agency Services at <a href="mailto:agencyappointment@markelcorp.com">agencyappointment@markelcorp.com</a>, or (888) 500-3344, to request the addition or removal of CSRs or producers.\n' +
    '				</div>\n' +
    '\n' +
    '				<br>\n' +
    '				<br>\n' +
    '				\n' +
    '				<h3 class="markel-grey">Billing questions for customers where billing is handled by Markel</h3>\n' +
    '				<div>\n' +
    '					<b>What is difference between direct bill and agency bill?</b> \n' +
    '					<br>\n' +
    '					“Direct bill” – billing is handled by the insurance company (Markel), directly to the policyholder. The policyholder is responsible for paying their insurance premium to Markel.<br><br>\n' +
    '\n' +
    '					“Agency bill” – billing is handled by the insurance agency. The agency bills the policyholder for the policy issued by the insurance company (Markel). The agent is responsible for collecting the total premium from the policyholder, and remitting the premium to Markel.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '				<div>\n' +
    '					<b>When setting up my payment, where can I find my bank\'s routing transit number and checking account number?</b> \n' +
    '					<br>\n' +
    '					The routing transit number can be found on the lower left hand side of your check. The checking account number is to the right of the routing number. An illustration is provided in the payment screen.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Is there a charge for a returned payment?</b> \n' +
    '					<br>\n' +
    '					Yes. If your financial institution returns your payment for insufficient funds or a stop payment, Markel may charge a fee in addition to any fees your financial institution may charge.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Can I make a payment after the due date of my invoice?</b> \n' +
    '					<br>\n' +
    '					You may make a payment after the invoice due date; however, the payment will not ensure continued policy coverage and may be subject to late fees.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Can I pay more than the amount shown on my invoice?</b> \n' +
    '					<br>\n' +
    '					Yes. You may pay more than the amount due.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Can I pay less than the amount shown on my invoice?</b> \n' +
    '					<br>\n' +
    '					You are able to pay what is due or specify an amount. If you pay less than the amount due, your policy may be cancelled for non-payment of premium.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Do you accept credit card or debit card payments?</b> \n' +
    '					<br>\n' +
    '					Yes.  We accept debit card payments, as well as multiple credit card options.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Are all my Markel accounts linked together? I am making a payment and I see multiple accounts on the Account Summary screen. </b> \n' +
    '					<br>\n' +
    '					When making a payment, please be aware each billing account needs to be paid separately to ensure accurate posting of your payment.  For questions about workers compensation and businessowners policy payments, email <a href="mailto:customerservice2@markelcorp.com">customerservice2@markelcorp.com</a>.  For all other Markel products, email <a href="mailto:directbill@markelcorp.com">directbill@markelcorp.com</a>. \n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Why do you need my email address?</b> \n' +
    '					<br>\n' +
    '					Your email address is used to provide confirmation for your submitted payment.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '				<div>\n' +
    '					<b>Will my email address be kept confidential?</b> \n' +
    '					<br>\n' +
    '					Yes, we will use your email address only for notices regarding your Markel policy.\n' +
    '				</div>\n' +
    '				<br>\n' +
    '\n' +
    '				<div>\n' +
    '					<b>What are my obligations to help maintain secure transactions with when making payments online?</b> \n' +
    '					<br>\n' +
    '					Please review our <a target="_blank" href="https://portal.markelinsurance.com/#/termsAndConditions">Terms and Conditions</a>. You also must be sure that you are using an appropriate browser. Be sure to close your browser immediately after you are finished making your payment(s).\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<br>\n' +
    '\n' +
    '				<h3 class="markel-grey">Workers compensation question</h3>\n' +
    '				<div>\n' +
    '					<b>What is a Workers Compensation audit and how does it work?</b> \n' +
    '					<br>\n' +
    '					Please use the following link to access <a target="_blank" href="https://www.markel.com/us/commercial/business-policyholder-services/premium-audit">Audit FAQs</a>\n' +
    '				</div>\n' +
    '				<br>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/forms/template/template.html',
    '<div class="form_skeleton">\n' +
    '	<div id="form-html" class="container-fluid">\n' +
    '		<div class="row-fluid">\n' +
    '			<div state-image-map url="#/form/?state=" on-select="loadForms"></div>\n' +
    '			<div class="span5" ng-if="initialView">\n' +
    '				<h1>Forms</h1>\n' +
    '                <p>For Workers compensation and BOP forms, click on your state to the left to find a list of state-specific forms.</p>\n' +
    '				<p>For all other products, <a href="http://www.markelinsurance.com/agent-resources/applications" target="_blank">click here</a> to find applications and forms by program.</p>\n' +
    '			</div>\n' +
    '			<div ng-if="!initialView" filterable-document-list placeholder="config.placeholder" documents="documents"></div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/home/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<div big-news news-items="newsItems"></div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '<div class="row-fluid">\n' +
    '	<div product-search-container include-location="product_search_location"></div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid homeContainer">\n' +
    '	<div class="span8">\n' +
    '		<div activity-feed></div>\n' +
    '	</div>\n' +
    '	<div class="span4">\n' +
    '		<div class="row-fluid">\n' +
    '			<div class="span12">\n' +
    '				<div recently-viewed></div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="row-fluid banner-link quick-link-container">\n' +
    '			<div class="span12" ng-if="user.hasComponentPermissions(\'quickLinks\')">\n' +
    '				<div quick-links></div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="row-fluid">\n' +
    '			<div class="span12">\n' +
    '				<div news-headlines news-items="newsItems"></div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="row-fluid" ng-if="user.hasComponentPermissions(\'rewards\')">\n' +
    '			<div class="span12">\n' +
    '				<div rewards-summary></div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="row-fluid" ng-if="user.hasComponentPermissions(\'promotionsSummary\')">\n' +
    '			<div class="span12">\n' +
    '				<div promotions-summary></div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/insuredPayment/template/template.html',
    '<div class="container unauthenticated-payment-form">\n' +
    '	<div ng-switch="uiState.step">\n' +
    '		<div ng-switch-when="payment">\n' +
    '			<div class="row">\n' +
    '				<div class="span5 offset3">\n' +
    '					<div class="alert alert-success" ng-if="!uiState.error">\n' +
    '						Your account has been verified.  Please wait a few moments while we take you to the payment system.\n' +
    '						<form action="{{ getFormAction() }}" method="post" id="isc-form" class="hidden">\n' +
    '							<input type="text" name="ticketId" ng-model="iscTicket.id">\n' +
    '							<input type="text" name="ticket" ng-model="iscTicket.ticketString">\n' +
    '							<input type="text" name="insuredenterpriseid" ng-model="iscTicket.enterpriseId">\n' +
    '							<input type="text" name="noheaders" value="true">\n' +
    '							<input type="text" name="nextEvent" value="page.payment">\n' +
    '							<input type="text" name="externalAccessPoint" value="{{ getReturnUrl() }}">\n' +
    '							<input type="text" name="externalTitle" value="mPortal Home">\n' +
    '						</form>\n' +
    '					</div>\n' +
    '					<div class="alert alert-error" ng-if="uiState.error">\n' +
    '						{{ uiState.message }}\n' +
    '						<p>\n' +
    '							<a href="#/">Click here to start over.</a>\n' +
    '						</p>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/insuredRegistration/template/template.html',
    '<div class="container register-form">\n' +
    '	<div ng-switch="uiState.step">\n' +
    '		<div ng-switch-when="accountLookup" account-lookup refresh="true" on-next-step="onNextStep" include-email="0" header-text="Create an Account"></div>\n' +
    '		<div ng-switch-when="terms" terms-and-conditions on-next-step="onNextStep"></div>\n' +
    '		<div ng-switch-when="register" register on-next-step="onNextStep" go-to-step="goToStep"></div>\n' +
    '		<div ng-switch-when="done">\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/isc/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<iframe iframe-resizer id="isc-frame" name="isc-frame" frameborder="0"></iframe>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<form id="isc-form" class="hidden" target="isc-frame" action="{{ getIframeSrc() }}" method="post">\n' +
    '	<input name="event" type="text" value="action.handleLogin">\n' +
    '	<input name="noheaders" type="text" value="true">\n' +
    '	<input name="externalAccessPoint" type="text" value="{{ getReturnUrl() }}">\n' +
    '	<input name="externalTitle" type="text" value="Account Summary">\n' +
    '</form>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/login/template/register.html',
    '<div class="row-fluid splash-page" ng-show="ui.view == \'registration\'">\n' +
    '	<div class="span12 row-fluid">\n' +
    '		<div class="span6">\n' +
    '			<div class="center-block span9">\n' +
    '				<div class="row-fluid">\n' +
    '					<legend>Finish your registration with Markel</legend>\n' +
    '					<table cellpadding="0" cellspacing="0" width="100%">\n' +
    '						<tr>\n' +
    '							<td colspan="2">\n' +
    '								<div class="control-group">\n' +
    '									<label for="email" class="control-label">Username</label>\n' +
    '									<div class="controls">\n' +
    '										<input type="text" class="span10" id="userName" ng-model="user.userName" disabled="disabled">\n' +
    '									</div>\n' +
    '								</div>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '						<tr>\n' +
    '							<td>\n' +
    '								<div class="control-group">\n' +
    '									<label for="first-name" class="control-label">First Name*</label>\n' +
    '									<div class="controls">\n' +
    '										<input type="text" id="first-name" ng-model="user.firstName">\n' +
    '										<div error-message error-message-array="errors.firstName" ng-if="errors.firstName.length"></div>\n' +
    '									</div>\n' +
    '								</div>\n' +
    '							</td>\n' +
    '							<td>\n' +
    '								<div class="control-group">\n' +
    '									<label for="last-name" class="control-label">Last Name*</label>\n' +
    '									<div class="controls">\n' +
    '										<input type="text" id="last-name" ng-model="user.lastName">\n' +
    '										<div error-message error-message-array="errors.lastName" ng-if="errors.lastName.length"></div>\n' +
    '									</div>\n' +
    '								</div>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '						<tr>\n' +
    '							<td>\n' +
    '								<div class="control-group">\n' +
    '									<label for="first-name" class="control-label">Work Phone*</label>\n' +
    '									<div class="controls">\n' +
    '										<input type="text" id="first-name" ng-model="user.workPhone" ng-keydown="format_phone()">\n' +
    '										\n' +
    '									</div>\n' +
    '								</div>\n' +
    '							</td>\n' +
    '							<td>\n' +
    '								<div class="control-group">\n' +
    '									<label for="email-confirmation" class="control-label">Extension</label>\n' +
    '									<div class="controls">\n' +
    '										<input type="text" id="email-confirmation" ng-model="user.extension" class="span2">\n' +
    '									</div>\n' +
    '								</div>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '						<tr ng-if="errors.workPhone.length">\n' +
    '							<td colspan="2">\n' +
    '								<div error-message error-message-array="errors.workPhone" ng-if="errors.workPhone.length"></div>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '					</table>		\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="span6" ng-show="!user.migrated">\n' +
    '			<div class="center-block span12">\n' +
    '				<div class="row-fluid span9">\n' +
    '					<div class="span11">\n' +
    '						<legend>Create your password</legend>\n' +
    '						<div class="control-group">\n' +
    '							<label for="password" class="control-label">Password*</label>\n' +
    '							<div class="controls">\n' +
    '								<input type="password" id="password" ng-model="user.password">\n' +
    '								<div error-message error-message-array="errors.password" ng-if="errors.password.length"></div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '						<div class="control-group">\n' +
    '							<label for="password-confirmation" class="control-label">Confirm Password*</label>\n' +
    '							<div class="controls">\n' +
    '								<input type="password" id="password-confirmation" ng-model="user.passwordConfirmation">\n' +
    '								<div error-message error-message-array="errors.passwordConfirmation" ng-if="errors.passwordConfirmation.length"></div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '						<p>Password requirements:</p>\n' +
    '						<ul>\n' +
    '							<li>At least 8 characters</li>\n' +
    '							<li>A lowercase letter</li>\n' +
    '							<li>An uppercase letter</li>\n' +
    '							<li>A number</li>\n' +
    '							<li>No parts of your username</li>\n' +
    '							<li>Does not include your first name</li>\n' +
    '							<li>Does not include your last name</li>\n' +
    '						</ul>\n' +
    '						\n' +
    '					</div>\n' +
    '				</div>\n' +
    '\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="span6" ng-show="user.migrated">\n' +
    '			<div class="center-block span12">\n' +
    '				<div class="row-fluid span9">\n' +
    '					<div class="span11">\n' +
    '						<legend>Password Confirmation</legend>\n' +
    '						<p>We found an existing account with Markel for the same email address. Please enter your markel account password to complete registration.</p>\n' +
    '						<br>\n' +
    '						<div class="control-group">\n' +
    '							<div ng-show="error.issue" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '								Sorry, Password was incorrect. Please try again.\n' +
    '							</div>\n' +
    '							<div ng-show="error.locked" class="alert alert-error" style="text-align:center;margin-right:-7px;">\n' +
    '								You have been temporarily locked out.\n' +
    '							</div>\n' +
    '							<label for="password" class="control-label">Markel Account Password*</label>\n' +
    '							<div class="controls">\n' +
    '								<input type="password" id="password" ng-model="user.password">\n' +
    '							</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	 <div class="span12 row-fluid">\n' +
    '		 <div class="form-actions center span11">\n' +
    '		 	<button id="registration-back" class="btn" ng-disabled="submitted" ng-click="show_terms()">\n' +
    '				<span>Back</span>\n' +
    '			</button>\n' +
    '			<button id="registration-update" class="btn btn-primary" ng-disabled="submitted" ng-click="update()">\n' +
    '				<span>Continue</span>\n' +
    '			</button>\n' +
    '		</div> \n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid splash-page" ng-show="ui.view == \'terms\'">\n' +
    '	<div class="span12 centered">\n' +
    '		<div class="span12 row centered">\n' +
    '			<div class="span11">\n' +
    '				<legend>Welcome to Markel, let\'s start with our terms and conditions.</legend>\n' +
    '			</div>\n' +
    '		\n' +
    '			<div class="span11" style="overflow-y: auto;height: 400px;" ng-bind-html="terms">\n' +
    '\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	 <div class="span12 row-fluid">\n' +
    '		 <div class="form-actions center span11">\n' +
    '			<button id="terms-agree" class="btn btn-primary" ng-disabled="submitted" ng-click="show_form()">\n' +
    '				<span>Agree To Terms</span>\n' +
    '			</button>\n' +
    '		</div> \n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/login/template/template.html',
    '<style>\n' +
    '  .span_link{\n' +
    '    color: #002B5A;\n' +
    '    text-decoration: none;\n' +
    '    cursor:pointer;\n' +
    '    margin-left:0px;\n' +
    '    font-weight: normal;\n' +
    '    padding: 4px 10px 4px;\n' +
    '	font-size: 13px;\n' +
    '	line-height: 18px;\n' +
    '	text-align: center;\n' +
    '  }\n' +
    '\n' +
    '  .span_link:hover{\n' +
    '    color: @teal;\n' +
    '    text-decoration: underline;\n' +
    '  }\n' +
    '</style>\n' +
    '\n' +
    '<div style="margin-top: 15px;width: 87%;margin-left:6%;text-align: center;" class="span12 row-fluid well alert-info" ng-show="!ui.submitted && ui.view == \'login\' && show.message">\n' +
    '	<p>We are updating our log in process to enhance security measures.<br><a href="/assets/documents/mPortal-login-update.pdf" target="_blank">Please click here to see what’s changing and to learn more.</a></p>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid splash-page" ng-show="!ui.submitted && ui.view == \'login\'">\n' +
    '	<div class="span6 left-partition">\n' +
    '		<div class="center-block span9">\n' +
    '			<form id="login-form" class="form" target="ssoFrame" method="post">\n' +
    '				<div class="control-group" id="username-control-group">\n' +
    '					<div ng-show="error.issue" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '						Sorry, the Username and/or Password was incorrect. We have recently updated our log-in process and your Username may have been changed to an email.  Please try again.\n' +
    '					</div>\n' +
    '					<div ng-show="error.locked" class="alert alert-error" style="text-align:center;margin-right:-7px;">\n' +
    '						You have been temporarily locked out\n' +
    '					</div>\n' +
    '					<input type="text" name="username" ng-model="form.username" ng-blur="user_blur()" placeholder="Username" class="span12 input-tall">\n' +
    '					<input type="text" name="id" ng-model="form.username" class="hidden">\n' +
    '				</div>\n' +
    '				 <div class="control-group" ng-show="show.password" id="password-control-group">\n' +
    '					<input type="password" name="password" ng-model="form.password" placeholder="Password" autocomplete="off" class="span12 input-tall" hidden="true">\n' +
    '				</div>\n' +
    '				<div class="form-actions center">\n' +
    '					<button id="check-user-button" class="btn btn-primary" ng-if="!show.password" ng-disabled="ui.submitted" ng-click="check_user()">\n' +
    '						<span>Next</span>\n' +
    '					</button>\n' +
    '\n' +
    '					<button id="login-button" class="btn btn-primary" ng-show="show.password" ng-disabled="ui.submitted" ng-click="check_ciam_user()">\n' +
    '						<span>Login</span>\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</form>\n' +
    '			<p><a href="" ng-click="forgotPassword()">Forgot your Username or password?</a></p>\n' +
    '			<p>New user?  Register as a\n' +
    '				<a href="{{ciam_domain}}#register">policy holder</a>\n' +
    '				or an\n' +
    '				<a href="{{ciam_domain}}#register">agent</a>.\n' +
    '			</p>\n' +
    '			<p class="login-footer">\n' +
    '				<a ng-if="!user.isLoggedIn" href="https://www.markelcorp.com/privacy-policy" target="_blank">\n' +
    '				Privacy Policy</a> | <a href="#/termsAndConditions">Terms and Conditions</a> | <a href="http://www.markelinsurance.com/" target="_blank">Markel Home Page</a>\n' +
    '			</p>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="span6">\n' +
    '		<div class="center-block span9">\n' +
    '			<div class="row-fluid">\n' +
    '				<span class="span6">\n' +
    '					<p><strong>In a hurry? Be our guest.</strong></p>\n' +
    '					<p>Make a One-Time Payment.</p>\n' +
    '				</span>				\n' +
    '			</div>\n' +
    '			<div class="form-actions center">\n' +
    '				<a class="btn btn-primary" href="{{ciam_domain}}#pay-my-bill">Pay now</a>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid splash-page" ng-if="ui.submitted">\n' +
    '	<div class="span12">\n' +
    '		<a href="" ng-click="init()">Return to login page.</a>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid" ng-show="ui.submitted">\n' +
    '	<div class="span12" id="ssoFrame-container">\n' +
    '		<iframe iframe-resizer height-calculation-method="\'taggedElement\'" class="sso-authentication" frameborder="0" id="ssoFrame" name="ssoFrame" src="about:blank"></iframe>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid splash-page" ng-show="ui.view == \'migration\'">\n' +
    '	<div class="span6 left-partition">\n' +
    '		<div class="center-block span9">\n' +
    '			<div class="row-fluid">\n' +
    '				<legend>Log in update</legend>\n' +
    '				<p>We are updating our log in process and need your help!</p><br>\n' +
    '				<p>This is the first amongst several steps to create a better experience for you.</p><br>\n' +
    '				<p>\n' +
    '					Your username will now be an email address. On the next step, we will \n' +
    '					send you an email to validate you have access to this email address.\n' +
    '				</p>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="span6">\n' +
    '		<div class="center-block span9">\n' +
    '			<div id="ciam-login-form" class="form">\n' +
    '				<div class="control-group" id="username-control-group-ciam">\n' +
    '					<div style="margin-bottom:15px;">\n' +
    '						Please confirm the email to use for your username. We have populated the email address from your profile.\n' +
    '					</div>\n' +
    '\n' +
    '					<div ng-show="error.email" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '						Please enter a valid email address.\n' +
    '					</div>\n' +
    '\n' +
    '					<div ng-show="error.unique" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '						The email address entered is already in use. \n' +
    '					</div>\n' +
    '\n' +
    '					<input type="text" id="ciam-username" name="username" placeholder="Username" class="span12 input-tall" ng-model="form.ciam_username" ng-change="ciam_username_change()" ng-keydown="keydown($event,\'migrate\')">\n' +
    '				</div>\n' +
    '				<div id="ciam-attempts-remaining" ng-if="attempts > 0">\n' +
    '					To continue without updating your username, please click skip. You will have <b>{{attempts}} logons</b> to skip this process.\n' +
    '				</div>\n' +
    '				<div class="form-actions center" style="height:20px;">\n' +
    '					<button id="migration-skip" class="btn" ng-disabled="ui.submitted" ng-click="skip_ciam_migration()" ng-if="attempts > 0">\n' +
    '						<span>Skip</span>\n' +
    '					</button>\n' +
    '					<button id="migration-continue" class="btn btn-primary" ng-disabled="ui.submitted" ng-click="continue_ciam_migration()">\n' +
    '						<span>Continue</span>\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid splash-page" ng-show="ui.view == \'verification\'">\n' +
    '	<div class="span6 left-partition">\n' +
    '		<div class="center-block span9">\n' +
    '			<div class="row-fluid">\n' +
    '				<legend>Email verification</legend>\n' +
    '				<p>\n' +
    '					We have sent a verification code to {{ ciam_username }}.\n' +
    '				</p>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="span6">\n' +
    '		<div class="center-block span9">\n' +
    '			<div id="ciam-verify-email-form" class="form">\n' +
    '				<div class="control-group" id="code-control-group-ciam">\n' +
    '					<div ng-show="error.issue" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '						Sorry, the code entered did not match. Please try again.\n' +
    '					</div>\n' +
    '					<div ng-show="error.code_required" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '						Please enter a verification code.\n' +
    '					</div>\n' +
    '					<div ng-show="show.resend" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '						New verification code sent to {{ ciam_username }}.\n' +
    '					</div>\n' +
    '					<div style="margin-bottom:15px;">\n' +
    '						Please enter the verification code sent to your email address:\n' +
    '					</div>\n' +
    '\n' +
    '					<div class="input-append" style="margin-top: 10px;">\n' +
    '						<input type="text" id="ciam-code" name="code" placeholder="verification code" class="span8 input-tall ng-pristine ng-untouched ng-valid ng-empty" ng-model="form.ciam_code" ng-blur="user_blur()" ng-keydown="keydown($event,\'verify\')" style="border-radius: 3px 0px 0px 3px;">\n' +
    '\n' +
    '						<button class="btn btn-large" id="verification-resend" style="height: 45px;width:125px;padding:4px;border-left: none;border-radius: 0px 3px 3px 0px;" ng-click="resend_ciam_code()">Resend Code</button>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="form-actions center" style="height:20px;">\n' +
    '					<button id="verification-back" class="btn" ng-disabled="ui.submitted" ng-click="navigate(\'migration\')">\n' +
    '						<span>Back</span>\n' +
    '					</button>\n' +
    '					<button id="verification-continue" class="btn btn-primary" style="margin-left:5px;" ng-disabled="ui.submitted" ng-click="verify_ciam_code()">\n' +
    '						<span>Continue</span>\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid splash-page" ng-show="ui.view == \'password\'">\n' +
    '	<div class="span3 left-partition">\n' +
    '		<div class="center-block span9">\n' +
    '			<div class="row-fluid">\n' +
    '				<p>\n' +
    '					&nbsp;\n' +
    '				</p>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="span6">\n' +
    '		<div class="center-block span9">\n' +
    '			<div id="ciam-password-form" class="form">\n' +
    '				<div class="control-group">\n' +
    '					<legend>Password Confirmation</legend>\n' +
    '					<p>Please enter your markel account password to complete the process.</p>\n' +
    '					<br>\n' +
    '					<div class="control-group">\n' +
    '						<div ng-show="error.issue" class="alert alert-info" style="text-align:center;margin-right:-7px;">\n' +
    '							Sorry, Password was incorrect. Please try again.\n' +
    '						</div>\n' +
    '						<div ng-show="error.locked" class="alert alert-error" style="text-align:center;margin-right:-7px;">\n' +
    '							You have been temporarily locked out.\n' +
    '						</div>\n' +
    '						<label for="password" class="control-label">Markel Account Password*</label>\n' +
    '						<div class="controls">\n' +
    '							<input type="password" name="password" ng-model="form.password" placeholder="Password" autocomplete="off" class="span12 input-tall" hidden="true">\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="form-actions center" style="height:20px;">\n' +
    '					<button id="password-continue" class="btn btn-primary" style="margin-left:5px;" ng-disabled="ui.submitted" ng-click="confirm_password()">\n' +
    '						<span>Continue</span>\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/marketedPrograms/template/detail.html',
    '<div id="homePageContainer" class="marketed-program-detail">\n' +
    '	<div class="row-fluid">\n' +
    '		<div marketed-program-detail></div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/marketedPrograms/template/list.html',
    '<div id="homePageContainer" class="marketed-programs">\n' +
    '	<div class="pagination-centered list-header">\n' +
    '		<h1>Programs</h1>\n' +
    '	</div>\n' +
    '	<div id="program-list">\n' +
    '		<div marketed-program-list></div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/marketedPrograms/template/template.html',
    '<div class="container-fluid marketed-programs">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12" id="pgHeader">\n' +
    '			<h1>Programs</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span3">\n' +
    '			<marketed-program-list programs="programs" current-program-id="selectedProgram.id" on-select="selectProgram"></marketed-program-list>\n' +
    '		</div>\n' +
    '		<div id="programDetail" class="span9">\n' +
    '			<marketed-program-detail program="selectedProgram"></marketed-program-detail>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/newsArchives/template/archives.html',
    '<div id="newsArchivesContainer">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h1>News Archive</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div id="newsArchives" class="row-fluid" ng-repeat="item in news">\n' +
    '		<div class="span8">\n' +
    '			<h3 class="subject">{{ item.subject }}</h3>\n' +
    '			<div class="dateCreated text-light">{{ item.dateCreated }}</div>\n' +
    '			<div class="message" ng-bind-html="item.message"></div>\n' +
    '			<hr>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<a href="#/" class="btn">Return to Home</a>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/reports/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="alert alert-warning">\n' +
    '		These reports are for Workers compensation policies only. To view loss runs for other products, search for the account with the top search bar, then go to the Account Summary tab.  To request Business Owners Policy loss runs, please contact Markel @ <a href="mailto:lossrun@markel.com">lossrun@markel.com</a>.\n' +
    '	</div>\n' +
    '	<div class="alert alert-warning" style="font-weight: bold">\n' +
    '		Alaska, Idaho, Montana, and Oregon Workers\' Compensation claims data is currently unavailable. Please contact Customer Service at 888-500-3344 for accurate claims details and/or loss runs.\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="row-fluid" id="reportsForms">\n' +
    '	<div class="span6">\n' +
    '		<div agency-experience-report></div>\n' +
    '	</div>\n' +
    '	<div class="span6">\n' +
    '		<fieldset>\n' +
    '			<legend>Loss Run Report</legend>\n' +
    '			<div loss-run-report></div>\n' +
    '		</fieldset>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/search/template/advanced.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<h1 class="pageHead">Advanced Search</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row-fluid">\n' +
    '	<div id="mainWrapper" class="span12">\n' +
    '		<tabset>\n' +
    '			<tab heading="2 Year View" ng-if="uiState.twoYearViewAvailable">\n' +
    '				<div class="two-year-view" two-year-view></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Accounts" ng-if="user.hasComponentPermissions(\'advancedAccountSearch\')">\n' +
    '				<div accounts-search search-data-results="searchData"></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Submissions" ng-if="user.hasComponentPermissions(\'advancedSubmissionSearch\')">\n' +
    '				<div submissions-search search-data-results="searchData"></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Policies" ng-if="user.hasComponentPermissions(\'advancedPolicySearch\')">\n' +
    '				<div policies-search search-data-results="searchData"></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Claims" ng-if="user.hasComponentPermissions(\'advancedClaimSearch\')">\n' +
    '				<div claims-search search-data-results="searchData" user="user"></div>\n' +
    '			</tab>\n' +
    '			<tab heading="Class Codes" ng-if="user.hasComponentPermissions(\'advancedClassCodeSearch\')">\n' +
    '				<div class-codes-search search-data-results="searchData"></div>\n' +
    '			</tab>\n' +
    '		</tabset>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/specialtyApplication/template/template.html',
    '<div class="specialty-application">\n' +
    '	<div id="specialty-application-navigation" class="specialty-application-header top-bar" ng-if="!nav_hidden && !show_wizard">\n' +
    '			\n' +
    '	</div> \n' +
    '	\n' +
    '	<div ng-if="show_wizard" quote-wizard></div>\n' +
    '	\n' +
    '	<div ng-if="view != \'section\'">\n' +
    '		<div id="content">\n' +
    '			<div ng-if="view == \'search\'" specialty-application-search></div>\n' +
    '			<div ng-if="view == \'eligibility\'" specialty-application-eligibility></div>\n' +
    '			<div ng-if="view == \'retrieve\'" specialty-application-retrieve-quote></div>\n' +
    '			<div ng-if="view == \'account\'" specialty-application-account></div>\n' +
    '			<div ng-if="view == \'questions\'" specialty-application-questions></div>\n' +
    '			<div ng-if="view == \'clearance\'" specialty-application-clearance></div>\n' +
    '			<div ng-if="view == \'payment\'" specialty-application-payment></div>\n' +
    '			<div ng-if="view == \'result\'" specialty-application-result></div>\n' +
    '			<div ng-if="view == \'result-summary\'" specialty-application-result-summary></div>\n' +
    '			<div ng-if="view == \'resume\'" specialty-application-resume></div>\n' +
    '			<div ng-if="view == \'review\'" specialty-application-review></div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div ng-if="view == \'section\'">\n' +
    '		<div id="content">\n' +
    '			<div specialty-application-section-container layout="layout"></div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/support/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<h1>Support</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<iframe ng-src="{{ iframeSrc }}" class="support-iframe" frameborder="0"></iframe>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/termsAndConditions/template/template.html',
    '<div class="row">\n' +
    '	<div class="span12">\n' +
    '		<h1>Terms and Conditions</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '	<div class="span12" ng-if="!terms">\n' +
    '		<div loading-bar></div>\n' +
    '	</div>\n' +
    '	<div class="span12" ng-if="terms">\n' +
    '		<div ng-bind-html="terms"></div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/training/template/template.html',
    '<div class="row">\n' +
    '    <div class="offset3 col-span5">\n' +
    '        <h1 class="training-tab">Training</h1>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="video-section">\n' +
    '\n' +
    '    <h2 class="training-header">mPortal Training Videos</h2>\n' +
    '    <div class="row">\n' +
    '        <div class="offset1 col-span3">\n' +
    '            <div class="img">\n' +
    '                <a class="training-link" href="https://vimeo.com/923090526/c5287d41db" target="_blank">\n' +
    '                    <img src="assets/images/training-videos/submit-and-bind.jpg">\n' +
    '                    <div class="text" style="text-align: left">How to submit and bind</div>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="offset1 col-span3">\n' +
    '            <div class="img">\n' +
    '                <a class="training-link" href="https://vimeo.com/773868469/11fd37c33e" target="_blank">\n' +
    '                    <img src="assets/images/training-videos/navigation.jpg">\n' +
    '                    <div class="text" style="text-align: left">Navigating mPortal</div>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '        <div class="offset1 col-span3">\n' +
    '            <div class="img">\n' +
    '                <a class="training-link" href="https://vimeo.com/775864042/5edf31d164" target="_blank">\n' +
    '                    <img src="assets/images/training-videos/endorsement.jpg">\n' +
    '                    <div class="text" style="text-align: left">mPortal endorsement tutorial</div>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="guide">\n' +
    '    <div class="guide-section">\n' +
    '        <h2 class="mportal-header">mPortal How-to Guides</h2>\n' +
    '        <div class="row">\n' +
    '            <div class="offset1 col-span5">\n' +
    '\n' +
    '                <ul style="list-style-type: none; margin: 0px;">\n' +
    '                    <li class="training-list">\n' +
    '                        <a href="https://content.markel.com/api/public/content/mPortal-how-to-submit-and-bind" target="_blank">How to submit and bind&nbsp;<img src="assets/images/pdf-small.gif"></a>\n' +
    '                    </li>\n' +
    '                    <li class="training-list">\n' +
    '                        <a href="https://content.markel.com/api/public/content/mPortal-new-producer-onboarding" target="_blank">How to add a new producer&nbsp;<img src="assets/images/pdf-small.gif"></a>\n' +
    '                    </li>\n' +
    '                    <li class="training-list">\n' +
    '                        <a href="https://content.markel.com/api/public/content/mPortal-WC-endorsement" target="_blank">How\n' +
    '                            to complete a workers compensation endorsement&nbsp;<img src="assets/images/pdf-small.gif"></a>\n' +
    '                    </li>\n' +
    '                    <li class="training-list">\n' +
    '                        <a href="https://content.markel.com/api/public/content/mPortal-FAQ" target="_blank">mPortal FAQs&nbsp;<img src="assets/images/pdf-small.gif"></a>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="offset1 col-span5 request-training">\n' +
    '                <div class="myDivBox" style="padding-top: 6%;display: flex;flex-direction: column;padding-left: 20px;">\n' +
    '                    <img src="assets\\images\\Request-training.png" style="max-width: 15%;">\n' +
    '                    <p style="margin-bottom: 0px;"> Request One on One mPortal training with a Customer Service\n' +
    '                        Representative</p>\n' +
    '\n' +
    '                    <a ng-href="mailto:customerservice2@markel.com?subject=mPortal%20Training&amp;body=Please%20Contact%20me%20for%20an%20mPortal%20Training!%0D%0D%20%20%20%201.%20%20Name:%20Julie%20Blank%0D%0D%20%20%20%202.%20%20Agency:%20Quincy%20Insurance%20Group,%20Inc.%20-%20748093%0D%0D%20%20%20%203.%20%20Phone%20Number:%20null" ng-click="close()" target="_blank" href="mailto:customerservice2@markel.com?subject=mPortal%20Training&amp;body=Please%20Contact%20me%20for%20an%20mPortal%20Training!%0D%0D%20%20%20%201.%20%20Name:%20Julie%20Blank%0D%0D%20%20%20%202.%20%20Agency:%20Quincy%20Insurance%20Group,%20Inc.%20-%20748093%0D%0D%20%20%20%203.%20%20Phone%20Number:%20null">\n' +
    '                        Request Training →</a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="resource-section">\n' +
    '    <h2 class="training-header" id="producer-resources">Producer Resources</h2>\n' +
    '    <div class="row text-center">\n' +
    '        <div class="offset1 span2">\n' +
    '            <a class="training-text" href="https://www.markel.com/us/applications" target="_blank">\n' +
    '                <img src="assets/images/commercial-program.png" class="resource">\n' +
    '                <span class="training-caption">Commercial Program<br>Applications</span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '        <div class="span2 training-resource">\n' +
    '            <a class="training-text" href="https://www.markel.com/us/contact-specialty" target="_blank">\n' +
    '                <img src="assets/images/change-request.png" class="resource">\n' +
    '                <span class="training-caption">Change Request</span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '        <div class="span2 training-resource">\n' +
    '            <a class="training-text" ng-click="openMarkelContacts()">\n' +
    '                <img src="assets/images/commercial-underwriting.png" class="resource">\n' +
    '                <span class="training-caption">Commercial<br> Underwriting Agency<br>Support Contacts</span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '        <div class="span2 training-resource">\n' +
    '            <a class="training-text" href="https://www.markel.com/m/small-commercial-marketing-materials" target="_blank">\n' +
    '                <img src="assets/images/commercial-marketing.png" class="resource">\n' +
    '                <span class="training-caption">Commercial Marketing <br>Materials</span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '        <div class="span2 training-resource">\n' +
    '            <a class="training-text" href="https://www.markel.com/us/commercial/business-policyholder-services/risk-solution-services" target="_blank">\n' +
    '                <img src="assets/images/loss-control.png" class="resource">\n' +
    '                <span class="training-caption">Risk Solution Services</span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/user/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span8">\n' +
    '		<h1>Account Settings</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="span12" ng-if="userProfile.agencyEmulation" style="margin-top:12px;">\n' +
    '	<span>Account settings are not available via Policy Management agency view.</span>\n' +
    '</div>\n' +
    '\n' +
    '<div class="span12">\n' +
    '	<div loading-bar ng-if="pageLoading  && !userProfile.agencyEmulation" style="margin-top:12px;"></div>\n' +
    '</div>\n' +
    '\n' +
    '<div id="accountSettings" ng-if="!pageLoading && !userProfile.agencyEmulation">\n' +
    '	<div class="personalDetails" personal-details user="user" states="states"></div>\n' +
    '	<div class="ciamAccountSettings" ciam-account-settings user="user"></div>\n' +
    '	<div class="subscriptions" ng-if="userProfile.hasComponentPermissions(\'stateNewsSubscriptions\')" state-news-subscriptions subscriptions="subscriptions"></div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountsSearch/template/template.html',
    '<div class="topMessage">\n' +
    '	<div error-message="searchResults.error.message" error-message-header="searchResults.error.header"></div>\n' +
    '</div>\n' +
    '<form name="accountsForm" id="accountsForm" class="form-horizontal ajax-process" ng-submit="getResults()">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<!-- \n' +
    '			this select literally does nothing and has no effect on the search. it is only here so that it is obvious to the user that this form\n' +
    '			is for searching wc/bop accounts and not mpolicy/icon/etc.\n' +
    '			-->\n' +
    '			<div class="control-group">\n' +
    '				<label for="accountsProductLine">Product Line</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="accountsProductLine">\n' +
    '						<option>WC/BOP</option>\n' +
    '					</select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="accountsInsuredNameControlGroup">\n' +
    '				<label for="insuredName">Insured Name</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="insuredName" name="insuredName" class="piiSafe" ng-model="searchParams.insuredName">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="accountsEffDateControlGroup">\n' +
    '				<label for="accountsEffDateFrom">Effective Date from</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="accountsEffDateFrom" name="effDateFrom" type="text" class="input-small piiSafe" ng-model="searchParams.effectiveDateFrom" date-picker> to\n' +
    '					<input id="accountsEffDateTo" type="text" name="effDateTo" class="input-small piiSafe" ng-model="searchParams.effectiveDateTo" date-picker>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="accountsInsuredAccountControlGroup">\n' +
    '				<label for="insuredAccount">Insured Account #</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="insuredAccount" name="insuredAccount" class="piiSafe" ng-model="searchParams.insuredAccount">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="accountsFeinControlGroup">\n' +
    '				<label for="accountsFein">FEIN</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="accountsFein" name="fein" class="piiMask" ng-model="searchParams.fein">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="accountsDBAControlGroup">\n' +
    '				<label for="accountsDbaName">DBA Name</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" name="dbaName" id="accountsDbaName" class="piiSafe" ng-model="searchParams.dbaName">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group stateDD" id="accountsPreStateControlGroup">\n' +
    '				<label for="accountsPreState">Predominant State</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="accountsPreState" name="state" size="1" class="piiSafe" ng-options="state.NAME as state.VALUE for state in searchData.states" ng-model="searchParams.state"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group">\n' +
    '				<div class="controls">\n' +
    '					<button id="accountsSubmit" class="btn btn-success" ng-hide="searchResults.status.working">Search</button>\n' +
    '					<div class="searchingMessage" ng-show="searchResults.status.working">Searching...</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '\n' +
    '	</div>\n' +
    '</form>\n' +
    '<div class="row-fluid" ng-show="searchResults.status.ready">\n' +
    '	<div class="span12" id="accountsSearchResultsContainer">\n' +
    '		<hr>\n' +
    '		<h3><span id="accountsResultsCount">{{ searchResults.results.length }}</span> Records Returned</h3>\n' +
    '		<table id="accountsSearchResults" name="accountsSearchResults" class="table table-striped table-condensed table-bordered">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th>Name</th>\n' +
    '					<th>Most Recent Policy</th>\n' +
    '					<th>Most Recent Effective Date</th>\n' +
    '					<th>FEIN</th>\n' +
    '					<th>DBA</th>\n' +
    '					<th>State</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody id="accountsSearchResultsBody">\n' +
    '				<tr class="acountsResults" ng-repeat="result in searchResults.filteredResults">\n' +
    '					<td class="INSUREDNAME">\n' +
    '						<a class="INSUREDNAME" ng-href="#/account/activity/{{ result.INSUREDID }}">{{ result.INSUREDNAME | truncate:30 }}</a>\n' +
    '					</td>\n' +
    '					<td class="POLICYNUMBER">\n' +
    '						<span ng-show="result.POLICYNUMBER && result.PRODUCTTYPE" product-label="result.PRODUCTTYPE">{{ result.PRODUCTTYPE }}</span>&nbsp;<a class="POLICYNUMBER" href="#/account/policy/{{ result.POLICYID }}">{{ result.POLICYNUMBER }}</a>\n' +
    '					</td>\n' +
    '					<td class="EFFECTIVEDATE">{{ result.EFFECTIVEDATE }}</td>\n' +
    '					<td class="FEIN piiMask">{{ result.FEIN }}</td>\n' +
    '					<td class="DBANAME">{{ result.DBANAME | truncate:30 }}</td>\n' +
    '					<td class="STATE">{{ result.STATE }}</td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '		<div class="pagination" id="accountsSearchResultsPagination">\n' +
    '			<pagination total-items="searchResults.results.length" items-per-page="searchResults.config.pagination.config.itemsPerPage" previous-text="&larr; Previous" next-text="Next &rarr;" ng-model="searchResults.config.pagination.config.currentPage" ng-change="searchResults.paginate()" ng-show="searchResults.results.length">\n' +
    '			</pagination>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/activityFeed/template/activityFeedItem.html',
    '<tr>\n' +
    '	<td class="policy-td" style="width:25%;">\n' +
    '		<div>\n' +
    '			<a ng-if="item.accountViewVersion == 1 &&  item.luxon_id" class="insuredname" ng-href="#/lx/submission/{{:: item.luxon_partition_key }}/{{:: item.luxon_id }}">{{:: item.accountName }}</a>\n' +
    '			<a ng-if="item.accountViewVersion != 1 &&  item.luxon_id" class="insuredname" ng-href="#/lx/submission/{{:: item.luxon_partition_key }}/{{:: item.luxon_id }}">{{:: item.accountName }}</a>\n' +
    '			<a ng-if="item.accountViewVersion == 1 && !item.luxon_id" class="insuredname" ng-href="#/account/activity/{{:: item.accountId }}/">{{:: item.accountName }}</a>\n' +
    '			<a ng-if="item.accountViewVersion != 1 && !item.luxon_id" class="insuredname" ng-href="#/account-summary/{{:: item.accountId }}">{{:: item.accountName }}</a>\n' +
    '		</div>\n' +
    '		<div class="text-light">\n' +
    '			<span product-label="item.normalizedProductType">{{:: item.productShortName }}</span>\n' +
    '			<span class="messageProductLine" ng-show="isMessageProductLineVisible( item )"></span>\n' +
    '			<span class="policynumber" ng-show="item.hasPolicy()">\n' +
    '				<a ng-if="item.accountViewVersion == 1 &&   item.luxon_id" class="ACCOUNTNUMBER" ng-href="#/lx/submission/{{:: item.luxon_partition_key }}/{{:: item.luxon_id }}">{{:: item.policyNumber }}</a>\n' +
    '				<a ng-if="item.accountViewVersion != 1 &&   item.luxon_id" class="ACCOUNTNUMBER" ng-href="#/lx/submission/{{:: item.luxon_partition_key }}/{{:: item.luxon_id }}">{{:: item.policyNumber }}</a>\n' +
    '				<a ng-if="item.accountViewVersion == 1 &&  !item.luxon_id" class="ACCOUNTNUMBER" ng-href="#/account/policy/{{:: item.policyId }}/">{{:: item.policyNumber }}</a>\n' +
    '				<a ng-if="item.accountViewVersion != 1 &&  !item.luxon_id" class="ACCOUNTNUMBER" ng-href="#/account-summary/{{:: item.accountId }}/policy/{{:: item.policyNumber }}">{{:: item.policyNumber }}</a>\n' +
    '			</span>\n' +
    '		</div>\n' +
    '	</td>\n' +
    '	<td class="message-td" style="width:50%;">\n' +
    '		<!--\n' +
    '		the ID for this element is (unfortunately) not unique. this is a requirement for tealeaf to be able to track our link clicks, since\n' +
    '		it does not support tracking by class. this is invalid markup, but it doesn\'t appear to cause any problems.\n' +
    '		-->\n' +
    '		<a class="link-strong ACTIVITY" ng-href="{{:: item.getActivityHref() }}" target="{{:: item.getActivityTarget() }}" id="{{:: item.getActivityId() }}" ng-bind-html="item.title | trustHtml"></a>\n' +
    '\n' +
    '		<span class="bindNow" ng-show="item.isBindable()">\n' +
    '			<a class="bindNowLink btn btn-success btn-small" ng-href="{{:: item.getBindNowHref() }}">Bind Now</a>\n' +
    '		</span>\n' +
    '		<span class="reply" ng-show="item.type === \'New Message\' && item.canReply">\n' +
    '			<a ng-if="item.accountViewVersion != 1" class="message-reply btn pull-right" href="{{:: item.getActivityHref() }}">Reply</a>\n' +
    '			<span ng-if="item.accountViewVersion == 1" class="message-reply btn pull-right" id="{{:: item.getMessageReplyId() }}" ng-click="openMessageModal( item.getMessageReplyId() )">Reply</span>\n' +
    '		</span>\n' +
    '		<p class="message" ng-bind-html="item.subtitle"></p>\n' +
    '\n' +
    '	</td>\n' +
    '	<td class="dateContainer text-light">\n' +
    '		<span class="pull-right">\n' +
    '			{{:: item.timestamp | date: "MM/dd/yyyy hh:mm a" }}\n' +
    '		</span>\n' +
    '	</td>\n' +
    '</tr>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/activityFeed/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<div class="big-banner">\n' +
    '			<div class="row-fluid">\n' +
    '				<div class="span12">\n' +
    '					Activity <small>What\'s happening with your insureds</small>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="row-fluid activity-container">\n' +
    '	<div class="banner-link">\n' +
    '		<div class="activity-header-padding activity-header-border">\n' +
    '\n' +
    '			<div class="row-fluid" ng-if="user.hasComponentPermissions(\'filtersForActivityFeed\')">\n' +
    '\n' +
    '				<div class="span12">\n' +
    '					<div class="activity-container-search">\n' +
    '						<input type="text" class="search" placeholder="Search Activity feed ..." ng-model="activityConfig.filters.keyword">\n' +
    '					</div>\n' +
    '					<label class="inline-label checkbox_container" ng-if="user.hasComponentPermissions(\'documentsFilterForActivityFeed\')">\n' +
    '						<input type="checkbox" ng-model="activityConfig.filters.documents" id="homeActivityFilterDocuments" name="homeActivityFilter">\n' +
    '						<span class="checkbox_container_checkmark"></span>Documents\n' +
    '					</label>\n' +
    '					<label class="inline-label checkbox_container">\n' +
    '						<input type="checkbox" ng-model="activityConfig.filters.statusChange" id="homeActivityFilterStatusChange" name="homeActivityFilter">\n' +
    '						<span class="checkbox_container_checkmark"></span>Status Change\n' +
    '					</label>\n' +
    '					<label class="inline-label checkbox_container" ng-if="user.hasComponentPermissions(\'messagesFilterForActivityFeed\')">\n' +
    '						<input type="checkbox" ng-model="activityConfig.filters.messages" id="homeActivityFilterMessages" name="homeActivityFilter">\n' +
    '						<span class="checkbox_container_checkmark"></span>Messages\n' +
    '					</label>\n' +
    '					<label class="inline-label checkbox_container" ng-if="user.hasComponentPermissions(\'notificationsFilterForActivityFeed\')">\n' +
    '						<input type="checkbox" ng-model="activityConfig.filters.notifications" id="homeActivityFilterNotifications" name="homeActivityFilter">\n' +
    '						<span class="checkbox_container_checkmark"></span>Notifications\n' +
    '					</label>\n' +
    '					<span id="activityPopover">\n' +
    '						<i class="icon-question-sign" popover-title="What Shows in Activity?" popover="Activity shows documents received, status changes on policies, messages and claims opened." popover-placement="top" popover-trigger="mouseenter" popover-popup-delay="500">\n' +
    '						</i>\n' +
    '					</span>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="feed">\n' +
    '			<div ng-show="!items.loaded" class="activity-header-padding">\n' +
    '				<p>Loading Activity Feed...<br>\n' +
    '					<img class="loader" src="/assets/images/ajax-loader-grey.gif">\n' +
    '				</p>\n' +
    '				\n' +
    '			</div>\n' +
    '			<div ng-show="filteredNewerItems.length" class="activity-header-padding">\n' +
    '				<div class="activity-last-ten">\n' +
    '					<span style="padding-right: 5px;">Last 10 days</span>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div>\n' +
    '				<table class="table-activity">\n' +
    '					<tbody activity-feed-item="item" open-message-modal="openMessageModal" ng-repeat="item in filteredNewerItems = ( items.newer | activityFeedFilter:activityConfig )"></tbody>\n' +
    '				</table>\n' +
    '			</div>\n' +
    '\n' +
    '			<div ng-show="filteredOlderItems.length" class="activity-header-padding-older">\n' +
    '				<div class="activity-last-ten">\n' +
    '					<span style="padding-right: 5px;">Older</span>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div>\n' +
    '				<table class="table-activity">\n' +
    '					<tbody activity-feed-item="item" open-message-modal="openMessageModal" ng-repeat="item in filteredOlderItems = ( items.older | activityFeedFilter:activityConfig )"></tbody>\n' +
    '					<tbody ng-if="!filteredNewerItems.length && !filteredOlderItems.length && items.loaded">\n' +
    '						<tr>\n' +
    '							<td colspan="2" class="text-big no-activity">No activity exists. Try using the <span class="activity-feed-link" ng-click="openGlobalSearch()">Global Search</span></td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '				</table>\n' +
    '				<button class="btn" id="homeActivityNext30" ng-click="showMore()" ng-show="isShowMoreVisible()">View Next 30</button>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div></div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/addAccount/template/modalTemplate.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="dismiss()">&times;</a>\n' +
    '	<h3>Add an account or policy</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body register-form">\n' +
    '		<div class="container-fluid">\n' +
    '			<div ng-if="!uiState.adding && !uiState.error" account-lookup refresh="false" condensed="true" on-next-step="addAccount" submit-button-text="Add now" insured-account-number="insuredAccountNumber"></div>\n' +
    '			<div ng-if="uiState.adding">\n' +
    '				<p>Adding account..</p>\n' +
    '			</div>\n' +
    '			<div ng-if="uiState.error" class="alert alert-error">\n' +
    '				You are not allowed to add this account to your profile at this time.\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="dismiss()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/addAccount/template/template.html',
    '<div class="alert alert-warning policyHolderAlertContainer">\n' +
    '	<p class="text-main-color">\n' +
    '		Are you not seeing one of your Markel policies?\n' +
    '	</p>\n' +
    '	<p class="policyHolderAlert">\n' +
    '	Click <a href ng-click="open()">here</a> to add it to your account view.\n' +
    '	You will need your Markel account number or policy number to continue.\n' +
    '	</p>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/agencyExperienceReport/template/template.html',
    '<form id="experience_form" class="form-horizontal" ng-submit="getReport()">\n' +
    '	<fieldset>\n' +
    '		<legend>Agency Experience Report</legend>\n' +
    '		<p>Obtain production figures for your agency.</p>\n' +
    '		<p>Please follow the instructions below to retrieve your Agency Experience Report:</p>\n' +
    '		<ul>\n' +
    '			<li>Agency Experience Reports are updated as of the last day of the previous month.</li>\n' +
    '			<li>If a date is selected which is past {{ firstAvailableRun | date: \'MMMM yyyy\'}} you will receive a blank report.</li>\n' +
    '			<li>Please disable any popup blockers. We open separate windows for each report.</li>\n' +
    '		</ul>\n' +
    '\n' +
    '		<div class="control-group">\n' +
    '			<label for="endMonth" class="control-label">End Month</label>\n' +
    '			<span class="controls">\n' +
    '				<select id="endMonth" name="endMonth" ng-model="reportParams.month" class="piiSafe" ng-options="month.value as month.label for month in months">\n' +
    '				</select>\n' +
    '			</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="control-group">\n' +
    '			<label for="endYear" class="control-label">End Year</label>\n' +
    '			<span class="controls">\n' +
    '				<select id="endYear" name="endYear" ng-model="reportParams.year" class="piiSafe" ng-options="year as year for year in years">\n' +
    '				</select>\n' +
    '			</span>\n' +
    '		</div>\n' +
    '					\n' +
    '		<div class="control-group">\n' +
    '			<label for="detail" class="control-label">Detail by Satellite Location</label>\n' +
    '			<span class="controls">\n' +
    '				<input type="checkbox" name="detail" id="detail" class="piiSafe" ng-model="reportParams.detail">\n' +
    '			</span>\n' +
    '		</div>\n' +
    '	\n' +
    '		<div class="form-actions">\n' +
    '			<div class="controls">\n' +
    '				<button id="experience_form_submit" class="btn btn-primary">Generate Report</button>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</fieldset>\n' +
    '</form>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/applicationActions/template/template.html',
    '<style>\n' +
    '    div.applicationActions button.btn-applicationActions {\n' +
    '        background-color: #fff !important;\n' +
    '        color: #009AA6 !important;\n' +
    '        display: block;\n' +
    '        margin-top: -5px;\n' +
    '    }\n' +
    '</style>\n' +
    '\n' +
    '<div class="applicationActions" ng-if="action !== \'\'">\n' +
    '    <button class="btn btn-info btn-applicationActions" qa-btn="action-resume-later" ng-click="resumeLater()" ng-if="action == \'resume\'">Finish Later</button>\n' +
    '    <button class="btn btn-info btn-applicationActions" qa-btn="action-print-application" ng-click="printApplication()" ng-if="action == \'print\'">Print Application</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/bigNews/template/template.html',
    '<div class="row-fluid big-news" ng-show="news.visible">\n' +
    '	<div class="big-news-left">{{ news.bigNewsDescriptor }}</div>\n' +
    '	<div class="big-news-right">\n' +
    '		{{ news.subject }}\n' +
    '		<small>\n' +
    '			{{ news.messageSnip | stripHtml }}\n' +
    '			<a ng-click="openNewsModal()" class="big-news-link newsmodal">Read More</a>\n' +
    '		</small>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/claimsSearch/template/template.html',
    '<div class="topMessage">\n' +
    '	<div error-message="searchResults.error.message" error-message-header="searchResults.error.header"></div>\n' +
    '</div>\n' +
    '<div class="topMessage alert alert-warning" style="font-weight: bold" ng-if="!showLossRuns()">\n' +
    '	Only workers’ compensation claims searches are available at this time.  For loss runs, please contact lossruns@markel.com\n' +
    '</div>\n' +
    '<form name="claimsForm" id="claimsForm" class="form-horizontal ajax-process" ng-submit="getResults()">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="claimsProgramControlGroup">\n' +
    '				<label for="claimsProgram">Program <span class="required-indicator">*</span></label>\n' +
    '				<div class="controls">\n' +
    '					<select id="claimsProgram" name="claimsProgram" class="piiSafe" ng-options="program as program.name for program in searchData.programs" ng-model="selectedProgram" size="1" ng-change="programChanged()"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="claimsFirstNameControlGroup" ng-show="selectedProgram.claimSystem != \'icon\'">\n' +
    '				<label for="claimsFirstName">Claimant First Name</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="claimsFirstName" name="claimFirstName" class="piiSafe" ng-model="searchParams.claimFirstName">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="claimsLastNameControlGroup" ng-show="selectedProgram.claimSystem != \'icon\'">\n' +
    '				<label for="claimsLastName">Claimant Last Name</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="claimsLastName" name="claimLastName" class="piiSafe" ng-model="searchParams.claimLastName">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="claimsNumberControlGroup" ng-show="selectedProgram.value == \'wc\'">\n' +
    '				<label for="claimNumber">Claim Number</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="claimNumber" name="claimNumber" class="piiSafe" ng-model="searchParams.claimNumber">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="occurrenceNumberControlGroup" ng-show="selectedProgram.value != \'wc\'">\n' +
    '				<label for="occurrenceNumber">Claim/Occurrence Number</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="occurrenceNumber" name="occurrenceNumber" class="piiSafe" ng-model="searchParams.occurrenceNumber">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="claimsNameInsuredControlGroup">\n' +
    '				<label for="claimsNameInsured">Insured Name</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="claimsNameInsured" name="insuredName" class="piiSafe" ng-model="searchParams.insuredName">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="claimsPolicyNumberControlGroup">\n' +
    '				<label for="claimsPolicyNumber">Policy Number</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="claimsPolicyNumber" name="policyNumber" class="piiSafe" ng-model="searchParams.policyNumber">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="accDateControlGroup" ng-show="selectedProgram.value == \'wc\'">\n' +
    '				<label for="claimsAccidentDate">Accident Date from</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="claimsAccidentDate" name="accDateFrom" ng-model="searchParams.accidentDateFrom" type="text" class="input-small piiSafe" date-picker> to\n' +
    '					<input id="claimsAccidentTo" name="accDateTo" ng-model="searchParams.accidentDateTo" type="text" class="input-small piiSafe" date-picker>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="occDateControlGroup" ng-show="selectedProgram.value != \'wc\'">\n' +
    '				<label for="claimsAccidentDate">Date of Loss From</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="occurrenceDate" name="occDateFrom" ng-model="searchParams.occurrenceDateFrom" type="text" class="input-small piiSafe" date-picker> to\n' +
    '					<input id="occurrenceTo" name="occDateTo" ng-model="searchParams.occurrenceDateTo" type="text" class="input-small piiSafe" date-picker>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group">\n' +
    '				<div class="controls">\n' +
    '					<button id="claimsSubmit" class="btn btn-success" ng-hide="searchResults.status.working">Search</button>\n' +
    '					<div class="searchingMessage" ng-show="searchResults.status.working">Searching...</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</form>\n' +
    '\n' +
    '<div class="row-fluid" ng-show="searchResults.status.ready">\n' +
    '	<div class="span12" id="claimsSearchResultsContainer">\n' +
    '		<div class="alert alert-warning" style="font-weight: bold">\n' +
    '			Alaska, Idaho, Montana, and Oregon Workers\' Compensation claims data is currently unavailable. Please contact Customer Service at 888-500-3344 for accurate claims details and/or loss runs.\n' +
    '		</div>\n' +
    '	\n' +
    '		<hr>\n' +
    '		<h3><span id="claimsResultsCount">{{ searchResults.results.length }}</span> Records Returned</h3>\n' +
    '		\n' +
    '		<!-- claims search results. used for wc -->\n' +
    '		<table id="claimsSearchResults" name="claimsSearchResults" class="table table-striped table-condensed table-bordered" ng-show="searchResults.visible.claims">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th>Claimant</th>\n' +
    '					<th>Status</th>\n' +
    '					<th>Accident Date</th>\n' +
    '					<th>Insured Name</th>\n' +
    '					<th>Benefit State</th>\n' +
    '					<th>Claim Number</th>\n' +
    '					<th>Reserved</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody id="claimsSearchResultsBody">\n' +
    '				<tr class="claimsResults" ng-repeat="result in searchResults.filteredResults">\n' +
    '					<td class="CLAIMANTNAME">{{ result.CLAIMANTNAME }}</td>\n' +
    '					<td class="STATUS">{{ result.STATUS }}</td>\n' +
    '					<td class="ACCIDENTDATE">{{ result.ACCIDENTDATE }}</td>\n' +
    '					<td class="INSUREDNAME">\n' +
    '						<a class="INSUREDNAME" href="#/account/activity/{{ result.INSUREDID }}">{{ result.INSUREDNAME }}</a>\n' +
    '					</td>\n' +
    '					<td class="BENEFITSTATE">{{ result.BENEFITSTATE }}</td>\n' +
    '					<td class="CLAIMNUMBER">\n' +
    '						<span ng-show="result.PRODUCTTYPE" product-label="result.PRODUCTTYPE">{{ result.PRODUCTTYPE }}</span>&nbsp;<a class="CLAIMNUMBER" href="#/account/claim/{{ result.CLAIMID }}">{{ result.CLAIMNUMBER }}</a>\n' +
    '					</td>\n' +
    '					<td class="TOTALAMOUNT align-right">{{ result.TOTALAMOUNT | formatMoney }}</td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '\n' +
    '		<!-- Maverick occurrence search results. used for bop -->\n' +
    '		<table id="occurrenceSearchResults" name="occurrenceSearchResults" class="table table-striped table-condensed table-bordered" ng-show="searchResults.visible.maverickOccurrence">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th>Insured Name</th>\n' +
    '					<th>Occurrence Number</th>\n' +
    '					<th>Occurrence Status</th>\n' +
    '					<th>Occurrence Date</th>\n' +
    '					<th>State</th>\n' +
    '					<th class="align-right">Reserved</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody id="occurrenceSearchResultsBody">\n' +
    '				<tr class="occurrenceResults" ng-repeat="result in searchResults.filteredResults">\n' +
    '					<td class="INSUREDNAME">\n' +
    '						<a class="INSUREDNAME" href="#/account/policy/{{ result.POLICYID }}">{{ result.INSUREDNAME }}</a>\n' +
    '					</td>\n' +
    '					<td class="OCCURRENCENUMBER">\n' +
    '						<span product-label="result.PRODUCTTYPE">{{ result.PRODUCTSHORTNAME }}</span>&nbsp;<a class="OCCURRENCENUMBER" href="#/account/occurrence/{{ result.OCCURRENCEID }}">{{ result.OCCURRENCENUMBER }}</a>\n' +
    '					</td>\n' +
    '					<td class="OCCURRENCESTATUS">{{ result.STATUS }}</td>\n' +
    '					<td class="OCCURRENCEDATE">{{ result.OCCURRENCEDATE }}</td>\n' +
    '					<td class="STATE">{{ result.STATE }}</td>\n' +
    '					<td class="TOTALAMOUNT align-right">{{ result.TOTALAMOUNT | formatMoney }}</td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '\n' +
    '		<!-- ICON occurrence search results -->\n' +
    '		<table id="iconOccurrenceSearchResults" name="iconOccurrenceSearchResults" class="table table-striped table-condensed table-bordered" ng-show="searchResults.visible.iconOccurrence">\n' +
    '			<thead>\n' +
    '			<tr>\n' +
    '				<th>Insured Name</th>\n' +
    '				<th>Status</th>\n' +
    '				<th>Loss Date</th>\n' +
    '				<th>Occurrence Number</th>\n' +
    '			</tr>\n' +
    '			</thead>\n' +
    '			<tbody id="iconOccurrenceSearchResultsBody">\n' +
    '			<tr class="occurrenceResults" ng-repeat="result in searchResults.filteredResults">\n' +
    '				<td class="INSUREDNAME">\n' +
    '					<a class="INSUREDNAME" href="#/account-summary/{{:: result.accountId }}">{{ result.insuredName }}</a>\n' +
    '				</td>\n' +
    '				<td class="OCCURRENCESTATUS">{{ result.status }}</td>\n' +
    '				<td class="OCCURRENCEDATE">{{ result.occurrenceDate | date:"MM/dd/yyyy" }}</td>\n' +
    '				<td class="OCCURRENCENUMBER">\n' +
    '					<a class="OCCURRENCENUMBER" href="#/account-summary/{{:: result.accountId }}/policy/{{:: result.policyNumberCore }}/effective-date/{{:: result.effectiveDate | date:\'yyyy-MM-dd\' }}/claim/{{:: result.occurrenceNumber }}">{{ result.occurrenceNumber }}</a>\n' +
    '				</td>\n' +
    '			</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '\n' +
    '		<div class="pagination" id="claimsSearchResultsPagination">\n' +
    '			<pagination total-items="searchResults.results.length" items-per-page="searchResults.config.pagination.config.itemsPerPage" previous-text="&larr; Previous" next-text="Next &rarr;" ng-model="searchResults.config.pagination.config.currentPage" ng-change="searchResults.paginate()" ng-show="searchResults.results.length">\n' +
    '			</pagination>\n' +
    '		</div>\n' +
    '\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/classCodesSearch/template/template.html',
    '<div class="topMessage">\n' +
    '	<div error-message="searchResults.error.message" error-message-header="searchResults.error.header"></div>\n' +
    '</div>\n' +
    '<form name="classCodesForm" ng-model="searchParams.classCodesForm" id="classCodesForm" class="form-horizontal ajax-process" ng-submit="getResults()">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="classCodesLobControlGroup">\n' +
    '				<label for="lob">Program <span class="required-indicator">*</span></label>\n' +
    '				<div class="controls">\n' +
    '					<select id="lob" name="lob" class="piiSafe" ng-options="program as program.name for program in searchData.programs" ng-model="selectedProgram" size="1"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="classCodeControlGroup">\n' +
    '				<label for="classCode">Class Code</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="classCode" name="classCode" class="piiSafe" ng-model="searchParams.classCode" type="text" class="span3">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group stateDD" id="classCodeStateControlGroup">\n' +
    '				<label for="classCodeState">State</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="classCodeState" name="classCodeState" class="piiSafe" ng-model="searchParams.classCodeState" size="1" ng-options="state.NAME as state.VALUE for state in searchData.states"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group">\n' +
    '				<label for="description">Description</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="description" name="description" class="piiSafe" ng-model="searchParams.description" type="text" class="span5">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group">\n' +
    '				<label for="eligibility">Eligibility</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="eligibility" name="eligibility" class="piiSafe" size="1" ng-model="selectedEligibilities" ng-options="eligibility as eligibility.NAME for eligibility in searchData.eligibilities"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group">\n' +
    '				<div class="controls">\n' +
    '					<button id="classCodesSubmit" class="btn btn-success" ng-hide="searchResults.status.working">Search</button>\n' +
    '					<div class="searchingMessage" ng-show="searchResults.status.working">Searching...</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</form>\n' +
    '<div class="row-fluid" ng-show="searchResults.status.ready">\n' +
    '	<div class="span12" id="classCodesSearchResultsContainer">\n' +
    '		<hr>\n' +
    '		<h3><span id="classCodesResultsCount">{{ searchResults.results.length }}</span> Records Returned</h3>\n' +
    '		<table id="classCodesSearchResults" name="classCodesSearchResults" ng-model="searchParams.classCodesSearchResults" class="table table-striped table-condensed table-bordered">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th>LoB</th>\n' +
    '					<th>State</th>\n' +
    '					<th>Class Code</th>\n' +
    '					<th>Eligibility</th>\n' +
    '					<th>Description</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody id="classCodesSearchResultsBody">\n' +
    '				<tr class="classCodeResults" ng-repeat="result in searchResults.filteredResults">\n' +
    '					<td class="PRODUCTTYPE">\n' +
    '						<span ng-show="result.PRODUCTTYPE" product-label="result.PRODUCTTYPE">{{ result.PRODUCTTYPE }}</span>\n' +
    '					</td>\n' +
    '					<td class="STATE">{{ result.STATE }}</td>\n' +
    '					<td class="CLASSCODE">{{ result.CLASSCODE }}</td>\n' +
    '					<td class="ELIGIBILITY">{{ result.ELIGIBILITY == \'Autoquote\' || result.ELIGIBILITY == \'Quote\' ? \'Quote\': result.ELIGIBILITY }}</td>\n' +
    '					<td class="DESCRIPTION" ng-bind-html="result.DESCRIPTION | formatClassCodeDescription"></td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '		<div class="pagination" id="classCodesSearchResultsPagination">\n' +
    '			<pagination total-items="searchResults.results.length" items-per-page="searchResults.config.pagination.config.itemsPerPage" previous-text="&larr; Previous" next-text="Next &rarr;" ng-model="searchResults.config.pagination.config.currentPage" ng-change="searchResults.paginate()" ng-show="searchResults.results.length">\n' +
    '			</pagination>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/documentDeliveryPreferences/template/template.html',
    '<style>\n' +
    '    div.doc-delivery-modal {\n' +
    '        margin-left: -25%;\n' +
    '        width: 850px;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface a.tcLink {\n' +
    '        text-decoration: underline;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface .preferenceRow {\n' +
    '        height: 42px;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface .recipientTitle, .emailTitle {\n' +
    '        font-weight: bold;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface p.emailInstruction {\n' +
    '        font-size: 90%;\n' +
    '        margin-top: -10px;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface select.deliveryPreferenceSelect {\n' +
    '        min-width: 100px !important;\n' +
    '        width: 100px !important;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface ul.preferenceErrors {\n' +
    '        list-style-type: none;\n' +
    '        margin-left: 0px;\n' +
    '        margin-top: 2ex;\n' +
    '        padding-left: 0px;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface ul.preferenceErrors > li {\n' +
    '        padding: 1ex 0em 1ex 1em;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface p, .documentType , .recipientTitle, .emailTitle{ font-size:14px; }\n' +
    '\n' +
    '    /* Prevents inputs overlapping if screen too small (forces horizontal scroll at certain point */\n' +
    '    #documentDeliveryPreferencesInterface.enforceMinWidth {\n' +
    '        min-width:685px;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface .tighterText {\n' +
    '        line-height: 1.3 !important;\n' +
    '    }\n' +
    '\n' +
    '    #documentDeliveryPreferencesInterface select.agencyPolicyEmailRecipient {\n' +
    '        min-width: 237px !important;\n' +
    '        width: 237px !important;\n' +
    '    }\n' +
    '\n' +
    '</style>\n' +
    '\n' +
    '<div id="documentDeliveryPreferencesInterface" ng-if="isAvailableForBind" ng-class="{enforceMinWidth: !isIssued }">\n' +
    '    <div class="row-fluid">\n' +
    '        <div class="span12">\n' +
    '            <h3 ng-if="!isIssued"><strong>Document delivery preferences</strong></h3>\n' +
    '\n' +
    '            <div style="margin-top:1ex;" ng-if="!hasData">\n' +
    '                <div class="alert">\n' +
    '                    Document delivery preferences cannot be set at this time. Please contact your Underwriter for assistance regarding delivery preferences.\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <p ng-if="hasData">\n' +
    '                <span>{{ introStatement }}</span>\n' +
    '                <span ng-if="isInsuredUser">\n' +
    '                    By selecting electronic delivery you acknowledge you have reviewed the <a class="tcLink" ng-click="openElectronicDeliveryTermsModal()">Markel electronic terms and conditions</a> and consent to receive documents electronically.\n' +
    '                </span>\n' +
    '                <span ng-if="!isInsuredUser">\n' +
    '                     By selecting electronic delivery for the insured you acknowledge that the insured has reviewed the <a class="tcLink" ng-click="openElectronicDeliveryTermsModal()">Markel electronic terms and conditions</a> and have the consent of the insured to deliver documents electronically.\n' +
    '                </span>\n' +
    '            </p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row-fluid preferenceGrid" ng-if="hasData">\n' +
    '        <div class="span4">\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    &nbsp;\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <span class="documentType">Billing documents\n' +
    '                        <i ng-if="showBillingToolTip()" class="glyphicon glyphicon-question-sign field-help" tooltip="{{docDeliveryConfig[ \'agency\' ][ \'billing\' ].hidden ? insuredBillingTip : agencyBillingTip}}" tooltip-trigger="hover" tooltip-placement="top" tooltip-class="custom-popover pull-right">\n' +
    '                        </i>\n' +
    '                        &nbsp;\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <span class="documentType">Policy and endorsements</span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow" ng-if="showInsuredEmailInstructions()">\n' +
    '                <div class="span12">\n' +
    '                    <span class="documentType">\n' +
    '                        &nbsp;\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '        <div class="span2" ng-if="!isInsuredUser">\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <p class="recipientTitle">Agency Delivery</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <select name="AgencyBilling" class="deliveryPreferenceSelect" ng-if="!docDeliveryConfig[ \'agency\' ][ \'billing\' ].hidden" ng-model="docDeliveryConfig[ \'agency\' ][ \'billing\' ].value" ng-disabled="docDeliveryConfig[ \'agency\' ][ \'billing\' ].disabled" ng-options="option.value as option.label for option in docDeliveryConfig[ \'agency\' ][ \'billing\' ].options">\n' +
    '                    </select>&nbsp;\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <select name="AgencyPolicy" class="deliveryPreferenceSelect" ng-if="!docDeliveryConfig[ \'agency\' ][ \'policy\' ].hidden" ng-model="docDeliveryConfig[ \'agency\' ][ \'policy\' ].value" ng-disabled="docDeliveryConfig[ \'agency\' ][ \'policy\' ].disabled" ng-options="option.value as option.label for option in docDeliveryConfig[ \'agency\' ][ \'policy\' ].options">\n' +
    '                    </select>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow" ng-if="showInsuredEmailInstructions()">\n' +
    '                <div class="span12">\n' +
    '                    &nbsp;\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '        <div class="span2">\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <p class="recipientTitle">Insured Delivery</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <select name="InsuredBilling" class="deliveryPreferenceSelect" ng-if="!docDeliveryConfig[ \'insured\' ][ \'billing\' ].hidden" ng-model="docDeliveryConfig[ \'insured\' ][ \'billing\' ].value" ng-disabled="docDeliveryConfig[ \'insured\' ][ \'billing\' ].disabled" ng-options="option.value as option.label for option in docDeliveryConfig[ \'insured\' ][ \'billing\' ].options">\n' +
    '                    </select>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <select name="InsuredPolicy" class="deliveryPreferenceSelect" ng-if="!docDeliveryConfig[ \'insured\' ][ \'policy\' ].hidden" ng-model="docDeliveryConfig[ \'insured\' ][ \'policy\' ].value" ng-disabled="docDeliveryConfig[ \'insured\' ][ \'policy\' ].disabled" ng-options="option.value as option.label for option in docDeliveryConfig[ \'insured\' ][ \'policy\' ].options">\n' +
    '                    </select>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow" ng-if="showInsuredEmailInstructions()">\n' +
    '                <div class="span12">\n' +
    '                    &nbsp;\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="showInsuredEmailColumn()" class="span4">\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <p class="emailTitle">{{emailColumnTitle}}</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <input class="quoteResultEmailPreference" ng-if="!docDeliveryConfig[ \'insured\' ][ \'billing\' ].hidden && docDeliveryConfig[ \'insured\' ][ \'billing\' ].value==\'EM\'" type="text" name="billingDeliveryEmail" ng-model="docDeliveryConfig[ \'insured\' ][ \'billing\' ].newEmail" placeholder="{{billingEmailPlaceholderText}}">\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow">\n' +
    '                <div class="span12">\n' +
    '                    <input class="quoteResultEmailPreference" ng-if="showInsuredEmail()" ng-disabled="isIssued" type="text" name="email" ng-model="docDeliveryConfig[ \'insured\' ][ \'policy\' ].newEmail" placeholder="{{emailPlaceholderText}}">\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row-fluid preferenceRow" ng-if="showInsuredEmailInstructions()">\n' +
    '                <div class="span12">\n' +
    '                    <p class="emailInstruction">{{ insuredEmailInstructions }}</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row-fluid" ng-if="showAgencyPolicyEmailRecipient()">\n' +
    '        <div class="span4">\n' +
    '            <div class="documentType tighterText">Agency electronic policy and endorsements recipient\n' +
    '                <i ng-if="showAgencyEmailTooltip()" class="glyphicon glyphicon-question-sign field-help" tooltip="To change your agency email address, please contact agency services at agencyappointment@markel.com" tooltip-trigger="hover" tooltip-placement="top" tooltip-class="custom-popover pull-right">\n' +
    '                </i>\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="span4">\n' +
    '            <select class="agencyPolicyEmailRecipient" name="AgencyPolicyEmailRecipient" ng-model="docDeliveryConfig.agencyPolicyEmailRecipient" ng-options="option.value as option.label for option in agencyPolicyEmailRecipientOptions">\n' +
    '            </select>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row-fluid validationMsgs" ng-if="hasData && deliveryErrors.length">\n' +
    '        <div class="span12">\n' +
    '            <ul class="preferenceErrors alert-error">\n' +
    '                <li ng-repeat="error in deliveryErrors">\n' +
    '                    {{error}}\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <br>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/editSubmission/template/insuredSaveDataWarning.html',
    '<div class="modal-header">\n' +
    '    <h3>Submission Locked</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <div class="message-modal_pure">\n' +
    '        <p>We are currently reviewing your submission and editing is not permitted. Please contact Markel for assistance.</p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn" ng-click="close()">Close</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/editSubmission/template/modalBlocked.html',
    '<div class="modal-header">\n' +
    '    <h3>Editing Not Permitted</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <div class="message-modal_pure">\n' +
    '        <p>Please send a message to your Underwriter to edit this submission.</p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn" ng-click="close()">Close</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/editSubmission/template/modalWarning.html',
    '<div class="modal-header">\n' +
    '    <h3>Warning</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <div class="message-modal_pure">\n' +
    '        <p>Once you begin editing this quoted submission, it will need to be re-submitted and re-evaluated, which could lead to a\n' +
    '        different outcome.</p>\n' +
    '        <p>Do you wish to continue with the editing process?</p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn btn-primary" ng-click="proceedToEdit()">Continue Edit Process</button>\n' +
    '    <button class="btn" ng-click="close()">Cancel</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/editSubmission/template/saveDataWarning.html',
    '<div class="modal-header">\n' +
    '       <h3>Submission Locked</h3>\n' +
    '    </div>\n' +
    '<div class="modal-body">\n' +
    '        <div class="message-modal_pure">\n' +
    '           <p>We are currently reviewing your submission and editing is not permitted. Please contact Markel for assistance.</p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '<div class="modal-footer">\n' +
    '        <button class="btn" ng-click="close()">Close</button>\n' +
    '    </div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/editSubmission/template/template.html',
    '<a href ng-if="!checkingLock" ng-click="editSubmission()">{{ linkText }}</a>\n' +
    '<span ng-if="checkingLock">Checking status...</span>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/documentDeliveryPreferencesLink/template/modalPreferencesUnavailable.html',
    '<div class="modal-header">\n' +
    '    <h3>Document Delivery Preferences Unavailable</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <div class="message-modal_pure">\n' +
    '        <p>There was a problem retrieving your document delivery preferences. Please send a message to your Underwriter to change your preferences.</p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn" ng-click="close()">Close</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/documentDeliveryPreferencesLink/template/modalUpdatePreferences.html',
    '<div class="modal-header">\n' +
    '    <h3>View/Edit Document Delivery Preferences</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <document-delivery-preferences master-quote-id="submissionId" doc-delivery-config="docDeliveryConfig" has-data="docDeliveryDataAvailable" delivery-errors="deliveryErrors" is-issued="true" button_text="button_text" insured-type="\'Direct\'" is-available-for-bind="true"></document-delivery-preferences>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn btn-primary pull-right" ng-click="saveSubmissionDeliveryPreferences()" ng-disabled="inProgress">{{ getSaveButtonText() }}</button>\n' +
    '    <button class="btn pull-right" ng-click="close()" ng-disabled="inProgress">Cancel</button>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/documentDeliveryPreferencesLink/template/template.html',
    '<a href ng-if="!retrievingData && isAgent()" ng-click="getPreferences( applicationId, submissionId, insuredType )">Update Delivery Preferences</a>\n' +
    '<a href ng-if="!retrievingData && isInsured()" ng-click="getPreferences( applicationId, submissionId, insuredType )">Update Document Delivery</a>\n' +
    '<span ng-if="retrievingData">Retrieving preferences...</span>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/errorModal/template/template.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>{{ title }}</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<div ng-bind-html="message"></div>\n' +
    '		<div ng-bind-html="exception"></div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/filterableDocumentList/template/template.html',
    '<div id="form_listing" class="span5 table table-bordered" ng-show="documents.length">\n' +
    '	<span class="span11 row-fluid filter-container">\n' +
    '		<span class="span12 filter-input">\n' +
    '			<input id="formFilter" type="text" class="span12 piiSafe" placeholder="{{ placeholder }}" ng-model="filter.document">\n' +
    '		</span>\n' +
    '		<table class="table table-striped table-condensed">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th>Type</th>\n' +
    '					<th>Description</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody class="filter-target">\n' +
    '				<tr ng-repeat="document in documents | filter: filterDocuments">\n' +
    '					<td class="type"><a class="filter-text" target="_blank" ng-href="{{ document.link }}">{{ document.type }}</a></td>\n' +
    '					<td class="description"><a class="filter-text" target="_blank" ng-href="{{ document.link }}">{{ document.description }}</a></td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '	</span>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/insuredClaims/template/template.html',
    '<ul class="nav nav-list">\n' +
    '	<li class="nav-header">Claims History</li>\n' +
    '</ul>\n' +
    '<div class="alert alert-warning policyHolderAlertContainer">\n' +
    '	<div ng-if="!showLossRuns()">\n' +
    '		<p>Only workers\' compensation claims are available at this time.</p>\n' +
    '	</div>\n' +
    '	<ul ng-repeat="year in claimYears" class="nav nav-list insured-claim-ul">\n' +
    '		<li class="insured-claim-year">{{:: year }}</li>\n' +
    '		<li ng-repeat="c in claimsByYear[year]" class="insured-claim-li">\n' +
    '			<div ng-if="c.claimId">\n' +
    '				<span product-label="c.program" auto-width="true">{{:: c.productLine }}</span>\n' +
    '				<a href ng-click="openClaimDetail(  c.claimId, c.productLine,\'\' )">Claim {{:: c.claimNumber }}</a>\n' +
    '			</div>\n' +
    '			<div ng-if="c.occurrenceNumber && showLossRuns()">\n' +
    '				<a href ng-click="openClaimDetail(  c.occurrenceNumber,\'ICON\', c.agencyId  )">{{c.occurrenceNumber }}</a>\n' +
    '			</div>\n' +
    '		</li>\n' +
    '	</ul>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/lossRunReport/template/template.html',
    '<form name="lossRunForm" id="lossRunForm" class="form-horizontal post" ng-submit="getReport()">\n' +
    '\n' +
    '	<p>Obtain loss information and/or a detailed claim report for specific insured(s) within your agency.</p>\n' +
    '	<p>Please follow the instructions below to retrieve Loss Runs:</p>\n' +
    '\n' +
    '	<ul>\n' +
    '		<li>Loss runs are updated as of the last day of the previous month.</li>\n' +
    '		<li>If an invalid policy number is entered you will receive a blank report.</li>\n' +
    '		<li>Please disable any popup blockers. The report will be popped up in a new window.</li>\n' +
    '		<li>Leave the policy number field blank to obtain the loss runs for all policies associated with the agency.</li>\n' +
    '	</ul>\n' +
    '\n' +
    '	<div class="control-group">\n' +
    '		<label for="lossRunEndDateMonth" class="control-label">End Date</label>\n' +
    '		<span class="controls">\n' +
    '			<select name="lossRunEndDateMonth" id="lossRunEndDateMonth" class="piiSafe" ng-model="reportParams.month" ng-options="month.value as month.label for month in months"></select>\n' +
    '		</span>\n' +
    '	</div>\n' +
    '\n' +
    '	<div class="control-group">\n' +
    '		<label for="lossRunEndDateYear" class="control-label"></label>\n' +
    '		<span class="controls">\n' +
    '			<select name="lossRunEndDateYear" id="lossRunEndDateYear" class="piiSafe" ng-model="reportParams.year" ng-options="year as year for year in years"></select>\n' +
    '		</span>\n' +
    '	</div>\n' +
    '\n' +
    '	<div class="control-group">\n' +
    '		<label for="policyNumber" class="control-label">Policy Number</label>\n' +
    '		<span class="controls">\n' +
    '			<input type="text" maxlength="14" name="policyNumber" id="policyNumber" class="piiSafe" ng-model="reportParams.policyNumber">\n' +
    '			<span class="required">ex: WVS0000000-01</span>\n' +
    '		</span>\n' +
    '	</div>\n' +
    '\n' +
    '	<div class="control-group">\n' +
    '		<label for="lossRunDetail" class="control-label">Show Detail</label>\n' +
    '		<span class="controls">\n' +
    '			<input type="checkbox" name="lossRunDetail" id="lossRunDetail" class="piiSafe" ng-model="reportParams.detail">\n' +
    '		</span>\n' +
    '	</div>\n' +
    '\n' +
    '	<div id="lossRunEndDateMessage">{{ error }}</div>\n' +
    '\n' +
    '	<div class="form-actions" id="lossRunFormButton">\n' +
    '		<div class="controls">\n' +
    '			<button id="lossRun_form_submit" class="btn btn-primary">Generate Report</button>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '</form>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/lossRuns/template/template.html',
    '<a href ng-click="openLossRunsWindow()">{{::getLossRunLinkText()}}</a>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/mvpSwitch/template/template.html',
    '<div class="mvp-switch">\n' +
    '	<span ng-if="account.pe_type != 10">{{::account.agencyID }} - {{::account.agencyName }} - </span>\n' +
    '	<span ng-if="account.pe_type == 10">{{::account.pe_id }} - </span><a href="#/user/edit">{{ account.firstName }} {{ account.lastName }} </a>\n' +
    '	<div class="btn pull-right" ng-click="logout()" style="margin-left:10px;">Logout</div>\n' +
    '	<div class="btn btn-primary pull-right" ng-click="show=!show">\n' +
    '		Switch Account\n' +
    '		<span class="glyphicon glyphicon-menu-down pull-right" ng-if="!show" style="margin-left:4px;margin-top:1px;"></span>\n' +
    '		<span class="glyphicon glyphicon-menu-up pull-right" ng-if="show" style="margin-left:4px;margin-top:1px;"></span>\n' +
    '	</div>\n' +
    '	<div class="span6 pull-right" style="text-align:left;">\n' +
    '		<div ng-if="show">\n' +
    '			<div class="mvp-filter" ng-hide="show_filter">\n' +
    '				<label for="mvp-filter">Filter accounts:</label>\n' +
    '				<input id="mvpfilter" type="text" ng-model="accountFilter" placeholder="Account Name">\n' +
    '			</div>\n' +
    '			<table class="mvp-filter-options span12">\n' +
    '				<tr ng-if="agency_accounts.length">\n' +
    '					<td colspan="2" style="text-decoration:underline">Agency Accounts</td>\n' +
    '				</tr>\n' +
    '				<tr ng-if="agency_accounts.length" ng-repeat="view in agency_accounts|filter:accountFilter" ng-class="{\'mvp-selected\' : view.pe_id == account.pe_id}" ng-click="switch(view)">\n' +
    '					<td class="mvp-link-btn"><a>[{{::view.agencyID }}] {{::view.agencyName }} - {{ view.firstName }} {{ view.lastName }}</a></td>\n' +
    '				</tr>		\n' +
    '				<tr ng-if="insured_accounts.length">\n' +
    '					<td colspan="2" style="text-decoration:underline">Insured Accounts</td>\n' +
    '				</tr>\n' +
    '				<tr ng-if="insured_accounts.length" ng-repeat="view in insured_accounts" ng-class="{\'mvp-selected\' : view.pe_id == account.pe_id}" ng-click="switch(view)">\n' +
    '					<td class="mvp-link-btn"><a>[{{::view.pe_id }}] {{::view.firstName }} {{::view.lastName }}</a></td>\n' +
    '				</tr>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/newsHeadlines/template/newsItem.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>News Item</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<h2>{{ newsItem.subject }}</h2>\n' +
    '		<div ng-bind-html="newsItem.trustedMessage"></div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/newsHeadlines/template/template.html',
    '<div class="banner" id="newsHeadlines">News Headlines</div>\n' +
    '<table class="table table-bordered table-condensed banner-link" id="homeNewsHeadlines">\n' +
    '	<tbody class="table-header">\n' +
    '		<tr ng-repeat="item in news">\n' +
    '			<td>\n' +
    '				<span ng-if="item.isRecent" class="label label-success newsLabelRecent">Recent</span>\n' +
    '			    <span class="dateCreated">{{ item.dateCreated }}</span>\n' +
    '				<br>\n' +
    '				<a ng-click="openItem( item )" class="subject newsmodal" ng-class="{ \'link-strong\': item.isRecent }">\n' +
    '					{{ item.subject }}\n' +
    '				\n' +
    '			</a></td>\n' +
    '		</tr>\n' +
    '	</tbody>\n' +
    '	<tfoot>\n' +
    '		<tr>\n' +
    '			<td><a class="footer-news" href="#/news/archives">Go To Archives <span style="font-size:13px;">&#8594;</span></a></td>\n' +
    '		</tr>\n' +
    '	</tfoot>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/paymentLink/template/template.html',
    '<a href ng-click="openPaymentWindow()">Make a Payment</a>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policiesSearch/template/template.html',
    '<div class="topMessage">\n' +
    '	<div error-message="searchResults.error.message" error-message-header="searchResults.error.header"></div>\n' +
    '</div>\n' +
    '<form name="policiesForm" id="policiesForm" class="form-horizontal ajax-process" ng-submit="getResults()">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="policiesLobControlGroup">\n' +
    '				<label for="lob">Program <span class="required-indicator">*</span></label>\n' +
    '				<div class="controls">\n' +
    '					<select id="lob" name="lob" ng-options="program as program.name for program in searchData.programs" ng-model="selectedProgram" size="1"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="policiesNameInsuredControlGroup">\n' +
    '				<label for="policiesNameInsured">Insured Name</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="policiesNameInsured" name="insuredName" class="piiSafe" ng-model="searchParams.insuredName">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="policiesStatusControlGroup">\n' +
    '				<label for="policiesStatus">Status</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="policiesStatus" name="status" ng-model="searchParams.status" size="1" class="piiSafe" ng-options="status.VALUE as status.NAME for status in searchData.statuses"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="policiesFeinControlGroup">\n' +
    '				<label for="policiesFeinSSN">FEIN</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="policiesFeinSSN" name="fein" class="piiMask" ng-model="searchParams.fein">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="policiesInsuredAccountControlGroup">\n' +
    '				<label for="insuredAccount">Insured Account #</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="insuredAccount" name="insuredAccount" class="piiSafe" ng-model="searchParams.insuredAccount">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="policiesPolicyNumberControlGroup">\n' +
    '				<label for="policiesPolicyNumber">Policy Number</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="policiesPolicyNumber" name="policyNumber" class="piiSafe" ng-model="searchParams.policyNumber">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="policiesEffDateControlGroup">\n' +
    '				<label for="policiesEffDate">Effective Date</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="policiesEffDate" name="effDateFrom" ng-model="searchParams.effectiveDateFrom" type="text" class="input-small piiSafe" date-picker> to\n' +
    '					<input id="policiesEffDateTo" name="effDateTo" ng-model="searchParams.effectiveDateTo" type="text" class="input-small piiSafe" date-picker>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group">\n' +
    '				<div class="controls">\n' +
    '					<button id="policiesSubmit" class="btn btn-success" ng-hide="searchResults.status.working">Search</button>\n' +
    '					<div class="searchingMessage" ng-show="searchResults.status.working">Searching...</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</form>\n' +
    '\n' +
    '<div class="row-fluid" ng-show="searchResults.status.ready">\n' +
    '	<div class="span12" id="policiesSearchResultContainer">\n' +
    '		<hr>\n' +
    '		<h3><span id="policiesResultsCount">{{ searchResults.results.length }}</span> Records Returned</h3>\n' +
    '		<table id="policiesSearchResults" name="policiesSearchResults" class="table table-striped table-condensed table-bordered">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th>Insured Name</th>\n' +
    '					<th>Policy Number</th>\n' +
    '					<th>Effective Date</th>\n' +
    '					<th>City</th>\n' +
    '					<th>State</th>\n' +
    '					<th>Postal Code</th>\n' +
    '					<th>Status</th>\n' +
    '					<th>FEIN</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody id="policiesSearchResultsBody">\n' +
    '				<tr class="policiesResult" ng-repeat="result in searchResults.filteredResults">\n' +
    '					<td class="INSUREDNAME">\n' +
    '						<a class="INSUREDNAME" ng-href="#/account/activity/{{ result.INSUREDID }}">{{ result.INSUREDNAME }}</a>\n' +
    '					</td>\n' +
    '					<td class="POLICYNUMBER">\n' +
    '						<span product-label="result.PRODUCTTYPE">{{ result.PRODUCTTYPE }}</span>&nbsp;<a class="POLICYNUMBER" ng-href="#/account/policy/{{ result.POLICYID }}">{{ result.POLICYNUMBER }}</a>\n' +
    '					</td>\n' +
    '					<td class="EFFECTIVEDATE">{{ result.EFFECTIVEDATE }}</td>\n' +
    '					<td class="CITY">{{ result.CITY }}</td>\n' +
    '					<td class="STATE">{{ result.STATE }}</td>\n' +
    '					<td class="POSTALCODE">{{ result.POSTALCODE }}</td>\n' +
    '					<td>\n' +
    '						<a class="STATUS" ng-href="#/account/policy/{{ result.POLICYID }}">{{ result.STATUS }}</a>\n' +
    '					</td>\n' +
    '					<td class="FEIN" class="piiMask">{{ result.FEIN }}</td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '		<div class="pagination" id="policiesSearchResultsPagination">\n' +
    '			<pagination total-items="searchResults.results.length" items-per-page="searchResults.config.pagination.config.itemsPerPage" previous-text="&larr; Previous" next-text="Next &rarr;" ng-model="searchResults.config.pagination.config.currentPage" ng-change="searchResults.paginate()" ng-show="searchResults.results.length">\n' +
    '			</pagination>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/promotionsSummary/template/modal.html',
    '<div class="modal-header">\n' +
    '	<a class="close" data-dismiss="modal">&times;</a>\n' +
    '	<h3>Vault Summary</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '	<div id="promotionSummaryBody" ng-bind-html="body"></div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a ng-click="close()" class="btn close-button">Close</a>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/promotionsSummary/template/template.html',
    '<div ng-if="promotionsSummary.hasAccess" id="promotionDiv">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<div class="banner">Vault Summary</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<h4>Winnings</h4>\n' +
    '			<table class="table-plain">\n' +
    '				<tr>\n' +
    '					<td id="currentMonthLabel">{{ promotionsSummary.currentMonthWinnings.month }}</td>\n' +
    '					<td><a ng-click="openDetail(\'thisMonth\')" id="currentMonthWinnings">{{ promotionsSummary.currentMonthWinnings.amount | formatMoney }}</a></td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<td id="previousMonthLabel">{{ promotionsSummary.previousMonthWinnings.month }}</td>\n' +
    '					<td><a ng-click="openDetail(\'lastMonth\')" id="previousMonthWinnings">{{ promotionsSummary.previousMonthWinnings.amount | formatMoney }}</a></td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<td>Paid</td>\n' +
    '					<td><a ng-click="openDetail(\'paid\')" id="paidWinningsAmountPromotion">{{ promotionsSummary.paidWinningsAmount | formatMoney }}</a></td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<td>Unpaid</td>\n' +
    '					<td><a ng-click="openDetail(\'unpaid\')" id="unpaidWinningsAmountPromotion">{{ promotionsSummary.unpaidWinnings | formatMoney }}</a></td>\n' +
    '				</tr>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/agencyBillingDocuments.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Agency Billing Documents</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '	<loading-bar ng-if="documentCount === -1"></loading-bar>\n' +
    '	<table ng-if="documentCount > -1" class="table table-striped table-condensed table-bordered">\n' +
    '		<tr class="tr-bold">\n' +
    '			<td colspan="2">Statements Date</td>\n' +
    '			<td>Agency</td>\n' +
    '		</tr>\n' +
    '		<tbody dir-paginate="item in agencyBillingDocuments =( agencyBillingDocuments|orderBy: \'Statement_Date\':true ) | itemsPerPage: pageSize ">\n' +
    '		<tr>\n' +
    '			<td style="width:22px">\n' +
    '				<a href="{{item.document_link}}" target="_blank"><img ng-src="{{item.imageSource}}"></a>\n' +
    '			</td>\n' +
    '			<td>\n' +
    '				{{item.Statement_Date | date:"MM/dd/yyyy"}}\n' +
    '			</td>\n' +
    '			<td>{{item.Agencyname}}</td>\n' +
    '		</tr>\n' +
    '		</tbody>\n' +
    '		<tr ng-if="documentCount === 0">\n' +
    '			<td colspan="3">No Documents found.</td>\n' +
    '		</tr>\n' +
    '	</table>\n' +
    '</div>\n' +
    '<div class="pagination-controls pagination-controls-bottom">\n' +
    '	<dir-pagination-controls template-url="app/components/quickLinks/template/documentPagination.html"></dir-pagination-controls>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/commissions.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Commission Statements</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '	<loading-bar ng-if="documentCount === -1"></loading-bar>\n' +
    '	<table ng-if="documentCount > -1" class="table table-striped table-condensed table-bordered">\n' +
    '		<tr class="tr-bold">\n' +
    '			<td colspan="2">Statements Date</td>\n' +
    '			<td>Agency</td>\n' +
    '		</tr>\n' +
    '		<tbody dir-paginate="item in commissionDocuments =( commissionDocuments|orderBy: \'Statement_Date\':true ) | itemsPerPage: pageSize ">\n' +
    '		<tr>\n' +
    '			<td style="width:22px">\n' +
    '				<a href="{{item.document_link}}" target="_blank"><img src="assets/images/Document_Icon.png"></a>\n' +
    '			</td>\n' +
    '			<td>\n' +
    '				{{item.Statement_Date | date:"MM/dd/yyyy"}}\n' +
    '			</td>\n' +
    '			<td>{{item.Agencyname}}</td>\n' +
    '		</tr>\n' +
    '		</tbody>\n' +
    '		<tr ng-if="documentCount === 0">\n' +
    '			<td colspan="3">No Documents found.</td>\n' +
    '		</tr>\n' +
    '	</table>\n' +
    '	<div class="ach-enrollment-message">\n' +
    '		<p>Want to enroll in ACH for commissions? <a href="https://payee.globalpay.convera.com/PayeeManager/BeneficiaryEnrollment/CustomizedEnrollmentProcess.aspx?id=88F7DB4E2FF631F0634C8FFCDFDABED3" target="_blank">Click here</a></p>\n' +
    '	</div>	\n' +
    '</div>\n' +
    '<div class="pagination-controls pagination-controls-bottom">\n' +
    '	<dir-pagination-controls template-url="app/components/quickLinks/template/documentPagination.html"></dir-pagination-controls>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/documentPagination.html',
    '<ul class="pagination" ng-if="1 < pages.length || !autoHide">\n' +
    '	<li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == 1 }">\n' +
    '		<a href="" ng-click="setCurrent(1)">&laquo;</a>\n' +
    '	</li>\n' +
    '	<li ng-if="directionLinks" ng-class="{ disabled : pagination.current == 1 }">\n' +
    '		<a href="" ng-click="setCurrent(pagination.current - 1)">&lsaquo;</a>\n' +
    '	</li>\n' +
    '	<li ng-repeat="pageNumber in pages track by tracker(pageNumber, $index)" ng-class="{ active : pagination.current == pageNumber, disabled : pageNumber == \'...\' }">\n' +
    '		<a href="" ng-click="setCurrent(pageNumber)">{{ pageNumber }}</a>\n' +
    '	</li>\n' +
    '\n' +
    '	<li ng-if="directionLinks" ng-class="{ disabled : pagination.current == pagination.last }">\n' +
    '		<a href="" ng-click="setCurrent(pagination.current + 1)">&rsaquo;</a>\n' +
    '	</li>\n' +
    '	<li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == pagination.last }">\n' +
    '		<a href="" ng-click="setCurrent(pagination.last)">&raquo;</a>\n' +
    '	</li>\n' +
    '</ul>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/lossRuns.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Loss Runs</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<div class="alert alert-warning">\n' +
    '			This feature is for Workers compensation policies only at this time. To request Business Owners Policy loss runs, please contact Markel @ <a href="mailto:lossrun@markel.com">lossrun@markel.com</a>\n' +
    '		</div>\n' +
    '		<div class="alert alert-warning" style="font-weight: bold">\n' +
    '			Alaska, Idaho, Montana, and Oregon Workers\' Compensation claims data is currently unavailable. Please contact Customer Service at 888-500-3344 for accurate claims details and/or loss runs.\n' +
    '		</div>\n' +
    '		<div loss-run-report></div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/makePayment.html',
    '<div class="modal-header">\n' +
    '    <h3 class="modal-title">Make a Payment</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <form name="makePaymentForm" novalidate>\n' +
    '        <div class="form-group" ng-class="{\'has-error\': errorMessage}">\n' +
    '            <label for="policySearch">To make a payment please enter a policy number.</label>\n' +
    '            <input type="text" class="form-control" id="policySearch" ng-model="policyQuery" placeholder="Enter policy number" typeahead="policy as policy.IPI_PolicyNumber for policy in searchPolicies(policyQuery)" typeahead-loading="loading" typeahead-no-results="noResults" typeahead-on-select="onPolicySelect($item)" typeahead-wait-ms="50" required>\n' +
    '            <span class="glyphicon glyphicon-refresh glyphicon-spin" ng-show="loading"></span>\n' +
    '            <div ng-show="noResults" class="text-danger">\n' +
    '                No results found.\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/massSubmission.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Mass Submission File Upload</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<iframe ng-src="{{ iframeSrc }}" frameborder="0">\n' +
    '			<p>Loading...</p>\n' +
    '		</iframe>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/quickEndorsement.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Endorsement Quick Search</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<table width="100%" class="table table-condensed">\n' +
    '			<thead>\n' +
    '				<th>To begin the endorsement process, please search for a policy to endorse. You can search by insured name or policy number.</th>\n' +
    '			</thead>\n' +
    '			<tbody>\n' +
    '				<tr>\n' +
    '					<td>\n' +
    '						<input type="text" class="endorsement-search" placeholder="Insured name or policy number" autocomplete autocomplete-html="\'html\'" autocomplete-min-length="3" autocomplete-source="lookup" autocomplete-select="select">\n' +
    '					</td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickLinks/template/template.html',
    '<div class="banner"><span>Quick Links</span></div>\n' +
    '<a class="quick-links-right" ng-click="openQuickEndorsement()">Quick Endorsement</a><br>\n' +
    '<a class="quick-links-right" ng-click="openMassSubmission()">Start Mass Submission</a><br>\n' +
    '<a class="quick-links-right" ng-click="openLossRunsReport()">Loss Runs</a><br>\n' +
    '<span ng-if="user.hasComponentPermissions(\'commissionReport\')">\n' +
    '	<a class="quick-links-right" ng-click="openCommissions()">View Commissions</a><br>\n' +
    '</span>\n' +
    '<a class="quick-links-right" ng-click="openAgencyBillingDocuments()">Agency Billing Documents</a><br>\n' +
    '<a class="quick-links-right" ng-click="openMarkelContacts()">Markel contacts</a><br>\n' +
    '<a class="quick-links-right" ng-click="addNewUser()">Add New User</a><br>\n' +
    '<a class="quick-links-right" ng-click="openMakePayment()">Make a Payment</a>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickSearch/template/quickSearchLabel.html',
    '<span ng-if="type === \'account\'">\n' +
    '	<strong ng-if="item.accountNumber">{{ item.accountNumber }}</strong>\n' +
    '	<strong ng-if="item.II_InsuredName">{{ item.II_InsuredName }}</strong>\n' +
    '	{{ item.II_DBAName }} <span class="piiMask">{{ item.II_FEIN }}</span> {{ item.II_MState }} {{ item.Demographics }}\n' +
    '</span>\n' +
    '<span ng-if="type === \'policy\'">\n' +
    '	<span class="label label-product" ng-if="item.productLine">{{ item.productLine == 1 ? "WC" : "BO" }}</span>\n' +
    '	<span class="label label-product" ng-if="!item.productLine && item.marketedProgram">{{ item.marketedProgram.abbreviation.toUpperCase() }}</span>\n' +
    '	<strong ng-if="item.IPI_PolicyNumber">{{ item.IPI_PolicyNumber }}</strong>\n' +
    '	<strong ng-if="item.II_InsuredName">{{ item.II_InsuredName }}</strong>\n' +
    '	{{ item.II_DBAName }} {{ item.IPI_EffectiveDate | date:"M/d/yyyy": \'UTC\' }} {{ item.IPI_PredominantState }} {{ renewalLabel }}\n' +
    '</span>\n' +
    '<span ng-if="type === \'luxon\'">\n' +
    '	<span class="label label-product">\n' +
    '		{{ item.label }}\n' +
    '	</span>\n' +
    '	<strong ng-if="item.insured_name">{{ item.insured_name }}</strong>\n' +
    '	{{ item.dba_name }} {{ item.effective_date | date:"M/d/yyyy": \'UTC\' }} {{ item.state_code }}\n' +
    '</span>\n' +
    '<span ng-if="type === \'claim\'">\n' +
    '	<span class="label label-product" ng-if="item.productLine">{{ item.productLine == 1 ? "WC" : "BO" }}</span>\n' +
    '	<span class="label label-product" ng-if="!item.productLine && item.marketedProgram">{{ item.marketedProgram.abbreviation.toUpperCase() }}</span>\n' +
    '	<strong ng-if="item.CP_ClaimantName">{{ item.CP_ClaimantName }}</strong>\n' +
    '	<strong ng-if="item.IC_ClaimNumber">{{ item.IC_ClaimNumber }}</strong>\n' +
    '	<em ng-if="item.CS_ClaimStatus">{{ item.CS_ClaimStatus }}</em>\n' +
    '	{{ item.IC_AccidentDate | date:"M/d/yyyy" : \'UTC\'}} {{ item.IC_StateBenefit }}\n' +
    '</span>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quickSearch/template/template.html',
    '<div class="search-box-container">\n' +
    '	<form class="form-search" id="searchForm">\n' +
    '		<input type="text" id="headerSearchInput" class="piiSafe" placeholder="Search submission, account, or policy" ng-model="search" ng-keyup="quickSearch()">\n' +
    '		<button type="button" class="delete-btn" ng-click="clearSearch()" ng-show="search.length > 0">&#10006;</button>\n' +
    '		<span class="search-icon"><i class="fa fa-search"></i></span>\n' +
    '		<div class="advanced-search"><a href="#/search/">Advanced Search</a></div>\n' +
    '	</form>\n' +
    '\n' +
    '	<div class="results-container" ng-if="searchItems.length > 0">\n' +
    '		<div ng-if="(searchItems | filter: {category: \'ACCOUNT\'}).length >0">\n' +
    '			<h3>ACCOUNT:</h3>\n' +
    '			<div ng-repeat="item in searchItems | filter: {category: \'ACCOUNT\'} " class="search-result">\n' +
    '				<span ng-click="select(item)" quick-search-label item="item"></span>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-if="(searchItems | filter: {category: \'SUBMISSION\'}).length >0">\n' +
    '			<h3>SUBMISSION:</h3>\n' +
    '			<div ng-repeat="item in searchItems | filter: {category: \'SUBMISSION\'} " class="search-result">\n' +
    '				<span ng-click="select(item)" quick-search-label item="item"></span>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-if="(searchItems | filter: {category: \'POLICY\'}).length >0">\n' +
    '			<h3>Policy:</h3>\n' +
    '			<div ng-repeat="item in searchItems | filter: {category: \'POLICY\'} " class="search-result">\n' +
    '				<span ng-click="select(item)" quick-search-label item="item"></span>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-if="(searchItems | filter: {category: \'ClAIM\'}).length >0">\n' +
    '			<h3>CLAIM:</h3>\n' +
    '			<div ng-repeat="item in searchItems | filter: {category: \'CLAIM\'} " class="search-result">\n' +
    '				<span ng-click="select(item)" quick-search-label item="item"></span>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div ng-if="(searchItems | filter: {category: \'No results found.\'}).length >0">\n' +
    '			<h3>No results found</h3>	\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/quoteWizard/template/template.html',
    '<div class="quote-wizard">\n' +
    '	<div class="row-fluid top-bar" ng-if="wizard_data.step > 0">\n' +
    '		<div class="center-container wizard">\n' +
    '			<div class="line row-fluid span12" ng-if="!account.isMobile">\n' +
    '				<div class="span3">\n' +
    '					<span class="dot"><span ng-if="wizard_data.step >= 2" class="glyphicon glyphicon-ok"></span></span>\n' +
    '				</div>\n' +
    '				<div class="span3">\n' +
    '					<span class="dot"><span ng-if="wizard_data.step >= 3" class="glyphicon glyphicon-ok"></span></span>\n' +
    '				</div>\n' +
    '				<div class="span3">\n' +
    '					<span class="dot"><span ng-if="wizard_data.step >= 4" class="glyphicon glyphicon-ok"></span></span>\n' +
    '				</div>\n' +
    '				<div class="span3">\n' +
    '					<span class="dot"><span ng-if="wizard_data.step >= 4" class="glyphicon glyphicon-ok"></span></span>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="row-fluid span12">\n' +
    '				<div class="wizard-step" ng-class="{\'span3\': !account.isMobile, \'span12\': account.isMobile}">\n' +
    '					<div class="wizard-content">\n' +
    '						<div class="step-banner">Step 1 <span ng-if="wizard_data.step >= 2  && account.isMobile" class="glyphicon glyphicon-ok"></span></div>\n' +
    '						<div class="step-product">Insured Information</div>\n' +
    '						\n' +
    '					</div>\n' +
    '				</div>\n' +
    '\n' +
    '				<div class="wizard-step" ng-class="{\'span3\': !account.isMobile, \'span12\': account.isMobile}">\n' +
    '					<div class="wizard-content">\n' +
    '						<div class="step-banner">Step 2 <span ng-if="wizard_data.step >= 3  && account.isMobile" class="glyphicon glyphicon-ok"></span></div>\n' +
    '						<div class="step-product" ng-if="wizard_data.maverick.wcBop">Workers Compensation</div>\n' +
    '						<div class="step-product" ng-if="wizard_data.maverick.bop">Business Owners</div>\n' +
    '						<div class="step-product" ng-if="wizard_data.maverick.miscErrorsOmissions">Miscellaneous E&O</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '\n' +
    '				<div class="wizard-step" ng-class="{\'span3\': !account.isMobile, \'span12\': account.isMobile}">\n' +
    '					<div class="wizard-content">\n' +
    '						<div class="step-banner">Step 3 <span ng-if="wizard_data.mPolicySkip">(N/A)</span><span ng-if="wizard_data.step >= 4 && account.isMobile" class="glyphicon glyphicon-ok"></span></div>\n' +
    '						<div class="step-product" ng-repeat="product in products">\n' +
    '							{{ product.title }}\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '\n' +
    '				<div class="wizard-step" ng-class="{\'span3\': !account.isMobile, \'span12\': account.isMobile}">\n' +
    '					<div class="wizard-content">\n' +
    '						<div class="step-banner">Step 4 <span ng-if="wizard_data.step >= 4  && account.isMobile" class="glyphicon glyphicon-ok"></span></div>\n' +
    '						<div class="step-product">Result</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/recentlyViewed/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<div class="big-banner big-banner-right">\n' +
    '			What you are working on...\n' +
    '		</div>\n' +
    '		<!-- tabset and tab are provided by ui bootstrap -->\n' +
    '		<tabset class="recentlyViewed">\n' +
    '			<tab heading="Recently Viewed">\n' +
    '				<div id="recentlyViewed">\n' +
    '					<table class="table table-bordered table-condensed pure">\n' +
    '						<tbody>\n' +
    '							<tr ng-repeat="account in recentlyViewed">\n' +
    '								<td>\n' +
    '									<div class="product-line">\n' +
    '										<span ng-hide="!account.productLine" product-label="account.productLine">{{ account.productLine }}</span>\n' +
    '									</div>\n' +
    '									<a ng-if="account.accountViewVersion === 1" ng-href="#/account/restore/{{ account.id }}">{{ account.name }}</a>\n' +
    '									<a ng-if="account.accountViewVersion !== 1" ng-href="#/account-summary/{{ account.id }}">{{ account.name }}</a>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '						</tbody>\n' +
    '					</table>\n' +
    '				</div>\n' +
    '			</tab>\n' +
    '			<tab heading="Renewals ({{ renewals.length }})">\n' +
    '				<div id="upcomingRenewals">\n' +
    '					<table class="table table-bordered table-condensed pure">\n' +
    '						<tbody>\n' +
    '							<tr class="renewalRow" ng-repeat="renewal in renewals">\n' +
    '								<td><a ng-if="renewal.accountViewVersion == 1" class="insuredName" ng-href="#/renewal/{{ renewal.quoteId }}">{{ renewal.insuredName }}</a>\n' +
    '									<a ng-if="renewal.accountViewVersion != 1" ng-href="#/account-summary/{{:: renewal.insuredid }}">{{ renewal.insuredName }}</a>\n' +
    '								</td>\n' +
    '								<td class="line">\n' +
    '									<span ng-hide="!renewal.productType" product-label="renewal.productType">{{ renewal.productType }}</span>\n' +
    '								</td>\n' +
    '								<td class="effectiveDate">{{ renewal.effectiveDate }}</td>\n' +
    '								<td class="renewalDocumentId">\n' +
    '									<a ng-if="renewal.renewalDocumentId && renewal.renewalDocumentId !== \'\'" ng-click="showRenewalDocument( renewal.renewalDocumentId )">\n' +
    '											<img src="assets/images/pdf-small.gif">\n' +
    '									</a>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '						</tbody>\n' +
    '					</table>\n' +
    '				</div>\n' +
    '			</tab>\n' +
    '			<tab heading="Pending Cancel ({{ pendingCancel.length }})">\n' +
    '				<div id="pendingCancel">\n' +
    '					<table class="table table-bordered table-condensed pure">\n' +
    '						<tbody>\n' +
    '						<tr class="renewalRow" ng-repeat="pendingCancels in pendingCancel">\n' +
    '							<td>\n' +
    '								<a ng-if="pendingCancels.accountViewVersion === 1" ng-href="#/account/restore/{{ pendingCancels.id }}">{{ pendingCancels.insuredName }}</a>\n' +
    '								<a ng-if="pendingCancels.accountViewVersion !== 1" ng-href="#/account-summary/{{ pendingCancels.id }}">{{ pendingCancels.insuredName }}</a>\n' +
    '							</td>\n' +
    '							<td class="line">\n' +
    '								<span product-label="pendingCancels.productType">{{ pendingCancels.productType }}</span>\n' +
    '							</td>\n' +
    '							<td class="effectiveDate">{{ pendingCancels.effectiveDate }}</td>\n' +
    '\n' +
    '						</tr>\n' +
    '						</tbody>\n' +
    '					</table>\n' +
    '				</div>\n' +
    '			</tab>\n' +
    '		</tabset>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/renewalsList/template/template.html',
    '<div class="banner-blue">Upcoming Renewals</div>\n' +
    '<table id="renewals" name="renewals" class="table table-striped table-bordered table-condensed">\n' +
    '	<thead>\n' +
    '		<tr>\n' +
    '			<th>Insured Name</th>\n' +
    '			<th>Line</th>\n' +
    '			<th>Effective Date</th>\n' +
    '		</tr>\n' +
    '	</thead>\n' +
    '	<tbody>\n' +
    '		<tr class="renewals" ng-repeat="renewal in renewals">\n' +
    '			<td>hey\n' +
    '				<a ng-if="renewal.accountViewVersion != 1 " class="insuredName" ng-href="#/renewal/{{ renewal.quoteId }}">{{ renewal.insuredName }}</a>\n' +
    '				<a ng-if="renewal.accountViewVersion != 1" ng-href="#/account-summary/{{:: renewal.insuredid }}">{{ renewal.insuredName }}</a>\n' +
    '			</td>\n' +
    '			<td class="line">\n' +
    '				<span product-label="renewal.productType">{{ renewal.productType }}</span>\n' +
    '			</td>\n' +
    '			<td class="effectiveDate">{{ renewal.effectiveDate }}</td>\n' +
    '		</tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/rewardsSummary/template/template.html',
    '<div ng-if="rewardsSummary.hasAccess">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<div class="banner">Rewards</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span6"><img ng-src="{{ maverickApiUrl }}?action=rewards.getpointschart"></div>\n' +
    '		<div class="span6">\n' +
    '			<table>\n' +
    '				<tr><td colspan="2">&nbsp;</td></tr>\n' +
    '				<tr>\n' +
    '					<td>Paid to Date:</td>\n' +
    '					<td align="right" id="paidWinnings">{{ rewardsSummary.redemptionValueToDate | formatMoney }}</td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<td>Current Points:</td>\n' +
    '					<td align="right" id="currentPoints">{{ rewardsSummary.currentPoints }}</td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<td>Points for Next:</td>\n' +
    '					<td align="right" id="pointsNeeded">{{ rewardsSummary.pointsNeededForNextReward }}</td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<td>Points Redeemable:</td>\n' +
    '					<td align="right" id="pointsAvailable">{{ rewardsSummary.pointsAvailableToRedeem }}</td>\n' +
    '				</tr>\n' +
    '				<tr>\n' +
    '					<td>Redeemable $:</td>\n' +
    '					<td align="right" id="unpaidWinnings">{{ rewardsSummary.amountAvailableToRedeem | formatMoney }}</td>\n' +
    '				</tr>\n' +
    '				<tr ng-if="rewardsSummary.pointsRedeemable">\n' +
    '					<td colspan="2" id="redeemPointsButtonContainer">\n' +
    '						<br>\n' +
    '						<a href="#/rewards/redeem" class="btn btn-success" id="redeemPointsButton">Redeem</a>\n' +
    '					</td>\n' +
    '				</tr>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<table id="rewardsHistory" class="table table-bordered table-condensed">\n' +
    '				<thead>\n' +
    '					<tr>\n' +
    '						<th align="left">Date</th>\n' +
    '						<th align="left">Activity</th>\n' +
    '						<th align="left">Points</th>\n' +
    '					</tr>\n' +
    '				</thead>\n' +
    '				<tbody>\n' +
    '					<tr class="rewardsHistory" ng-repeat="history in rewardsSummary.rewardsHistory.TRANSACTIONHISTORY">\n' +
    '						<td align="left" class="rewardDate">{{ history.CREATEDATE }}</td>\n' +
    '						<td align="left" class="rewardActivity">{{ history.ACTIVITY }}</td>\n' +
    '						<td align="right" class="rewardPoints">{{ history.POINTS }}</td>\n' +
    '					</tr>\n' +
    '				</tbody>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/startQuote/template/productLineCheck.html',
    '<div class="checkContainer" ng-show="$parent.hasProductLineAvailable( line.productLine )">\n' +
    '	<input type="checkbox" id="{{ line.productLine }}" class="piiSafe" ng-model="line.enabled" ng-change="$parent.disableLine( line.productLine )">\n' +
    '	<label for="{{ line.productLine }}" class="inline-label">\n' +
    '		{{ label }}\n' +
    '	</label>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/startQuote/template/productLineClassCodeRow.html',
    '<div class="row-fluid" ng-if="line.enabled">\n' +
    '	<!-- offsets don\'t work with row-fluid in our version of bootstrap, so we have to drop an empty div here to push the row over -->\n' +
    '	<div class="span2">&nbsp;</div>\n' +
    '	<div class="span10 offset2">\n' +
    '		<div class="row-fluid">\n' +
    '			<div class="span3">\n' +
    '				<h3 class="pushDownLOB">{{ rowTitle }}</h3>\n' +
    '			</div>\n' +
    '			<div class="span8">\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span12" ng-if="!productLineCheck()">\n' +
    '						<label for="{{ line.productLine }}ClassCode">Business Type / Class Code</label>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span5 eoEligibility" ng-if="productLineCheck()">\n' +
    '						<label for="{{ line.productLine }}ClassCode">Business Type</label>\n' +
    '					</div>\n' +
    '					<div class="span5" ng-if="productLineCheck() && uiState.showDescriptions">\n' +
    '						<label for="{{ line.productLine }}ClassCode">Classification</label>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span4" ng-if="!productLineCheck()">\n' +
    '						<input type="text" id="{{ line.productLine }}ClassCode" class="span12 piiSafe" ng-model="line.classCode" autocomplete autocomplete-min-length="2" autocomplete-source="classCodes" autocomplete-html="true" autocomplete-select="selectDescription" ng-change="updateBusinessType()">\n' +
    '					</div>\n' +
    '					<div class="span5 eoEligibility" ng-if="productLineCheck()">\n' +
    '						<select name="group" id="{{ line.productLine }}Group" class="groupResolution span10 piiSafe" ng-model="line.group" ng-options="item.label for item in groups" ng-change="filterByBusinessGroup()">\n' +
    '						</select>\n' +
    '					</div>\n' +
    '					<div class="span5 eoEligibility" ng-if="productLineCheck() && uiState.showDescriptions">\n' +
    '						<select name="group" id="{{ line.productLine }}Description" class="groupResolution span10 piiSafe" ng-model="line.classCode" ng-options="item.label for item in desc" style="width: 322px;">\n' +
    '						</select>\n' +
    '					</div>\n' +
    '					<div ng-class="line.productLine == \'eo\'? \'span6 eligibilityMessages\' : \'span8 eligibilityMessages\'">\n' +
    '						<div class="alert alert-small alert-extra-padding alert-error" ng-show="line.messages.validation">\n' +
    '							{{ line.messages.validation }}\n' +
    '						</div>\n' +
    '						<div class="alert alert-small alert-extra-padding alert-error" ng-show="line.messages.failed">\n' +
    '							{{ line.messages.failed }}\n' +
    '						</div>\n' +
    '						<div class="alert alert-small alert-extra-padding alert-success" ng-show="line.messages.passed">\n' +
    '							{{ line.messages.passed }}\n' +
    '						</div>\n' +
    '						<div class="alert alert-small alert-extra-padding alert-info" ng-show="line.messages.checking">\n' +
    '							<img src="assets/images/small-ajax-loader.gif"> Checking...\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span12 classCodeDescription" ng-if="uiState.showGuidelines && !line.classCode.label">\n' +
    '						<span class="help-inline">After entering the code, select the appropriate description</span>\n' +
    '					</div>\n' +
    '					<div class="span12 classCodeDescription" ng-show="line.classCode.label" ng-if="!productLineCheck() && !line.group">\n' +
    '						<span ng-bind-html="line.classCode.label"></span>\n' +
    '				</div>\n' +
    '				<div id="programBlock" ng-if="programs.length">\n' +
    '					<div class="row-fluid">\n' +
    '						<div class="span12">\n' +
    '							<label for="{{ line.productLine }}Program">Program</label>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<div class="row-fluid">\n' +
    '						<div class="span12">\n' +
    '							<select id="{{ line.productLine }}Program" ng-options="program.ID as program.NAME for program in programs" ng-model="line.program"></select>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div id="guidelinesBlock" ng-if="uiState.showGuidelines">\n' +
    '					<div class="row-fluid">\n' +
    '						<div class="span12" ng-if="uiState.loading">\n' +
    '							<img class="loader" src="/assets/images/ajax-loader-grey.gif">\n' +
    '						</div>\n' +
    '						<div class="span12" ng-if="!uiState.loading && uiState.guidelines && uiState.guidelines.length && line.classCode.label">\n' +
    '							<p><strong>The BOP eligibility guidelines are as follows:</strong></p>\n' +
    '							<div ng-repeat="category in uiState.guidelines">\n' +
    '								<p><strong>{{:: category.category }}</strong></p>\n' +
    '								<p ng-if="category.guidelines.length == 1">\n' +
    '									{{:: category.guidelines[ 0 ].content }}\n' +
    '								</p>\n' +
    '								<ul ng-if="category.guidelines.length > 1">\n' +
    '									<li ng-repeat="guideline in category.guidelines">\n' +
    '										{{:: guideline.content }}\n' +
    '									</li>\n' +
    '								</ul>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/startQuote/template/template.html',
    '<div class="start-quote">\n' +
    '	<form>\n' +
    '		<div class="row-fluid">\n' +
    '			<div class="headline pushDown span2">Start Quote</div>\n' +
    '			<div class="span7">\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span3">\n' +
    '						<label class="inline-top-label" for="effectiveDate">Effective Date</label>\n' +
    '					</div>\n' +
    '					<div class="span3">\n' +
    '						<label class="inline-top-label" for="state">State</label>\n' +
    '					</div>\n' +
    '					<div class="span6">\n' +
    '						<label class="inline-top-label">Product Line</label>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span3">\n' +
    '						<input type="text" class="span10 piiSafe" id="effectiveDate" ng-model="selected.effectiveDate" ng-change="resetLines()" date-picker date-picker-on-select="loadPrograms" date-picker-min-date="0" date-picker-defaultdate="+0">\n' +
    '					</div>\n' +
    '					<div class="span3">\n' +
    '						<select class="span10 piiSafe" id="state" ng-model="selected.state" ng-options="state.abbreviation as state.label for state in states" ng-change="resetLines()">\n' +
    '						</select>\n' +
    '					</div>\n' +
    '					<div class="span6 productLineChecks">\n' +
    '						<div product-line-check label="Workers\' Compensation" line="lines.wc"></div>\n' +
    '						<div product-line-check label="Business Owners Policy" line="lines.bo"></div>\n' +
    '						<div product-line-check label="Miscellaneous E&O" line="lines.eo"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div class="span3">\n' +
    '				<div class="pushDown" id="buttonWrapper">\n' +
    '					<button type="submit" ng-disabled="areButtonsDisabled()" class="btn btn-success" id="continueQuote" ng-click="continueQuote()">Continue</button>\n' +
    '					&nbsp;or&nbsp;\n' +
    '					<button type="submit" ng-disabled="areButtonsDisabled()" class="btn" id="checkEligibility" ng-click="checkEligibility()">Check Eligibility</button>\n' +
    '					<i id="buttonHelper" class="icon-info-sign" ng-show="areButtonsDisabled()" popover-title="Why are the buttons disabled?" popover="Select a Product Line to continue." popover-placement="top" popover-trigger="mouseenter" popover-popup-delay="500">\n' +
    '					</i>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<div product-line-class-code-row row-title="Workers\' Compensation" line="lines.wc" class-codes="wcClassCodes" groups="eoGroups" classifications="eoDescriptions" programs="programs.wc" effective-date="selected.effectiveDate" state="selected.state" product-lines="lines"></div>\n' +
    '		<div product-line-class-code-row row-title="Business Owners Policy" line="lines.bo" class-codes="boClassCodes" groups="eoGroups" classifications="eoDescriptions" programs="programs.bo" effective-date="selected.effectiveDate" state="selected.state" product-lines="lines"></div>\n' +
    '		<div product-line-class-code-row row-title="Miscellaneous E&O" class="eoEligibility" line="lines.eo" class-codes="eoDescriptions" groups="eoGroups" classifications="eoDescriptions" programs="programs.eo" effective-date="selected.effectiveDate" state="selected.state" product-lines="lines"></div>\n' +
    '		<div class="row-fluid">\n' +
    '			<!-- offsets don\'t work with row-fluid in our version of bootstrap, so we have to drop an empty div here to push the row over -->\n' +
    '			<div class="span8">\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span12 showEoEligible hidden" style="margin-left:57%">\n' +
    '						<span class="link-strong">This classification may qualify for Miscellaneous E&O coverage.  Please select the Miscellaneous E&O check box above to see the available business types.</span>\n' +
    '					</div>\n' +
    '					<div class="span12 showEoError hidden" style="margin-left:57%">\n' +
    '						<span class="link-strong">Miscellaneous E&O is not available for this classification please remove the E&O selection above. </span>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</form>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/startQuoteCommercialPackage/template/template.html',
    '<div class="start-quote" id="commercial-package">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="headline pushDown">Start Commercial Package Quote</div>\n' +
    '		<div class="span7">\n' +
    '			<div class="row-fluid">\n' +
    '				<div class="span3">\n' +
    '					<label class="inline-top-label" for="start-quote-commercial-package-businessType">Business Type</label>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div class="row-fluid">\n' +
    '				<div class="span3">\n' +
    '					<select class="span12 piiSafe" id="start-quote-commercial-package-businessType" class="piiSafe" ng-model="selectedProgram" ng-options="program as program.name for program in programs">\n' +
    '					</select>\n' +
    '				</div>\n' +
    '				<div class="span3">\n' +
    '					<button type="button" class="btn btn-success" id="commercial-package-continue" ng-disabled="!isContinueEnabled()" ng-click="continue()">\n' +
    '							Continue\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/startQuoteDropdown/template/template.html',
    '<!-- wc/bop only: just show the nav link -->\n' +
    '<a ng-if="wcBopOnly" id="startQuote" href="#/quote" class="navPrimaryLink">Start Quote</a>\n' +
    '\n' +
    '<!-- commercial programs: show a dropdown -->\n' +
    '<a ng-if="!wcBopOnly" id="startQuote" class="navPrimaryLink">Start Quote</a>\n' +
    '<div id="quoteSubMenu" ng-if="!wcBopOnly">\n' +
    '	<span ng-repeat="link in navElements">\n' +
    '		<a ng-href="{{ link.href }}">{{ link.label }}</a>\n' +
    '	</span>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/stateImageMap/template/template.html',
    '<div class="span6 well">\n' +
    '	<div class="span11">\n' +
    '		<img class="map" map-hilight src="assets/images/US_Map2.png" width="521" height="286" usemap="#usa" border="no">\n' +
    '		<map name="usa">\n' +
    '			<area ng-href="{{ url }}AL" ng-click="onSelect( \'AL\' )" title="Alabama" class="reisState" shape="poly" coords="344,172,344,184,344,193,344,201,343,206,344,211,345,215,346,221,347,222,348,220,349,219,351,219,352,221,355,222,355,221,355,218,354,215,366,213,372,213,376,213,380,212,381,211,379,206,379,201,379,198,376,193,374,186,373,183,372,178,370,175,369,171,369,170,344,172">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="93,262,96,260,98,259,99,260,101,263,104,266,107,269,109,272,112,273,114,273,116,277,115,279,113,279,112,278,110,278,110,280,106,278,106,276,104,274,102,271,101,274,98,270,97,267,95,266,93,265,91,262,89,261,86,259,81,259,77,258,74,257,72,256,70,254,66,254,67,257,67,258,62,257,59,259,57,259,57,256,59,254,61,253,62,252,59,251,57,252,55,254,53,256,51,258,49,260,50,262,47,264,40,267,35,270,29,270,26,271,24,272,20,272,15,272,16,270,27,269,35,267,38,265,41,261,41,259,37,259,34,257,30,256,30,253,30,251,27,250,26,249,25,243,26,240,30,236,35,237,40,237,40,235,40,230,37,230,32,231,30,229,30,226,29,224,30,223,36,222,39,225,43,226,44,223,41,221,38,216,37,213,40,212,44,211,46,209,50,208,55,207,57,206,60,207,61,210,63,209,65,211,68,212,70,212,71,213,74,214,80,214,83,217,83,222,84,243,84,256,89,257,91,260,93,262">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="41,486, 43,491, 43,492, 40,491, 39,488, 37,486, 35,486, 34,484, 36,481, 37,484, 39,485, 41,486">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="39,518, 42,519, 46,520, 47,521, 45,524, 42,524, 39,521, 39,518">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="19,504, 20,507, 21,508, 20,509, 18,506, 18,504, 19,504">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="5,575, 9,573, 12,572, 14,572, 15,574, 17,574, 19,572, 18,571, 21,570, 24,573, 23,574, 19,576, 16,575, 12,574, 8,575, 7,576, 5,575">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="53,571, 54,573, 56,571, 55,570, 53,571">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="56,574, 57,571, 59,572, 58,574, 56,574">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="78,572, 80,573, 81,572, 80,571, 78,572">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="87,560, 88,565, 91,566, 96,563, 100,561, 98,558, 99,556, 97,557, 94,557, 95,556, 97,556, 101,555, 102,553, 99,552, 100,551, 97,552, 93,556, 88,559, 87,560">\n' +
    '			<area ng-href="{{ url }}AK" ng-click="onSelect( \'AK\' )" title="Alaska" class="reisState" shape="poly" coords="128,541, 130,539, 129,537, 127,538, 128,541">\n' +
    '			<area ng-href="{{ url }}AZ" ng-click="onSelect( \'AZ\' )" title="Arizona" class="reisState" shape="poly" coords="89,140,90,143,89,146,88,149,87,150,85,149,85,149,83,148,81,148,80,149,80,152,80,154,79,156,79,158,79,161,80,163,81,166,81,168,79,169,78,171,76,173,75,175,74,176,74,178,74,179,74,180,75,181,75,183,73,184,71,185,76,187,80,189,84,191,87,194,89,195,91,196,97,198,100,200,104,202,108,204,117,205,122,206,127,207,129,206,129,200,131,187,134,175,135,162,137,150,137,147,132,145,124,144,116,143,108,142,99,140,93,139,89,140">\n' +
    '			<area ng-href="{{ url }}AR" ng-click="onSelect( \'AR\' )" title="Arkansas" class="reisState" shape="poly" coords="279,159,279,161,280,165,280,169,280,173,280,177,280,182,280,189,280,189,282,191,284,191,285,196,294,196,303,196,306,195,308,195,311,195,315,195,316,194,314,190,314,187,314,184,316,183,319,179,321,178,321,176,323,173,324,171,324,169,326,167,327,164,326,163,321,163,320,162,322,160,323,159,322,158,317,158,309,159,294,158,290,158,288,159,279,159">\n' +
    '			<area ng-href="{{ url }}CA" ng-click="onSelect( \'CA\' )" title="California" class="reisState" shape="poly" coords="12,68,11,74,10,76,6,81,4,83,5,87,6,88,6,90,6,96,6,99,6,101,7,103,8,105,9,106,10,108,10,110,9,111,11,112,11,113,12,115,12,116,11,117,11,123,12,124,14,124,15,125,16,126,16,127,13,129,13,130,14,133,18,141,19,143,21,146,20,148,20,150,19,153,20,154,22,154,24,154,26,156,28,156,29,156,31,159,33,161,35,162,36,162,38,163,39,164,39,165,41,166,42,166,43,168,45,170,47,172,47,179,51,181,56,181,65,183,70,184,73,184,74,183,75,182,74,179,73,178,75,177,76,176,76,173,78,171,79,170,81,169,82,167,80,164,79,159,63,139,57,131,48,120,41,111,39,109,42,100,44,91,46,84,47,78,39,74,25,71,12,68">\n' +
    '			<area ng-href="{{ url }}CO" ng-click="onSelect( \'CO\' )" title="Colorado" class="reisState" shape="poly" coords="144,104,142,116,141,125,139,133,137,146,144,147,159,149,168,149,176,151,183,151,196,151,205,152,206,151,207,135,208,123,208,109,199,108,192,108,168,106,160,105,144,104">\n' +
    '			<area ng-href="{{ url }}CT" ng-click="onSelect( \'CT\' )" title="Connecticut" class="reisState" shape="poly" coords="482,77,479,77,474,78,471,79,469,80,469,82,470,85,470,87,470,89,474,88,475,85,479,85,481,85,483,84,484,80,482,77">\n' +
    '			<area ng-href="{{ url }}DE" ng-click="onSelect( \'DE\' )" title="Delaware" class="reisState" shape="poly" coords="455,110,455,114,456,117,457,118,457,119,459,119,460,119,461,119,462,119,463,119,462,117,460,115,459,114,458,112,457,111,455,110">\n' +
    '			<area ng-href="{{ url }}FL" ng-click="onSelect( \'FL\' )" title="Florida" class="reisState" shape="poly" coords="354,215,355,220,355,222,360,221,363,220,367,220,371,221,375,222,378,225,380,227,382,226,387,224,388,222,389,221,396,222,398,224,401,226,404,228,407,229,408,231,408,234,409,240,409,243,411,243,412,242,413,242,412,244,412,246,413,248,415,251,417,254,418,256,419,256,420,256,422,258,423,261,429,263,432,265,433,268,435,268,438,267,440,266,441,263,442,260,441,255,441,251,438,245,433,239,432,237,431,233,428,229,420,218,418,212,415,211,412,211,411,215,405,213,392,214,382,214,376,213,364,213,360,214,355,213,354,215">\n' +
    '			<area ng-href="{{ url }}GA" ng-click="onSelect( \'GA\' )" title="Georgia" class="reisState" shape="poly" coords="381,214,386,215,390,215,394,215,397,214,401,214,406,215,409,215,412,215,413,216,413,215,412,214,412,212,417,212,417,212,418,207,419,202,421,198,420,196,417,193,414,189,410,185,407,184,406,181,403,179,400,178,398,175,395,173,393,172,392,171,392,170,392,169,390,169,379,170,373,170,369,171,369,174,373,181,375,188,377,194,379,199,380,207,381,214">\n' +
    '			<area ng-href="{{ url }}HI" ng-click="onSelect( \'HI\' )" title="Hawaii" class="reisState" shape="poly" coords="133,247,135,247,136,246,137,244,134,243,133,247">\n' +
    '			<area ng-href="{{ url }}HI" ng-click="onSelect( \'HI\' )" title="Hawaii" class="reisState" shape="poly" coords="152,253,155,254,155,252,154,250,151,249,150,251">\n' +
    '			<area ng-href="{{ url }}HI" ng-click="onSelect( \'HI\' )" title="Hawaii" class="reisState" shape="poly" coords="161,254,164,254,166,254,164,256,161,254">\n' +
    '			<area ng-href="{{ url }}HI" ng-click="onSelect( \'HI\' )" title="Hawaii" class="reisState" shape="poly" coords="168,258,171,261,174,261,175,259,172,257,169,256">\n' +
    '			<area ng-href="{{ url }}HI" ng-click="onSelect( \'HI\' )" title="Hawaii" class="reisState" shape="poly" coords="177,264,177,267,174,270,176,272,177,274,177,276,180,277,181,275,182,274,184,274,187,273,189,273,189,271,186,269,185,268,181,266,177,264">\n' +
    '			<area ng-href="{{ url }}ID" ng-click="onSelect( \'ID\' )" title="Idaho" class="reisState" shape="poly" coords="93,10,91,21,89,29,87,34,86,38,85,40,86,41,87,42,88,44,89,45,87,48,83,51,81,55,79,56,79,57,80,58,81,60,80,62,79,63,78,66,77,73,75,80,75,82,101,87,128,91,129,82,131,74,133,68,133,64,133,62,131,61,130,61,128,63,127,64,125,64,124,63,124,62,122,63,121,63,120,63,118,63,117,63,116,61,116,59,114,58,112,55,112,52,111,48,108,49,107,50,106,50,106,47,107,43,109,40,109,38,107,36,106,35,104,33,103,31,101,29,100,26,101,24,100,22,100,19,101,16,101,15,101,11,93,10">\n' +
    '			<area ng-href="{{ url }}IL" ng-click="onSelect( \'IL\' )" title="Illinois" class="reisState" shape="poly" coords="311,111,310,113,309,114,307,115,306,118,306,121,312,128,315,128,315,130,316,131,320,132,321,133,320,135,319,137,319,140,320,141,322,142,324,143,325,143,326,145,327,145,328,147,328,150,329,151,332,151,334,150,337,149,340,146,341,143,341,140,342,137,344,135,345,132,345,130,344,126,344,121,344,117,343,108,343,104,342,100,340,98,338,96,338,92,334,92,330,92,323,94,318,94,315,95,316,97,317,100,316,103,313,105,311,105,310,106">\n' +
    '			<area ng-href="{{ url }}IN" ng-click="onSelect( \'IN\' )" title="Indiana" class="reisState" shape="poly" coords="343,103,345,102,347,100,349,99,357,99,363,99,367,99,368,102,368,105,369,113,370,120,370,125,370,127,369,130,368,131,366,131,365,133,363,134,362,136,361,138,359,139,356,139,355,140,353,141,350,141,346,140,344,142,342,141,341,138,343,136,345,133,345,130,345,121,345,116,344,112,343,103">\n' +
    '			<area ng-href="{{ url }}IA" ng-click="onSelect( \'IA\' )" title="Iowa" class="reisState" shape="poly" coords="259,85,270,84,275,84,285,84,296,84,306,84,307,84,308,86,308,89,308,91,310,91,311,92,312,92,312,94,314,95,315,96,317,98,317,101,316,103,314,105,310,106,310,108,311,110,311,112,310,114,308,116,306,116,304,114,301,114,289,114,289,115,267,114,266,113,265,108,264,105,263,102,261,99,261,96,260,94,259,93,259,92,260,89,261,88,259,85">\n' +
    '			<area ng-href="{{ url }}KS" ng-click="onSelect( \'KS\' )" title="Kansas" class="reisState" shape="poly" coords="208,120,208,125,208,130,207,133,207,141,206,148,206,152,211,152,216,152,222,153,227,153,230,153,242,153,249,154,264,154,270,154,275,154,278,154,278,151,278,142,278,139,279,137,278,133,276,130,275,129,274,127,274,125,274,124,271,122,269,120,228,120,222,120,220,120,216,119,208,120">\n' +
    '			<area ng-href="{{ url }}KY" ng-click="onSelect( \'KY\' )" title="Kentucky" class="reisState" shape="poly" coords="331,156,331,153,333,151,334,150,338,149,339,149,340,145,341,143,342,142,346,141,348,141,351,141,351,141,353,141,355,141,356,139,357,139,359,139,361,139,362,136,364,134,366,133,369,130,371,129,371,127,374,127,375,127,376,128,378,129,382,129,386,129,389,128,390,129,392,131,393,132,393,134,395,136,399,139,399,141,397,143,395,145,391,148,388,150,386,151,379,152,370,152,358,154,357,154,348,154,348,155,344,156,343,157,331,156">\n' +
    '			<area ng-href="{{ url }}LA" ng-click="onSelect( \'LA\' )" title="Louisiana" class="reisState" shape="poly" coords="284,196,289,196,296,196,302,196,307,196,309,195,313,195,314,195,314,197,314,199,316,200,316,202,316,204,316,205,313,208,313,210,312,212,311,214,311,216,311,217,313,217,320,217,322,217,325,216,328,216,330,216,330,218,330,219,331,220,332,221,333,224,332,226,330,227,332,228,334,228,334,227,335,227,335,227,335,228,334,230,332,231,333,232,336,233,338,234,339,236,337,237,336,236,334,234,332,233,331,233,329,234,329,236,328,237,326,235,325,235,324,236,322,237,319,237,317,236,317,234,314,233,313,231,307,231,308,232,309,233,306,233,302,232,297,231,292,230,288,231,288,228,288,224,289,219,290,217,289,214,285,206,284,203,284,196">\n' +
    '			<area ng-href="{{ url }}ME" ng-click="onSelect( \'ME\' )" title="Maine" class="reisState" shape="poly" coords="480,42,481,45,483,50,484,54,486,59,487,61,489,62,490,60,491,56,492,54,493,53,495,53,496,52,498,51,499,49,499,46,501,47,502,49,504,45,505,43,506,44,509,43,512,40,514,38,514,35,512,35,510,35,509,34,508,33,508,31,506,31,504,31,501,21,499,17,497,15,494,15,492,15,491,17,489,17,488,16,487,16,486,20,484,23,484,29,484,33,482,37,480,42">\n' +
    '			<area ng-href="{{ url }}MD" ng-click="onSelect( \'MD\' )" title="Maryland" class="reisState" shape="poly" coords="460,126,462,124,464,121,462,119,459,119,457,120,456,118,455,114,455,111,454,110,454,109,451,108,446,108,440,110,435,110,431,112,428,112,424,113,420,114,420,116,421,117,425,115,427,114,429,113,432,114,433,115,435,114,438,115,443,117,445,118,444,123,445,124,451,125,451,125,450,121,449,118,449,115,450,112,451,112,452,116,453,120,453,122,455,123,457,124,460,126">\n' +
    '			<area ng-href="{{ url }}MA" ng-click="onSelect( \'MA\' )" title="Massachusetts" class="reisState" shape="poly" coords="468,72,473,71,477,70,479,69,481,69,485,68,486,66,487,65,488,64,488,67,489,70,489,71,491,72,492,73,493,75,494,76,496,76,498,76,499,74,499,74,500,75,500,77,497,77,495,78,493,78,492,78,492,80,491,80,489,79,486,77,482,77,478,77,475,78,471,79,468,79,468,72">\n' +
    '			<area ng-href="{{ url }}MI" ng-click="onSelect( \'MI\' )" title="Michigan" class="reisState" shape="poly" coords="335,66,338,63,340,59,341,57,345,56,348,55,352,53,355,53,361,53,365,52,367,52,365,49,362,48,360,48,358,48,358,46,353,46,347,48,346,49,342,50,338,49,335,47,332,46,329,46,327,46,330,44,332,42,333,40,329,40,329,41,326,44,325,45,320,48,315,50,315,51,318,53,322,54,326,55,329,56,332,57,334,58,335,62,335,66">\n' +
    '			<area ng-href="{{ url }}MI" ng-click="onSelect( \'MI\' )" title="Michigan" class="reisState" shape="poly" coords="349,99,356,99,364,99,377,98,380,97,380,95,382,93,382,89,382,87,385,87,386,87,385,83,384,79,383,76,382,73,380,72,378,72,376,74,376,76,374,78,373,78,373,75,374,71,376,68,376,66,375,63,373,60,370,58,367,58,364,56,360,56,359,57,360,59,360,60,358,61,357,65,357,66,355,66,355,64,355,63,352,66,350,71,349,76,350,81,352,84,353,88,353,92,349,99">\n' +
    '			<area ng-href="{{ url }}MN" ng-click="onSelect( \'MN\' )" title="Minnesota" class="reisState" shape="poly" coords="255,26,255,29,255,32,254,34,255,36,256,37,257,40,258,42,258,45,258,46,258,48,258,51,259,52,259,55,259,57,259,59,258,60,258,62,258,64,260,65,261,66,260,84,262,84,272,84,298,84,306,84,307,83,307,82,307,80,305,79,302,77,300,76,297,74,294,72,292,70,293,67,293,65,294,63,294,62,292,60,292,58,294,58,296,56,297,53,297,51,298,48,301,46,304,44,305,41,307,40,312,38,315,37,317,36,315,35,311,34,307,33,305,34,303,35,301,35,299,33,297,33,293,32,291,31,290,30,287,30,286,31,283,31,281,31,278,30,276,28,275,26,274,24,272,23,271,26,264,26,255,26">\n' +
    '			<area ng-href="{{ url }}MS" ng-click="onSelect( \'MS\' )" title="Mississippi" class="reisState" shape="poly" coords="323,173,321,175,320,178,318,180,316,184,314,186,314,192,313,197,314,198,315,200,316,202,314,208,312,211,311,215,311,216,314,217,320,217,324,217,324,216,327,216,329,216,330,216,330,218,331,219,331,221,332,222,333,224,334,223,336,222,340,222,345,222,345,216,345,210,343,207,344,187,344,172,343,172,339,173,334,173,327,173,323,173">\n' +
    '			<area ng-href="{{ url }}MO" ng-click="onSelect( \'MO\' )" title="Missouri" class="reisState" shape="poly" coords="266,115,268,118,269,120,271,122,273,123,274,124,274,126,275,128,276,130,277,132,278,134,278,138,278,142,278,156,279,159,284,160,289,160,290,158,295,158,302,158,303,160,309,159,310,158,315,158,322,158,323,159,322,161,322,163,325,163,327,163,327,160,329,158,329,157,331,155,332,153,331,152,329,151,328,150,328,147,325,144,322,142,319,140,319,136,320,134,321,133,319,131,315,131,314,129,312,127,309,125,307,123,307,117,306,116,303,114,294,114,286,115,280,115,266,115">\n' +
    '			<area ng-href="{{ url }}MT" ng-click="onSelect( \'MT\' )" title="Montana" class="reisState" shape="poly" coords="101,12,101,15,100,18,100,21,100,23,101,24,101,26,99,26,100,29,102,30,104,33,107,36,109,36,109,38,108,41,107,43,106,44,106,46,106,48,106,50,107,50,109,48,110,48,111,48,111,51,111,53,113,56,115,58,116,60,117,62,119,62,121,62,124,62,126,63,128,62,130,61,132,61,135,59,141,60,146,61,151,61,162,62,172,63,183,64,190,65,194,66,195,62,196,46,197,40,197,34,197,29,198,24,192,24,186,23,172,22,153,20,137,18,122,16,112,14,101,12">\n' +
    '			<area ng-href="{{ url }}NE" ng-click="onSelect( \'NE\' )" title="Nebraska" class="reisState" shape="poly" coords="192,87,192,94,191,99,190,102,190,108,200,108,208,109,208,120,219,120,223,121,232,121,238,121,270,121,269,120,268,118,267,116,266,113,265,109,265,105,263,102,262,97,260,94,258,93,256,92,254,91,252,91,250,91,248,92,247,92,244,91,242,89,224,89,218,88,216,87,208,87,201,87,200,86,196,86,192,87">\n' +
    '			<area ng-href="{{ url }}NV" ng-click="onSelect( \'NV\' )" title="Nevada" class="reisState" shape="poly" coords="47,77,55,78,65,81,72,82,75,82,82,84,88,84,93,85,99,87,101,87,100,92,98,96,98,101,97,109,95,118,93,123,92,130,91,133,90,134,90,138,89,144,88,147,87,149,85,149,84,149,83,149,82,148,80,148,80,151,80,154,79,157,79,159,77,156,72,151,64,141,53,127,39,109,47,77">\n' +
    '			<area ng-href="{{ url }}NH" ng-click="onSelect( \'NH\' )" title="New Hampshire" class="reisState" shape="poly" coords="477,43,477,46,477,48,477,49,477,50,476,52,474,53,473,54,473,60,474,63,474,67,474,69,474,71,477,70,479,69,482,68,485,68,486,66,488,65,488,63,488,61,486,59,485,56,484,53,483,50,482,47,480,45,479,41,477,43">\n' +
    '			<area ng-href="{{ url }}NJ" ng-click="onSelect( \'NJ\' )" title="New Jersey" class="reisState" shape="poly" coords="458,89,458,92,457,94,458,97,460,99,461,101,459,103,457,106,456,108,457,109,459,110,461,111,463,112,464,113,465,111,466,109,468,107,469,104,469,101,468,99,466,97,466,94,467,92,466,90,463,89,458,89">\n' +
    '			<area ng-href="{{ url }}NM" ng-click="onSelect( \'NM\' )" title="New Mexico" class="reisState" shape="poly" coords="137,147,148,147,159,149,164,149,171,150,176,150,180,151,196,151,193,185,193,192,192,197,192,202,191,204,191,205,166,203,161,203,155,202,154,203,154,204,151,204,148,204,146,203,144,203,142,203,140,202,139,202,137,202,137,205,137,207,129,206,129,204,129,196,130,190,131,184,133,175,134,168,135,157,137,147">\n' +
    '			<area ng-href="{{ url }}NY" ng-click="onSelect( \'NY\' )" title="New York" class="reisState" shape="poly" coords="468,90,466,90,462,89,459,88,456,87,455,85,452,84,446,83,441,85,429,88,421,88,416,89,412,89,412,87,415,84,417,83,419,81,419,79,417,79,416,77,416,74,419,74,424,74,430,73,437,72,439,69,440,68,440,65,439,64,437,62,439,59,442,57,445,54,447,52,449,51,454,51,458,50,459,49,461,49,461,52,463,54,463,56,463,59,464,62,466,64,467,66,468,69,468,78,468,82,469,85,470,86,468,90">\n' +
    '			<area ng-href="{{ url }}NY" ng-click="onSelect( \'NY\' )" title="New York" class="reisState" shape="poly" coords="468,94,472,94,473,93,476,93,477,91,479,90,481,89,482,88,480,88,477,90,472,90,470,90,470,91,468,92,468,94">\n' +
    '			<area ng-href="{{ url }}NC" ng-click="onSelect( \'NC\' )" title="North Carolina" class="reisState" shape="poly" coords="383,166,382,168,385,170,389,170,393,169,397,167,400,166,401,166,402,164,407,164,413,164,415,165,417,166,418,167,422,167,424,165,428,166,430,167,433,169,437,170,438,172,440,173,446,173,447,171,447,169,448,168,450,165,453,163,455,163,457,162,460,161,460,161,460,159,457,158,457,156,458,154,460,155,462,153,463,151,465,148,463,145,462,143,461,141,458,141,453,142,447,144,443,144,436,145,428,146,421,147,416,148,410,148,406,150,404,150,403,152,399,154,396,156,391,160,388,162,383,166">\n' +
    '			<area ng-href="{{ url }}NC" ng-click="onSelect( \'NC\' )" title="North Carolina" class="reisState" shape="poly" coords="808,340, 810,337, 813,335, 815,334, 815,332, 814,326, 813,324, 812,322, 813,322, 816,327, 816,331, 816,335, 813,336, 810,339, 809,340, 808,340">\n' +
    '			<area ng-href="{{ url }}ND" ng-click="onSelect( \'ND\' )" title="North Dakota" class="reisState" shape="poly" coords="199,25,247,26,255,26,255,28,255,31,254,33,254,35,255,36,256,37,256,38,257,40,257,43,257,45,258,46,258,49,258,51,259,52,259,55,259,58,232,58,221,57,213,57,205,57,196,55,196,46,196,42,197,30,196,26,199,25">\n' +
    '			<area ng-href="{{ url }}OH" ng-click="onSelect( \'OH\' )" title="Ohio" class="reisState" shape="poly" coords="367,99,370,99,374,98,379,98,384,98,386,99,388,100,393,99,396,97,404,92,406,93,407,99,407,102,407,106,408,112,407,115,404,119,401,120,400,122,399,125,397,125,396,127,396,130,393,131,392,131,391,130,389,128,388,128,386,129,379,130,377,129,375,126,371,126,371,125,369,118,368,114,367,107,367,99">\n' +
    '			<area ng-href="{{ url }}OK" ng-click="onSelect( \'OK\' )" title="Oklahoma" class="reisState" shape="poly" coords="205,152,197,151,196,153,196,157,202,158,207,158,215,158,221,158,225,159,225,165,225,168,224,174,225,178,226,181,228,181,230,181,231,181,232,182,232,183,235,184,238,184,240,184,242,184,243,185,245,187,249,187,250,187,252,189,254,189,255,187,256,187,256,188,259,188,261,189,263,189,263,187,265,187,267,187,270,187,271,188,272,188,274,188,275,188,276,189,278,190,280,191,281,178,280,174,280,171,279,168,279,164,279,161,279,158,278,155,274,153,251,153,244,153,224,152,215,152,208,151,205,152">\n' +
    '			<area ng-href="{{ url }}OR" ng-click="onSelect( \'OR\' )" title="Oregon" class="reisState" shape="poly" coords="12,68,23,71,30,73,48,77,74,82,75,79,77,73,78,68,79,64,81,61,81,59,79,57,80,55,83,53,84,51,87,48,89,45,88,43,86,42,84,41,80,40,77,38,74,38,70,37,65,38,59,38,55,37,53,37,51,36,48,36,44,35,39,35,37,35,37,33,37,30,36,29,35,28,34,27,33,27,30,27,29,28,28,31,24,38,22,41,21,46,18,51,15,55,13,57,11,58,12,68">\n' +
    '			<area ng-href="{{ url }}PA" ng-click="onSelect( \'PA\' )" title="Pennsylvania" class="reisState" shape="poly" coords="410,89,407,90,406,92,407,96,407,102,408,106,408,109,408,111,411,114,413,115,414,114,415,113,423,113,426,112,429,111,432,111,434,110,440,110,442,109,443,108,447,108,451,108,453,108,455,107,457,106,459,105,461,103,462,101,459,99,457,97,457,96,458,89,456,87,455,85,453,84,450,84,447,83,443,84,440,85,433,86,424,87,421,87,418,88,412,89,410,89">\n' +
    '			<area ng-href="{{ url }}RI" ng-click="onSelect( \'RI\' )" title="Rhode Island" class="reisState" shape="poly" coords="484,77,484,81,484,83,487,82,487,81,488,81,489,80,490,79,489,78,488,77,487,77,484,77">\n' +
    '			<area ng-href="{{ url }}SC" ng-click="onSelect( \'SC\' )" title="South Carolina" class="reisState" shape="poly" coords="393,171,400,177,405,181,408,183,408,184,412,186,414,189,419,195,420,196,422,195,424,194,425,193,424,191,426,190,429,189,432,186,435,182,437,180,437,177,437,176,440,174,440,173,438,171,435,169,431,167,429,166,425,166,418,167,417,167,416,164,413,164,410,164,406,164,403,164,400,166,395,168,393,171">\n' +
    '			<area ng-href="{{ url }}SD" ng-click="onSelect( \'SD\' )" title="South Dakota" class="reisState" shape="poly" coords="195,56,193,73,193,81,193,86,194,87,199,87,211,88,224,89,243,89,245,91,246,92,247,92,249,91,252,91,254,92,256,92,258,93,259,93,259,91,260,89,260,88,258,87,258,85,260,85,261,66,261,65,258,64,257,62,257,61,259,60,258,57,256,57,240,57,229,57,224,57,223,56,213,56,204,56,203,55,195,56">\n' +
    '			<area ng-href="{{ url }}TN" ng-click="onSelect( \'TN\' )" title="Tennessee" class="reisState" shape="poly" coords="324,173,343,172,352,172,362,172,368,171,372,171,381,170,382,169,382,166,386,164,389,162,392,160,395,158,397,156,399,155,401,154,404,151,405,149,402,150,395,150,391,151,385,151,380,151,374,152,367,153,360,153,353,154,346,155,344,155,342,157,335,157,331,157,329,157,328,157,326,163,324,166,323,170,324,173">\n' +
    '			<area ng-href="{{ url }}TX" ng-click="onSelect( \'TX\' )" title="Texas" class="reisState" shape="poly" coords="194,157,195,164,193,175,193,186,192,194,192,199,191,204,184,204,173,204,169,204,165,202,161,202,156,202,154,202,155,205,157,206,161,210,163,213,166,215,168,216,170,217,171,219,172,221,173,223,173,226,173,228,174,229,176,231,178,233,181,234,183,235,185,236,187,236,188,236,190,235,191,232,193,230,194,229,199,229,202,229,205,230,206,230,208,231,210,233,212,234,214,237,216,241,217,245,219,247,222,250,224,252,226,253,227,254,226,257,226,259,227,261,228,261,229,264,230,267,232,267,235,268,238,268,239,270,242,270,245,270,247,272,248,272,250,272,250,269,249,264,250,255,250,252,250,251,254,250,255,248,260,246,263,244,265,244,267,242,273,239,275,238,279,235,283,234,285,232,288,231,288,227,288,223,289,221,290,219,291,216,289,213,287,209,286,206,284,202,284,195,284,190,282,191,279,190,276,188,274,188,270,187,268,186,266,186,264,186,262,188,261,188,258,188,257,187,255,187,253,188,252,189,251,188,250,186,248,186,246,186,245,185,243,184,242,184,236,183,234,183,232,183,233,180,230,180,225,180,225,178,225,159,223,158,214,157,199,156,194,157">\n' +
    '			<area ng-href="{{ url }}UT" ng-click="onSelect( \'UT\' )" title="Utah" class="reisState" shape="poly" coords="100,87,106,88,110,88,117,89,121,90,128,91,128,94,127,99,127,102,134,104,142,104,143,104,143,107,143,111,143,116,142,123,140,132,138,146,134,146,127,145,117,144,106,142,99,140,92,139,90,139,90,138,90,134,92,127,93,121,95,117,96,109,97,102,98,98,99,93,100,87">\n' +
    '			<area ng-href="{{ url }}VT" ng-click="onSelect( \'VT\' )" title="Vermont" class="reisState" shape="poly" coords="461,48,461,52,464,54,463,58,464,60,465,64,466,64,467,66,468,69,468,70,470,71,472,71,474,70,475,66,475,64,473,62,473,57,473,53,475,52,477,51,477,48,476,44,475,45,471,46,469,47,466,47,461,48">\n' +
    '			<area ng-href="{{ url }}VA" ng-click="onSelect( \'VA\' )" title="Virginia" class="reisState" shape="poly" coords="399,141,396,144,394,146,390,149,388,151,391,151,398,151,405,151,409,149,415,148,420,148,423,148,429,146,437,145,440,145,443,144,446,144,450,144,455,143,459,142,461,140,460,139,457,138,455,137,455,135,452,133,453,133,454,131,454,130,453,128,450,126,445,124,444,123,444,121,444,119,444,118,443,118,440,117,439,115,437,115,436,117,434,117,434,116,432,116,430,116,430,119,429,121,426,123,424,125,423,126,421,127,419,126,420,128,418,131,417,134,417,137,416,139,411,140,405,142,404,143,402,142,399,141">\n' +
    '			<area ng-href="{{ url }}WA" ng-click="onSelect( \'WA\' )" title="Washington" class="reisState" shape="poly" coords="48,11,46,8,43,8,37,7,34,4,31,2,30,4,30,10,30,14,32,16,32,17,31,18,30,19,30,19,31,20,31,21,30,22,28,23,28,25,31,25,34,26,35,27,37,30,38,33,39,34,42,35,47,35,49,35,51,36,52,37,56,37,60,36,63,37,67,37,70,37,73,37,77,38,80,39,85,40,86,35,89,29,90,24,92,17,93,13,93,10,79,7,71,5,63,3,53,2,51,1,47,0,48,11">\n' +
    '			<area ng-href="{{ url }}WV" ng-click="onSelect( \'WV\' )" title="West Virginia" class="reisState" shape="poly" coords="394,132,394,135,396,137,398,139,399,141,402,142,406,141,409,140,413,140,415,139,417,138,418,135,418,132,419,130,420,127,421,125,422,125,423,127,424,126,424,124,426,123,427,122,429,121,430,119,430,115,432,115,433,115,435,117,435,116,434,113,431,112,427,113,424,115,421,117,420,118,420,115,420,113,417,113,413,115,410,114,409,112,408,111,406,115,404,118,401,120,399,123,397,125,395,129,394,132">\n' +
    '			<area ng-href="{{ url }}WI" ng-click="onSelect( \'WI\' )" title="Wisconsin" class="reisState" shape="poly" coords="338,92,338,89,337,85,337,82,338,77,338,74,339,72,339,70,340,67,341,65,341,64,338,66,336,69,335,70,335,67,336,66,336,64,336,62,335,58,331,57,327,56,321,54,317,53,314,52,312,51,308,50,308,49,308,48,306,47,305,49,301,49,297,50,296,51,296,54,295,57,292,57,292,58,292,61,294,63,294,65,292,66,292,68,292,70,295,73,300,76,304,79,307,81,307,85,308,89,310,93,313,94,314,95,316,95,317,93,325,93,328,92,338,92">\n' +
    '			<area ng-href="{{ url }}WY" ng-click="onSelect( \'WY\' )" title="Wyoming" class="reisState" shape="poly" coords="135,59,132,75,129,83,128,91,127,98,127,102,131,103,139,104,145,104,152,105,162,105,169,106,173,107,182,107,184,108,191,107,194,66,194,66,183,64,153,62,135,59">\n' +
    '		</map>\n' +
    '	</div>\n' +
    '\n' +
    '	<div class="span12 row-fluid filter-container">\n' +
    '		<span class="span12">\n' +
    '			<input id="stateFilter" type="text" class="span11 piiSafe" placeholder="Search States" ng-model="filter.state">\n' +
    '		</span>\n' +
    '		<span class="filter-target">\n' +
    '			<span class="span2" ng-repeat="state in states | filter: filterStates">\n' +
    '				<a class="filter-text" ng-href="{{ url }}{{ state.PAYROLLSTATE }}" ng-click="onSelect( state.PAYROLLSTATE )">{{ state.FULLSTATENAME }}</a>\n' +
    '			</span>\n' +
    '		</span>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/submissionsList/template/submissionListItem.html',
    '<td>\n' +
    '	<a class="insuredName" ng-href="#/account/issuance/{{ submission.INSUREDID }}/na/{{ submission.POLICYID }}">{{ submission.INSUREDNAME }}</a>\n' +
    '</td>\n' +
    '<td class="line">\n' +
    '	<span product-label="submission.PRODUCTTYPE">{{ submission.PRODUCTTYPE }}</span>\n' +
    '</td>\n' +
    '<td class="effectiveDate">{{ submission.EFFECTIVEDATE }}</td>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/submissionsList/template/template.html',
    '<div class="banner-blue">\n' +
    '	Submissions\n' +
    '	<div class="btn-toolbar">\n' +
    '		<div id="tooManySubmissions" class="alert alert-error" ng-show="submissions.tooMany">\n' +
    '			<p><strong>Too many records returned.</strong></p>\n' +
    '			<p>Showing first {{ submissions.maxRecords }}</p>\n' +
    '		</div>\n' +
    '		<div class="btn-group">\n' +
    '			<button class="btn" ng-class="{ \'btn-info\': selected.submitted }" ng-click="setSelected(\'submitted\')">Submitted ({{ submissions.submitted.length }})</button>\n' +
    '			<button class="btn" ng-class="{ \'btn-info\': selected.quoted }" ng-click="setSelected(\'quoted\')">Quoted ({{ submissions.quoted.length }})</button>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<table id="submissions" name="submissions" class="table table-bordered table-condensed">\n' +
    '	<thead>\n' +
    '		<tr>\n' +
    '			<th>Insured Name</th>\n' +
    '			<th>Line</th>\n' +
    '			<th>Effective Date</th>\n' +
    '		</tr>\n' +
    '	</thead>\n' +
    '	<!-- \n' +
    '	rendering both the submitted and quoted tabs so angular doesn\'t have to thrash the dom every time someone toggles a tab. the result set is\n' +
    '	small enough that it shouldn\'t adversely affect performance and it makes switching between submitted and quoted status much quicker.\n' +
    '	-->\n' +
    '	<tbody ng-show="selected.submitted">\n' +
    '		<tr ng-repeat="submission in submissions.submitted" submission-list-item submission="submission"></tr>\n' +
    '	</tbody>\n' +
    '	<tbody ng-show="selected.quoted">\n' +
    '		<tr ng-repeat="quoted in submissions.quoted" submission-list-item submission="quoted"></tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/submissionsSearch/template/template.html',
    '<div class="topMessage">\n' +
    '	<div error-message="searchResults.error.message" error-message-header="searchResults.error.header"></div>\n' +
    '</div>\n' +
    '<form name="submissionsForm" id="submissionsForm" class="form-horizontal ajax-process" ng-submit="getResults()">\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="accountsLobControlGroup">\n' +
    '				<label for="lob">Program <span class="required-indicator">*</span></label>\n' +
    '				<div class="controls">\n' +
    '					<select id="lob" name="lob" size="1" class="piiSafe" ng-options="program as program.name for program in searchData.programs" ng-model="selectedProgram"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="licensedAgentControlGroup">\n' +
    '				<label for="licensedAgent">Licensed Agent</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="licensedAgent" name="licensedAgent" class="piiSafe" size="1" ng-model="searchParams.licensedAgent" ng-options="agent.VALUE as agent.NAME for agent in searchData.licensedAgents"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="submissionsStatusControlGroup">\n' +
    '				<label for="status">Status</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="status" name="status" size="1" class="piiSafe" ng-model="searchParams.status" ng-options="status.VALUE as status.NAME for status in searchData.statuses"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="effDateControlGroup">\n' +
    '				<label for="effDateFrom">Effective Date</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="effDateFrom" name="effDateFrom" type="text" class="input-small piiSafe" ng-model="searchParams.effectiveDateFrom" date-picker> to\n' +
    '					<input id="effDateTo" name="effDateTo" type="text" class="input-small piiSafe" ng-model="searchParams.effectiveDateTo" date-picker>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="subDateControlGroup">\n' +
    '				<label for="subDateFrom">Submission Date</label>\n' +
    '				<div class="controls">\n' +
    '					<input id="subDateFrom" name="subDateFrom" type="text" class="input-small piiSafe" ng-model="searchParams.submissionDateFrom" date-picker> to\n' +
    '					<input id="subDateTo" name="subDateTo" type="text" class="input-small piiSafe" ng-model="searchParams.submissionDateTo" date-picker>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '		<div class="span6">\n' +
    '\n' +
    '			<div class="control-group" id="servicingAgentControlGroup">\n' +
    '				<label for="servicingAgent">Servicing Agent</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="servicingAgent" name="servicingAgent" size="1" class="piiSafe" ng-model="searchParams.servicingAgent" ng-options="agent.VALUE as agent.NAME for agent in searchData.servicingAgents"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="sourceControlGroup" ng-show="searchParams.sources.length">\n' +
    '				<label for="source">Source</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="source" name="source" size="1" class="piiSafe" ng-model="searchParams.source" ng-options="source.VALUE as source.NAME for source in searchData.sources"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="typeControlGroup">\n' +
    '				<label for="type">Type</label>\n' +
    '				<div class="controls">\n' +
    '					<select id="type" name="type" size="1" class="piiSafe" ng-model="searchParams.type" ng-options="type.VALUE as type.NAME for type in searchData.types"></select>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="submissionsFeinControlGroup">\n' +
    '				<label for="submissionsFein">FEIN</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="submissionsFein" name="fein" class="piiMask" ng-model="searchParams.fein">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="submissionsPolicyNumberControlGroup">\n' +
    '				<label for="submissionsPolicyNumber">Policy Number</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="submissionsPolicyNumber" class="piiSafe" name="policyNumber" ng-model="searchParams.policyNumber">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="ControlGroup">\n' +
    '				<label for="search">Insured Name</label>\n' +
    '				<div class="controls">\n' +
    '					<input maxlength="30" name="search" id="search" type="text" class="piiSafe" ng-model="searchParams.insuredName">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group" id="accountsInsuredAccountControlGroup">\n' +
    '				<label for="insuredAccount">Insured Account #</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="insuredAccount" name="insuredAccount" class="piiSafe" ng-model="searchParams.insuredAccount">\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="control-group">\n' +
    '				<div class="controls">\n' +
    '					<button id="submissionsSubmit" class="btn btn-success" ng-hide="searchResults.status.working">Search</button>\n' +
    '					<div class="searchingMessage" ng-show="searchResults.status.working">Searching...</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</form>\n' +
    '\n' +
    '<div class="row-fluid" ng-show="searchResults.status.ready">\n' +
    '	<div class="span12" id="submissionsSearchResultContainer">\n' +
    '		<hr>\n' +
    '		<h3><span id="submissionsResultsCount">{{ searchResults.results.length }}</span> Records Returned</h3>\n' +
    '		<table id="submissionsSearchResults" name="submissionsSearchResults" class="table table-striped table-condensed table-bordered">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th>Insured Name</th>\n' +
    '					<th>State</th>\n' +
    '					<th>Status</th>\n' +
    '					<th>Type</th>\n' +
    '					<th>Effective Date</th>\n' +
    '					<th>Producer</th>\n' +
    '					<th>Contact</th>\n' +
    '					<th>Policy #</th>\n' +
    '					<th>Premium</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody id="submissionsSearchResultsBody">\n' +
    '				<tr class="submissionsResult" ng-repeat="result in searchResults.filteredResults">\n' +
    '					<td class="INSUREDNAME">\n' +
    '						<a class="INSUREDNAME" ng-href="#/account/activity/{{ result.INSUREDID }}">{{ result.INSUREDNAME }}</a>\n' +
    '					</td>\n' +
    '					<td class="STATE">{{ result.STATE }}</td>\n' +
    '					<td>\n' +
    '						<a class="STATUS" ng-href="{{ getStatusHref( result ) }}">{{ result.STATUS }}</a>\n' +
    '					</td>\n' +
    '					<td class="TYPE">{{ result.TYPE }}</td>\n' +
    '					<td class="EFFECTIVEDATE">{{ result.EFFECTIVEDATE }}</td>\n' +
    '					<td class="LICENSEDAGENT">{{ result.LICENSEDAGENT }}</td>\n' +
    '					<td class="SERVICINGAGENT">{{ result.SERVICINGAGENT }}</td>\n' +
    '					<td>\n' +
    '						<span ng-show="result.POLICYNUMBER && result.PRODUCTTYPE" product-label="result.PRODUCTTYPE">{{ result.PRODUCTTYPE }}</span>&nbsp;<span class="POLICYNUMBER">{{ result.POLICYNUMBER }}</span>\n' +
    '					</td>\n' +
    '					<td class="PREMIUM align-right" ng-if="isQuoted(result.STATUS)">{{ result.PREMIUM | formatMoney }}</td>\n' +
    '					<td class="PREMIUM align-left" ng-if="!isQuoted(result.STATUS)">NA</td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '		<div class="pagination" id="submissionsSearchResultsPagination">\n' +
    '			<pagination total-items="searchResults.results.length" items-per-page="searchResults.config.pagination.config.itemsPerPage" previous-text="&larr; Previous" next-text="Next &rarr;" ng-model="searchResults.config.pagination.config.currentPage" ng-change="searchResults.paginate()" ng-show="searchResults.results.length">\n' +
    '			</pagination>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/surveyList/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<div class="banner">How are we doing?</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="row-fluid">\n' +
    '	<div class="span12" id="surveyLinks">\n' +
    '		Please take our<br>\n' +
    '		<div class="surveyRow" ng-repeat="survey in surveys">\n' +
    '			<a ng-href="#/survey/{{ survey.ID }}">{{ survey.name }}</a>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/viewApplication/template/template.html',
    '<a href ng-click="viewApplication()">{{ linkText }}</a>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/insuredAccountActivity/template/template.html',
    '<div class="banner">Account Activity</div>\n' +
    '<div class="contentBlock">\n' +
    '	<p ng-if="!activityItems.length">No recent activity was found for this account.</p>\n' +
    '	<table class="table-activity" ng-if="activityItems.length">\n' +
    '		<tbody ng-repeat="item in activityItems">\n' +
    '			<tr>\n' +
    '				<td class="span3 policy-td">{{:: item.policyNumber }}</td>\n' +
    '				<td class="message-td">\n' +
    '					<strong>{{:: item.title }}</strong>\n' +
    '				</td>\n' +
    '				<td class="dateContainer text-light">\n' +
    '					<span class="pull-right">{{:: item.timestamp | date: "MM/dd/yyyy hh:mm a" }}</span>\n' +
    '				</td>\n' +
    '			</tr>\n' +
    '		</tbody>\n' +
    '	</table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/insuredInfo/template/template.html',
    '<div class="well">\n' +
    '	<table class="table-plain">\n' +
    '		<tbody>\n' +
    '			<tr>\n' +
    '				<td nowrap="nowrap">Account #</td>\n' +
    '				<td ng-if="!accountIdList">{{:: account.accountId }}</td>\n' +
    '				<td ng-if="accountIdList">\n' +
    '					<div ng-repeat="id in accountIdList">{{:: id }}</div>\n' +
    '				</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="!accountIdList">\n' +
    '				<td>DBA</td>\n' +
    '				<td>{{:: account.dbaName ? account.dbaName : "N/A" }}</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="!accountIdList">\n' +
    '				<td>Address</td>\n' +
    '				<td>\n' +
    '					{{:: account.address1 }}<br>\n' +
    '					{{:: account.address2 }}<br ng-if="account.address2">\n' +
    '					{{:: account.city }}<br>\n' +
    '					{{:: account.state }}, {{:: account.zip }}\n' +
    '				</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="account.isEnrolledInAutoPay && !accountIdList">\n' +
    '				<td>Auto pay</td>\n' +
    '				<td>Yes</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="!account.isEnrolledInAutoPay && !accountIdList">\n' +
    '				<td>Auto pay</td>\n' +
    '				<td>No</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="account.showCurrentDue">\n' +
    '				<td>Current due</td>\n' +
    '				<td>{{::account.due | formatMoney }}</td>\n' +
    '			</tr>\n' +
    '\n' +
    '			<tr ng-if="account.showPastDue">\n' +
    '				<td>Past due</td>\n' +
    '				<td>{{::account.pastDue | formatMoney}}</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="account.pastDueFees || account.currentDueFees">\n' +
    '				<td>Fees</td>\n' +
    '				<td>{{:: account.pastDueFees+account.currentDueFees | formatMoney}}</td>\n' +
    '			</tr>\n' +
    '		</tbody>\n' +
    '	</table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/accountList/template/template.html',
    '<div class="row-fluid">\n' +
    '    <div class="span12">\n' +
    '\n' +
    '        <div class="contentBlock" ng-repeat="account in accounts" ng-if="accounts.length">\n' +
    '            <div class="banner">\n' +
    '                <span class="accountListHeader tooltip.top" title="{{:: account.accountName}}">Account Number {{:: account.accountId}} / Billing Account {{:: account.piNumber ? account.piNumber : account.accountId }} {{:: truncName(account) }}</span>\n' +
    '				<span class="pull-right showExpired">\n' +
    '					<a ng-click="toggleExpiredVisibility( account )" ng-if="!account.showingExpired">View Inactive Policies</a>\n' +
    '					<a ng-click="toggleExpiredVisibility( account )" ng-if="account.showingExpired">Hide Inactive Policies</a>\n' +
    '				</span>\n' +
    '            </div>\n' +
    '            <div class="banner" ng-if="account.primaryAccountId !== null && account.accountId !== account.primaryAccountId">\n' +
    '                <span style="color:teal;">   This is a merged account. Payments will be made through the primary account.</span>\n' +
    '            </div>\n' +
    '            <div class="contentBlock" ng-repeat="policy in account.policies" ng-if="account.policies.length && isVisible( account, policy )">\n' +
    '                <div quote-table quote="policy" account="account" open-tab="openTab" set-message-view="setMessageView" user="user"></div>\n' +
    '            </div>\n' +
    '            <div class="contentBlock" ng-if="!account.policies.length">\n' +
    '                <p>No {{:: quoteType.toLowerCase() }} were found for this account.</p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/paymentSystem/template/template.html',
    '<div class="contentBlock" ng-if="bundle_payment_visible">\n' +
    '	<table class="table quote-table" border="0" cellpadding="0" cellspacing="0">\n' +
    '		<tbody>\n' +
    '			<tr>\n' +
    '				<th colspan="2">\n' +
    '					<a><span>Payments Due - All Policies</span></a>\n' +
    '				</th>\n' +
    '			</tr>\n' +
    '			<tr ng-repeat="item in payment_system.itemsDue">\n' +
    '				<td><div><span ng-if="item.isRenewalDownPayment">Deposit on renewal of </span>{{:: item.policyNumber }}</div></td>\n' +
    '				<td><div>{{:: item.amountDue | currency:\'$\':\'2\' }}</div></td>\n' +
    '			</tr>\n' +
    '			<tr>\n' +
    '				<td><div style="width:150px"><b>Total :</b> </div></td>\n' +
    '				<td><div><b>{{:: payment_system.totalCurrentDue | currency:\'$\':\'2\' }}</b></div></td>\n' +
    '			</tr>\n' +
    '		</tbody> \n' +
    '		<tfoot>\n' +
    '			<tr>\n' +
    '				<td colspan="2">\n' +
    '					<button class="btn btn-success pull-right" ng-click="open_payment($event)">Make Payment</button>\n' +
    '				</td>\n' +
    '			</tr>\n' +
    '		</tfoot> \n' +
    '	</table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/quoteList/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">		\n' +
    '		<div class="banner">\n' +
    '			{{:: quoteType }}\n' +
    '			<span class="pull-right showExpired">\n' +
    '				<a ng-click="toggleExpiredVisibility()" ng-if="!uiState.showingExpired">View Inactive {{:: quoteType }}</a>\n' +
    '				<a ng-click="toggleExpiredVisibility()" ng-if="uiState.showingExpired">Hide Inactive {{:: quoteType }}</a>\n' +
    '			</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="contentBlock" ng-repeat="quote in quotes" ng-if="quotes.length && isVisible( quote )">\n' +
    '			<div quote-table quote="quote" account="account" open-tab="openTab" user="user" set-message-view="setMessageView" open-application-tab="openApplicationTab"></div>\n' +
    '		</div>\n' +
    '		<div class="contentBlock" ng-if="!quotes.length">\n' +
    '			<p>No {{:: quoteType.toLowerCase() }} were found for this account.</p>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/quoteSummary/template/template.html',
    '<div class="quoteGridRow">\n' +
    '    <div class="row-fluid">\n' +
    '        <div class="span1">\n' +
    '            <strong>{{:: quote.submissionId }}</strong>\n' +
    '        </div>\n' +
    '        <div class="span3">\n' +
    '            <span product-label="quote.programAbbreviation" auto-width="true">{{:: quote.programAbbreviation}}</span> {{:: quote.program }}\n' +
    '            <div ng-if="quote.lineOfBusiness">{{:: quote.lineOfBusiness }}</div>\n' +
    '            <div ng-if="quote.submissionLinesOfBusiness">{{:: quote.submissionLinesOfBusiness }}</div>\n' +
    '        </div>\n' +
    '        <div class="span2">\n' +
    '            <div ng-if="quote.premium && quote.canDisplayPremium">{{:: quote.premium | formatMoney }}</div>\n' +
    '            <div ng-if="!quote.premium || !quote.canDisplayPremium">N/A</div>\n' +
    '        </div>\n' +
    '        <div class="span2">\n' +
    '            {{:: quote.effectiveDate }}\n' +
    '        </div>\n' +
    '        <div class="span2">\n' +
    '            {{:: quote.status }}\n' +
    '\n' +
    '            <div ng-if="showFutureControls">  <!-- Future feature -->\n' +
    '                <a ng-click="toggleQuoteSelection(quote)">\n' +
    '                    <img ng-if="quote.status === \'Quoted\'" ng-class="{ \'unselectedCheckmark\': !quote.selected }" class="selectionCheckmark" src="assets/images/flaticon-round-green-64.png" alt="">\n' +
    '                </a>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '        <div class="span2">\n' +
    '            <ul ng-if="quote.accountViewVersion === 0">\n' +
    '                <li ng-if="isPaymentLinkVisible()">\n' +
    '                    <span payment-link account-id="account.piNumber"></span>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <ul ng-if="quote.accountViewVersion === 1">\n' +
    '                <li>\n' +
    '                    <a ng-href="#/isc/payment/{{:: account.accountId }}/{{:: quote.submissionId }}">Make a Payment</a>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <a ng-href="#/isc/policy/{{:: quote.policyNumber }}">View Documents</a>\n' +
    '                </li>\n' +
    '                <li>\n' +
    '                    <a ng-href="#/isc/policy/{{:: quote.policyNumber }}">View Billing Detail</a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <ul ng-if="quote.accountViewVersion === 2 && !quote.isRenewal">\n' +
    '                <li ng-if="isPaymentLinkVisible()">\n' +
    '                    <span payment-link account-id="account.piNumber"></span>\n' +
    '                </li>\n' +
    '                <li ng-if="isLossRunVisible()">\n' +
    '                    <a href ng-click="openLossRunsWindow()">{{::getLossRunLinkText()}}</a>\n' +
    '                </li>\n' +
    '                <li ng-if="isBillingInfoVisible()">\n' +
    '                    <a href ng-click="openTab( quote.policyNumber, quote )">View Billing Detail</a>\n' +
    '                </li>\n' +
    '                <li ng-if="user.hasComponentPermissions( \'claimsData\' ) && quote.isPolicy">\n' +
    '                    <a href ng-click="openTab( quote.policyNumber, quote )">View Claims</a>\n' +
    '                </li>\n' +
    '                <li ng-if="quote.canEdit && !quote.isUploadBasedSubmission && !isAvailableForEdit">\n' +
    '                    <img class="loader" src="/assets/images/ajax-loader-grey.gif">\n' +
    '                </li>\n' +
    '                <li ng-if="quote.canEdit && !quote.isUploadBasedSubmission && isAvailableForEdit">\n' +
    '                    <span edit-submission application-id="{{ quote.applicationId }}" status="{{ quote.status }}" luxon-id="{{luxonData.luxon_id}}" luxon-partition-key="{{luxonData.luxon_partition_key}}"></span>\n' +
    '                </li>\n' +
    '                <li ng-if="canViewResult()">\n' +
    '                    <a ng-href="#/application/result/{{:: quote.applicationId }}/account/{{:: quote.accountId }}">View {{::outcomeType}}</a>\n' +
    '                </li>\n' +
    '                <li ng-if="canViewSubmissionDocuments()">\n' +
    '                    <a href ng-click="openTab( quote.submissionId, quote )">View Documents</a>\n' +
    '                </li>\n' +
    '                <li ng-if="quote.isUploadBasedSubmission">\n' +
    '                    <a href ng-click="setMessageView(\'send\', {submission : quote, typeId : 3 , application: true})">Upload Application</a>\n' +
    '                </li>\n' +
    '                <li ng-if="isSubmissionChangeRequestVisible()">\n' +
    '                    <a href ng-click="setMessageView(\'send\', {submission : quote, typeId : 2})">Request Change</a>\n' +
    '                </li>\n' +
    '                <li ng-if="showViewApplicationLink()">\n' +
    '                    <a href ng-click="openApplicationTab( quote.applicationId, quote )">View Application</a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <ul ng-if="quote.accountViewVersion === 2 && quote.isRenewal && quote.status === \'Quoted\'">\n' +
    '                <li ng-if="canViewRenewal() && isBindAndInvoice()">\n' +
    '                    <a ng-href="#/application/result/{{:: quote.applicationId }}/account/{{:: quote.accountId }}">Bind and Invoice</a>\n' +
    '                </li>\n' +
    '                <li ng-if="canViewRenewal()">\n' +
    '                    <a ng-href="#/application/result/{{:: quote.applicationId }}/account/{{:: quote.accountId }}">View {{::outcomeType}}</a>\n' +
    '                </li>\n' +
    '                <li ng-if="showViewApplicationLink()">\n' +
    '                    <a href ng-click="openApplicationTab( quote.applicationId, quote )">View Application</a>\n' +
    '                </li>\n' +
    '                <li ng-if="isSubmissionChangeRequestVisible()">\n' +
    '                    <a href ng-click="setMessageView(\'send\', {submission : quote, typeId : 2})">Request Change</a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '            <ul ng-if="quote.accountViewVersion === 2 && quote.isRenewal && quote.status === \'Bound\'">\n' +
    '                <li ng-if="canViewRenewal()">\n' +
    '                    <a ng-href="#/application/result/{{:: quote.applicationId }}/account/{{:: quote.accountId }}">View {{::outcomeType}}</a>\n' +
    '                </li>\n' +
    '                <li ng-if="canViewSubmissionDocuments()">\n' +
    '                    <a href ng-click="openTab( quote.submissionId, quote )">View Documents</a>\n' +
    '                </li>\n' +
    '                <li ng-if="isSubmissionChangeRequestVisible()">\n' +
    '                    <a href ng-click="setMessageView(\'send\', {submission : quote, typeId : 2})">Request Change</a>\n' +
    '                </li>\n' +
    '                <li ng-if="showViewApplicationLink()">\n' +
    '                    <a href ng-click="openApplicationTab( quote.applicationId, quote )">View Application</a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/quoteSummarySection/template/template.html',
    '<div class="row-fluid">\n' +
    '    <div class="span12">\n' +
    '        <div class="banner">\n' +
    '            {{:: quoteType }}\n' +
    '			<span class="pull-right showExpired">\n' +
    '				<a ng-click="toggleExpiredVisibility()" ng-if="!uiState.showingExpired">View Inactive {{:: quoteType }}</a>\n' +
    '				<a ng-click="toggleExpiredVisibility()" ng-if="uiState.showingExpired">Hide Inactive {{:: quoteType }}</a>\n' +
    '			</span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="contentBlock" ng-if="showQuoteTable()">\n' +
    '            <div class="row-fluid quoteGridHeader">\n' +
    '                <div class="span1">&nbsp;</div>\n' +
    '                <div class="span3">&nbsp;</div>\n' +
    '                <div class="span2">Premium</div>\n' +
    '                <div class="span2">Effective Date</div>\n' +
    '                <div class="span2">Status</div>\n' +
    '                <div class="span2">Actions</div>\n' +
    '            </div>\n' +
    '            <div quote-summary ng-repeat="quote in quotes" ng-if="isVisible(quote)" show-future-controls="showFutureControls" quote="quote" account="account" open-tab="openTab" user="user" set-message-view="setMessageView" open-application-tab="openApplicationTab"></div>\n' +
    '            <div ng-if="showFutureControls" class="row-fluid quoteGridFooter">  <!-- Feature flag conditional -->\n' +
    '                <div class="span10">&nbsp;</div>\n' +
    '                <div class="span2">\n' +
    '                    <button ng-if="isMultiButtonDisplayed()" ng-disabled="!uiState.selectedQuotes.length" class="btn btn-success">Accept Selected Quotes</button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <span class="pull-right hidden"><a ng-click="toggleFutureControls()">*</a></span> <!-- Feature flag toggle -->\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="contentBlock" ng-if="!showQuoteTable() && uiState.hasExpiredQuotes && !uiState.showingExpired">\n' +
    '            <p>There are only inactive {{:: quoteType.toLowerCase() }} found on this account.</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="contentBlock" ng-if="!quotes.length">\n' +
    '            <p>No {{:: quoteType.toLowerCase() }} were found for this account.</p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/accountSummary/quoteTable/template/template.html',
    '<table class="table quote-table" border="0" cellpadding="0" cellspacing="0">\n' +
    '	<tbody>\n' +
    '		<tr>\n' +
    '			<th ng-if="quote.isPolicy">\n' +
    '				<div ng-if="quote.accountViewVersion === 0">\n' +
    '					<span ng-if="quote.program">{{:: quote.program }} - </span><span ng-if="quote.lineOfBusiness">{{:: quote.lineOfBusiness }} - </span> {{:: quote.policyNumber || quote.submissionId }}\n' +
    '				</div>\n' +
    '				<a ng-if="quote.accountViewVersion === 1" ng-href="#/isc/policy/{{:: quote.policyNumber }}">\n' +
    '					<span ng-if="quote.lineOfBusiness">{{:: quote.lineOfBusiness }} - </span> {{:: quote.policyNumber || quote.submissionId }}\n' +
    '				</a>\n' +
    '				<a href ng-if="quote.accountViewVersion === 2" ng-click="openTab( quote.policyNumber, quote )">\n' +
    '					<span ng-if="quote.program">{{:: quote.program }} - </span><span ng-if="quote.lineOfBusiness">{{:: quote.lineOfBusiness }} - </span> {{:: quote.policyNumber || quote.submissionId }}\n' +
    '				</a>\n' +
    '				<div ng-if="user.type == \'Insured\'">\n' +
    '					{{:: quote.account.dbaName }}\n' +
    '				</div>\n' +
    '			</th>\n' +
    '			<th ng-if="!quote.isPolicy">\n' +
    '				<span ng-if="quote.accountViewVersion === 2">{{:: quote.program }} - </span><span ng-if="quote.lineOfBusiness">{{:: quote.lineOfBusiness }} - </span> {{:: quote.policyNumber || quote.submissionId }}\n' +
    '			</th>\n' +
    '			<th class="span4 quote-status">{{:: quote.status }}</th>\n' +
    '			<th class="span3">Actions</th>\n' +
    '		</tr>\n' +
    '		<tr>\n' +
    '			<td>\n' +
    '				<div><strong>Effective: {{:: quote.effectiveDate | date:"MM/dd/yyyy" }} - {{:: quote.expirationDate | date:"MM/dd/yyyy" }}</strong></div>\n' +
    '				<div ng-if="quote.premium && quote.canDisplayPremium"><strong>Premium: {{:: quote.premium | formatMoney }}</strong></div>\n' +
    '				<div ng-if="isPayPlanVisible()">Pay Plan: {{:: quote.payPlan }}</div>\n' +
    '				<div ng-if="isBillTypeVisible()">Bill Type: {{:: quote.billType }}\n' +
    '                    <i ng-if="quote.billType === \'Direct bill\'" id="buttonHelper" class="icon-info-sign" popover-title="" popover="&quot;Direct bill&quot; - billing is handled by the insurance company (Markel), directly to the policyholder. The policyholder is responsible for paying their insurance premium to Markel." popover-placement="right" popover-trigger="mouseenter" popover-popup-delay="500">\n' +
    '                    </i>\n' +
    '                    <i ng-if="quote.billType !== \'Direct bill\'" id="buttonHelper" class="icon-info-sign" popover-title="" popover="&quot;Agency bill&quot; - billing is handled by the insurance agency. The agency bills the policyholder for the policy issued by the insurance company (Markel). The agent is responsible for collecting the total premium from the policyholder, and remitting the premium to Markel." popover-placement="right" popover-trigger="mouseenter" popover-popup-delay="500">\n' +
    '                    </i>\n' +
    '                </div>\n' +
    '				<div ng-if="(account.isEnrolledInAutoPay && (userProfile.type === \'Insured\' || userProfile.insuredEmulation)) ||(quote.accountViewVersion === 1 && quote.paymentMethod === 5)">Auto pay: Yes</div>\n' +
    '				<div ng-if="(!account.isEnrolledInAutoPay && (userProfile.type === \'Insured\' || userProfile.insuredEmulation))  || (quote.accountViewVersion === 1 && quote.paymentMethod !== 5)">Auto pay: No</div>\n' +
    '\n' +
    '			</td>\n' +
    '			<td>\n' +
    '				<div ng-if="quote.lineOfBusiness">{{:: quote.lineOfBusiness }}</div>\n' +
    '				<div ng-if="quote.submissionLinesOfBusiness">{{:: quote.submissionLinesOfBusiness }}</div>\n' +
    '				<div ng-if="isBalanceVisible()">Policy Balance: {{:: quote.balance | formatMoney }}</div>\n' +
    '				<div ng-if="isCurrentDueVisible()">Policy Current Due: {{:: quote.currentDue | formatMoney }}</div>\n' +
    '				<div ng-if="isRenewalDepositDueVisible()"><strong>Renewal Down Payment Due: {{:: quote.renewalDepositDue | formatMoney }}</strong></div>\n' +
    '			</td>\n' +
    '			<th class="actions">\n' +
    '				<ul ng-if="quote.accountViewVersion === 0">\n' +
    '					<li ng-if="isPaymentLinkVisible()">\n' +
    '						<span payment-link account-id="quote.billingAccountId" billing-system="quote.billingSystem"></span>\n' +
    '					</li>\n' +
    '				</ul>\n' +
    '				<ul ng-if="quote.accountViewVersion === 1">\n' +
    '					<li>\n' +
    '						<a ng-href="#/isc/payment/{{:: account.accountId }}/{{:: quote.submissionId }}">Make a Payment</a>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						<a ng-href="#/isc/policy/{{:: quote.policyNumber }}">View Documents</a>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						<a ng-href="#/isc/policy/{{:: quote.policyNumber }}">View Billing Detail</a>\n' +
    '					</li>\n' +
    '				</ul>\n' +
    '				<ul ng-if="quote.accountViewVersion === 2">\n' +
    '					<li ng-if="isPaymentLinkVisible()">\n' +
    '						<span payment-link account-id="quote.billingAccountId" billing-system="quote.billingSystem"></span>\n' +
    '					</li>\n' +
    '					<li ng-if="isLossRunVisible()">\n' +
    '						<a href ng-click="openLossRunsWindow()">{{::getLossRunLinkText()}}</a>\n' +
    '					</li>\n' +
    '					<li ng-if="canViewPolicyDocuments()">\n' +
    '						<a href ng-click="openTab( quote.policyNumber, quote )">View Documents</a>\n' +
    '					</li>\n' +
    '					<li ng-if="isBillingInfoVisible()">\n' +
    '						<a href ng-click="openTab( quote.policyNumber, quote )">View Billing Detail</a>\n' +
    '					</li>\n' +
    '					<li ng-if="canAlterDocumentDeliveryPreferences()">\n' +
    '						<span document-delivery-preferences-link application-id="{{ quote.applicationId }}" submission-id="{{ quote.submissionId }}" insured-type="{{ quote.insuredType }}"></span>\n' +
    '					</li>\n' +
    '					<li ng-if="user.hasComponentPermissions( \'claimsData\' ) && quote.isPolicy">\n' +
    '						<a href ng-click="openTab( quote.policyNumber, quote )">View Claims</a>\n' +
    '					</li>\n' +
    '					<li ng-if="quote.canEdit">\n' +
    '						<span edit-submission application-id="{{ quote.applicationId }}" status="{{ quote.status }}"></span>\n' +
    '					</li>\n' +
    '					<li ng-if="canViewResult()">\n' +
    '						<a ng-href="#/application/result/{{:: quote.applicationId }}/account/{{:: quote.accountId }}">View {{::outcomeType}}</a>\n' +
    '					</li>\n' +
    '					<li ng-if="canViewSubmissionDocuments()">\n' +
    '						<a href ng-click="openTab( quote.submissionId, quote )">View Documents</a>\n' +
    '					</li>\n' +
    '					<li ng-if="isPolicyChangeRequestVisible()">\n' +
    '						<a href ng-click="setMessageView(\'send\', {quote : quote, typeId : 2})">Request Change to Policy</a>\n' +
    '					</li>\n' +
    '					<li ng-if="isPolicyChangeRequestVisibleForInsured()">\n' +
    '						<a href ng-click="setMessageView(\'send\', {quote : quote, typeId : 2})">Send a question or change</a>\n' +
    '					</li>\n' +
    '					<li ng-if="isSubmissionChangeRequestVisible()">\n' +
    '						<a href ng-click="setMessageView(\'send\', {submission : quote, typeId : 2})">Request Change</a>\n' +
    '					</li>\n' +
    '					<li ng-if="showViewApplicationLink()">\n' +
    '						<a href ng-click="openApplicationTab( quote.applicationId, quote )">View Application</a>\n' +
    '					</li>\n' +
    '				</ul>\n' +
    '			</th>\n' +
    '		</tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/applicationData/applicationData/template/template.html',
    '<div id="applicationView" class="row-fluid policy-detail application-data-container" ng-class="{\'mobile\': isMobile}">\n' +
    '    <div ng-class="{ \'span2\' : !isMobile, \'span12\' : isMobile }" ng-if="application.programAbbreviation">\n' +
    '        <div class="submission-info well" application-info application="application" context="contextData"></div>\n' +
    '    </div>\n' +
    '    <div ng-if="application.programAbbreviation" ng-class="{ \'span10\' : !isMobile, \'span12\' : isMobile }" class="application-data-table">\n' +
    '\n' +
    '	    <h2>Application <a ng-if="!uiState.loading && application.applicationId" class="pull-right" style="font-size: 12.5px;" ng-click="getApplicationDocument()"><img style="width: 15px; margin-right:2px; margin-bottom: -3.2px;" src="../assets/images/printer-icon.png">Application pdf</a></h2>\n' +
    '\n' +
    '        <div class="documents" ng-if="uiState.loading">\n' +
    '            <loading-bar></loading-bar>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="alert alert-error" ng-if="!uiState.loading && uiState.error">\n' +
    '            An error occurred while trying to retrieve your application.\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="topSection" ng-if="!uiState.error" ng-show="!uiState.loading" ng-repeat="section in topSections" ng-if="!uiState.loading" repeat-complete="repeatDone()">\n' +
    '            <div class="banner-white topSectionTitle" ng-if="section.isSectionShow">{{::section.title}}</div>\n' +
    '	        <div ng-if="section.name ===\'Insured\'">\n' +
    '		        <div class="row-fluid viewItem">\n' +
    '			        <span class="span6">Insured Name: {{::insuredInfo.name}}</span>\n' +
    '			        <span class="span6">Doing Business As: {{::insuredInfo.dba}}</span>\n' +
    '		        </div>\n' +
    '		        <div class="row-fluid viewItem">\n' +
    '			        <span class="span6">FEIN: {{::insuredInfo.fein}}</span>\n' +
    '			        <span class="span6">Phone: {{::insuredInfo.phone}}</span>\n' +
    '		        </div>\n' +
    '		        <div class="row-fluid viewItem">\n' +
    '			        <span class="span6">Website: {{::insuredInfo.website}}</span>\n' +
    '			        <span class="span6">Email Address: {{::insuredInfo.email}}</span>\n' +
    '		        </div>\n' +
    '		        <div class="row-fluid viewItem">\n' +
    '			        <span class="span6">Address: {{::insuredInfo.physicaladdress1}}, {{::insuredInfo.physicalcity}}, <span ng-if="insuredInfo.county">{{::insuredInfo.county}},</span> {{::insuredInfo.physicalstate}}, {{::insuredInfo.physicalzip}}<br></span>\n' +
    '		        </div>\n' +
    '	        </div>\n' +
    '            <application-section section="section" depth="0" previous-enumeration="" program="application.programAbbreviation"></application-section>\n' +
    '	        <div ng-if="section.name === \'Insured\'"><span class="span12" style="margin-left:0px"><br></span></div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div ng-if="!application.programAbbreviation" ng-class="{ \'span10\' : !isMobile, \'span12\' : isMobile }" class="application-data-table" style="margin-left: 7%">\n' +
    '\n' +
    '		<h2>Application <a ng-if="!uiState.loading && application.applicationId" class="pull-right" style="font-size: 12.5px;" ng-click="getApplicationDocument()"><img style="width: 15px; margin-right:2px; margin-bottom: -3.2px;" src="../assets/images/printer-icon.png">Application pdf</a></h2>\n' +
    '\n' +
    '		<div class="documents" ng-if="uiState.loading">\n' +
    '			<loading-bar></loading-bar>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="alert alert-error" ng-if="!uiState.loading && uiState.error">\n' +
    '			An error occurred while trying to retrieve your application.\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="topSection" ng-if="!uiState.error" ng-show="!uiState.loading" ng-repeat="section in topSections" ng-if="!uiState.loading" repeat-complete="repeatDone()">\n' +
    '			<div class="banner-white topSectionTitle" ng-if="section.isSectionShow">{{::section.title}}</div>\n' +
    '			<div ng-if="section.name ===\'Insured\'">\n' +
    '				<div class="row-fluid viewItem">\n' +
    '					<span class="span6">Insured Name: {{::insuredInfo.name}}</span>\n' +
    '					<span class="span6">Doing Business As: {{::insuredInfo.dba}}</span>\n' +
    '				</div>\n' +
    '				<div class="row-fluid viewItem">\n' +
    '					<span class="span6">FEIN: {{::insuredInfo.fein}}</span>\n' +
    '					<span class="span6">Phone: {{::insuredInfo.phone}}</span>\n' +
    '				</div>\n' +
    '				<div class="row-fluid viewItem">\n' +
    '					<span class="span6">Website: {{::insuredInfo.website}}</span>\n' +
    '					<span class="span6">Email Address: {{::insuredInfo.email}}</span>\n' +
    '				</div>\n' +
    '				<div class="row-fluid viewItem">\n' +
    '					<span class="span6">Address: {{::insuredInfo.physicaladdress1}}, {{::insuredInfo.physicalcity}}, <span ng-if="insuredInfo.county">{{::insuredInfo.county}},</span> {{::insuredInfo.physicalstate}}, {{::insuredInfo.physicalzip}}<br></span>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<application-section section="section" depth="0" previous-enumeration="" program="application.programAbbreviation"></application-section>\n' +
    '			<div ng-if="section.name === \'Insured\'"><span class="span12" style="margin-left:0px"><br></span></div>\n' +
    '		</div>\n' +
    '\n' +
    '		</div>\n' +
    '	\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/applicationData/applicationInfo/template/template.html',
    '<table class="table-plain">\n' +
    '	<tbody>\n' +
    '	<tr>\n' +
    '		<td>Account</td>\n' +
    '		<td>{{:: application.accountId }}</td>\n' +
    '	</tr>\n' +
    '	<tr ng-if="application.policyNumber">\n' +
    '		<td>Policy #</td>\n' +
    '		<td>{{:: application.policyNumber }}\n' +
    '	</td></tr>\n' +
    '	<tr>\n' +
    '		<td colspan="2">\n' +
    '			<div class="sectionTitleLevel1">Insured Information</div>\n' +
    '	</td></tr>\n' +
    '	<tr>\n' +
    '		<td>Program</td>\n' +
    '		<td>{{:: application.programAbbreviation}}</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td>Status</td>\n' +
    '		<td>{{:: application.status }}</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td>Effective Date</td>\n' +
    '		<td>{{:: application.effectiveDate | date:"MM/dd/yyyy" }}</td>\n' +
    '	</tr>\n' +
    '	<tr ng-show="!isInsured" ng-if="!areRequestsPending()">\n' +
    '		<td>Underwriter</td>\n' +
    '		<td>{{:: context.underwriter.name }}</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td colspan="2">\n' +
    '			<div class="sectionTitleLevel1">Agency Information</div>\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td colspan="2">{{:: application.agencyInfo.agencyName}}</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td>Phone</td>\n' +
    '		<td>{{:: application.agencyInfo.phone ? application.agencyInfo.phone : \'N/A\' }}</td>\n' +
    '	</tr>\n' +
    '	<tr ng-show="!isInsured" ng-if="!areRequestsPending()">\n' +
    '		<td>Licensed agent</td>\n' +
    '		<td>{{::licensedAgentFullName()}}</td>\n' +
    '	</tr>\n' +
    '	<tr ng-show="!isInsured" ng-if="!areRequestsPending()">\n' +
    '		<td>Servicing Agent</td>\n' +
    '		<td>{{::servicingAgentFullName() }}</td>\n' +
    '	</tr>\n' +
    '	<tr ng-if="areRequestsPending()">\n' +
    '		<td colspan="2">\n' +
    '			<img class="loader" src="/assets/images/ajax-loader-grey.gif">\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/applicationData/applicationSection/template/template.html',
    '<div class="section sectionLevel{{::depth}}">\n' +
    '\n' +
    '	<div ng-if="!section.enumeration && section.isSectionShow">\n' +
    '		<div ng-if="section.title && depth > 0" class="viewItem sectionTitleLevel{{::depth}}">{{::section.title}}</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div ng-if="!section.enumeration && locationFields() " class="alert alert-text">\n' +
    '		<div> <span ng-if="fetchLocationDefaultValues()"> Location: {{::section.locationDefaultValues}}</span></div>\n' +
    '	</div>\n' +
    '\n' +
    '\n' +
    '	<div ng-if="section.enumeration && AdditionalInsuredFields() " class="alert alert-text" style="margin-bottom: 10px">\n' +
    '		<div> <span ng-if="fetchAdditionalInsuredInfoValues()"> Additional Insured : </span> <span ng-if="fetchAdditionalInsuredInfoValues()">{{::section.additionalInsuredInfoValues}}</span></div>\n' +
    '	</div>\n' +
    '\n' +
    '\n' +
    '	<div class="enumerationBlock" ng-if="AdditionalInsuredFields() " class="alert alert-text" style="margin-bottom: 10px">\n' +
    '		<div> <span ng-if="fetchLocationDefaultValues()" style="margin-left: 10px">  Address: {{::section.locationDefaultValues}}</span></div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div ng-if="!section.enumeration && buildingOeAiFields()">\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'Loss Payable\', \'Loss Payable Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'Loss Payable Section\',\'Loss Payable\', \'Loss Payable Number\', \'Loss Payee Name\', \'Loss Payee Zip\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Description of Property {{aiLossPayDesc[$index/2]}} </span> <br>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 0px; line-height: 26px" ng-if="$index%2 == 0">Applicable Clause {{aiLossPayClause[$index/2]}}</span>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Loss Payee Name {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'Mortgage Holder\', \'Mortgage Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'Mortgage Holder Section\',\'Mortgage Holder\', \'Mortgage Number\', \'Mortgage Holder Name\', \'Mortgage Holder Zip\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Mortgage Holder Name {{obj1}} </span> <br>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 0px; line-height: 26px" ng-if="$index%2 == 0">Account# {{mortgageAccount[$index/2]}}</span>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Building Owner\', \'AI Building Owner Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Building Owner Section\',\'AI Building Owner\', \'AI Building Owner Number\', \'AI Building Owner Name\', \'AI Building Owner Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Co Owner\', \'AI Co Owner Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Co Owner Section\',\'AI Co Owner\', \'AI Co Owner Number\', \'AI Co Owner Name\', \'AI Co Owner Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Lessor of Leased Equipment\', \'AI Leased Equipment Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%;  margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Leased Equipment Section\',\'AI Lessor of Leased Equipment\', \'AI Leased Equipment Number\', \'AI Leased Equipment Name\', \'AI Leased Equipment Zip\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 0px; line-height: 26px" ng-if="$index%2 == 0">Description of Equipment {{aiLeasedDesc[$index/2]}}</span>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '	    <div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Mortgagee\', \'AI Mortgagee Number\') track by $index">\n' +
    '		    <span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '		    <span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '	    </div>\n' +
    '	    <div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Mortgagee Section\',\'AI Mortgagee\', \'AI Mortgagee Number\', \'AI Mortgagee Name\', \'AI Mortgagee Zip\') track by $index">\n' +
    '		    <span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '		    <span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '	    </div>\n' +
    '\n' +
    '	    <div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Managers or Lessors\', \'AI Managers or Lessors Number\') track by $index">\n' +
    '		    <span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '		    <span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '	    </div>\n' +
    '	    <div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Managers or Lessors Section\',\'AI Managers or Lessors\', \'AI Managers or Lessors Number\', \'AI Managers or Lessors Name\', \'AI Managers or Lessors Zip\') track by $index">\n' +
    '		    <span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '		    <span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '	    </div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Owner of Leased Land\', \'AI Owner of Leased Land Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Owner of Leased Land Section\',\'AI Owner of Leased Land\', \'AI Owner of Leased Land Number\', \'AI Owner of Leased Land Name\', \'AI Owner of Leased Land Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '	    <div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Vendors\', \'AI Vendors Number\') track by $index">\n' +
    '		    <span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '		    <span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '	    </div>\n' +
    '	    <div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Vendors Section\',\'AI Vendors\', \'AI Vendors Number\', \'AI Vendors Name\', \'AI Vendors Zip\') track by $index">\n' +
    '		    <span class="span6" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span>\n' +
    '		    <span class="span5" style="width: 48%; margin-left: 0px; line-height: 26px" ng-if="$index%2 == 0">Description of Products {{aiVendorsDesc[$index/2]}}</span>\n' +
    '		    <span class="span12" style="margin-left:13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '	    </div>\n' +
    '\n' +
    '	</div>\n' +
    '\n' +
    '	<div ng-if="!section.enumeration && policyOeAiFields()">\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'Waiver of Transfer\', \'Waiver Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'Waiver of Transfer\',\'Waiver of Transfer\', \'Waiver Number\', \'Waiver Name\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Person or Organization {{obj1}} </span> <br>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'viewConstructionProject\', \'viewConstructionNumber\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'Designated Construction Proj Section\',\'viewConstructionProject\', \'viewConstructionNumber\', \'viewConstructionName\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Designated Project {{obj1}} </span> <br>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Controlling Interest\', \'AI Controlling Interest Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Controlling Interest Section\',\'AI Controlling Interest\', \'AI Controlling Interest Number\', \'AI CI Name\', \'AI CI Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Designated Person\', \'AI Designated Person Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Designated Person Section\',\'AI Designated Person\', \'AI Designated Person Number\', \'AI Designated Name\', \'AI Designated Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Engineers\', \'AI Engineers Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Engineers Section\',\'AI Engineers\', \'AI Engineers Number\', \'AI Engineers Name\', \'AI Engineers Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Engineers Not Engaged\', \'AI Engineers Not Engaged Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Engineers Not Engaged Section\',\'AI Engineers Not Engaged\', \'AI Engineers Not Engaged Number\', \'AI Engineers Not Engaged Name\',\'AI Engineers Not Engaged Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Grantor\', \'AI Grantor Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Grantor Section\',\'AI Grantor\', \'AI Grantor Number\', \'AI Grantor Name\', \'AI Grantor Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Owners\', \'AI Owners Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Owners Section\',\'AI Owners\', \'AI Owners Number\', \'AI Owners Name\', \'AI Owners Zip\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 0px; line-height: 26px" ng-if="$index%2 == 0">Description of Completed Operations {{aiOwnersDesc[$index/2]}}</span>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Subdivisions\', \'AI Subdivisions Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Subdivisions Section\',\'AI Subdivisions\', \'AI Subdivisions Number\', \'AI Subdivisions Name\', \'AI Subdivisions Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'AI Premises Permits\', \'AI Premises Permits Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'AI Premises Permits Section\',\'AI Premises Permits\', \'AI Premises Permits Number\', \'AI Premises Permits Name\', \'AI Premises Permits Zip\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Name of Additional Insured {{obj1}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 != 0">Mailing Address {{obj1}}</span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'Contractors\',\'Tools Scheduled Number\') track by $index">\n' +
    '			<span class="span12" style="margin-left: 0px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'Contractors Section\') track by $index">\n' +
    '			<span><span ng-if="obj1.substr(0,29) !== \'Tools and Equipment - Blanket\' && obj1.substr(0,3) !== \'Sub\' && obj1.substr(0,3) !== \'Non\' && obj1.substr(0,3) !== \'Emp\'" class="span12" style="margin-left: 13px; line-height: 26px;">{{obj1}}</span>\n' +
    '			<span ng-if="obj1.substr(0,3) === \'Non\'" class="span6" style="width: 48.8%;margin-left: 13px; line-height: 26px;">{{obj1}}</span>\n' +
    '			<span ng-if="obj1.substr(0,3) === \'Emp\'" class="span5" style="width: 48.6%; margin-left: 13px; line-height: 26px;">{{obj1}}</span>\n' +
    '			<span ng-if="obj1.substr(0,29) === \'Tools and Equipment - Blanket\'" class="span6" style="margin-left: 13px; line-height: 26px;">{{obj1}}</span>\n' +
    '			<span ng-if="obj1.substr(0,3) === \'Sub\'" class="span5" style="width: 45%; margin-left: 13px; line-height: 26px;">{{obj1}}</span></span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj2 in fetchDescriptionFields(\'Contractors Section\') track by $index">\n' +
    '			<span class="span5" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Description: {{obj2}} </span>\n' +
    '			<span class="span6" style="width: 48%; margin-left: 3px; line-height: 26px" ng-if="$index%2 != 0">Limit: {{obj2}} </span>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-repeat="obj in fetchBusinessOeAiHeaderFields(\'Photography\', \'Photography Number\') track by $index">\n' +
    '			<span class="span6" style="width: 50%; margin-left: 6px; line-height: 26px" ng-if="$index == 0">{{obj}}</span>\n' +
    '			<span class="span5" style="width: 48%; margin-left: 6px; line-height: 26px" ng-if="$index == 1">Quantity: {{obj}}</span>\n' +
    '		</div>\n' +
    '		<div ng-repeat="obj1 in fetchOeAiDatapointFields(\'Photography\',\'Photography\', \'Photography Number\', \'Photography Limit\') track by $index" style="height: 0px;">\n' +
    '			<span class="span5" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Description {{aiPhotographyDesc[$index/2]}} </span> <br>\n' +
    '			<span class="span6" style="width: 48%; margin-left: 0px; line-height: 26px" ng-if="$index%2 == 0">Make {{aiPhotographyMake[$index/2]}} </span> <br>\n' +
    '			<span class="span12" style="margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Limit {{obj1}} </span> <br>\n' +
    '			<span class="span5" style="width: 50%; margin-left: 13px; line-height: 26px" ng-if="$index%2 == 0">Model Number {{aiPhotographyModel[$index/2]}} </span> <br>\n' +
    '			<span class="span6" style="width: 48%; margin-left: 0px; line-height: 26px" ng-if="$index%2 == 0">Serial Number {{aiPhotographySerial[$index/2]}} </span> <br>\n' +
    '		</div>\n' +
    '\n' +
    '	</div>\n' +
    '\n' +
    '	<div ng-if="section.enumeration && ! AdditionalInsuredFields()" class="alert alert-text" style="margin-bottom: 10px; clear: both">\n' +
    '		<div class="enumerationTitle"><span ng-if="previousEnumeration">{{::previousEnumeration}} -&gt; </span>{{::section.enumeration}}&nbsp;{{::section.title}}<span ng-if="fetchLocationDefaultValues()">&#44; {{::section.locationDefaultValues}}</span></div>\n' +
    '	</div>\n' +
    '\n' +
    '	<formly-form model="{}" fields="fields"></formly-form>\n' +
    '	<div ng-repeat="branch in sectionBranches">\n' +
    '		<application-section section="branch" depth="nextDepth" previous-enumeration="section.enumeration" program="program"></application-section>\n' +
    '	</div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/claimDetail/claimDetail/template/template.html',
    '<div class="policy-detail" ng-if="uiState.loading">\n' +
    '    <loading-bar></loading-bar>\n' +
    '</div>\n' +
    '<div class="policy-detail" ng-if="!uiState.loading && uiState.error">\n' +
    '    <div class="alert alert-error">Occurrence detail not available at this time. Please contact your adjuster for more information.</div>\n' +
    '</div>\n' +
    '<div class="row-fluid policy-detail" ng-if="!uiState.loading && !uiState.error">\n' +
    '    <div class="span2">\n' +
    '        <div class="policy-info well" claim-info policy-number="policyNumber" claim="claim"></div>\n' +
    '    </div>\n' +
    '    <div class="span10">\n' +
    '        <div class="banner">\n' +
    '            Insured Information\n' +
    '        </div>\n' +
    '        <table class="table-plain">\n' +
    '            <tbody>\n' +
    '                <tr>\n' +
    '                    <td class="info span2">Insured</td>\n' +
    '                    <td><span class="pull-left">{{:: claim.insuredName }}</span></td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td class="info span2">Address</td>\n' +
    '                    <td>\n' +
    '                        <ul class="unstyled">\n' +
    '                            <li>{{:: claim.account.address1 }}</li>\n' +
    '                            <li ng-if="claim.account.address2">{{:: claim.account.address2 }}</li>\n' +
    '                            <li>{{:: claim.account.city }}</li>\n' +
    '                            <li>{{:: claim.account.state }}, {{:: claim.account.zipCode }}</li>\n' +
    '                        </ul>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '        <div class="banner">\n' +
    '            Occurrence Information\n' +
    '        </div>\n' +
    '        <table class="table-plain">\n' +
    '            <tbody>\n' +
    '                <tr>\n' +
    '                    <td class="info span2">Loss Date</td>\n' +
    '                    <td>{{:: claim.occurrenceDate | date:"MM/dd/yyyy" }}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td class="info span2">Reported Date</td>\n' +
    '                    <td>{{:: claim.carrierNotifiedDate | date:"MM/dd/yyyy" }}</td>\n' +
    '                </tr>\n' +
    '\n' +
    '                <tr ng-if="!claim.insuredType">\n' +
    '                    <td class="info span2">Description</td>\n' +
    '                    <td>{{:: claim.occurrenceNarrative }}</td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/claimDetail/claimInfo/template/template.html',
    '<h3>Details</h3>\n' +
    '<table class="table-plain">\n' +
    '    <tbody>\n' +
    '        <tr>\n' +
    '            <td>Policy #</td>\n' +
    '            <td>{{:: policyNumber }}\n' +
    '        </td></tr>\n' +
    '        <tr>\n' +
    '            <td>Occurrence #</td>\n' +
    '            <td>{{:: claim.occurrenceNumber }}</td>\n' +
    '        </tr>\n' +
    '        <tr>\n' +
    '            <td>Loss Date</td>\n' +
    '            <td>{{:: claim.occurrenceDate | date:"MM/dd/yyyy" }}</td>\n' +
    '        </tr>\n' +
    '        <tr>\n' +
    '            <td>Reported Date</td>\n' +
    '            <td>{{:: claim.carrierNotifiedDate | date:"MM/dd/yyyy" }}</td>\n' +
    '        </tr>\n' +
    '   		<!--\n' +
    '        <tr>\n' +
    '            <td>Reserved</td>\n' +
    '            <td>{{:: claim.lossCoverage[0].reserved | formatMoney }}</td>\n' +
    '        </tr>\n' +
    '        <tr>\n' +
    '            <td>Paid</td>\n' +
    '            <td>{{:: claim.lossCoverage[0].paid | formatMoney }}</td>\n' +
    '        </tr>\n' +
    '        <tr>\n' +
    '            <td>Outstanding</td>\n' +
    '            <td>{{:: claim.lossCoverage[0].outstanding | formatMoney }}</td>\n' +
    '        </tr>\n' +
    '		<tr>\n' +
    '            <td>Premium</td>\n' +
    '            <td>{{:: claim.premium | formatMoney }}</td>\n' +
    '        </tr>\n' +
    '		-->\n' +
    '        <tr>\n' +
    '            <td>Status</td>\n' +
    '            <td>{{:: claim.status }}</td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/claimDetail/insuredClaimInfo/template/template.html',
    '<div class="claim row-fluid">\n' +
    '    <div class="span2">\n' +
    '        <div class="detail_pure well sidebar-nav pure">\n' +
    '            <h3>Details</h3>\n' +
    '            <table width="100%" class="table-plain detailsTable">\n' +
    '                <tr>\n' +
    '                    <td><span class="label label-product-wc">WC</span> Policy #</td>\n' +
    '                    <td class="policyNumber">{{:: claim.claim.policyNumber}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Claim #</td>\n' +
    '                    <td class="claimNumber">{{:: claim.claim.claimNumber}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Accident Date</td>\n' +
    '                    <td class="accidentDate">{{:: claim.accident.date}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Carrier Notified</td>\n' +
    '                    <td class="notifyDate">{{:: claim.claim.notifyDate}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Reserved</td>\n' +
    '                    <td class="reserved">{{:: claim.claim.reserved}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Paid</td>\n' +
    '                    <td class="paid">{{:: claim.claim.paid}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Outstanding</td>\n' +
    '                    <td class="outstanding">{{:: claim.claim.outstanding}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Premium</td>\n' +
    '                    <td class="premium">{{:: claim.claim.premium}}</td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>Status</td>\n' +
    '                    <td class="status">{{:: claim.claim.status}}</td>\n' +
    '                </tr>\n' +
    '\n' +
    '            </table>\n' +
    '        </div>\n' +
    '        <!--/.well -->\n' +
    '\n' +
    '        <ul class="nav nav-list">\n' +
    '            <li class="nav-header">Claims History</li>\n' +
    '        </ul>\n' +
    '        <div class="alert alert-warning policyHolderAlertContainer">\n' +
    '            <ul ng-repeat="year in claimYears" class="nav nav-list">\n' +
    '                <li>{{:: year }}</li>\n' +
    '                <li ng-repeat="c in claimsByYear[year]">\n' +
    '                    <div>\n' +
    '                        <span product-label="c.program" auto-width="true">{{:: c.productLine }}</span>\n' +
    '                        <a href ng-click="openClaimDetail(  c.claimId, c.productLine )">Claim {{:: c.claimNumber }}</a>\n' +
    '                    </div>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '        <!--/.well -->\n' +
    '    </div> <!--- close span2 --->\n' +
    '\n' +
    '    <div class="span10">\n' +
    '        <div class="claimSubHeader_pure">\n' +
    '            <h2>&nbsp<small><span class="claimInsuredCityState">{{:: claim.insured.cityState}}</span>&nbsp;|&nbsp;Claim Number #: <span class="claimNumber">{{:: claim.claim.claimNumber}}</span></small></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid">\n' +
    '            <div class="span6">\n' +
    '                <div class="banner-blue">Claim Information</div>\n' +
    '                <table class="claim_pure table-plain pure">\n' +
    '                    <tr>\n' +
    '                        <td class="info">Adjuster</td>\n' +
    '                        <td class="adjuster">{{:: claim.claim.adjuster}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Adjuster Email</td>\n' +
    '                        <td class="adjusterEmail">{{:: claim.claim.adjusterEmail}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Adjuster Phone</td>\n' +
    '                        <td class="adjusterPhone">{{:: claim.claim.adjusterDirectLine}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Claim Type</td>\n' +
    '                        <td class="claimType">{{:: claim.claim.claimType}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Benefit State</td>\n' +
    '                        <td class="benefitState">{{:: claim.claim.benefitState}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Close Date</td>\n' +
    '                        <td class="closeDate">{{:: claim.claim.closeDate}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info"></td>\n' +
    '                        <td class="daysOpen">{{:: claim.claim.daysOpen}}</td>\n' +
    '                    </tr>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '            <div class="span6">\n' +
    '                <div class="banner-blue">Insured Information</div>\n' +
    '                <table class="insured_pure table-plain pure">\n' +
    '                    <tr>\n' +
    '                        <td class="info">Insured</td>\n' +
    '                        <td class="name">{{:: claim.insured.name}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">SIC Code</td>\n' +
    '                        <td class="sicCode">{{:: claim.insured.sicCode}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">NAICS Code</td>\n' +
    '                        <td class="naicsCode">{{:: claim.insured.naicsCode}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Address</td>\n' +
    '                        <td ng-bind-html="claim.insured.address" class="address">{{:: claim.insured.address}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Contact</td>\n' +
    '                        <td ng-bind-html="claim.insured.contact" class="contact">{{:: claim.insured.contact}}</td>\n' +
    '                    </tr>\n' +
    '                </table>\n' +
    '                <br>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="row-fluid">\n' +
    '            <div class="span12">\n' +
    '                <div class="banner-blue">Accident Information</div>\n' +
    '                <table class="accident_pure table-plain pure">\n' +
    '                    <tr>\n' +
    '                        <td class="info span2">Accident Date</td>\n' +
    '                        <td class="date">{{:: claim.accident.date}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Accident Time</td>\n' +
    '                        <td class="time">{{:: claim.accident.time}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Carrier Notified</td>\n' +
    '                        <td class="notifyDate">{{:: claim.accident.notifyDate}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Class Code</td>\n' +
    '                        <td><span class="classCode">{{:: claim.accident.classCode}}</span>: <span class="classCodeDescription">{{:: claim.accident.classCodeDescription}}</span></td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Death Date</td>\n' +
    '                        <td class="deathDate">{{:: claim.accident.deathDate}}</td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="info">Cause of Injury</td>\n' +
    '                        <td class="cause">{{:: claim.accident.cause}}</td>\n' +
    '                    </tr>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <br>\n' +
    '\n' +
    '        <div class="banner-blue">Payment Information</div>\n' +
    '        <table class="payment_pure table table-striped table-condensed table-bordered pure">\n' +
    '            <thead>\n' +
    '            <tr>\n' +
    '                <th>Medical</th>\n' +
    '                <th>Expense</th>\n' +
    '                <th>Indemnity</th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '            <tr>\n' +
    '                <td class="medical">{{:: claim.payment.medical}}</td>\n' +
    '                <td class="expense">{{:: claim.payment.expense}}</td>\n' +
    '                <td class="indemnity">{{:: claim.payment.indemnity}}</td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '        <br>\n' +
    '\n' +
    '        <div class="banner-blue">Injury Information</div>\n' +
    '        <table class="injury_pure table table-striped table-condensed table-bordered pure">\n' +
    '            <thead>\n' +
    '            <tr>\n' +
    '                <th>#</th>\n' +
    '                <th>Body Part</th>\n' +
    '                <th>Location</th>\n' +
    '                <th>Sub-Location</th>\n' +
    '                <th>Side</th>\n' +
    '                <th>#</th>\n' +
    '                <th>Nature of Injury</th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '            <tr ng-repeat="row in claim.rows">\n' +
    '                <td class="bodyPartCode" class="piiMask">{{:: row.bodyPartCode}}</td>\n' +
    '                <td class="bodyPartCategoryName" class="piiMask">{{:: row.bodyPartCategoryName}}</td>\n' +
    '                <td class="bodyPartName" class="piiMask">{{:: row.bodyPartName}}</td>\n' +
    '                <td class="bodyPartSubName" class="piiMask">{{:: row.bodyPartSubName}}</td>\n' +
    '                <td class="side" class="piiMask">{{:: row.side}}</td>\n' +
    '                <td class="natureCode" class="piiMask">{{:: row.natureCode}}</td>\n' +
    '                <td class="natureName" class="piiMask">{{:: row.natureName}}</td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div> <!--- close span10--->\n' +
    '</div> <!--- close row-fluid --->');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/insuredRegistration/accountLookup/template/template.html',
    '<div class="row" ng-if="!condensed">\n' +
    '	<div class="span5 offset3">\n' +
    '		<h1>{{ headerText }}</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row" ng-if="!condensed">\n' +
    '	<div class="span5 offset3">\n' +
    '		<p class="login-message">Already have an account? <a href="#/login">Log In</a></p>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-class="!condensed ? \'row\' : \'\'">\n' +
    '	<div ng-class="!condensed ? \'span5 offset3\' : \'\'">\n' +
    '		<div error-message="\'We were unable to uniquely identify your account. Please double check your account or policy number and zip code and then try again. If this error persists, please contact customer support at 888-500-3344.\'" ng-if="uiState.cantFindAccount"></div>\n' +
    '		<form class="form" ng-submit="submit()">\n' +
    '			<input id="test" ng-model="form.test" type="text">\n' +
    '			<div class="control-group">\n' +
    '				<label for="account-number" class="control-label">Markel account number or policy number</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="account-number" class="piiSafe" ng-model="form.accountNumber">\n' +
    '					<div error-message error-message-array="uiState.errors.accountId" ng-if="uiState.errors.accountId.length"></div>\n' +
    '				</div>\n' +
    '				<span class="help-block">\n' +
    '					<p>\n' +
    '						<strong>Quick tip:</strong>\n' +
    '						Your Markel account number and policy number can be found on the invoice or payment schedule.\n' +
    '						When making a payment, please be aware each billing account needs to be paid separately to ensure accurate posting of your payment.\n' +
    '						If you need additional assistance, please call us at 888-500-3344.\n' +
    '					</p>\n' +
    '				</span>\n' +
    '			</div>\n' +
    '			<div class="control-group">\n' +
    '				<label for="zip-code" class="control-label">Mailing Zip Code</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="zip-code" class="piiSafe" ng-model="form.zipCode">\n' +
    '					<div error-message error-message-array="uiState.errors.zipCode" ng-if="uiState.errors.zipCode.length"></div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div class="control-group" ng-if="includeEmail">\n' +
    '				<label for="zip-code" class="control-label">Email Address</label>\n' +
    '				<div class="controls">\n' +
    '					<input type="text" id="email" ng-model="form.email">\n' +
    '					<div error-message error-message-array="uiState.errors.email" ng-if="uiState.errors.email.length"></div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<button type="submit" class="btn btn-primary" ng-disabled="!formIsValid() || uiState.submitting">\n' +
    '				<span ng-if="!uiState.submitting">{{submitButtonText}}</span>\n' +
    '				<span ng-if="uiState.submitting">Submitting...</span>\n' +
    '			</button>\n' +
    '		</form>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/insuredRegistration/register/template/template.html',
    '<div class="row">\n' +
    '	<div class="span12">\n' +
    '		<h1>Registration</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '	<div class="span12">\n' +
    '		<form class="form" ng-submit="submit()">\n' +
    '			<hr>\n' +
    '			<fieldset>\n' +
    '				<legend>User Information</legend>\n' +
    '				<div class="control-group">\n' +
    '					<label for="username" class="control-label">Username*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="text" id="username" ng-model="form.userName">\n' +
    '						<div error-message error-message-array="uiState.errors.userName" ng-if="uiState.errors.userName.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<label for="first-name" class="control-label">First Name*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="text" id="first-name" ng-model="form.firstName">\n' +
    '						<div error-message error-message-array="uiState.errors.firstName" ng-if="uiState.errors.firstName.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<label for="last-name" class="control-label">Last Name*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="text" id="last-name" ng-model="form.lastName">\n' +
    '						<div error-message error-message-array="uiState.errors.lastName" ng-if="uiState.errors.lastName.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<label for="email" class="control-label">Email Address*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="text" id="email" ng-model="form.email">\n' +
    '						<div error-message error-message-array="uiState.errors.email" ng-if="uiState.errors.email.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<label for="email-confirmation" class="control-label">Confirm Email Address*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="text" id="email-confirmation" ng-model="form.emailConfirmation">\n' +
    '						<div error-message error-message-array="uiState.errors.emailConfirmation" ng-if="uiState.errors.emailConfirmation.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</fieldset>\n' +
    '			<fieldset>\n' +
    '				<legend>Create your Password</legend>\n' +
    '				<div class="control-group">\n' +
    '					<label for="password" class="control-label">Password*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="password" id="password" ng-model="form.password">\n' +
    '						<div error-message error-message-array="uiState.errors.password" ng-if="uiState.errors.password.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<label for="password-confirmation" class="control-label">Confirm Password*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="password" id="password-confirmation" ng-model="form.passwordConfirmation">\n' +
    '						<div error-message error-message-array="uiState.errors.passwordConfirmation" ng-if="uiState.errors.passwordConfirmation.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<p>Your password must:</p>\n' +
    '				<ul>\n' +
    '					<li>Be at least 8 characters long</li>\n' +
    '					<li>Contain at least one uppercase letter, one lowercase letter, and one digit. </li>\n' +
    '					<li>Not contain your username.</li>\n' +
    '					<li>Not contain your email address.</li>\n' +
    '				</ul>\n' +
    '			</fieldset>\n' +
    '			<fieldset>\n' +
    '				<legend>Security Settings</legend>\n' +
    '				<div class="control-group">\n' +
    '					<label for="question" class="control-label">Security Question*</label>\n' +
    '					<div class="controls">\n' +
    '						<select id="question" class="input-xxlarge" ng-model="form.securityQuestionId" ng-options="q.id as q.text for q in questions">\n' +
    '						</select>\n' +
    '						<div error-message error-message-array="uiState.errors.securityQuestionId" ng-if="uiState.errors.securityQuestionId.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<label for="answer" class="control-label">Answer*</label>\n' +
    '					<div class="controls">\n' +
    '						<input type="text" id="answer" ng-model="form.securityQuestionAnswer" class="input-xlarge">\n' +
    '						<div error-message error-message-array="uiState.errors.securityQuestionAnswer" ng-if="uiState.errors.securityQuestionAnswer.length"></div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</fieldset>\n' +
    '			<button type="submit" class="btn btn-primary" ng-disabled="uiState.submitting">\n' +
    '				<span ng-if="!uiState.submitting">Register</span>\n' +
    '				<span ng-if="uiState.submitting">Registering...</span>\n' +
    '			</button>\n' +
    '		</form>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/insuredRegistration/termsAndConditions/template/template.html',
    '<div class="row">\n' +
    '	<div class="span12">\n' +
    '		<h1>Terms and Conditions</h1>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '	<div class="span12" ng-if="!terms">\n' +
    '		<div loading-bar></div>\n' +
    '	</div>\n' +
    '	<div class="span12" ng-if="terms">\n' +
    '		<div ng-bind-html="terms"></div>\n' +
    '		<button type="button" class="btn btn-primary" ng-click="submit()">Accept Terms</button>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/marketedPrograms/detail/template/fileUploadModal.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Upload an Application</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<form class="form" method="post" action="{{ formAction }}" ng-submit="onSubmit()" file-upload file-upload-on-success="onUploadSuccess" file-upload-on-error="onUploadFailure">\n' +
    '			<input type="hidden" name="marketedProgramId" value="{{ program.id }}">\n' +
    '			<input type="hidden" name="profileToken" value="{{ profileToken }}">\n' +
    '			<div class="alert alert-error" ng-if="uiState.errors.length">\n' +
    '				<p ng-repeat="error in uiState.errors">{{ error }}</p>\n' +
    '			</div>\n' +
    '			<div ng-if="!uiState.completed">\n' +
    '				<div class="control-group">\n' +
    '					<div class="controls" data-ng-repeat="application in applications">\n' +
    '						<input type="file" name="uploadedApplications" multiple="multiple">\n' +
    '						<button type="button" class="btn btn-small uploadSetRemoval" ng-if="!$first" ng-click="removeApplication($index)">\n' +
    '							<span><i class="icon-minus"></i> Remove</span>\n' +
    '						</button>\n' +
    '					</div>\n' +
    '					<div>\n' +
    '						<p>Click <a href ng-click="addNewApplication()">here</a> to choose additional files.</p>\n' +
    '					</div>\n' +
    '					<div>\n' +
    '						Comments: <textarea class="span7" rows="5" name="comments"></textarea>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<button type="submit" class="btn btn-primary" ng-disabled="uiState.submitting">\n' +
    '					<span ng-if="!uiState.submitting">Upload</span>\n' +
    '					<span ng-if="uiState.submitting">Uploading...</span>\n' +
    '				</button>\n' +
    '			</div>\n' +
    '			<div class="alert alert-success" ng-if="uiState.completed">\n' +
    '				<p>Your application has been uploaded and you will hear from an underwriter soon.</p>\n' +
    '			</div>\n' +
    '		</form>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/marketedPrograms/detail/template/template.html',
    '<!-- the "overview" program -->\n' +
    '<div class="span12 alpha coverage-cnt" ng-if="!program.id">\n' +
    '	<h2>Markel Specialty provides customized small business insurance programs</h2>\n' +
    '	<h3>Specialty insurance by real specialists</h3>\n' +
    '	<p>Markel Specialty specializes in providing insurance products for a variety of niche markets. Working with retail agents across the United States, our experienced underwriters know the risks your clients face and can craft coverage to meet their unique needs.</p>\n' +
    '	<p>Producers like you are the backbone of our business. We strive to give you the tools you need to grow your business; because when you succeed, so do we. Building on our underwriting and risk management expertise, you have access to specialty commercial programs to increase your revenues and make you an even more valuable resource for your clients.</p>\n' +
    '</div>\n' +
    '\n' +
    '<!-- dynamic programs -->\n' +
    '<div ng-if="program.id">\n' +
    '	<div class="span8 alpha coverage-cnt">\n' +
    '		<h2>{{ program.name }}</h2>\n' +
    '		<h3>{{ program.shortDescription }}</h3>\n' +
    '		<div ng-bind-html="program.description"></div>\n' +
    '	</div>\n' +
    '	<div class="span3 omega">\n' +
    '		<div class="subnav" style="width: 220px">\n' +
    '			<ul class="fa-ul">			\n' +
    '				<li ng-if="program.name != \'Thimble\'"> \n' +
    '					<a ng-href="{{ getApplicationUrl() }}" target="{{ getTarget() }}">\n' +
    '						<span>Get a Quote</span>\n' +
    '					</a>\n' +
    '				</li>	\n' +
    '				<li ng-if="program.contactInformation">\n' +
    '					<a ng-href="{{ program.contactInformation }}" target="_blank">\n' +
    '						<span>Contact Us</span>\n' +
    '					</a>\n' +
    '				</li>\n' +
    '				<li ng-if="program.isUploadBasedSubmission && program.id != 17">\n' +
    '					<a href ng-click="openUploadModal()">\n' +
    '						<span>Upload an Application</span>\n' +
    '					</a>\n' +
    '				</li>\n' +
    '				<li ng-if="program.offlineApplicationUrl">\n' +
    '					<a href="/#/application/search" target="_blank">\n' +
    '						<span>Access Markel application(s)</span>\n' +
    '					</a>\n' +
    '				</li>\n' +
    '			</ul>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/marketedPrograms/list/template/template.html',
    '<ul id="leftNav" class="nav nav-tabs nav-stacked">\n' +
    '	<li ng-class="{ active: !currentProgramId }">\n' +
    '		<a href ng-click="onSelect({})">Overview</a>\n' +
    '	</li>\n' +
    '	<li ng-repeat="program in programs" ng-class="{ active: currentProgramId == program.id }">\n' +
    '		<a ng-if="program.isNewProgram" href ng-click="onSelect( program )">{{ program.name }}&nbsp;&nbsp;<span id="newProgram">Featured!</span></a>\n' +
    '	</li>\n' +
    '	<li ng-repeat="program in programs" ng-class="{ active: currentProgramId == program.id }">\n' +
    '		<a ng-if="!program.isNewProgram" href ng-click="onSelect( program )">{{ program.name }}</a>\n' +
    '	</li>\n' +
    '</ul>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/container/template/template.html',
    '<style>\n' +
    '    .message-list .table{ margin-bottom:0px;}\n' +
    '    .message-list .arrow{ width: 20px;}\n' +
    '    .message-list .unread{ width: 20px;}\n' +
    '    .message-list .number{ width: 200px;}\n' +
    '    .message-list td.attachment, .message-list th.attachment{ width: 80px;}\n' +
    '    .message-list td.attachment , .message-list td.number{text-align:left;}\n' +
    '    .message-list td.attachment ul{list-style: none;padding:0px;margin:0 0 0 -5px;}\n' +
    '    .message-list td.attachment .glyphicon-paperclip{margin-right:5px;}\n' +
    '    .message-list .type{ width: 165px;}\n' +
    '    .message-list .type span{ \n' +
    '        width: 80%;\n' +
    '        text-transform: uppercase;\n' +
    '        font-weight: bold;\n' +
    '        font-size: 11px;\n' +
    '        color: #3336;\n' +
    '    }\n' +
    '    .message-list .btn-default{background-color:white;background-image:none;}\n' +
    '    .message-list .date-time{width:150px;}\n' +
    '    .message-list .subject{}\n' +
    '    .message-list td.arrow span{cursor:pointer;}\n' +
    '    .message-list .no_messages{height:130px;width:600px;font-size:20px;text-transform: uppercase;border-radius:4px;margin-top:30px;}\n' +
    '    .message-list .no_messages td{vertical-align:middle;text-align:center;}\n' +
    '    .message-container .toolbar input{margin-bottom:2px;margin-left:50px;}\n' +
    '    .message-form label{width:160px!important;}\n' +
    '    .message-container .uploaded-file span{ \n' +
    '        padding-top: 8px;\n' +
    '        padding-bottom: 8px;\n' +
    '        margin-top: 2px;\n' +
    '        margin-bottom: 2px;\n' +
    '        margin-right:5px;\n' +
    '        padding-left: 8px;\n' +
    '        padding-right: 4px;\n' +
    '        border-radius: 5px;\n' +
    '        background-color: #e2e3e3;\n' +
    '     }\n' +
    '\n' +
    '     .message-container .remove-file{\n' +
    '        color:grey;\n' +
    '     }\n' +
    '\n' +
    '    .detail-list .type .label{margin-left:10px;}\n' +
    '\n' +
    '    .reply-table th,.reply-table td{border:none;}\n' +
    '    .reply-table {width:100%;}\n' +
    '    .reply-row:hover{background-color:none;}\n' +
    '\n' +
    '    .message-actions {padding: 10px 20px 38px;}\n' +
    '\n' +
    '\n' +
    '\n' +
    '</style>\n' +
    '<span class="message-container">\n' +
    '    <div class="well span12 toolbar" style="margin:0px 0px 5px 0px;">\n' +
    '        <span class="btn btn-success btn-new-message" ng-click="setMessageView($event,{view:\'send\'})" ng-show="view === \'list\'"><span class="glyphicon glyphicon-inbox"></span>&nbsp; New Message</span> \n' +
    '        <span class="btn btn-primary btn-back-to-inbox" ng-click="setMessageView($event,{view:\'list\'})" ng-show="view !== \'list\'"><span class="glyphicon glyphicon-th-list"></span> &nbsp;Back to Inbox</span>\n' +
    '        <span ng-show="view === \'list\'"><input class="span6" ng-model="query" placeholder="Filter messages..."></span>\n' +
    '        <!--<span class="pull-right"><span class="btn btn-primary" ng-click="get_threads()"><span class="glyphicon glyphicon-refresh" ng-show="view === \'list\'"></span> &nbsp;Refresh</span></span>-->\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row-fluid">\n' +
    '        <div class="span12">\n' +
    '            <div message-list ng-show="view === \'list\'" threads="threads" account="account" query="query"></div>\n' +
    '            <div message-form ng-show="view === \'send\'" account="account" accounts="accounts"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</span>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/form/template/template.html',
    '<div class="message-form form-horizontal">\n' +
    '    <div ng-show="errors.visible" class="alert alert-error">\n' +
    '        <p><strong>Please correct the following fields: </strong></p>\n' +
    '        <ul>\n' +
    '            <li ng-show="errors.reason">Account / Policy Number is a required field</li> \n' +
    '            <li ng-show="errors.message">Message is a required field</li>\n' +
    '            <li ng-show="errors.subject">Subject is a required field</li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="policy-row control-group">\n' +
    '        <label for="policy">Account / Policy Number <span style="color:red;font-weight: bold;">*</span></label>\n' +
    '        <div class="controls">\n' +
    '            <select ng-model="form.reasonId" class="submissionId piiSafe" ng-class="{ \'alert alert-error\' : errors.reason }" ng-change="set_subject()">\n' +
    '                <optgroup label="Policies" ng-if="reasons.policies.length > 0">\n' +
    '                    <option ng-repeat="policy in visibilePolicies = (reasons.policies | PolicyVisibleFilter:user.type)" value="{{:: policy.policyNumber}}">{{:: policy.policyNumber}} - {{policy.program}}</option>\n' +
    '                </optgroup>\n' +
    '                <optgroup label="Submissions" ng-if="reasons.submissions.length > 0">\n' +
    '                    <option ng-repeat="submission in reasons.submissions | deDuplicate:\'submissionId\'" value="{{:: submission.submissionId}}">{{:: submission.submissionId}} - {{submission.program}}</option>\n' +
    '                </optgroup>\n' +
    '                <optgroup label="Renewals" ng-if="reasons.renewals.length > 0">\n' +
    '                    <option ng-repeat="renewal in reasons.renewals | deDuplicate:\'submissionId\'" value="{{:: renewal.submissionId}}">{{:: renewal.submissionId}}</option>\n' +
    '                </optgroup>\n' +
    '            </select>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="subject-row control-group">\n' +
    '        <label class="control-label">Subject <span style="color:red;font-weight: bold;">*</span></label>\n' +
    '        <div class="subject controls">\n' +
    '            <input ng-model="form.subject" ng-class="{ \'alert alert-error\' : errors.subject }" maxlength="255" class="span8 input-count piiSafe" size="30" type="text">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="message-row control-group">\n' +
    '        <label>Message <span style="color:red;font-weight: bold;">*</span></label>\n' +
    '        <div class="message controls">\n' +
    '            <textarea ng-model="form.message" rows="15" class="span8 piiSafe" ng-class="{ \'alert alert-error\' : errors.message }" cols="80"></textarea>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '   <div class="file-row control-group">\n' +
    '        <label class="control-label">&nbsp;</label>\n' +
    '        <div class="controls">\n' +
    '            <div class="uploaded-file">\n' +
    '                <span ng-repeat="fileName in form.fileNames track by $index">{{fileName}}\n' +
    '                    <span class="remove-file glyphicon glyphicon-remove" ng-click="removeItem($index, thread)"></span>\n' +
    '                </span>\n' +
    '                <div ng-if="form.fileNames.length == 0">&nbsp;</div>\n' +
    '            </div>\n' +
    '            <span class="upload-file btn" ng-click="openFileUpload()">Attach File</span>\n' +
    '            <span class="delete-file btn" ng-click="clearSearch()">Clear</span>\n' +
    '            <input class="upload-file" type="hidden">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="form-actions">\n' +
    '        <button class="message-send btn btn-primary" ng-click="send($event)">Send Message</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/form/template/uploadAttachmentModal.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="$parent.close()">&times;</a><h3>Select File to Attach</h3>\n' +
    '</div>\n' +
    '\n' +
    '<div class="modal-body" style="padding-left: 17px;">\n' +
    '	<div class="alert alert-error" ng-if="status.requiredError">File is required.</div>\n' +
    '	<div class="alert alert-error" ng-if="status.uploadError">{{ status.uploadError }}</div>\n' +
    '\n' +
    '	<form class="post" method="post" id="form_{{ formConfig.name }}" action="{{ formConfig.action }}" target="{{ formConfig.name }}" enctype="multipart/form-data" encoding="multipart/form-data">\n' +
    '		<input id="file-upload" type="file" class="span7" name="uploadDocument" ng-show="browser.msie" onchange="angular.element(this).scope().setFile(this)">\n' +
    '		<input type="hidden" name="profileToken" value="{{ formConfig.profileToken }}">\n' +
    '		<div class="uploaded-file" ng-hide="browser.msie">{{ form.fileName }}</div>\n' +
    '		<button class="btn" type="button" ng-click="browse()" ng-hide="browser.msie">Browse</button>\n' +
    '		<button class="btn" ng-disabled="isRemoveFileEnabled()" ng-click="removeFile()">Remove File</button>\n' +
    '	</form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn" ng-click="$parent.close()">Close</button>\n' +
    '	<button class="btn btn-primary" ng-click="submit()">Attach File</button>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/list/template/forward.html',
    '<div class="modal-header">\n' +
    '    <h3>Forward Message Thread</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '    <div>\n' +
    '        <div ng-show="errors.visible" class="alert alert-error">\n' +
    '            <p><strong>Please correct the following fields: </strong></p>\n' +
    '            <ul>\n' +
    '                <li ng-show="errors.email">Email is a required field Please enter a valid Email Address</li>\n' +
    '                <li ng-show="errors.invalid">Please enter a valid Email Address</li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '        <label class="control-label" for="email">\n' +
    '            To Email\n' +
    '        </label>\n' +
    '        <div class="controls">\n' +
    '            <input ng-model="form.email" maxlength="120" class="span4" ng-class="{ \'alert alert-error\' : errors.email || errors.invalid }">\n' +
    '        </div>\n' +
    '        <div class="alert alert-success hidden" id="emailSent">Email Sent</div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn btn-success" ng-click="send($event)">Send</button>\n' +
    '    <button class="btn" ng-click="close()">Close</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/list/template/template.html',
    '<div class="message-list">\n' +
    '    <table class="table table-condensed" ng-if="threads.length">\n' +
    '        <thead>\n' +
    '        <tr>\n' +
    '            <th class="arrow">&nbsp;</th>\n' +
    '            <!--<th class="type">LOB</th>-->\n' +
    '            <th class="subject">Subject</th>\n' +
    '            <th class="attachment">Attachment</th>\n' +
    '            <th class="number">Policy # / Submission #</th>\n' +
    '            <th class="date-time">Last Updated</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '       \n' +
    '    </table>\n' +
    '\n' +
    '    <div ng-if="threads.length == 0">\n' +
    '        <table width="100%" height="100%">\n' +
    '            <tr>\n' +
    '                <td align="center" valign="middle">\n' +
    '                    <table class="no_messages alert alert-info">\n' +
    '                        <tr>\n' +
    '                            <td>No Messages in Inbox</td>\n' +
    '                        </tr>\n' +
    '                    </table>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '    <div ng-repeat="thread in threads | orderBy:\'recentDatePosted\':true | filter:query">\n' +
    '        <table class="table table-condensed">\n' +
    '            <tr class="messageThread">\n' +
    '                <td class="arrow">\n' +
    '                    <span ng-click="thread_click(thread)" ng-if="thread.open" class="glyphicon glyphicon-chevron-down" aria-hidden="true"></span>\n' +
    '                    <span ng-click="thread_click(thread)" ng-if="!thread.open" class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>\n' +
    '                </td>\n' +
    '	            <td class="subject" ng-class="{ \'link-strong\': thread.totalUnread > 0 }" style="display:flex;">\n' +
    '		            <a ng-click="thread_click(thread)" ng-bind-html="thread.subject"></a>\n' +
    '		            <span class="recent_message">&nbsp; - &nbsp;{{ thread.recentMessage.message }}</span>\n' +
    '	            </td>\n' +
    '              <td class="attachment">\n' +
    '                    <span ng-if="thread.attachmentCount" class="glyphicon glyphicon-paperclip"></span> \n' +
    '                    <span ng-if="!thread.attachmentCount" class="glyphicon glyphicon-minus"></span>\n' +
    '                </td>\n' +
    '                <td class="number">\n' +
    '                    <span ng-if="!thread.policyNumber">{{:: thread.masterQuoteId }}</span>\n' +
    '                    <span ng-if="thread.policyNumber">{{:: thread.policyNumber }}</span>\n' +
    '                </td>\n' +
    '                \n' +
    '                <td class="date-time">{{:: thread.recentDatePosted | date: "MM/dd/yyyy hh:mm a" }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr ng-if="thread.open">\n' +
    '                <td colspan="6" style="padding-left: 100px;border-top: none;">\n' +
    '                    <table class="table table-condensed" border="0">\n' +
    '                        <tbody ng-repeat="message in thread.messages | orderBy:\'datePosted\':true | filter:query" class="detail-list" ng-if="thread.open">\n' +
    '                            <tr>\n' +
    '                                <!--<td class="arrow"></td>-->\n' +
    '                                <td class="fromAddress" style="width: 120px;">{{:: message.fullName }}</td>\n' +
    '                                <td class="message">\n' +
    '	                                <span class="messageBody" ng-bind-html="message.message"></span>\n' +
    '                                </td>\n' +
    '                                <td class="attachment" colspan="1" style="width: 280px;">\n' +
    '                                    <span ng-if="!message.attachments.length" class="glyphicon glyphicon-minus pull-left"></span>\n' +
    '\n' +
    '                                        <span ng-if="message.attachments.length">\n' +
    '                                        <div class="attachment">\n' +
    '                                            <ul>\n' +
    '                                                <li ng-repeat="attachments in message.attachments">\n' +
    '                                                    <span class="glyphicon glyphicon-paperclip"></span>\n' +
    '                                                    <a ng-click="getDocument(thread,message.messageId,attachments.documentId)" target="_blank">{{attachments.fileName}}.{{attachments.type}}</a>\n' +
    '                                                </li>\n' +
    '                                            </ul>\n' +
    '                                        </div>\n' +
    '                                    </span>\n' +
    '                                </td>\n' +
    '                                <td class="date-time" style="width: 150px;">{{:: message.datePosted | date: "MM/dd/yyyy hh:mm a" }}</td>\n' +
    '                            </tr>\n' +
    '                        </tbody>\n' +
    '                    </table>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '\n' +
    '             <tr ng-if="thread.open" class="reply-row">\n' +
    '                <td colspan="6" style="border-top: none;">\n' +
    '                    <table class="reply-table">\n' +
    '                        <tr class="form-row" ng-if="thread.reply">\n' +
    '                            <td>\n' +
    '                                <div>\n' +
    '                                    <div ng-show="thread.errors.visible" class="alert alert-error">\n' +
    '                                        <p><strong>Please correct the following fields: </strong></p>\n' +
    '                                        <ul>\n' +
    '                                            <li ng-show="thread.errors.message">Message is a required field</li>\n' +
    '                                        </ul>\n' +
    '                                    </div>\n' +
    '                                    <div>\n' +
    '                                        <textarea ng-model="thread.form.message" ng-class="{ \'alert alert-error\' : thread.errors.message }" rows="15" class="span10" cols="80"></textarea>\n' +
    '                                    </div>\n' +
    '                                    <div class="file-row control-group">\n' +
    '                                        <label class="control-label">&nbsp;</label>\n' +
    '                                        <div class="controls">\n' +
    '                                            <div class="uploaded-file">\n' +
    '                                                <span ng-repeat="fileName in thread.form.fileNames track by $index">{{fileName}} \n' +
    '                                                    <span class="remove-file glyphicon glyphicon-remove" ng-click="removeItem($index, thread)"></span>\n' +
    '                                                </span>\n' +
    '                                                <div ng-if="thread.form.fileNames.length == 0">&nbsp;</div>\n' +
    '                                            </div>\n' +
    '                                            <span class="upload-file btn" ng-click="openFileUpload(thread)">Attach File</span>\n' +
    '                                            <span class="delete-file btn" ng-click="clearSearch(thread)">Clear</span>\n' +
    '                                            <input class="upload-file" type="hidden">\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                    <div class="form-actions">\n' +
    '                                        <div class="message-reply btn btn-primary" ng-click="send($event,thread)">Send Message</div>\n' +
    '                                        <span class="btn form-hide-btn" ng-click="cancel(thread)">Cancel</span>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                        <tr>\n' +
    '                            <td>\n' +
    '                                <div class="form-actions message-actions" ng-if="!thread.reply">\n' +
    '                                    <span class="pull-right">\n' +
    '                                        <span class="btn btn-primary" ng-click="reply(thread)">Reply</span>\n' +
    '                                        <span class="btn" ng-click="thread_forward(thread)"><span class="glyphicon glyphicon-envelope"></span> Forward</span>\n' +
    '                                        <span class="btn" ng-click="thread_print(thread)"><span class="glyphicon glyphicon-print"></span> Print</span>\n' +
    '                                        <span class="btn" ng-click="thread_pdf(thread)"><span class="glyphicon glyphicon-file"></span> PDF</span>\n' +
    '                                    </span>\n' +
    '                                </div>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                    </table>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/modal/template/sent.html',
    '<div class="modal-header">\n' +
    '	<h3>Message</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '	<div class="message-modal_pure">\n' +
    '		<p>Your Message has been sent.</p>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn" ng-click="close()">Close</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/modal/template/template.html',
    '<div class="modal-header">\n' +
    '	<h3>Reply to Message</h3>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '	<div class="message-modal_pure">\n' +
    '		<table width="100%">\n' +
    '			<tr>\n' +
    '				<td class="subject">\n' +
    '					<strong><a class="link-strong" ng-href="#" ng-bind-html="trusted.subject"></a></strong>\n' +
    '				</td>\n' +
    '			</tr>\n' +
    '			<tr>\n' +
    '				<td class="message" ng-bind-html="trusted.message"></td>\n' +
    '			</tr>\n' +
    '			<tr>\n' +
    '				<td>\n' +
    '					<div class="form-horizontal">\n' +
    '						<div class="message-row control-group">\n' +
    '							<label>Message</label>\n' +
    '							<div class="message controls">\n' +
    '								<textarea rows="5" class="span8" cols="40" ng-model="form.message"></textarea>\n' +
    '							</div>\n' +
    '							<br>\n' +
    '						</div>\n' +
    '						<div class="file-row control-group">\n' +
    '							<label class="control-label">&nbsp;</label>\n' +
    '							<div class="controls">\n' +
    '								<div class="uploaded-file">{{ form.upload.join(", ") }}&nbsp;</div>\n' +
    '								<span class="upload-file btn" ng-click="openFileUpload()">Attach File</span>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</td>\n' +
    '			</tr>\n' +
    '		</table>\n' +
    '		<div class="thread-link">\n' +
    '			<a href="" ng-click="goToThread()">View entire Message Thread</a>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn" ng-click="close()">Close</button>\n' +
    '	<button class="message-send btn btn-primary" ng-disabled="status.sending" ng-click="send()">Send Message</button>\n' +
    '	<div class="align-right" ng-if="status.sending">Processing...</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/modal/template/uploadAttachmentModal.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a><h3>Select File to Attach</h3>\n' +
    '</div>\n' +
    '\n' +
    '<div class="modal-body">\n' +
    '	<div class="alert alert-error" ng-if="status.requiredError">File is required.</div>\n' +
    '	<div class="alert alert-error" ng-if="status.uploadError">{{ status.uploadError }}</div>\n' +
    '\n' +
    '	<form class="post" method="post" id="form_{{ formConfig.name }}" action="{{ formConfig.action }}" target="{{ formConfig.name }}" enctype="multipart/form-data" encoding="multipart/form-data">\n' +
    '		<input id="file-upload" type="file" class="span7" name="uploadDocument" ng-show="browser.msie" onchange="angular.element(this).scope().setFile(this)">\n' +
    '		<div class="uploaded-file" ng-hide="browser.msie">{{ form.fileName }}</div>\n' +
    '		<button class="btn" type="button" ng-click="browse()" ng-hide="browser.msie">Browse</button>\n' +
    '	</form>\n' +
    '</div>\n' +
    '\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn" ng-click="$parent.close()">Close</button>\n' +
    '	<button class="btn btn-primary" ng-click="submit()">Attach File</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/message/modal/template/windowTemplate.html',
    '<div tabindex="-1" role="dialog" class="modal fade" ng-class="{in: animate}" ng-style="{\'z-index\': 1050 + index*10, display: \'block\', width: \'auto\', height: \'auto\', left: \'40%\'}" ng-click="close($event)">\n' +
    '	<div class="modal-dialog">\n' +
    '		<div class="modal-content" modal-transclude></div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policyDetail/accountHistory/template/template.html',
    '<ul class="nav nav-list">\n' +
    '	<li class="nav-header">Account History</li>\n' +
    '</ul>\n' +
    '<ul ng-repeat="year in policyYears" class="nav nav-list">\n' +
    '	<li>{{:: year }}</li>\n' +
    '	<li ng-repeat="p in policiesByYear[year]">\n' +
    '		<div>\n' +
    '			<span product-label="p.program" auto-width="true">{{:: p.program }}</span><br>\n' +
    '			Policy \n' +
    '			<a ng-if="p.accountViewVersion === 1" ng-href="#/isc/policy/{{:: p.policyNumber }}">{{:: p.policyNumber }}</a>\n' +
    '			<a href ng-if="p.accountViewVersion === 2" ng-click="openPolicyTab( p.policyNumber, p )">{{:: p.policyNumber }}</a>\n' +
    '		</div>\n' +
    '	</li>\n' +
    '</ul>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policyDetail/policyBilling/template/billingDocumentPagination.html',
    '<ul class="pagination" ng-if="1 < pages.length || !autoHide">\n' +
    '    <li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == 1 }">\n' +
    '        <a href="" ng-click="setCurrent(1)">&laquo;</a>\n' +
    '    </li>\n' +
    '    <li ng-if="directionLinks" ng-class="{ disabled : pagination.current == 1 }">\n' +
    '        <a href="" ng-click="setCurrent(pagination.current - 1)">&lsaquo;</a>\n' +
    '    </li>\n' +
    '    <li ng-repeat="pageNumber in pages track by tracker(pageNumber, $index)" ng-class="{ active : pagination.current == pageNumber, disabled : pageNumber == \'...\' }">\n' +
    '        <a href="" ng-click="setCurrent(pageNumber)">{{ pageNumber }}</a>\n' +
    '    </li>\n' +
    '\n' +
    '    <li ng-if="directionLinks" ng-class="{ disabled : pagination.current == pagination.last }">\n' +
    '        <a href="" ng-click="setCurrent(pagination.current + 1)">&rsaquo;</a>\n' +
    '    </li>\n' +
    '    <li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == pagination.last }">\n' +
    '        <a href="" ng-click="setCurrent(pagination.last)">&raquo;</a>\n' +
    '    </li>\n' +
    '</ul>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policyDetail/policyBilling/template/template.html',
    '<div class="banner">\n' +
    '	Billing\n' +
    '	<button class="btn btn-small pull-right btn-toggler" ng-click="toggleVisibility()">\n' +
    '		<span ng-if="uiState.visible"><i class="icon-minus toggler"></i> Hide</span>\n' +
    '		<span ng-if="!uiState.visible"><i class="icon-plus toggler"></i> Show</span>\n' +
    '	</button>\n' +
    '</div>\n' +
    '<div class="billing" ng-if="uiState.loading">\n' +
    '	<loading-bar></loading-bar>\n' +
    '</div>\n' +
    '<div class="billing" ng-if="!uiState.loading && !uiState.hasBillingAccountId && uiState.visible">\n' +
    '	<div class="alert alert-warning">Billing data for this policy is not yet available.</div>\n' +
    '</div>\n' +
    '<div class="billing" ng-if="!uiState.loading && uiState.error && uiState.visible && !uiState.isAgent && showBillingErrorMessage()">\n' +
    '	<div class="alert alert-error">We were unable to load your billing details.</div>\n' +
    '</div>\n' +
    '<div class="billing" ng-if="!uiState.loading && uiState.error && uiState.visible && !uiState.isAgent && !showBillingErrorMessage()">\n' +
    '	<div class="alert alert-error">The billing information on this account has been merged under the primary billing account. Please refer to account number <b>{{::account.primaryAccountId}}</b> for current billing details. If the account is not visible in the list, click <a href ng-click="openAddAccountModal()">here</a> to add it to the account view and make a payment.</div>\n' +
    '</div>\n' +
    '<div class="billing" ng-if="!uiState.loading && !uiState.error && uiState.visible && !uiState.isAgent && !showBillingErrorMessage()">\n' +
    '	<div class="alert alert-warning">The billing information on this account has been merged under the primary billing account. Please refer to account number <b>{{::account.primaryAccountId}}</b> for current billing details. If the account is not visible in the list, click <a href ng-click="openAddAccountModal()">here</a> to add it to the account view and make a payment.</div>\n' +
    '</div>\n' +
    '<div class="billing" ng-if="!uiState.loading && uiState.error && uiState.visible && uiState.isAgent && !showBillingErrorMessage()">\n' +
    '	<div ng-if="isSameAgencyPrimaryAccount()" class="alert alert-error">The billing information on this account has been merged under the primary billing account. Please refer to account number <b><a ng-href="#/account-summary/{{::account.primaryAccountId}}">{{::account.primaryAccountId}}</a></b> for current billing details.</div>\n' +
    '	<div ng-if="!isSameAgencyPrimaryAccount()" class="alert alert-error">The billing information on this account has been merged under the primary billing account <b>{{::account.primaryAccountId}}</b>. Please contact your Markel underwriter for account billing details.</div>\n' +
    '</div>\n' +
    '<div class="billing" ng-if="!uiState.loading && !uiState.error && uiState.visible && uiState.isAgent && !showBillingErrorMessage()">\n' +
    '	<div ng-if="isSameAgencyPrimaryAccount()" class="alert alert-warning">The billing information on this account has been merged under the primary billing account. Please refer to account number <b><a ng-href="#/account-summary/{{::account.primaryAccountId}}">{{::account.primaryAccountId}}</a></b> for current billing details.</div>\n' +
    '	<div ng-if="!isSameAgencyPrimaryAccount()" class="alert alert-warning">The billing information on this account has been merged under the primary billing account. Please contact your Markel underwriter for account billing details.</div>\n' +
    '</div>\n' +
    '<!---Billing Icon Summary Block-->\n' +
    '<div class="billing" ng-if=\'!uiState.loading && !uiState.error && uiState.hasBillingAccountId && uiState.visible && policy.billingSystem !="BC9"\'>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span3">\n' +
    '			<h3>Summary</h3>\n' +
    '			<div class="well billing-panel">\n' +
    '				<ul class="summary-list">\n' +
    '					<li>\n' +
    '						<strong>Current Due:</strong>\n' +
    '						<span class="pull-right"><strong>{{:: billingDetail.due | formatMoney }}</strong></span>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						Current Due Date:\n' +
    '						<span class="pull-right">{{:: billingDetail.currentDueDate | date:"MM/dd/yyyy" }}</span>\n' +
    '					</li>\n' +
    '					<li ng-if="account.pastDueFees || account.currentDueFees">\n' +
    '						Fees:\n' +
    '						<span class="pull-right">{{:: account.pastDueFees+account.currentDueFees | formatMoney}}</span>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						Balance:\n' +
    '						<span class="pull-right">{{:: billingDetail.balance | formatMoney }}</span>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						Last Payment Amount:\n' +
    '						<span class="pull-right">{{:: billingDetail.lastPaymentAmount }}</span>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						Last Payment Date:\n' +
    '						<span class="pull-right">{{:: billingDetail.lastPaymentDate | date:"MM/dd/yyyy" }}</span>\n' +
    '					</li>\n' +
    '				</ul>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="span9">\n' +
    '			<h3>Activity</h3>\n' +
    '			<div class="well billing-panel">\n' +
    '				<tabset class="billing-activity">\n' +
    '					<tab heading="Billing Schedule">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th>Due Date</th>\n' +
    '								<th ng-if="!billingDetail.showUnpaid"><span class="pull-right">Gross Amount</span></th>\n' +
    '								<th ng-if="billingDetail.showUnpaid">Gross Amount</th>\n' +
    '								<th ng-if="billingDetail.showUnpaid"><span class="pull-right">Unpaid Amount</span></th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.invoices.length">\n' +
    '								<td colspan="3">\n' +
    '									<div class="center">There is currently no billing schedule for this policy.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr ng-repeat="payment in billingDetail.invoices | orderBy:\'dueDate\'" ng-if="billingDetail.invoices.length">\n' +
    '								<td>{{:: payment.dueDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td ng-if="!billingDetail.showUnpaid"><span class="pull-right">{{:: payment.amount | formatMoney }}</span>\n' +
    '								</td>\n' +
    '								<td ng-if="billingDetail.showUnpaid">{{:: payment.amount | formatMoney }}</td>\n' +
    '								<td ng-if="billingDetail.showUnpaid"><span class="pull-right">{{:: payment.unpaid | formatMoney }}</span>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '						<div class="alert" ng-if="uiState.showWarningOnPaymentSchedule">\n' +
    '							Please note: agency bill amounts reflected are gross and should be paid net of commissions.\n' +
    '							At this time installment premium changing endorsements will not be displayed. Please contact\n' +
    '							Markel\n' +
    '							with any questions to these transactions.\n' +
    '						</div>\n' +
    '					</tab>\n' +
    '					<tab heading="Payments">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th>Date</th>\n' +
    '								<th>Method</th>\n' +
    '								<th>Check Number</th>\n' +
    '								<th><span class="pull-right">Amount</span></th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.paymentHistory.length">\n' +
    '								<td colspan="4">\n' +
    '									<div class="center">There is no payment history for this policy.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr ng-repeat="payment in billingDetail.paymentHistory | orderBy:\'-paymentDate\'" ng-if="billingDetail.paymentHistory.length">\n' +
    '								<td>{{:: payment.paymentDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td>{{:: payment.paymentMethod }}</td>\n' +
    '								<td>{{:: payment.checkNumber }}</td>\n' +
    '								<td><span class="pull-right">{{:: payment.amount | formatMoney }}</span></td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '					</tab>\n' +
    '					<tab heading="Refunds">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th>Date</th>\n' +
    '								<th><span class="pull-right">Amount</span></th>\n' +
    '								<th>Reason</th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.refunds.length">\n' +
    '								<td colspan="3">\n' +
    '									<div class="center">There are no refunds for this policy.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr ng-repeat="payment in billingDetail.refunds | orderBy:\'-paymentDate\'" ng-if="billingDetail.refunds.length">\n' +
    '								<td>{{:: payment.paymentDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td><span class="pull-right">{{:: payment.amount | formatMoney }}</span></td>\n' +
    '								<td>Refund</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '					</tab>\n' +
    '					<tab heading="Documents">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th colspan="2">Date</th>\n' +
    '								<th>Type</th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.documents.length">\n' +
    '								<td colspan="3">\n' +
    '									<div class="center">No documents found.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr dir-paginate="document in billingDetail.documents = ( billingDetail.documents | orderBy: \'-documentDate\') | itemsPerPage: pageSize" ng-if="billingDetail.documents.length">\n' +
    '								<td style="width: 21.4667px;"><a href="" ng-click="getBillingDocument(document.id)">\n' +
    '									<img src="assets/images/Document_Icon.png"></a></td>\n' +
    '								<td>{{:: document.documentDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td>{{:: document.type }}</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '						<div class="pagination-controls pagination-controls-bottom">\n' +
    '							<dir-pagination-controls template-url="app/components/policyDetail/policyBilling/template/billingDocumentPagination.html"></dir-pagination-controls>\n' +
    '						</div>\n' +
    '					</tab>\n' +
    '				</tabset>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<!--Billing BC9 Summary Block-->\n' +
    '<div class="billing" ng-if=\'!uiState.loading && !uiState.error && uiState.hasBillingAccountId && uiState.visible && policy.billingSystem ==="BC9"\'>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span3">\n' +
    '			<h3>Summary</h3>\n' +
    '			<div class="well billing-panel">\n' +
    '				<ul class="summary-list" ng-repeat="currentPolicy in billingDetail.policySummary" ng-if="currentPolicy.accountpolicies.policyNumber === policy.policyNumber">\n' +
    '					<!--Account Policy Details Summary-->\n' +
    '					<li>\n' +
    '						Policy Current Due:\n' +
    '						<span class="pull-right">{{:: currentPolicy.accountpolicies.currentDue | formatMoney }}</span>\n' +
    '					</li>\n' +
    '\n' +
    '					<li>\n' +
    '						Policy Balance:\n' +
    '						<span class="pull-right">{{:: currentPolicy.accountpolicies.balance | formatMoney }}</span>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						Policy Past Due:\n' +
    '						<span class="pull-right">{{:: currentPolicy.accountpolicies.pastDue | formatMoney }}</span>\n' +
    '					</li>\n' +
    '				</ul>\n' +
    '\n' +
    '				<ul class="summary-list">\n' +
    '						<li ng-if="account.pastDueFees || account.currentDueFees">\n' +
    '						Fees:\n' +
    '						<span class="pull-right">{{:: account.pastDueFees+account.currentDueFees | formatMoney}}</span>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						Account Current Due:\n' +
    '						<span class="pull-right">{{:: billingDetail.accountSummary.due | formatMoney }}</span>\n' +
    '					</li>\n' +
    '					<li>\n' +
    '						Account Past Due\n' +
    '						<span class="pull-right">{{:: billingDetail.accountSummary.pastDue | formatMoney }}</span>\n' +
    '					</li>\n' +
    '				</ul>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="span9">\n' +
    '			<h3>Activity</h3>\n' +
    '			<div class="well billing-panel">\n' +
    '				<tabset class="billing-activity">\n' +
    '					<tab heading="Billing Schedule">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th>Billed Date</th>\n' +
    '								<th>Due Date</th>\n' +
    '								<th>\n' +
    '									<span class="pull-right">Gross Amount</span>\n' +
    '								</th>\n' +
    '								<th>\n' +
    '									<span class="pull-right">Outstanding Amount</span>\n' +
    '								</th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr>\n' +
    '								<div class="accountLevelFilter">\n' +
    '										Show invoices with outstanding amounts\n' +
    '										<input type="checkbox" ng-model="showUnPaidDue">\n' +
    '									<div class="pull-right">\n' +
    '										<select ng-options="policy as policy for policy in filterOptions.policy" ng-model="filterValues.policy" ng-change="filterByPolicyValue()">\n' +
    '										</select>\n' +
    '									</div>\n' +
    '								</div>\n' +
    '							</tr>\n' +
    '							<tr ng-if="!accountSummaryInvoice.length">\n' +
    '								<td colspan="4">\n' +
    '									<div class="center">There is currently no billing schedule for this policy.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr ng-repeat="payment in summaryInvoices =(accountSummaryInvoice | UnPaidDueFilter:showUnPaidDue) | orderBy:\'billedDate\'">\n' +
    '								<!--Billed Amount-->\n' +
    '								<td>\n' +
    '									{{:: payment.billedDate | date:"MM/dd/yyyy" }}\n' +
    '								</td>\n' +
    '								<td>{{:: payment.dueDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td>\n' +
    '									<span class="pull-right">{{:: payment.amount | formatMoney }}</span>\n' +
    '								</td>\n' +
    '								<td>\n' +
    '									<span class="pull-right">{{:: payment.unpaid | formatMoney }}</span>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '						<div class="alert" ng-if="uiState.showWarningOnPaymentSchedule">\n' +
    '							Please note: agency bill amounts reflected are gross and should be paid net of commissions.\n' +
    '							At this time installment premium changing endorsements will not be displayed. Please contact\n' +
    '							Markel\n' +
    '							with any questions to these transactions.\n' +
    '						</div>\n' +
    '					</tab>\n' +
    '					<tab heading="Policy Details">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th>Policy Number</th>\n' +
    '								<th>Pay Plan</th>\n' +
    '								<th>Effective Date</th>\n' +
    '								<th>Expire Date</th>\n' +
    '								<th>Amount Due</th>\n' +
    '								<th>Past Due</th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.policySummary.length">\n' +
    '								<td colspan="6">\n' +
    '									<div class="center">There is no Policy details.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr ng-repeat="policyDetail in billingDetail.policySummary">\n' +
    '								<td>{{:: policyDetail.accountpolicies.policyNumber}}</td>\n' +
    '								<td>{{:: policyDetail.accountpolicies.payPlan}}</td>\n' +
    '								<td>{{:: policyDetail.accountpolicies.effectiveDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td>{{:: policyDetail.accountpolicies.expirationDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td>{{:: policyDetail.accountpolicies.currentDue | formatMoney }}</td>\n' +
    '								<td>{{:: policyDetail.accountpolicies.pastDue | formatMoney }}</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '					</tab>\n' +
    '					<tab heading="Payments">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th>Date</th>\n' +
    '								<th>Method</th>\n' +
    '								<th>Check/Confirmation number</th>\n' +
    '								<th><span class="pull-right">Amount</span></th>\n' +
    '								<th style="text-align: center;">Void payment</th>\n' +
    '								<th>Reason reversed</th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.accountSummary.paymentHistory.length">\n' +
    '								<td colspan="4">\n' +
    '									<div class="center">There is no payment history for this policy.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr ng-repeat="payment in billingDetail.accountSummary.paymentHistory | orderBy:\'-paymentDate\'" ng-if="billingDetail.accountSummary.paymentHistory.length">\n' +
    '								<td>{{:: payment.paymentDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td>{{:: payment.paymentMethod }}</td>\n' +
    '								<td>{{:: payment.checkNumber }}</td>\n' +
    '								<td><span class="pull-right">{{:: payment.amount | formatMoney }}</span></td>\n' +
    '								<td style="text-align: center;">{{:: payment.voidedPayment === \'Y\' ? \'Yes\' : \'No\' }}</td>\n' +
    '								<td>{{:: payment.voidedPayment === \'Y\' ? payment.reason : \'\'}}</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '					</tab>\n' +
    '					<tab heading="Refunds">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th>Date</th>\n' +
    '								<th><span class="pull-right">Amount</span></th>\n' +
    '								<th>Reason</th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.accountSummary.refunds.length">\n' +
    '								<td colspan="3">\n' +
    '									<div class="center">There are no refunds for this policy.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr ng-repeat="refund in billingDetail.accountSummary.refunds | orderBy:\'-paymentDate\'" ng-if="billingDetail.accountSummary.refunds.length">\n' +
    '								<td>{{:: refund.paymentDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td><span class="pull-right">{{:: refund.amount | formatMoney }}</span></td>\n' +
    '								<td>Refund</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '					</tab>\n' +
    '					<tab heading="Documents">\n' +
    '						<table class="activity table table-striped table-condensed table-bordered">\n' +
    '							<thead>\n' +
    '							<tr>\n' +
    '								<th colspan="2">Date</th>\n' +
    '								<th>Type</th>\n' +
    '							</tr>\n' +
    '							</thead>\n' +
    '							<tbody>\n' +
    '							<tr ng-if="!billingDetail.billingDocuments.length">\n' +
    '								<td colspan="3">\n' +
    '									<div class="center">No documents found.</div>\n' +
    '								</td>\n' +
    '							</tr>\n' +
    '							<tr dir-paginate="document in filteredBillingDocuments = ( filteredBillingDocuments | orderBy: \'-documentDate\') | itemsPerPage: pageSize" ng-if="filteredBillingDocuments.length">\n' +
    '								<td style="width: 21.4667px;"><a href="" ng-click="getBillingDocument(document.id)">\n' +
    '									<img src="assets/images/Document_Icon.png"></a></td>\n' +
    '								<td>{{:: document.documentDate | date:"MM/dd/yyyy" }}</td>\n' +
    '								<td>{{:: document.type }}</td>\n' +
    '							</tr>\n' +
    '							</tbody>\n' +
    '						</table>\n' +
    '						<div class="pagination-controls pagination-controls-bottom">\n' +
    '							<dir-pagination-controls template-url="app/components/policyDetail/policyBilling/template/billingDocumentPagination.html"></dir-pagination-controls>\n' +
    '						</div>\n' +
    '					</tab>\n' +
    '				</tabset>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policyDetail/policyClaims/template/template.html',
    '<div class="banner">\n' +
    '    Claims <span ng-if="visibleClaims.length">({{ visibleClaims.length }})</span>\n' +
    '    <button class="btn btn-small pull-right btn-toggler" ng-click="toggleVisibility()">\n' +
    '        <span ng-if="uiState.visible"><i class="icon-minus toggler"></i> Hide</span>\n' +
    '        <span ng-if="!uiState.visible"><i class="icon-plus toggler"></i> Show</span>\n' +
    '    </button>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="uiState.loading">\n' +
    '    <loading-bar></loading-bar>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="!uiState.loading && uiState.error && uiState.visible">\n' +
    '    <div class="alert alert-error">We were unable to load your claims. Please try again.</div>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="!uiState.loading && !uiState.error && uiState.visible">\n' +
    '    <table class="table table-striped table-condensed table-bordered">\n' +
    '        <thead>\n' +
    '        <tr>\n' +
    '            <th>Occurrence Number</th>\n' +
    '            <th>Loss Date</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody>\n' +
    '        <tr ng-if="retrievalError">\n' +
    '            <td colspan="3" class="alert alert-error">An error occurred while attempting to retrieve your list of claims.</td>\n' +
    '        </tr>\n' +
    '        <tr ng-if="!visibleClaims.length && !retrievalError">\n' +
    '            <td colspan="3">No claims found.</td>\n' +
    '        </tr>\n' +
    '        <tr ng-repeat="claim in visibleClaims">\n' +
    '            <td><a href ng-click="openClaimTab( policy, claim.occurrenceNumber, claim, true )">{{:: claim.occurrenceNumber }}</a></td>\n' +
    '            <td>{{:: claim.occurrenceDate | date: "MM/dd/yyyy" }}</td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policyDetail/policyDetail/template/template.html',
    '<div class="row-fluid policy-detail">\n' +
    '	<div class="span2">\n' +
    '		<div class="policy-info well" policy-info policy="policy" user="user"></div>\n' +
    '		<div class="actions well-action">\n' +
    '			<ul class="nav nav-list">\n' +
    '				<li class="nav-header">I want to</li>\n' +
    '				<li class="detail" ng-if="isSendMessageVisible()">\n' +
    '                    <span>\n' +
    '                        <a href ng-click="setMessageView(\'send\', {policy : policy, typeId : 1})">\n' +
    '                            <span>Send A Message</span>\n' +
    '                        </a>\n' +
    '                    </span>\n' +
    '	            </li>\n' +
    '				<li class="detail" ng-if="isPolicyChangeRequestVisible()">\n' +
    '                    <span>\n' +
    '                        <a href ng-click="setMessageView(\'send\', { policy : policy , typeId : 2})">\n' +
    '	                        <span>Request Change to Policy</span>\n' +
    '                        </a>\n' +
    '                    </span>\n' +
    '				</li>\n' +
    '                <li class="detail" ng-if="isPolicyChangeRequestVisibleForInsured()">\n' +
    '                    <span>\n' +
    '                        <a href ng-click="setMessageView(\'send\', { policy : policy , typeId : 2})">\n' +
    '	                        <span>Send a question or change</span>\n' +
    '                        </a>\n' +
    '                    </span>\n' +
    '                </li>\n' +
    '				<li class="detail" ng-if="policy.paymentEnabled && isMergedInsuredEnabled()">\n' +
    '					<span payment-link account-id="policy.billingAccountId" billing-system="policy.billingSystem"></span>\n' +
    '				</li>\n' +
    '				<li class="detail" ng-if="isLossRunLinkVisible()">\n' +
    '					<span loss-runs account-id="account.piNumber" user="user"></span>\n' +
    '				</li>\n' +
    '			</ul>\n' +
    '		</div>\n' +
    '		<div class="history well" account-history policies="allPolicies" open-policy-tab="openPolicyTab" submissions="allSubmissions" open-submission-tab="openSubmissionTab" account="account" user="user"></div>\n' +
    '	</div>\n' +
    '	<div class="span10">\n' +
    '		<div class="documents-container" ng-if="canDisplayDocuments()" policy-documents account-id="account.accountId" policy-id="policy.policyNumber" user="user" policy-bill-type="policy.billType"></div>\n' +
    '		<div class="claims-container" ng-if="user.hasComponentPermissions( \'claimsData\' ) && showLossRuns()" policy-claims policy="policy" open-claim-tab="openClaimTab"></div>\n' +
    '\n' +
    '		<div class="claims-container" ng-if="!showLossRuns()">\n' +
    '			<div class="banner">\n' +
    '				Claims 		\n' +
    '			</div>\n' +
    '			<p>Claims detail for this policy is temporarily unavailable. Please contact Markel at 888-500-3344 for more information.</p>\n' +
    '			\n' +
    '		</div>		\n' +
    '		\n' +
    '		<div class="billing-container" ng-if="isBillingInfoVisible()" policy-billing account-id="account.piNumber" account="account" policy="policy" policy-effective-date="policy.effectiveDate" policy-bill-type="policy.billType" user="user"></div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policyDetail/policyDocuments/template/template.html',
    '<div class="banner">\n' +
    '	Documents <span ng-if="visibleDocuments.length">({{ visibleDocuments.length }})</span>\n' +
    '	<button class="btn btn-small pull-right btn-toggler" ng-click="toggleVisibility()">\n' +
    '		<span ng-if="uiState.visible"><i class="icon-minus toggler"></i> Hide</span>\n' +
    '		<span ng-if="!uiState.visible"><i class="icon-plus toggler"></i> Show</span>\n' +
    '	</button>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="uiState.loading">\n' +
    '	<loading-bar></loading-bar>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="!uiState.loading && uiState.error && uiState.visible">\n' +
    '	<div class="alert alert-error">We were unable to load your documents. Please try again.</div>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="!uiState.loading && !uiState.error && uiState.visible">\n' +
    '	<div class="documents-filter">\n' +
    '		<form ng-submit="filterDocuments()">\n' +
    '			Date Range\n' +
    '			<select ng-options="opt as opt.label for opt in filterDateOptions" ng-model="filter.date"></select>\n' +
    '			for\n' +
    '			<input type="text" ng-model="filter.description">\n' +
    '			<button class="btn" type="submit">Go</button>\n' +
    '		</form>\n' +
    '	</div>\n' +
    '	<table class="table table-striped table-condensed table-bordered">\n' +
    '		<thead>\n' +
    '			<tr>\n' +
    '				<th>Date</th>\n' +
    '				<th>Type</th>\n' +
    '				<th>Description</th>\n' +
    '			</tr>\n' +
    '		</thead>\n' +
    '		<tbody>\n' +
    '			<tr ng-if="retrievalError">\n' +
    '				<td colspan="3" class="alert alert-error">An error occurred while attempting to retrieve your list of documents.</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="!visibleDocuments.length && !retrievalError">\n' +
    '				<td colspan="3">No documents found.</td>\n' +
    '			</tr>\n' +
    '			<tr ng-repeat="document in visibleDocuments">\n' +
    '				<td>{{:: document.date | date: "MM/dd/yyyy" }}</td>\n' +
    '				<td><a ng-click="getDocument(document)"><img src="assets/images/pdf-small.gif"> {{:: document.type }}</a></td>\n' +
    '				<td>{{:: document.description }}</td>\n' +
    '			</tr>\n' +
    '		</tbody>\n' +
    '	</table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/policyDetail/policyInfo/template/template.html',
    '<table class="table-plain">\n' +
    '	<tbody>\n' +
    '		<tr>\n' +
    '			<td>Policy #</td>\n' +
    '			<td>{{:: policy.policyNumber }}\n' +
    '		</td></tr>\n' +
    '		<tr>\n' +
    '			<td>Effective Date</td>\n' +
    '			<td>{{:: policy.effectiveDate | date:"MM/dd/yyyy" }}</td>\n' +
    '		</tr>\n' +
    '		<tr>\n' +
    '			<td>Status</td>\n' +
    '			<td>{{:: policy.status }}</td>\n' +
    '		</tr>\n' +
    '		<tr>\n' +
    '			<td>Phone</td>\n' +
    '			<td>{{:: policy.phone ? policy.phone : \'N/A\' }}\n' +
    '		</td></tr>\n' +
    '		<tr>\n' +
    '			<td>Email</td>\n' +
    '			<td style="display:flex; word-break: break-all">{{:: policy.email ? policy.email : \'N/A\'  }}</td>\n' +
    '		</tr>\n' +
    '		<tr ng-if="isBillTypeVisible()">\n' +
    '			<td>Bill Type</td>\n' +
    '			<td>{{:: policy.billType }}\n' +
    '                <i ng-if="policy.billType === \'Direct bill\'" id="buttonHelper" class="icon-info-sign" popover-title="" popover="&quot;Direct bill&quot; - billing is handled by the insurance company (Markel), directly to the policyholder. The policyholder is responsible for paying their insurance premium to Markel." popover-placement="right" popover-trigger="mouseenter" popover-popup-delay="500">\n' +
    '                </i>\n' +
    '                <i ng-if="policy.billType !== \'Direct bill\'" id="buttonHelper" class="icon-info-sign" popover-title="" popover="&quot;Agency bill&quot; - billing is handled by the insurance agency. The agency bills the policyholder for the policy issued by the insurance company (Markel). The agent is responsible for collecting the total premium from the policyholder, and remitting the premium to Markel." popover-placement="right" popover-trigger="mouseenter" popover-popup-delay="500">\n' +
    '                </i>\n' +
    '            </td>\n' +
    '		</tr>\n' +
    '		<tr>\n' +
    '			<td>Premium</td>\n' +
    '			<td>{{:: policy.premium | formatMoney }}</td>\n' +
    '		</tr>\n' +
    '		<tr ng-if="isBalanceVisible()">\n' +
    '			<td>Balance</td>\n' +
    '			<td>{{:: policy.balance | formatMoney }}</td>\n' +
    '		</tr>\n' +
    '		<tr ng-if="uiState.additionalData && uiState.loadingData">\n' +
    '			<td colspan="2"><img class="loader" src="/assets/images/ajax-loader-grey.gif"></td>\n' +
    '		</tr>\n' +
    '		<tr ng-if="uiState.additionalData && !uiState.loadingData && agency">\n' +
    '			<td>Agency</td>\n' +
    '			<td>{{:: agency.name }}</td>\n' +
    '		</tr>\n' +
    '		<tr ng-if="uiState.additionalData && !uiState.loadingData && agency">\n' +
    '			<td>Agency Phone</td>\n' +
    '			<td>{{:: agency.phone }}</td>\n' +
    '		</tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/productSearch/container/template/template.html',
    '<div class="span12 row-fluid search-container product-search-container" ng-class="{\'mobile\': account.isMobile}" ng-style="{\'margin-top\' : !user.isLoggedIn ? \'10px\' : \'0px\' }">\n' +
    '\n' +
    '    <div class="row-fluid top-bar start-quote product-search-header">\n' +
    '        <div class="pushDown span12">\n' +
    '            <table class="span12">\n' +
    '                <tr ng-if="isUnAuthenticatedUser">\n' +
    '                    <td colspan="3"><span class="headline">Start a quote </span></td>\n' +
    '                </tr>\n' +
    '                <tr ng-if="!isUnAuthenticatedUser">\n' +
    '                    <td colspan="3"><span class="headline">Start quote or check eligibility</span></td>\n' +
    '                </tr>\n' +
    '                \n' +
    '                <tr>\n' +
    '                    \n' +
    '                     <td class="row-fluid">\n' +
    '                      <div class="options row-fluid" ng-class="{ \'span4\' : !account.isMobile, \'span12\' : account.isMobile }">\n' +
    '                            <span class="span5 filter" ng-class="{ \'span5\' : !account.isMobile, \'span11\' : account.isMobile }">\n' +
    '                                <input type="text" id="effective-date" class="piiSafe span12 input-tall highlight" placeholder="Effective Date" date-picker date-picker-min-date="0" date-picker-defaultdate="+0" date-picker-on-select="calendar_select" ng-model="filters.date.value" filter-id="2" value="filters.date.value" type="text" ng-disabled="true">  \n' +
    '\n' +
    '                                <span class="glyphicon glyphicon-calendar pull-right" ng-click="calendar_click($event)"></span>\n' +
    '                            </span> \n' +
    '\n' +
    '                            <span class="span5 filter state-filter" ng-class="{ \'span5\' : !account.isMobile, \'span11\' : account.isMobile }">\n' +
    '                                <input type="text" id="state" class="piiSafe span12 input-tall highlight business-type-input" placeholder="State" autocomplete autocomplete-source="filters.state.source" autocomplete-html="true" autocomplete-min-length="0" autocomplete-append-to="product-search-container" autocomplete-select="set_value" ng-blur="check_state()" ng-model="filters.state.display_value" value="{{:: filters.state.display_value}}" filter-id="1"> \n' +
    '\n' +
    '                                <span class="glyphicon glyphicon-chevron-down pull-right" ng-click="open_dropdown($event,filters.state)"></span>\n' +
    '                            </span>\n' +
    '                        </div>\n' +
    '                        <div class="business-type-autocomplete-container" ng-class="{ \'span8\' : !account.isMobile, \'span12\' : account.isMobile }">\n' +
    '                            <input id="business-type-autocomplete" ng-model="business_type_input" autocomplete autocomplete-min-length="0" autocomplete-source="filter_matches" autocomplete-html="true" autocomplete-append-to="product-search-container" autocomplete-select="select_description" autocomplete-render-item="render_item" placeholder="{{business_type_text}}" class="business-type-input piiSafe input-tall highlight ng-pristine ng-valid ng-empty ng-touched" ng-class="{ \'span12\' : !account.isMobile, \'span11\' : account.isMobile }" type="text" ng-change="business_type_auto_focus()">\n' +
    '                        </div>\n' +
    '                        \n' +
    '                     </td>\n' +
    '                </tr>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <table width="100%" ng-show="business_type_input != \'\' && includeLocation !=\'home\' &&  business_type_tag == \'\'" class="product-search-lower">\n' +
    '      <tr ng-if="products.source.length > 0 && !loading.business_type && products.selected.length == 0 && !loading.products && products.unselected.length != 0">\n' +
    '        <td>\n' +
    '          <div class="min-width no-selection span12">\n' +
    '              <div class="row-fluid">\n' +
    '                Please select a product below to continue\n' +
    '              </div>\n' +
    '          </div>\n' +
    '        </td>\n' +
    '      </tr>\n' +
    '      <tr>\n' +
    '        <td>\n' +
    '            <div class="min-width span12">\n' +
    '                <div class="row-fluid center-container">\n' +
    '                    \n' +
    '                    <div class="row-fluid span12 product-search-result">\n' +
    '\n' +
    '	                    <div class="span5" ng-if=" ( products.source.length == 0 || products.allowed.length == 0 ) && !loading.business_type">\n' +
    '\n' +
    '		                    <div class="span12 box-shadow product-search-no-results">\n' +
    '			                    <div ng-if="noProductsAvailableMessage">\n' +
    '				                    {{:: noProductsAvailableMessage}}\n' +
    '			                    </div>\n' +
    '			                    <div ng-if="!noProductsAvailableMessage">\n' +
    '				                    Unfortunately, we do not have any products available for your selections. Questions? Contact us at 888-500-3344.\n' +
    '			                    </div>\n' +
    '		                    </div>\n' +
    '	                    </div>\n' +
    '                       <div class="span5" ng-if="loading.business_type || loading.products">\n' +
    '                            <div class="span12 box-shadow product-search-loading" ng-if="loading.business_type || loading.products">\n' +
    '                                  <table style="width:100%;height:100%;">\n' +
    '                                    <tr>\n' +
    '                                        <td style="vertical-align: middle;"><span class="glyphicon glyphicon-cog spin"></span></td>\n' +
    '                                        <td style="vertical-align: middle;">Please wait loading available products</td>\n' +
    '                                        <td style="vertical-align: middle;text-align:right;"><span class="glyphicon glyphicon-cog spin"></span></td>\n' +
    '                                    </tr>\n' +
    '                                </table>\n' +
    '                            </div>\n' +
    '\n' +
    '                       </div>\n' +
    '                        <div ng-if="!user.isLoggedIn && (products.selected.length != 0 || products.unselected.length != 0)" style="margin-left:4px;position: absolute;">\n' +
    '                            <a target="_blank" href="https://www.markelinsurance.com/referral-information"><button class="btn btn-primary" style="background-color: #009aa6;">Refer to Markel Services</button></a>\n' +
    '                        </div>\n' +
    '                        \n' +
    '                       <div class="product-search-filter-container" ng-class="{ \'span3\' : !account.isMobile, \'span12\' : account.isMobile,\'refer-markel-services\': !user.isLoggedIn && (products.selected.length != 0 || products.unselected.length != 0) }" ng-show="filters.source.length > 0 && products.source.length > 0 && !loading.products">\n' +
    '\n' +
    '                            <div ng-class="{ \'span11\' : !account.isMobile, \'span12\' : account.isMobile }" style="margin-left:4px;">\n' +
    '                                \n' +
    '                                <div class="span12 product-search-filter filter-required box-shadow" ng-if="filters.required.length && filters_visible(\'required\')"> \n' +
    '                                    <div class="product-search-filter-caption">Required Filters</div> \n' +
    '                                    <div product-search-filter filters="filters.required" rules="rules"></div>\n' +
    '                                </div>\n' +
    '                                <div class="span12 product-search-filter box-shadow" ng-if="filters.optional.length && filters_visible(\'optional\')">\n' +
    '                                    <div class="product-search-filter-caption">Tell us more...</div> \n' +
    '                                    <div product-search-filter filters="filters.optional" rules="rules"></div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                        <div class="product-search-list" ng-class="{ \'span9\' : !account.isMobile, \'span12\' : account.isMobile }" ng-show="products.allowed.length > 0 && !loading.products">\n' +
    '\n' +
    '                            <div ng-if="!user.isLoggedIn && (products.selected.length != 0 || products.unselected.length != 0)" class="span12" style="padding-left: 50px; margin-bottom: 20px">\n' +
    '                                <h3>Click the links below to access the applications for the lines of business you would like to submit for a quote. You do not need to submit all available lines of business.\n' +
    '                                    All lines <u><b>except</b></u> commercial property, commercial auto, excess, and umbrella are available for monoline coverage.\n' +
    '                                </h3>\n' +
    '                            </div>\n' +
    '\n' +
    '	                        <div style="padding-left:25px;">\n' +
    '		                        <div class="box-shadow product-search-no-products span12" ng-if="products.selected.length == 0 && products.unselected.length == 0">\n' +
    '			                        <div ng-if="noProductsAvailableMessage">\n' +
    '				                        {{:: noProductsAvailableMessage}}\n' +
    '			                        </div>\n' +
    '			                        <div ng-if="!noProductsAvailableMessage">\n' +
    '				                        Unfortunately, we do not have any products available for your selections. Questions? Contact us at 888-500-3344.\n' +
    '			                        </div>\n' +
    '		                        </div>\n' +
    '	                        </div>\n' +
    '\n' +
    '\n' +
    '                            <div class="product-search-list-child" product-search-list products="products.selected" ng-if="products.selected.length > 0"></div>\n' +
    '\n' +
    '                            <ul style="list-style: none;" ng-if="products.selected.length > 0 && products.allowed.length > 0 && ( user.isLoggedIn || isUnAuthenticatedDirect )">\n' +
    '                                <li class="btn span6 box-shadow product-continue-button" ng-if="continue  && filters_required_completed(\'required\') " ng-click="validate_filters()">\n' +
    '                                        Click to Quote Selected Products\n' +
    '                                </li>\n' +
    '                                <li class="btn span6 box-shadow product-continue-button" ng-if="!filters_required_completed(\'required\')" ng-class="{\'disabled\': true}">\n' +
    '                                    Please Complete All Required Filters\n' +
    '                                </li>\n' +
    '                            </ul>\n' +
    '\n' +
    '                            <div class="product-search-list-child" product-search-list products="products.unselected" ng-if="products.unselected.length > 0"></div>\n' +
    '\n' +
    '                            <div class="product-search-list-child" product-search-list products="products.ineligible" ng-if="products.ineligible.length > 0"></div>\n' +
    '\n' +
    '                            <!-- <div product-search-list products="products.unavailable" ng-if="products.unavailable.length > 0"></div> -->\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </td>\n' +
    '      </tr>\n' +
    '    </table>\n' +
    '\n' +
    '     <div class="bottom-bar" ng-show="business_type_input != \'\' && includeLocation !=\'home\'">\n' +
    '        <div class="center-container">\n' +
    '            \n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/productSearch/filter/template/template.html',
    '<div class="filter span12" ng-if="filter.visible" ng-repeat="filter in filters | orderBy :\'sortOrder\'">\n' +
    '\n' +
    '    <div ng-if="filter.type.display == \'calendar\'">\n' +
    '        <div class="filter-required-message span12 alert-error span12 alert-error" ng-if="filter.invalid && filter.required">\n' +
    '            Please enter a date\n' +
    '        </div>\n' +
    '        <div class="filter-title">\n' +
    '          {{:: filter.display }}\n' +
    '            <span ng-if="filter.required" class="required-indicator">*</span>\n' +
    '          <span ng-if="filter.help.length > 0" popover="{{filter.help}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '              \n' +
    '              <span class="glyphicon glyphicon-question-sign"></span>    \n' +
    '          </span>\n' +
    '        </div>\n' +
    '      \n' +
    '        <div class="filter-controls">\n' +
    '            <input class="span8 piiSafe" date-picker date-picker-min-date="-6" date-picker-defaultdate="+0" ng-model="filter.value" ng-change="validate(filter)" value="filter.value" type="text" ng-disabled="true">\n' +
    '            <button class="btn btn-primary piiSafe btn-fix" ng-click="calendar_click($event)"><span class="glyphicon glyphicon-calendar"></span></button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="filter.type.display == \'autocomplete\'">\n' +
    '        <div class="filter-required-message span12 alert-error" ng-if="filter.invalid && filter.required">\n' +
    '            Please select a value\n' +
    '        </div>\n' +
    '        <div class="filter-title">\n' +
    '           {{:: filter.display }}\n' +
    '            <span ng-if="filter.required" class="required-indicator">*</span>\n' +
    '            <span ng-if="filter.help.length > 0" popover="{{filter.help}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '              \n' +
    '                <span class="glyphicon glyphicon-question-sign"></span>    \n' +
    '            </span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="filter-controls">\n' +
    '            <input autocomplete autocomplete-source="filter.source" autocomplete-html="true" autocomplete-min-length="0" autocomplete-select="set_value" autocomplete-append-to="product-search-container" ng-blur="validate(filter)" ng-model="filter.display_value" value="{{:: filter.display_value}}" class="span8 piiSafe" type="text" filter-id="{{:: filter.id}}"> \n' +
    '                   \n' +
    '            <button class="btn btn-primary piiSafe btn-fix" ng-click="open_dropdown($event,filter)"><span class="glyphicon glyphicon-chevron-down"></span></button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="filter.type.display == \'input\'">\n' +
    '        <div class="filter-required-message span12 alert-error" ng-if="filter.invalid && filter.required">\n' +
    '            Please enter a value\n' +
    '        </div>\n' +
    '        \n' +
    '        <div class="filter-title">\n' +
    '            {{:: filter.display }}\n' +
    '            <span ng-if="filter.required" class="required-indicator">*</span>\n' +
    '            <span ng-if="filter.help.length > 0" popover="{{filter.help}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '              \n' +
    '                <span class="glyphicon glyphicon-question-sign"></span>    \n' +
    '            </span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="filter-controls">\n' +
    '           <input type="text" class="span8 piiSafe" ng-blur="validate(filter)" ng-model="filter.value" value="{{:: filter.value }}">\n' +
    '           <span ng-if="filter.label.length">{{:: filter.label }} </span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="filter.type.display == \'label\'">\n' +
    '        <div class="filter-title filter-label">\n' +
    '          <span ng-bind-html="::filter.display | linky"></span>\n' +
    '\n' +
    '            <span ng-if="filter.help.length > 0" popover="{{filter.help}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '              \n' +
    '                <span class="glyphicon glyphicon-question-sign"></span>    \n' +
    '            </span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="filter.type.display == \'multi-select\' || filter.type.display== \'single-select\'">\n' +
    '        <div class="filter-required-message span12 alert-error" ng-if="filter.invalid && filter.required">\n' +
    '            Please select a value\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="filter-title">\n' +
    '          {{:: filter.display }}\n' +
    '            <span ng-if="filter.required" class="required-indicator">*</span>\n' +
    '          <span ng-if="filter.help.length > 0" popover="{{filter.help}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '              \n' +
    '              <span class="glyphicon glyphicon-question-sign"></span>    \n' +
    '          </span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="filter-controls">\n' +
    '          <div>\n' +
    '            <label class="filter-option piiSafe" ng-repeat="option in filter.options | orderBy:\'sortOrder\'" ng-class="{ \'active\': option.selected }">\n' +
    '                <span ng-click="option_click($event,filter,option)" class="piiSafe">{{:: option.display }}</span>\n' +
    '            </label>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="filter.type.display == \'slider\'">\n' +
    '        <div class="filter-title">\n' +
    '            <div>\n' +
    '              {{:: filter.display }}\n' +
    '                <span ng-if="filter.required" class="required-indicator">*</span>&nbsp;\n' +
    '              (\n' +
    '               <span ng-if="filter.format != \'dollar\'">\n' +
    '                  <span ng-if="filter.value == filter.max">{{:: filter.max }}+</span>\n' +
    '                  <span ng-if="filter.value != filter.max">{{filter.value }}{{:: filter.label }}</span>\n' +
    '               </span>\n' +
    '               <span ng-if="filter.format == \'dollar\'">\n' +
    '                  <span ng-if="filter.value == filter.max">{{:: filter.max | currency:\'$\':\'0\' }}+</span>\n' +
    '                  <span ng-if="filter.value != filter.max">{{filter.value | currency:\'$\':\'0\'  }}{{:: filter.label }}</span>\n' +
    '               </span>\n' +
    '              ) \n' +
    '           \n' +
    '              <span ng-if="filter.help.length > 0" popover="{{filter.help}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '                \n' +
    '                  <span class="glyphicon glyphicon-question-sign"></span>    \n' +
    '              </span> \n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="filter-controls">\n' +
    '          <div class="progress-box">\n' +
    '            <div class="progress-bar progress-bar-slider">\n' +
    '              <input class="progress-slider piiSafe" type="range" min="{{:: filter.min }}" max="{{:: filter.max }}" step="{{:: filter.step }}" value="{{:: filter.value }}" ng-model="filter.value" ng-change="slider_update($event,filter)">\n' +
    '              <div class="inner" ng-style="{ width: filter.percent + \'%\' || \'0%\' }"></div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/productSearch/list/template/template.html',
    '<ul style="list-style: none;" ng-if="user.isLoggedIn || isUnAuthenticatedDirect">\n' +
    '    <li class="product-results" ng-repeat="product in products | orderBy: [\'sortOrder\']">\n' +
    '\n' +
    '        <div class="result span12 box-shadow" ng-class="{ \'selected\': product.selected , \'not-allowed\': !product.allowed, \'product-disabled\': product.disabled || product.forbidden , \'product-enabled\': !product.disabled && !product.forbidden }" ng-click="select_product($event,product)">\n' +
    '\n' +
    '            <table border="0" width="100%">\n' +
    '                <tr ng-show="visible_messages(product)">\n' +
    '                  <td colspan="2" class="product-message-check">\n' +
    '                       <ul style="list-style: none;" class="product-message">\n' +
    '                          <li ng-repeat="message in product.messages" ng-if="message.visible && !message.closed" class="span12">\n' +
    '                            {{:: message.text}}\n' +
    '                            <span class="glyphicon glyphicon-remove pull-right product-message-close" ng-click="hide_message($event,message,product)"></span>\n' +
    '                          </li>\n' +
    '                       </ul>\n' +
    '                  </td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                   <td class="check">\n' +
    '                        <label ng-if="!product.disabled && !product.forbidden" class="product-search-check">\n' +
    '                          <input type="checkbox" ng-checked="product.selected" disabled="disabled">\n' +
    '                          <span class="checkmark"></span>\n' +
    '                        </label>\n' +
    '                        <span ng-if="product.disabled || product.forbidden" class="glyphicon glyphicon glyphicon-ban-circle"></span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <table border="0" width="100%" class="table-product">\n' +
    '                            <tbody>\n' +
    '                                <tr>\n' +
    '                                    <td class="product-img" rowspan="2">\n' +
    '                                        <div class="img-container pull-left">\n' +
    '                                            <img class="img-polaroid" ng-src="assets\\images\\product_search\\{{ product.id }}.png">\n' +
    '                                        </div>\n' +
    '                                        <!-- <div class="grey-line-box pull-right">\n' +
    '                                            <div class="grey-line"></div>\n' +
    '                                        </div> -->\n' +
    '                                    </td> \n' +
    '                                    <td colspan="2">\n' +
    '                                        <span class="product-title">\n' +
    '                                            <table>\n' +
    '                                              <tr>\n' +
    '                                                <td class="product-info-container"> \n' +
    '                                                  <h2 class="pull-left">{{product.title}}</h2>\n' +
    '\n' +
    '                                                  <span class="product-info-popover-trigger pull-left" popover="{{product.description}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '                                                      \n' +
    '                                                      <span class="product-info">i</span>    \n' +
    '                                                  </span>\n' +
    '                                                </td>\n' +
    '                                              </tr>\n' +
    '                                              <tr>\n' +
    '                                                  <td class="product-quote-container">\n' +
    '                                                      <span class="online" ng-if="product.online">online</span> \n' +
    '                                                      <span class="instant-quote" ng-if="product.quote && product.default_quote && !product.disabled && !product.forbidden">Instant Quote</span> \n' +
    '                                                      <span class="refer-quote" ng-if="product.refer && product.online && !product.disabled && !product.forbidden">Needs Review</span> \n' +
    '                                                      <span class="refer-quote" style="background-color: #B71234" ng-if="product.disabled || product.forbidden">Ineligible</span>\n' +
    '                                                      <a href="{{ (product.url)?product.url:product.program.offlineApplicationUrl }}" ng-click="open_link($event)" target="_blank" class="offline" ng-if="!product.online && !product.disabled">\n' +
    '                                                            <span class="glyphicon glyphicon-file pull-left"></span>\n' +
    '                                                            <div class="link_text pull-left"> Access Markel applications</div>\n' +
    '                                                      </a>\n' +
    '                                                  </td>\n' +
    '                                              </tr>\n' +
    '                                            </table>\n' +
    '                                        </span>                            \n' +
    '                                    </td>\n' +
    '                                </tr>   \n' +
    '                                <tr>\n' +
    '                                    <td colspan="4">\n' +
    '                                        <div class="product-show-eligibility pull-left" ng-click="show_eligibility($event,product)">\n' +
    '                                            <span class="glyphicon" ng-class="{ \'glyphicon-chevron-down\': product.show_eligibility , \'glyphicon-chevron-right\' : !product.show_eligibility }"></span> \n' +
    '\n' +
    '                                             <span class="product-guidelines-header"><a>What you need to know</a></span>\n' +
    '                                        </div>\n' +
    '                                    </td>\n' +
    '                                    <td>\n' +
    '                                        <div ng-if="product.underwriting" class="product-underwriting pull-right">\n' +
    '                                            {{ product.underwriting }}\n' +
    '                                        </div> \n' +
    '                                    </td>\n' +
    '                                </tr>  \n' +
    '\n' +
    '                               \n' +
    '                                <tr ng-show="product.show_eligibility">\n' +
    '                                    <td colspan="6">\n' +
    '                                        <div class="product-guidelines">\n' +
    '                                            <div ng-bind-html="product.eligibility" class="product-guidelines-content well"></div>\n' +
    '                                        </div>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '                                 <tr ng-if="product.marketing">\n' +
    '                                    <td colspan="6">\n' +
    '                                        <div class="product-marketing">\n' +
    '                                            {{ product.marketing }}\n' +
    '                                        </div>\n' +
    '                                    </td>\n' +
    '                                </tr> \n' +
    '\n' +
    '                            </tbody>\n' +
    '                        </table>\n' +
    '                    </td>\n' +
    '                </tr> \n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '\n' +
    '<ul style="list-style: none;" ng-if="!user.isLoggedIn && !isUnAuthenticatedDirect">\n' +
    '    <li class="product-results" ng-repeat="product in singleProducts | orderBy: [\'sortOrder\']">\n' +
    '        <div class="result span12 box-shadow" ng-class="{ \'selected\': false , \'not-allowed\': !product.allowed, \'product-disabled\': product.disabled || product.forbidden , \'product-enabled-msi\': !product.disabled && !product.forbidden }" style="padding-right: 37px;">\n' +
    '            <table border="0" width="100%">\n' +
    '                <tr ng-show="visible_messages(product)">\n' +
    '                    <td colspan="2" class="product-message-check">\n' +
    '                        <ul style="list-style: none;" class="product-message">\n' +
    '                            <li ng-repeat="message in product.messages" ng-if="message.visible && !message.closed" class="span12">\n' +
    '                                {{:: message.text}}\n' +
    '                                <span class="glyphicon glyphicon-remove pull-right product-message-close" ng-click="hide_message($event,message,product)"></span>\n' +
    '                            </li>\n' +
    '                        </ul>\n' +
    '                    </td>\n' +
    '\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>\n' +
    '                        <table border="0" width="100%" class="table-product">\n' +
    '                            <tbody>\n' +
    '                            <tr>\n' +
    '                                <td class="product-img" rowspan="2">\n' +
    '                                    <div class="img-container pull-left">\n' +
    '                                        <img class="img-polaroid" ng-src="assets\\images\\product_search\\{{ product.id }}.png">\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                                <td colspan="2">\n' +
    '                                        <span class="product-title">\n' +
    '                                            <table>\n' +
    '                                              <tr>\n' +
    '                                                <td class="product-info-container">\n' +
    '                                                  <h2 class="pull-left">{{product.title}}</h2>\n' +
    '\n' +
    '                                                  <span class="product-info-popover-trigger pull-left" popover="{{product.description}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '\n' +
    '                                                      <span class="product-info">i</span>\n' +
    '                                                  </span>\n' +
    '                                                </td>\n' +
    '                                              </tr>\n' +
    '                                              <tr>\n' +
    '                                                  <td class="product-quote-container">\n' +
    '                                                      <span class="online" ng-if="product.online">online</span>\n' +
    '                                                      <span class="instant-quote" ng-if="product.quote && product.default_quote && !product.disabled && !product.forbidden">Instant Quote</span>\n' +
    '                                                      <span class="refer-quote" ng-if="product.refer && product.online && !product.disabled && !product.forbidden">Needs Review</span>\n' +
    '                                                      <span class="refer-quote" style="background-color: #B71234" ng-if="product.disabled || product.forbidden">Ineligible</span>\n' +
    '                                                      <a href="{{ (product.url)?product.url:product.program.offlineApplicationUrl }}" ng-click="open_link($event)" target="_blank" class="offline" ng-if="!product.online && !product.disabled">\n' +
    '                                                            <span class="glyphicon glyphicon-file pull-left"></span>\n' +
    '                                                            <div class="link_text pull-left"> Access Markel applications</div>\n' +
    '                                                      </a>\n' +
    '                                                  </td>\n' +
    '                                              </tr>\n' +
    '                                            </table>\n' +
    '                                        </span>\n' +
    '                                </td>\n' +
    '                                <td colspan="4">\n' +
    '                                    <div class="pull-right" style="margin-top: 20px; padding: 5px;">\n' +
    '                                        <button ng-show="!product.disabled && !product.forbidden" ng-click="open_product_page($event,product)" class="btn btn-primary" style="background-color: #009aa6;"> Download form <span class="glyphicon glyphicon-download-alt"></span></button>\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '                            <tr>\n' +
    '                                <td colspan="4">\n' +
    '                                    <div class="product-show-eligibility pull-left" ng-click="show_eligibility($event,product)">\n' +
    '                                        <span class="glyphicon" ng-class="{ \'glyphicon-chevron-down\': product.show_eligibility , \'glyphicon-chevron-right\' : !product.show_eligibility }"></span>\n' +
    '\n' +
    '                                        <span class="product-guidelines-header"><a>What you need to know</a></span>\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                                <td>\n' +
    '                                    <div ng-if="product.underwriting" class="product-underwriting pull-left" style="padding-top: 14px; padding-right: 15px;">\n' +
    '                                        {{ product.underwriting }}\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '                            <tr ng-show="product.show_eligibility">\n' +
    '                                <td colspan="6">\n' +
    '                                    <div class="product-guidelines">\n' +
    '                                        <div ng-bind-html="product.eligibility" class="product-guidelines-content well"></div>\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '\n' +
    '                            <tr ng-if="product.marketing">\n' +
    '                                <td colspan="6">\n' +
    '                                    <div class="product-marketing">\n' +
    '                                        {{ product.marketing }}\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '\n' +
    '                            </tbody>\n' +
    '                        </table>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '\n' +
    '<div class="result span12 box-shadow" ng-if="groupedProducts.length > 0" ng-class="{ \'selected\': false, \'product-enabled-msi\': true }" style="margin-left: 25px">\n' +
    '<ul style="list-style: none; margin: 0" ng-if="!user.isLoggedIn && !isUnAuthenticatedDirect">\n' +
    '    <li class="product-results span12" ng-repeat="product in groupedProducts | orderBy: [\'sortOrder\']">\n' +
    '        <div ng-class="{ \'not-allowed\': !product.allowed, \'product-disabled\': product.disabled || product.forbidden }">\n' +
    '            <table border="0" width="100%">\n' +
    '                <tr ng-show="visible_messages(product)">\n' +
    '                    <td colspan="2" class="product-message-check">\n' +
    '                        <ul style="list-style: none;" class="product-message">\n' +
    '                            <li ng-repeat="message in product.messages" ng-if="message.visible && !message.closed" class="span12">\n' +
    '                                {{:: message.text}}\n' +
    '                                <span class="glyphicon glyphicon-remove pull-right product-message-close" ng-click="hide_message($event,message,product)"></span>\n' +
    '                            </li>\n' +
    '                        </ul>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                <tr>\n' +
    '                    <td>\n' +
    '                        <table border="0" width="100%" class="table-product">\n' +
    '                            <tbody>\n' +
    '                            <tr>\n' +
    '                                <td class="product-img" rowspan="2">\n' +
    '                                    <div class="img-container pull-left">\n' +
    '                                        <img class="img-polaroid" ng-src="assets\\images\\product_search\\{{ product.id }}.png">\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                                <td colspan="2">\n' +
    '                                        <span class="product-title">\n' +
    '                                            <table>\n' +
    '                                              <tr>\n' +
    '                                                <td class="product-info-container">\n' +
    '                                                  <h2 class="pull-left">{{product.title}}</h2>\n' +
    '\n' +
    '                                                  <span class="product-info-popover-trigger pull-left" popover="{{product.description}}" popover-trigger="hover" popover-placement="right" popover-popup-delay="0">\n' +
    '\n' +
    '                                                      <span class="product-info">i</span>\n' +
    '                                                  </span>\n' +
    '                                                </td>\n' +
    '                                              </tr>\n' +
    '                                              <tr>\n' +
    '                                                  <td class="product-quote-container">\n' +
    '                                                      <span class="online" ng-if="product.online">online</span>\n' +
    '                                                      <span class="instant-quote" ng-if="product.quote && product.default_quote && !product.disabled && !product.forbidden">Instant Quote</span>\n' +
    '                                                      <span class="refer-quote" ng-if="product.refer && product.online && !product.disabled && !product.forbidden">Needs Review</span>\n' +
    '                                                      <span class="refer-quote" style="background-color: #B71234" ng-if="product.disabled || product.forbidden">Ineligible</span>\n' +
    '                                                      <a href="{{ (product.url)?product.url:product.program.offlineApplicationUrl }}" ng-click="open_link($event)" target="_blank" class="offline" ng-if="!product.online && !product.disabled">\n' +
    '                                                            <span class="glyphicon glyphicon-file pull-left"></span>\n' +
    '                                                            <div class="link_text pull-left"> Access Markel applications</div>\n' +
    '                                                      </a>\n' +
    '                                                  </td>\n' +
    '                                              </tr>\n' +
    '                                            </table>\n' +
    '                                        </span>\n' +
    '                                </td>\n' +
    '                                <td colspan="4">\n' +
    '                                    <div ng-show="$first" class="pull-right" style="margin-top: 25px; margin-right: 25px; padding: 5px;">\n' +
    '                                        <button ng-show="!product.disabled && !product.forbidden" ng-click="open_product_page($event,product)" class="btn btn-primary" style="background-color: #009aa6;"> Download form <span ng-show="$first" class="glyphicon glyphicon-download-alt"></span></button>\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '                            <tr>\n' +
    '                                <td colspan="4">\n' +
    '                                    <div class="product-show-eligibility pull-left" ng-click="show_eligibility($event,product)">\n' +
    '                                        <span class="glyphicon" ng-class="{ \'glyphicon-chevron-down\': product.show_eligibility , \'glyphicon-chevron-right\' : !product.show_eligibility }"></span>\n' +
    '\n' +
    '                                        <span class="product-guidelines-header"><a>What you need to know</a></span>\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                                <td>\n' +
    '                                    <div ng-if="product.underwriting" class="product-underwriting pull-left" style="padding-top: 14px; padding-right: 15px;">\n' +
    '                                        {{ product.underwriting }}\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '                            <tr ng-show="product.show_eligibility">\n' +
    '                                <td colspan="6">\n' +
    '                                    <div class="product-guidelines">\n' +
    '                                        <div ng-bind-html="product.eligibility" class="product-guidelines-content well"></div>\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '                            <tr ng-if="product.marketing">\n' +
    '                                <td colspan="6">\n' +
    '                                    <div class="product-marketing">\n' +
    '                                        {{ product.marketing }}\n' +
    '                                    </div>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '                            </tbody>\n' +
    '                        </table>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/account/template/clearance.html',
    '<form class="form-horizontal" role="form" ng-submit="submitForm()">\n' +
    '\n' +
    '	<br>\n' +
    '	<div class="alert alert-info" ng-if="clearanceSearchResults.length">\n' +
    '		It looks like you may have applied with us before. If so please select your information and click <strong>Continue</strong><br>\n' +
    '	</div>\n' +
    '\n' +
    '	<table class="table table-bordered table-striped">\n' +
    '		<thead>\n' +
    '			<tr><th>Select&nbsp;</th>\n' +
    '			<th>Insured Name</th>\n' +
    '			<th>DBA</th>\n' +
    '			<th>Address 1</th>\n' +
    '			<th>Address 2</th>\n' +
    '			<th>City</th>\n' +
    '			<th>State</th>\n' +
    '			<th>Zip Code</th>\n' +
    '		</tr></thead>\n' +
    '		<tbody>\n' +
    '			<tr ng-repeat="insured in clearanceSearchResults">\n' +
    '				<td><input name="selectedInsuredIndex" type="radio" class="piiSafe" ng-value="$index" ng-model="form.selectedInsuredIndex" ng-click="enableContinueBtn()"></td>\n' +
    '				<td>{{insured.name}}</td>\n' +
    '				<td>{{insured.dba}}</td>\n' +
    '				<td>{{insured.address1}}</td>\n' +
    '				<td>{{insured.address2}}</td>\n' +
    '				<td>{{insured.city}}</td>\n' +
    '				<td>{{insured.state}}</td>\n' +
    '				<td>{{insured.zip}}</td>\n' +
    '			</tr>\n' +
    '		</tbody>\n' +
    '	</table>\n' +
    '\n' +
    '	<div class="alert alert-info" ng-if="clearanceSearchResults.length">\n' +
    '		 If none of the information above matches, please click <strong>Create New</strong>\n' +
    '	</div>\n' +
    '	<div class="alert alert-info" ng-if="!clearanceSearchResults.length">\n' +
    '		No results were found for <strong>{{searchName}}</strong>. Do you want to <strong>Create New?</strong>\n' +
    '	</div>\n' +
    '\n' +
    '\n' +
    '	<br>\n' +
    '\n' +
    '	<button type="button" class="btn btn-success btn-success-markel" ng-disabled="backBtnDisabled" ng-click="goBack()">Back</button>\n' +
    '	<button type="submit" class="btn btn-success btn-success-markel" ng-disabled="continueBtnDisabled">Continue</button>\n' +
    '	<button type="button" class="btn btn-success btn-success-markel" ng-click="createNew()" loading-button="status.loading">Create New</button>\n' +
    '\n' +
    '</form>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/account/template/editableSubmissionsModal.html',
    '<style>\n' +
    '    div.editable-submissions-modal {\n' +
    '        margin-left: -25%;\n' +
    '        width: 850px;\n' +
    '    }\n' +
    '\n' +
    '    #existingSubmissionsTable {\n' +
    '        margin-bottom: 4ex;\n' +
    '        margin-top: 2ex;\n' +
    '        width: 100%;\n' +
    '    }\n' +
    '\n' +
    '    #existingSubmissionsTable tbody > tr {\n' +
    '        border-top: 1px solid #ccc;\n' +
    '    }\n' +
    '\n' +
    '    #existingSubmissionsTable td, th {\n' +
    '        padding: 1ex 0ex 1ex 1ex;\n' +
    '        text-align:left;\n' +
    '    }\n' +
    '\n' +
    '</style>\n' +
    '\n' +
    '<div class="modal-header">\n' +
    '    <h3>We found\n' +
    '        <span ng-if="multipleExisting">these</span><span ng-if="!multipleExisting">this</span>\n' +
    '        <span ng-if="!canEndorse">recent submission<span ng-if="multipleExisting">s</span></span>\n' +
    '        <span ng-if="canEndorse && !multipleExisting">policy</span><span ng-if="canEndorse && multipleExisting">policies</span>\n' +
    '        for this insured</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll row-fluid">\n' +
    '    <div class="modal-body">\n' +
    '        <table id="existingSubmissionsTable">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th>&nbsp;</th>\n' +
    '                    <th>Effective Date</th>\n' +
    '                    <th>Submission Date</th>\n' +
    '                    <th>Status</th>\n' +
    '                    <th ng-if="hasPolicies">Policy Number(s)</th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '                <tr ng-repeat="submission in existingSubmissions">\n' +
    '                    <td>\n' +
    '                        <span product-label="submission.programAbbreviation" auto-width="true">{{ submission.programAbbreviation}}</span> {{ submission.marketedProgram }}\n' +
    '                    </td>\n' +
    '                    <td>{{ submission.effectiveDate }}</td>\n' +
    '                    <td>{{ submission.createdOn }}</td>\n' +
    '                    <td>{{ submission.status }}</td>\n' +
    '                    <td ng-if="hasPolicies">\n' +
    '                        <span ng-if="submission.status !== \'Issued\'">N/A</span>\n' +
    '                        <span ng-if="submission.status === \'Issued\'" ng-repeat="policyNumber in submission.policyNumbers">{{ policyNumber }}<br></span>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '\n' +
    '        <div ng-if="canEdit && !isBlocked">\n' +
    '            <p ng-if="multipleExisting">\n' +
    '                Click "Edit" to modify the first submission.\n' +
    '                <span ng-if="issuedCount > 0">\n' +
    '                    To endorse or inquire about the issued <span ng-if="issuedCount == 1">policy</span><span ng-if="issuedCount > 1">policies</span>, please click "Account Summary".\n' +
    '                </span>\n' +
    '            </p>\n' +
    '            <p>\n' +
    '                Editing the submission will update the effective date to <strong>{{ newEffectiveDate }}</strong>.\n' +
    '                Editing a submission may result in a different submission outcome.\n' +
    '                <span ng-if="isMultiSystemBundle">You will edit the submission during Step 3 of the submission process.</span>\n' +
    '            </p>\n' +
    '            <p ng-if="!isMultiSystemBundle">\n' +
    '                For more details or to message your underwriter, click "Account Summary".\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div ng-if="!canEdit && !isBlocked && !canEndorse && !multiplePrograms">\n' +
    '            <p ng-if="!multipleExisting && isMultiSystemBundle">\n' +
    '                This submission cannot be edited at this time. To proceed with your {{ maverickSubmission }} submission, click "Continue".\n' +
    '                You will be able to view the above submission or contact your underwriter at the end of the process.\n' +
    '            </p>\n' +
    '            <p ng-if="!multipleExisting && !isMultiSystemBundle">\n' +
    '                This submission cannot be edited at this time.  Please click "Account summary" to view the submission or contact your underwriter.\n' +
    '            </p>\n' +
    '            <p ng-if="multipleExisting">\n' +
    '                For more details or to message your underwriter, click "Account Summary".\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div ng-if="canEndorse && !multiplePrograms">\n' +
    '            You can continue to create a new submission or contact your underwriter to endorse the existing policy.\n' +
    '        </div>\n' +
    '        <div ng-if="canEndorse && multiplePrograms">\n' +
    '            You can continue to create new submissions or contact your underwriter to endorse one or more existing policies.\n' +
    '        </div>\n' +
    '        <div ng-if="!canEndorse && multiplePrograms">\n' +
    '            You cannot proceed with this submission at this time. Please click "Account summary" to contact your underwriter.\n' +
    '        </div>\n' +
    '        <div ng-if="isBlocked" class="alert alert-error">\n' +
    '            This submission cannot be edited at this time.  Please click "Account summary" to view the submission or contact your underwriter.\n' +
    '        </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn" ng-click="close()" ng-disabled="isProcessing">Cancel</button>\n' +
    '    <button class="btn btn-primary" ng-if="!isMultiSystemBundle && !canEndorse" ng-click="navigateToAccountSummary()" ng-disabled="isProcessing">Account summary</button>\n' +
    '    <button class="btn btn-primary" ng-if="canEndorse" ng-click="startEndorsementMessage()" ng-disabled="isProcessing">Contact underwriter</button>\n' +
    '    <button class="btn btn-success" ng-if="canEdit" ng-click="editExistingSubmission()" ng-disabled="isProcessing">Edit</button>\n' +
    '    <button class="btn btn-success" ng-if="canEndorse" ng-click="createNewSubmission()" ng-disabled="isProcessing">Continue</button>\n' +
    '    <button class="btn btn-success" ng-if="!canEdit && isMultiSystemBundle" ng-click="skipSubmission()" ng-disabled="isProcessing">Continue</button>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/account/template/insuredAccountTemplate.html',
    '<style>\n' +
    '\n' +
    '    div.setInsured div.placeSearch{\n' +
    '        margin-bottom: 2em;\n' +
    '    }\n' +
    '\n' +
    '    div.setInsured div.placeSearch > ul.ui-autocomplete {\n' +
    '        max-width: none !important;\n' +
    '    }\n' +
    '\n' +
    '    div.setInsured div.searchBar input {\n' +
    '        margin-left:3px;\n' +
    '    }\n' +
    '\n' +
    '    div.setInsured div.searchBar span {\n' +
    '        font-size:24px;\n' +
    '    }\n' +
    '\n' +
    '    div.setInsured div.searchBar select {\n' +
    '        height: 46px;\n' +
    '        padding: 10px 0px;\n' +
    '    }\n' +
    '\n' +
    '    div.setInsured span.nonChoice { color: #009AA6; }\n' +
    '    div.setInsured div.showMoreInsureds { color: #808080; padding-top: 2.5px; padding-bottom: 11px}\n' +
    '    div.setInsured span.choiceHeading { color: #009AA6; text-decoration: underline;}\n' +
    '\n' +
    '    div.setInsured h4 {\n' +
    '        font-weight:bold;\n' +
    '        margin-bottom:1.2ex;\n' +
    '    }\n' +
    '\n' +
    '    div.setInsured button.toggleSearch {\n' +
    '        float:right;\n' +
    '        margin-right:10px;\n' +
    '    }\n' +
    '\n' +
    '    div.setInsured button.toggleEdit {\n' +
    '        padding: 7px !important;\n' +
    '    }\n' +
    '\n' +
    '    div.insuredForm h3 {\n' +
    '        border-top: 1px solid #DFE0E0;\n' +
    '        color: #323334;\n' +
    '        font-size:110%;\n' +
    '        font-weight:bold;\n' +
    '        margin-bottom:1.5ex;\n' +
    '        margin-top:1ex;\n' +
    '        padding-top:.5ex;\n' +
    '    }\n' +
    '\n' +
    '    div.insuredForm p.staticState {\n' +
    '        height: 25px;\n' +
    '        margin-top: 0px;\n' +
    '        padding: 4px;\n' +
    '    }\n' +
    '\n' +
    '    div.insuredForm input {\n' +
    '        padding-left: 8px;\n' +
    '    }\n' +
    '\n' +
    '    div.insuredForm div.selectedMailingAddress {\n' +
    '        margin-bottom:3em;\n' +
    '    }\n' +
    '\n' +
    '    div.insuredForm div.selectedMailingAddress p {\n' +
    '        font-size:110%;\n' +
    '        margin-bottom: .5ex;\n' +
    '    }\n' +
    '\n' +
    '    img.searchResultLoader {\n' +
    '        margin-left: 5px;\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '    div.placeSearch div.searchBoxHeader {\n' +
    '        padding-bottom:5px;\n' +
    '    }\n' +
    '\n' +
    '    div.placeSearch div.searchBoxHeader span {\n' +
    '        padding-left:2px;\n' +
    '    }\n' +
    '\n' +
    '    div.placeSearch input#searchPhrase {\n' +
    '        width: 100% !important;\n' +
    '    }\n' +
    '\n' +
    '    @media(min-width:1320px) {\n' +
    '\n' +
    '        div.placeSearch div.searchBoxHeader {\n' +
    '            padding-bottom:5px;\n' +
    '            padding-top:0px;\n' +
    '        }\n' +
    '\n' +
    '        div.placeSearch div.rightSearchColumn div {\n' +
    '            padding-left:15px;\n' +
    '        }\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '    @media(max-width:1319px) {\n' +
    '\n' +
    '        div.placeSearch div.leftSearchColumn{ float: none !important;}\n' +
    '\n' +
    '        div.placeSearch div.rightSearchColumn {\n' +
    '            float: none !important;\n' +
    '            margin-left: -3px !important;\n' +
    '        }\n' +
    '\n' +
    '        div.placeSearch div.rightSearchColumn div {\n' +
    '            padding-left:0px;\n' +
    '        }\n' +
    '\n' +
    '        div.placeSearch div.searchBoxHeader {\n' +
    '            padding-bottom:5px;\n' +
    '            padding-top:5px;\n' +
    '        }\n' +
    '\n' +
    '    }\n' +
    '\n' +
    '\n' +
    '</style>\n' +
    '\n' +
    '\n' +
    '<div class="center-container">\n' +
    '    <div class="well quote-well" ng-if="status.loadingSections">\n' +
    '        <div class="loading-box" ng-class="{\'box-shadow\' : !legacyMode}">\n' +
    '            <p>\n' +
    '                <img src="/assets/images/mkl-logo-loading.gif" ng-if="!legacyMode">\n' +
    '                <img src="../assets/images/ajax-loader-grey.gif" ng-if="legacyMode">\n' +
    '            </p>\n' +
    '            <h1>Retrieving data</h1>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '<div class="section box-shadow setInsured" ng-if="!status.loadingSections">\n' +
    '    <div class="banner insuredInformation">Insured Information</div><br>\n' +
    '\n' +
    '    <div class="row-fluid" ng-if="!status.hideSearch">\n' +
    '        <div class="span12 placeSearch">\n' +
    '            <div class="control-group field-position">\n' +
    '                <div class="well">\n' +
    '                    <h4>Step 1: {{ searchTitle }}</h4>\n' +
    '                    <p>Enter any of the following:  Insured name, business name or address</p>\n' +
    '                </div>\n' +
    '                <div class="alert alert-error" ng-if="status.insuredRetrievalError">Information about the insured you selected could not be found.  Please try again.</div>\n' +
    '                <div class="controls field-position searchBar">\n' +
    '\n' +
    '                    <div class="row-fluid">\n' +
    '                        <div class="span2 leftSearchColumn">\n' +
    '                            <div class="searchBoxHeader">\n' +
    '                               <span>State</span>\n' +
    '                            </div>\n' +
    '                            <div>\n' +
    '                                <select name="state" id="searchState" class="input-tall piiSafe" ng-options="state.id as state.label for state in states" ng-model="form.state">\n' +
    '                                </select>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="span9 rightSearchColumn">\n' +
    '                            <div class="searchBoxHeader">\n' +
    '                                <span>Name or address</span>\n' +
    '                            </div>\n' +
    '                            <div>\n' +
    '                                <input id="searchPhrase" ng-model="searchPhrase" ng-disabled="status.searchInProgress" autocomplete autocomplete-min-length="3" autocomplete-source="lookupPlace" autocomplete-html="true" autocomplete-append-to="placeSearch" autocomplete-select="parsePlace" placeholder="Enter business name or address" class="piiSafe business-type-input input-tall highlight ng-pristine ng-valid ng-empty ng-touched" type="text">\n' +
    '                                <img class="searchResultLoader" ng-if="status.searchInProgress" src="../assets/images/ajax-loader-grey.gif">\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Information block when search is hidden, conditional on whether new/edited submission -->\n' +
    '    <div class="row-fluid" ng-if="status.hideSearch">\n' +
    '\n' +
    '        <div class="well" ng-if="!status.editingSubmission">\n' +
    '            <h4>{{ status.existingInsuredSelected ? "Step 2: Verify Insured Selection" : "Step 2: Enter Name and Contact Information" }}</h4>\n' +
    '            <button ng-if="status.canEditInsured" class="btn btn-info toggleButton toggleSearch" ng-click="showSearch()">Search Again</button>\n' +
    '            <div ng-if="!status.continueWithoutSearchResult && !status.existingInsuredSelected">\n' +
    '                <p>If the insured address is correct, enter the required name and contact information fields and click the "Continue" button.</p>\n' +
    '                <p>If the address is not correct, you can search again.</p>\n' +
    '            </div>\n' +
    '            <div ng-if="status.continueWithoutSearchResult && !status.existingInsuredSelected">\n' +
    '                <p>Please enter the insured address and contact information in the form fields below.</p>\n' +
    '                <p>Once you complete the form, click the "Continue" button.</p>\n' +
    '            </div>\n' +
    '            <div ng-if="status.existingInsuredSelected">\n' +
    '                <p>If this is the insured you want to use for this this submission, click the "Continue" button.</p>\n' +
    '                <p>If this is not the correct insured, you can search again.</p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="well" ng-if="status.editingSubmission">\n' +
    '            <h4>Update the Insured Information</h4>\n' +
    '            <p ng-if="status.canEditInsured">\n' +
    '                Use the form below to update the insured address, name, and contact information.\n' +
    '            </p>\n' +
    '            <div ng-if="!status.canEditInsured">\n' +
    '                <p>The insured information cannot be edited because the insured already has at least one active policy with Markel.</p>\n' +
    '                <p>\n' +
    '                    Please use the message board to contact your Underwriter if this information needs to be corrected.  Otherwise, please\n' +
    '                    click the "Continue" button to continue editing.\n' +
    '                </p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="status.showInsuredForm" class="insuredForm">\n' +
    '\n' +
    '        <form name="insuredForm" ng-class="{\'submitted\': submitted}" role="form" novalidate>\n' +
    '            <h3 class="subHeader">Insured Information</h3>\n' +
    '\n' +
    '            <div class="contactInfo">\n' +
    '                <div class="row-fluid">\n' +
    '                    <div class="span6">\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="insuredName" class="control-label">{{status.showInsuredName ?  \'Insured Name\' :\'Business Name\' }}<span class="required-indicator">*</span> </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="name" id="insuredName" type="text" class="piiSafe inputClear" ng-model="form.name" ng-disabled="!status.canEditInsured || disableField(\'name\')" required>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="dba" class="control-label">Doing Business As (DBA) </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="dba" id="dba" type="text" class="piiSafe inputClear" ng-model="form.dba" ng-disabled="!status.canEditInsured || disableField(\'dba\')" required>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="phone" class="control-label">Phone Number </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input type="text" name="phone" id="phone" class="piiSafe inputClear" ng-model="form.phone" maxlength="16" ng-keyup="format_phone()" required ng-disabled="!status.canEditInsured">\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="email" class="control-label">Email Address <span ng-if="status.isEmailRequired" class="required-indicator">*</span></label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="email" id="email" type="text" class="piiSafe inputClear" ng-model="form.email" ng-disabled="!status.canEditInsured" required>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid" ng-if="!status.editingSubmission">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="website" class="control-label">Website <span ng-if="isWebsiteRequired" class="required-indicator">*</span> </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="website" id="website" type="text" class="piiSafe inputClear" ng-model="form.website" ng-disabled="!status.canEditInsured" required>\n' +
    '                                    <span ng-if="isWebsiteRequired">\n' +
    '                                        <input type="checkbox" ng-model="form.isChecked"> No website available\n' +
    '                                    </span>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid" ng-if="showFein()">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="fein" class="control-label">FEIN <span ng-if="form.isFeinRequired" class="required-indicator">*</span></label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="fein" id="fein" type="text" class="piiSafe inputClear" ng-model="form.fein" ng-disabled="!status.canEditInsured || disableField(\'fein\')" ng-keyup="format_fein()" required>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '	                    <div class="row-fluid" ng-show="showAgencyLocations">\n' +
    '		                         <div class="span4">\n' +
    '	                             <label for="agency" class="control-label">Agency Location <span class="required-indicator">*</span></label>\n' +
    '		                         </div>\n' +
    '		                        <div class="span8 field-position">\n' +
    '			                        <select name="agency" id="agency" ng-options="agency as agency.name for agency in userProfile.iconAgencies" ng-init="form.agency = userProfile.iconAgencies[ 0 ]" ng-model="form.agency" ng-change="changeAgencyId( )">\n' +
    '			                        </select>\n' +
    '		                        </div>\n' +
    '                        </div>\n' +
    '                        <div class="control-group" ng-if="status.editingSubmission">\n' +
    '                            <div class="row-fluid">\n' +
    '                                <div class="span4">\n' +
    '                                    <label for="effectiveDate" class="control-label">Effective Date<span class="required-indicator">*</span></label>\n' +
    '                                </div>\n' +
    '                                <div class="span8 field-position">\n' +
    '                                    <div class="controls input-append">\n' +
    '                                        <input id="amEffectiveDate" type="text" class="piiSafe" ng-model="form.effectiveDate" date-picker date-picker-min-date="-6" date-picker-defaultdate="0" ng-if="marketedPrograms[0].programCode== \'AM\'">\n' +
    '                                        <input id="effectiveDate" type="text" class="piiSafe" ng-model="form.effectiveDate" date-picker date-picker-min-date="0" date-picker-defaultdate="0" ng-if="marketedPrograms[0].programCode != \'AM\'"><span class="add-on"><i id="effectiveDate_icon" class="icon-calendar"></i></span>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="span4">\n' +
    '                        <div ng-if="contactMessages.error.length" class="alert alert-error">\n' +
    '                            <ul>\n' +
    '                                <li ng-repeat="error in contactMessages.error track by $index">{{ error }}</li>\n' +
    '                            </ul>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="span4">\n' +
    '                        <div ng-if="notEligibleMsg" class="alert alert-error">\n' +
    '                            <ul>\n' +
    '                                <li>{{ notEligibleMsg }}</li>\n' +
    '                            </ul>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            \n' +
    '            <h3 class="subHeader">Insured Mailing Address</h3>\n' +
    '\n' +
    '                <div class="row-fluid">\n' +
    '                    <div class="span6">\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="address1" class="control-label">Address 1 <span class="required-indicator">*</span> </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="address1" id="address1" type="text" class="piiSafe inputClear" ng-model="form.address1" ng-disabled="!status.canEditInsured || status.selectedInsuredAddressError" required>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="address2" class="control-label">Address 2 </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="address2" id="address2" type="text" class="piiSafe inputClear" ng-model="form.address2" ng-disabled="!status.canEditInsured || status.selectedInsuredAddressError">\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="zipCode" class="control-label">Zip Code <span class="required-indicator">*</span> </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <input name="zipCode" id="zipCode" type="text" maxlength="5" class="piiSafe inputClear" ng-model="form.zip" ng-blur="format_zip()" required>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="city" class="control-label">City <span class="required-indicator">*</span> </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <select name="city" id="city" class="piiSafe" ng-model="form.city" ng-options="city.value as city.label for city in form_data.default.cities">\n' +
    '                                </select>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="row-fluid">\n' +
    '                            <div class="span4">\n' +
    '                                <label for="state" class="control-label">State <span class="required-indicator">*</span> </label>\n' +
    '                            </div>\n' +
    '                            <div class="span8 field-position">\n' +
    '                                <select name="state" id="state" class="piiSafe" ng-model="form.state" ng-options="state.value as state.label for state in form_data.default.states">\n' +
    '                                </select>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="span4">\n' +
    '                        <div ng-if="addressMessages.error.length" class="alert alert-error">\n' +
    '                            <ul>\n' +
    '                                <li ng-repeat="error in addressMessages.error track by $index">{{ error }}</li>\n' +
    '                            </ul>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '             <input type="text" class="hidden" qa-id="insured-max-score" qa-value="{{ form.maxscore }}" ng-model="form.maxscore"> \n' +
    '        </form>\n' +
    '\n' +
    '    </div>\n' +
    '</div>\n' +
    '    <div class="bottom-bar row-fluid span12">\n' +
    '        <div class="center-container span12">\n' +
    '               <div class="section-actions pull-right">\n' +
    '                <button class="btn btn-success eligibility-continue" loading-button="status.loading" ng-click="submitForm()" qa-btn="insured-account-continue">Continue\n' +
    '                </button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/account/template/licensedInfoModal.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Licensed Agent</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll row-fluid">\n' +
    '	<div class="modal-body">\n' +
    '		<table class="table table-condensed table-bordered">\n' +
    '			<tr>\n' +
    '				<th>Agent</th>\n' +
    '				<th>State</th>\n' +
    '				<th>Start</th>\n' +
    '				<th>End</th>\n' +
    '				<th>Status</th>\n' +
    '			</tr>\n' +
    '			<tr>\n' +
    '				<td>{{ name }}</td>\n' +
    '				<td>{{ state }}</td>\n' +
    '				<td>{{ effectiveDate | date : "MM/dd/yyyy" }}</td>\n' +
    '				<td>{{ expirationDate | date : "MM/dd/yyyy" }}</td>\n' +
    '				<td ng-if="status">Active</td>\n' +
    '				<td ng-if="!status">Not Active</td>\n' +
    '			</tr>\n' +
    '		</table>\n' +
    '\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn" ng-click="close()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/account/template/template.html',
    '\n' +
    '<div class="section box-shadow center-container">\n' +
    '    <div class="banner">Insured Information</div><br>\n' +
    '    <div class="row-fluid"> \n' +
    '        <div class="span12">\n' +
    '            <div ng-if="messages.error.length" error-message error-message-array="messages.error"></div>\n' +
    '            <div class="well" ng-if="!status.canEditInsured">The insured information cannot be edited because the\n' +
    '                insured already has at least one active policy with Markel.\n' +
    '                Please use the message board to contact your Underwriter if this information needs to be corrected.\n' +
    '            </div>\n' +
    '            <form name="insuredForm" ng-class="{\'submitted\': submitted}" role="form" novalidate>\n' +
    '                <div class="control-group span3 field-position">\n' +
    '                    <label for="name" class="control-label">Insured Name <span class="required-indicator">*</span> </label>\n' +
    '                    <div class="controls field-position">\n' +
    '                        <input name="name" id="name" type="text" class="piiSafe inputClear" ng-model="form.name" style="padding-right: 35px;" required>\n' +
    '                        <span class="field-OK glyphicon glyphicon-ok" ng-show="insuredForm.name.$valid"></span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="control-group span3 field-position">\n' +
    '                    <label for="dba" class="control-label">Doing Business As (DBA) </label>\n' +
    '                    <div class="controls field-position">\n' +
    '                        <input name="dba" id="dba" type="text" class="piiSafe inputClear" ng-model="form.dba" style="padding-right: 35px" required>\n' +
    '                        <span class="field-OK glyphicon glyphicon-ok" ng-show="insuredForm.dba.$valid"></span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="control-group span3 field-position">\n' +
    '                    <label for="phone" class="control-label">Phone Number</label>\n' +
    '                    <div class="controls field-position">\n' +
    '                        <input type="text" class="piiSafe inputClear" ng-model="form.phone" value="{{:: form.phone }}" maxlength="16" ng-keyup="format_phone()" style="padding-right: 35px" required>\n' +
    '\n' +
    '                        <span class="field-OK glyphicon glyphicon-ok" ng-show="insuredForm.phone.$valid"></span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="row-fluid">\n' +
    '                    <div class="span12">\n' +
    '                        <div class="control-group span3 field-position">\n' +
    '                            <label for="email" class="control-label">Email Address <span class="required-indicator">*</span> </label>\n' +
    '                            <div class="controls field-position">\n' +
    '                                <input name="email" id="email" type="email" class="inputClear" ng-model="form.email" style="padding-right: 35px" required>\n' +
    '                                <span class="field-OK glyphicon glyphicon-ok" ng-show="insuredForm.email.$valid"></span>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div class="control-group span3">\n' +
    '                            <label for="preferreddeliverytype" class="control-label">Document Delivery Preference</label>\n' +
    '                            <div class="controls">\n' +
    '                                <select name="preferreddeliverytype" class="piiSafe" id="preferreddeliverytype" ng-options="preference.value as preference.label for preference in form_data.delivery" ng-model="form.deliveryPreference">\n' +
    '                                </select>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <div ng-if="status.isFeinRequired" class="control-group span3 field-position">\n' +
    '                            <label for="fein" class="control-label">FEIN <span class="required-indicator">*</span></label>\n' +
    '                            <div class="controls field-position">\n' +
    '                                <input name="fein" id="fein" type="text" maxlength="9" value="{{:: form.fein }}" ng-keyup="format_fein()" class="inputClear" ng-model="form.fein" style="padding-right: 35px" required>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="control-group" id="zipEntryControlGroup">\n' +
    '                    <h3 id="specialtyApplicationAddressHeader">Mailing Address</h3>\n' +
    '                    <div class="row-fluid">\n' +
    '                        <div class="span12">\n' +
    '                            <table class="table table-condensed table-bordered" id="tableLocations" style="margin-left: 15px; width: 98%">\n' +
    '                                <tr>\n' +
    '                                    <th class="span3">Address <span class="required-indicator">*</span></th>\n' +
    '                                    <th class="span3">City</th>\n' +
    '                                    <th class="span3">State</th>\n' +
    '                                    <th class="span3">Zip</th>\n' +
    '                                    <th class="span3">County</th>\n' +
    '                                </tr>\n' +
    '                                <tr>\n' +
    '                                    <td class="controls field-position">\n' +
    '                                        <input type="text" name="address1" class="piiSafe inputClear" ng-model="form.default.address1" value="{{:: form.default.address1}}" required>\n' +
    '                                        <span class="tableGlip-OK glyphicon glyphicon-ok" ng-show="insuredForm.address1.$valid"></span>\n' +
    '                                    </td>\n' +
    '\n' +
    '                                    <td>\n' +
    '                                        <select name="city" class="piiSafe" ng-model="form.default.city">\n' +
    '                                            <option ng-repeat="option in form_data.default.cities" selected="{{::option.value == form.default.city }}" value="{{:: option.value }}">{{:: option.label }}</option>\n' +
    '                                        </select>\n' +
    '                                    </td>\n' +
    '                                    <td>\n' +
    '                                        <select name="state" class="piiSafe" ng-model="form.default.state">\n' +
    '                                            <option ng-repeat="option in form_data.default.states" selected="{{::option.value == form.default.state }}" value="{{:: option.value }}"> {{:: option.label }}</option>\n' +
    '                                        </select>\n' +
    '                                    </td>\n' +
    '                                    <td>\n' +
    '                                        {{:: form.default.zip }}\n' +
    '                                    </td>\n' +
    '                                    <td>\n' +
    '                                        <select name="county" class="piiSafe" ng-model="form.default.county">\n' +
    '                                            <option ng-repeat="option in form_data.default.counties" selected="{{::option.value == form.default.county }}" value="{{:: option.value }}"> {{:: option.label }}</option>\n' +
    '                                        </select>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '                            </table>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '<div class="bottom-bar">\n' +
    '    <div class="section-actions center-container">\n' +
    '        <button class="btn btn-success pull-left" qa-btn="account-back" ng-click="navigate_back()">Back</button>\n' +
    '\n' +
    '        <button class="btn btn-success pull-right" loading-button="status.loading" qa-btn="account-continue" ng-click="submitForm()">Continue\n' +
    '        </button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/eligibility/template/template.html',
    '<div class="row-fluid section center-container" style="margin-top:25px;">\n' +
    '	<div class="span12">\n' +
    '		<div class="sub-section box-shadow">\n' +
    '			<div class="well quote-well" ng-if="status.retrievingApplication">\n' +
    '				<h1>Retrieving data...</h1>\n' +
    '				<p><img src="/assets/images/ajax-loader-grey.gif"></p>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="row-fluid span8" ng-if="status.retrievalError">\n' +
    '				<div class="alert alert-error">\n' +
    '					There was a problem with opening your submission for editing. Please use the message board to contact your Underwriter to edit this submission.\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div error-message error-message-array="messages.error"></div>\n' +
    '			<div class="alert alert-warning" ng-if="status.notEligible">\n' +
    '				<p>{{notEligibleMsg}}</p>\n' +
    '			</div>\n' +
    '			<div class="alert alert-warning" ng-if="status.showDateChangedMessage">\n' +
    '				<p>\n' +
    '					Note: because past effective dates cannot be accepted, the previous effective date\n' +
    '					({{::status.previousEffectiveDate}}) has been updated to today\'s date.\n' +
    '				</p>\n' +
    '			</div>\n' +
    '			<form class="form-horizontal" style="padding:15px;" role="form" ng-if="!status.retrievingApplication && !status.retrievalError" ng-submit="submitForm()">\n' +
    '				<div class="control-group">\n' +
    '					<label for="zip" class="control-label">Zip Code</label>\n' +
    '					<div class="controls">\n' +
    '						<input id="zip" type="text" class="piiSafe" ng-model="form.zip" ng-disabled="!status.canEditZip">\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<label for="effectiveDate" class="control-label">Desired Effective Date</label>\n' +
    '					<div class="controls input-append">\n' +
    '						<input id="amEffectiveDate" type="text" class="piiSafe" ng-model="form.effectiveDate" date-picker date-picker-min-date="-6" date-picker-defaultdate="0" ng-if="marketedPrograms[0].programCode== \'AM\'">\n' +
    '						<input id="effectiveDate" type="text" class="piiSafe" ng-model="form.effectiveDate" date-picker date-picker-min-date="0" date-picker-defaultdate="0" ng-if="marketedPrograms[0].programCode != \'AM\'">\n' +
    '						<span class="add-on"><i id="effectiveDate_icon" class="icon-calendar"></i></span>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group" ng-if="!isUnAuthenticatedUser">\n' +
    '					<label for="marketedProgram" class="control-label">Business Type</label>\n' +
    '					<div class="controls">\n' +
    '						<select ng-if="marketedPrograms.length > 1" id="marketedProgram" class="piiSafe" ng-model="form.marketedProgram" ng-options="program.value as program.label for program in marketedPrograms"></select>\n' +
    '						<span ng-if="marketedPrograms.length === 1">{{ marketedPrograms[0].label }}</span>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group" ng-if="isUnAuthenticatedUser">\n' +
    '					<label for="businessTypeDescription" class="control-label">Business Type</label>\n' +
    '					<div class="controls">\n' +
    '						<select ng-if="businessTypeDescriptions.length > 1" id="businessTypeDescription" class="piiSafe" ng-model="form.businessTypeDescription">\n' +
    '						<option value="" ng-selected="true">Select One</option>\n' +
    '						<option ng-repeat=" businessTypeDescription in businessTypeDescriptions" value="{{businessTypeDescription.businessTypeID}}-{{businessTypeDescription.label}}">{{businessTypeDescription.label}}\n' +
    '						</option>\n' +
    '						</select>\n' +
    '						<span ng-if="businessTypeDescriptions.length === 1">{{ businessTypeDescriptions[0].label }}</span>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				\n' +
    '			</form>\n' +
    '		\n' +
    '\n' +
    '		</div>\n' +
    '		\n' +
    '		<div class="bottom-bar row-fluid span12">\n' +
    '        <div class="center-container span12">\n' +
    '               <div class="section-actions pull-right">\n' +
    '					<div class="pull-right next-button">\n' +
    '						<button ng-click="submitForm()" qa-btn="eligibility-continue" class="btn btn-success eligibility-continue" loading-button="status.loading">Continue</button>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/payment/template/template.html',
    '<div class="well quote-well">\n' +
    '\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span8">\n' +
    '			<h1 ng-if="paymentInfo.loading">Loading Payment Information...</h1>\n' +
    '			<br>\n' +
    '			<p><img src="../assets/images/ajax-loader-grey.gif" ng-if="paymentInfo.loading"></p>\n' +
    '			<iframe src="{{paymentURL}}" scrolling="no" id="payment-frame" width="100%" ng-if="!paymentInfo.loading && !paymentInfo.error && !!paymentURL" iframe-resizer></iframe>\n' +
    '			<div class="alert alert-error" ng-if="paymentInfo.error">Unable to load payment information.</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/pricingDetails/template/template.html',
    '<div ng-switch="program">\n' +
    '    <animal-mortality ng-switch-when="AM" pricing-data="pricingData"></animal-mortality>\n' +
    '    <div ng-switch-default><p class="defaultPlaceholder">&nbsp;</p></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/questions/template/template.html',
    '<div class="row-fluid specialty-application-questions" ng-class="{ \'center-container\' : !legacyMode , \'mobile\' : user.isMobile }">\n' +
    '	<div class="well quote-well" ng-if="status.loadingSections">\n' +
    '		<div class="loading-box" ng-class="{\'box-shadow\' : !legacyMode}">\n' +
    '			<p>\n' +
    '				<img src="/assets/images/mkl-logo-loading.gif" ng-if="!legacyMode">\n' +
    '				<img src="../assets/images/ajax-loader-grey.gif" ng-if="legacyMode">\n' +
    '			</p>\n' +
    '			<h1>Retrieving data</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div class="row-fluid specialty-application-questions" ng-if="status.finalizedApplication || ( !status.loadingSections && !status.hasVisibleSections && !status.expired )">\n' +
    '		<div class="alert alert-info">\n' +
    '			<p ng-if="!isAgent">Your application cannot be edited once it has been submitted. Please <a href="#/application/result">click here</a> to return to the outcome page.</p>\n' +
    '			<p ng-if="isAgent">Your application has been submitted. Please <a href="#/application/result">click here</a> to return to the outcome page, where you may have the option of\n' +
    '			editing the application.</p>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '		\n' +
    '	<div ng-if="!status.loadingSections && tree.sections.length && status.hasVisibleSections" class="row-fluid specialty-lower">\n' +
    '\n' +
    '		<div class="row-fluid left-bar" ng-if="!legacyMode" ng-class="{ \'span3\' : !user.isMobile, \'span12\': user.isMobile}">\n' +
    '			<div ng-class="{ \'span11\' : !user.isMobile, \'span12\': user.isMobile}">\n' +
    '				<div class="span12 box-shadow center">\n' +
    '					<div ng-if="1==2" class="span11 specialty-communication banner">\n' +
    '						<div class="pull-left contact-options span12">\n' +
    '							<span class="glyphicon glyphicon-earphone pull-left"></span>\n' +
    '							<span class="glyphicon glyphicon-envelope"></span>\n' +
    '							<span class="glyphicon glyphicon-comment pull-right"></span>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '\n' +
    '					<div progress-dial container-id="\'sectionProgressDial\'" size="50" percentage="status.percentComplete" style="margin-top:5px;margin-bottom:5px;"></div>\n' +
    '\n' +
    '					<div class="span12 noHorizontalPadding" ng-class="{ hidden: !status.menuVisible }">\n' +
    '						<div class="tree-content">\n' +
    '							<div treecontrol class="tree-mql" ng-class="{ disabled: areRequestsPending() }" tree-model="tree.sections" options="tree.options" on-selection="loadSection( node )" expanded-nodes="tree.expandedNodes" selected-node="tree.selectedNode" filter-expression="showSectionInTree">\n' +
    '\n' +
    '								<div class="node-label" ng-class="{\n' +
    '									visited: node.visited,\n' +
    '									openBranch: isCurrentRootNode( node ),\n' +
    '									closedBranch: isClosedRootNode( node ),\n' +
    '									closedItem: !showNode( node ) }">\n' +
    '									<span class="nodeTitle">{{ node.title }}</span>\n' +
    '									<span ng-if="node.type !== \'applicationReview\'">\n' +
    '										<span ng-if="node.depth == 1">\n' +
    '\n' +
    '											<span ng-if="node.sections.length">\n' +
    '												<span ng-if="isClosedRootNode( node) ">\n' +
    '													<span ng-if="node.visited && node.isComplete()" class="glyphicon glyphicon-ok section-status section-status-ok"></span>\n' +
    '													<span ng-if="node.visited && !node.isComplete()" class="glyphicon glyphicon-exclamation-sign section-status section-status-invalid"></span>\n' +
    '												</span>\n' +
    '												<span ng-if="isCurrentRootNode( node )">\n' +
    '													<span ng-if="node.visited && node.isSectionValid() && node.isComplete()" class="glyphicon glyphicon-ok section-status section-status-ok"></span>\n' +
    '													<span ng-if="node.visited && node.isSectionValid() && !node.isComplete()" class="glyphicon glyphicon-pencil section-status section-status-ok"></span>\n' +
    '													<span ng-if="node.visited && !node.isSectionValid()" class="glyphicon glyphicon-exclamation-sign section-status section-status-invalid"></span>\n' +
    '												</span>\n' +
    '											</span>\n' +
    '\n' +
    '											<span ng-if="!node.sections.length">\n' +
    '												<span ng-if="node.visited && node.isComplete()" class="glyphicon glyphicon-ok section-status section-status-ok"></span>\n' +
    '												<span ng-if="node.visited && !node.isComplete()" class="glyphicon glyphicon-exclamation-sign section-status section-status-invalid"></span>\n' +
    '											</span>\n' +
    '\n' +
    '										</span>\n' +
    '\n' +
    '										<span ng-if="node.depth != 1">\n' +
    '											<span ng-if="node.visited && node.isSectionValid()" class="glyphicon glyphicon-ok section-status section-status-ok"></span>\n' +
    '											<span ng-if="node.visited && !node.isSectionValid() && showInvalidMarker( node )" class="glyphicon glyphicon-exclamation-sign section-status section-status-invalid"></span>\n' +
    '											<span ng-if="node.visited && !node.isSectionValid() && !showInvalidMarker( node )" class="glyphicon glyphicon-pencil section-status section-status-ok"></span>\n' +
    '										</span>\n' +
    '\n' +
    '									</span>\n' +
    '								</div>\n' +
    '							</div>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div> \n' +
    '			</div> \n' +
    '		</div>\n' +
    '\n' +
    '\n' +
    '		<div ng-class="{ \'span9 section-container\' : !legacyMode && !user.isMobile , \'span12\' : legacyMode && !user.isMobile, \'section-container span12\': user.isMobile  }">\n' +
    '			<div class="span12 section-quote-well" ng-if="status.loading" ng-class="{\'well quote-well\' : legacyMode}">\n' +
    '				<div class="loading-box" ng-class="{\'box-shadow\' : !legacyMode}">\n' +
    '					<p>\n' +
    '						<img src="/assets/images/mkl-logo-loading.gif" ng-if="!legacyMode">\n' +
    '						<img src="../assets/images/ajax-loader-grey.gif" ng-if="legacyMode">\n' +
    '					</p>\n' +
    '					<h1>Retrieving data</h1>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="span12" ng-class="{ \'section box-shadow\' : !legacyMode , \'legacy-section\' : legacyMode, \'section-disable\' : disableSection && areRequestsPending() }" ng-if="!status.loading && !status.onEnumerationLandingPage && !tree.selectedNode.shellSection">\n' +
    '				<div class="banner">\n' +
    '					<div style="float:right;" ng-if="!legacyMode" application-actions store="store"></div>\n' +
    '					{{ tree.selectedNode.title }}\n' +
    '\n' +
    '					<input typ="text" qa-section-id="{{ tree.selectedNode.sectionId }}" qa-section-key="{{ tree.selectedNode.name.toLowerCase() }}" qa-enumeration="{{ tree.selectedNode.enumeration.toLowerCase() }}" qa-instance="{{ tree.selectedNode.instance }}" class="hidden">\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<formly-form ng-if="tree.selectedNode.widgetReference != \'Building\'" class="form form-horizontal" model="form.model" fields="form.fields"></formly-form>\n' +
    '\n' +
    '				<div class="row-fluid" ng-if="tree.selectedNode.widgetReference == \'Building\' && !show_all" building-table model="form.model" fields="form.fields"></div>\n' +
    '			</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '			<div class="span12" ng-class="{ \'section box-shadow\' : !legacyMode , \'legacy-section\' : legacyMode }" ng-if="!status.loading && !status.onEnumerationLandingPage && tree.selectedNode.shellSection">\n' +
    '				<div class="banner">\n' +
    '					<div style="float:right;" ng-if="!legacyMode" application-actions store="store"></div>\n' +
    '					{{ tree.selectedNode.title }}\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<div ng-if="tree.selectedNode.id = \'agentDateSelections\'" agent-date-selections section="tree.selectedNode" store="store" user="user"></div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div class="span12 enumeration-landing" ng-if="!status.loading && status.onEnumerationLandingPage">\n' +
    '				<div class="span12 alert alert-info enum-alert">\n' +
    '					Please select either the Next or Edit buttons to proceed in providing more details.\n' +
    '				</div>\n' +
    '				<table class="table table-striped table-bordered">\n' +
    '					<thead ng-if="tree.selectedNode.sections.length">\n' +
    '						<tr>\n' +
    '							<th class="span9">{{ tree.selectedNode.title }}</th>\n' +
    '							<th class="span3">Actions</th>\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '					<tbody>\n' +
    '						<tr ng-repeat="section in tree.selectedNode.sections">\n' +
    '							<td>{{ section.title }}</td>\n' +
    '							<td>\n' +
    '								<button type="button" class="btn btn-info" qa-btn="enumeration-load" ng-click="loadSection( section )">Edit</button>\n' +
    '								<button type="button" qa-btn="enumeration-delete" class="btn btn-danger" ng-click="deleteEnumeration( section )" ng-disabled="tree.selectedNode.sections.length === 1">\n' +
    '									Delete\n' +
    '								</button>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '					<tfoot>\n' +
    '						<tr>\n' +
    '							<td colspan="2" class="text-center">\n' +
    '								<button type="button" class="btn btn-primary" qa-btn="enumeration-add" ng-click="addEnumeration( tree.selectedNode )">Add {{ tree.selectedNode.title }}</button>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '					</tfoot>\n' +
    '				</table>\n' +
    '			</div>\n' +
    '			\n' +
    '\n' +
    '			<div class="section-navigation row-fluid" ng-if="!status.loading && legacyMode">\n' +
    '				<div class="span12">\n' +
    '					<div class="pull-left previous-button">\n' +
    '						<button qa-btn="application-legacy-previous" class="btn btn-success" ng-if="showPreviousButton()" ng-disabled="areRequestsPending()" ng-click="loadSection( sectionGraph[ tree.selectedNode.sectionId ].previous )">\n' +
    '								&laquo; Previous\n' +
    '						</button>\n' +
    '						<span class="alert hidden legacyMessage" ng-if="sectionGraph[ tree.selectedNode.sectionId ].previous && !allSectionsAreVisitedAndComplete() && isOnLastSection()">You may not Continue until all fields pass validation. Use the Next and Previous buttons to complete all pages.</span>\n' +
    '					</div>\n' +
    '					<div class="pull-right next-button">\n' +
    '						<div>\n' +
    '							<button qa-btn="application-legacy-next" class="btn btn-success" ng-if="sectionGraph[ tree.selectedNode.sectionId ].next" ng-disabled="areRequestsPending()" ng-click="loadSection( sectionGraph[ tree.selectedNode.sectionId ].next )">\n' +
    '									Next &raquo;\n' +
    '							</button>\n' +
    '							<span class="alert hidden legacyMessage" ng-if="sectionGraph[ tree.selectedNode.sectionId ].next">The E & O application contains multiple pages. Use the Next and Previous buttons to complete all pages.</span>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<!-- the div below is hidden unless this template is viewed from a legacy WC/BOP + EO submission -->\n' +
    '					<div class="legacySubmitButtonDiv span12 hidden">\n' +
    '						<br>\n' +
    '						<button qa-btn="application-legacy-submit" id="legacySubmitButton" class="btn btn-success legacySubmitButton span2 stepSubmit" ng-if="isOnLastSection() && !preBindEdit" ng-disabled="areRequestsPending() || !allSectionsAreVisitedAndComplete()" ng-click="finalizeApplication()">\n' +
    '							Continue\n' +
    '						</button>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '\n' +
    '	</div>\n' +
    '\n' +
    '\n' +
    '	<div class="bottom-bar row-fluid span12" ng-if="!legacyMode">\n' +
    '		<div class="section-navigation center-container span12" ng-if="!status.loading && tree.selectedNode">\n' +
    '			<div class="span12 section-actions">\n' +
    '				<div class="pull-right next-button">\n' +
    '					<button class="btn btn-success btn-application-submit" qa-btn="application-previous" ng-if="showPreviousButton()" ng-disabled="areRequestsPending()" ng-click="loadSection( sectionGraph[ tree.selectedNode.sectionId ].previous, true )">\n' +
    '							<span class="glyphicon glyphicon-chevron-left"></span> <span class="btn_txt"></span>\n' +
    '					</button>\n' +
    '\n' +
    '					<button qa-btn="application-next" class="btn btn-success btn-application-next" ng-if="sectionGraph[ tree.selectedNode.sectionId ].next" ng-disabled="areRequestsPending() || !blockingDataPointsAreChecked()" ng-click="loadSection( sectionGraph[ tree.selectedNode.sectionId ].next )">\n' +
    '							<span class="btn_txt">NEXT</span> <span class="glyphicon glyphicon-chevron-right"></span>\n' +
    '					</button>\n' +
    '\n' +
    '		\n' +
    '					<button qa-btn="application-submit" class="btn btn-success btn-application-submit" ng-if="isOnLastSection() && !preBindEdit" ng-disabled="areRequestsPending() || !allSectionsAreVisited() || !sectionsAreCompleted" ng-click="finalizeApplication()">\n' +
    '							<span class="btn_txt">Submit Application</span>\n' +
    '					</button>\n' +
    '					<button qa-btn="application-submit-additional" class="btn btn-success btn-additional-questions" ng-if="isOnLastSection() && preBindEdit" ng-disabled="areRequestsPending() || !allSectionsAreVisitedAndComplete()" ng-click="finalizeAdditionalQuestions()">\n' +
    '						<span class="btn_txt">Submit Additional Questions</span>\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div class="alert-message pull-right" style="color:white;margin-right:-20px;" ng-if="isOnLastSection() && (areRequestsPending() || !allSectionsAreVisitedAndComplete()) && !sectionsAreCompleted">\n' +
    '					<span ng-if="!preBindEdit">Please review each application section prior to Submitting</span>\n' +
    '					<span ng-if="preBindEdit">Please review each question section prior to submitting</span>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '	</div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/quoteResult/template/ConfirmTemplate.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a><h4>&nbsp;</h4>\n' +
    '</div>\n' +
    '<div class="modal-body" style="padding-left: 17px;">\n' +
    '	<h>The policy effective date is {{effectiveDate}}. Please confirm this is correct.</h>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn btn-primary" ng-click="buyNow()">Confirm</button>\n' +
    '	<button class="btn" ng-click="messageUW( accountId, submissionId )">Update</button>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/quoteResult/template/ElectronicDeliveryTermsModal.html',
    '<div class="modal-header">\n' +
    '	<h2>Markel<sup>&#174;</sup> Electronic Delivery and Signature Consent</h2>\n' +
    '</div>\n' +
    '<div class="modal-body">\n' +
    '	<p>\n' +
    '		Markel Corporation affiliated insurance companies along with its underwriting and service manager,\n' +
    '		Markel Service Incorporated, known collectively as “Company,” is providing You with the opportunity\n' +
    '		to receive electronic delivery of any available insurance related documents, notices, billing and policies\n' +
    '		relating to coverage You now have, may apply for or may later have.\n' +
    '	</p>\n' +
    '\n' +
    '	<p>\n' +
    '		For the purposes of this Consent, “You or Your” shall mean the Named Insured, Prospective Named Insured,\n' +
    '		Customer or Client and refers to the person, legal entity or authorized representative of the Named Insured.\n' +
    '		Insurance Producers authorized by You, must be licensed and appointed by the Company to complete this form on\n' +
    '		Your behalf.\n' +
    '	</p>\n' +
    '\n' +
    '	<p>\n' +
    '		The purpose of this consent is to ensure that You are fully aware of the consequences of agreeing to receive\n' +
    '		and sign documents electronically. “Electronic documents” include all electronic documents we may send to You,\n' +
    '		including documents You may complete via web page and save on Your computer or attach to e-mail. An “Electronic\n' +
    '		Signature”\n' +
    '		includes any mark, symbol, sound or process that is written, stamped, engraved, attached to or logically\n' +
    '		associated\n' +
    '		with an electronic document and executed by a person with the intent to sign.\n' +
    '	</p>\n' +
    '\n' +
    '	<h3>You have the following rights:</h3>\n' +
    '	<ol>\n' +
    '		<li>\n' +
    '			Right to Receive Paper Documents: You have the right to receive any document provided in paper or\n' +
    '			non-electronic form.\n' +
    '			If You want a paper copy of any document, please contact the Company. There is not a fee.\n' +
    '		</li>\n' +
    '		<li>\n' +
    '			Right to Withdraw Consent: You have the right to withdraw Your consent to receive documents electronically\n' +
    '			by\n' +
    '			contacting the Company. The legal validity and enforceability of the electronic documents, signatures and\n' +
    '			deliveries used prior to withdrawal of consent will not be affected. In other words, all prior electronic\n' +
    '			deliveries\n' +
    '			shall be fully valid and enforceable.\n' +
    '		</li>\n' +
    '		<li>\n' +
    '			Changes to Your E-Mail Address: You should keep the Company informed of any change in Your electronic or\n' +
    '			e-mail address.\n' +
    '			Please contact the Company as soon as possible if there are any changes.\n' +
    '		</li>\n' +
    '		<li>\n' +
    '			Your Ability to Access Disclosures: By agreeing to this consent, You acknowledge that You can access and\n' +
    '			retain\n' +
    '			the electronic documents. The hardware and software required is an email address, a document reader and\n' +
    '			access to\n' +
    '			a computer or a device that can access the internet. To retain the notices or documents, you must have the\n' +
    '			ability\n' +
    '			to retain the notices or documents delivered by electronic means, which means access to a printer and/or the\n' +
    '			ability\n' +
    '			to download the notices or documents to a hard drive or an external media storage device1.\n' +
    '		</li>\n' +
    '		<li>\n' +
    '			You may contact us at:\n' +
    '			<blockquote>\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span4">\n' +
    '						<strong>Mailing Address:</strong>\n' +
    '					</div>\n' +
    '					<div class="span8">\n' +
    '						Markel <sup>&#174;</sup> <br>\n' +
    '						P.O. Box 3009<br>\n' +
    '						Omaha, NE 68103\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span4">\n' +
    '						<strong>phone:</strong>\n' +
    '					</div>\n' +
    '					<div class="span8">\n' +
    '						888-500-3344\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<br>\n' +
    '				<div class="row-fluid">\n' +
    '					<div class="span4">\n' +
    '						<strong>E-mail:</strong>\n' +
    '					</div>\n' +
    '					<div class="span8">\n' +
    '						markelesign@MarkelCorp.com\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</blockquote>\n' +
    '		</li>\n' +
    '		<li>\n' +
    '			Unless required by law, all notices, disclosures, authorizations, acknowledgements and other ‎documents\n' +
    '			relating\n' +
    '			to coverages You may have with us may be provided to You in electronic ‎form. We may still send some notices\n' +
    '			to\n' +
    '			You in paper at Your regular mailing address. For ‎this reason, it is important that You inform us of any\n' +
    '			changes\n' +
    '			to Your regular mailing address. ‎\n' +
    '		</li>\n' +
    '		<li>\n' +
    '			Your rights relating to insurance we provide to You may be dependent on when You receive ‎certain\n' +
    '			information from us.\n' +
    '			You are considered to have received information from us when ‎we notify You at Your email address alerting\n' +
    '			You that\n' +
    '			the information is waiting for You at ‎Your secure mailbox at the web portal or as posted on our website, as\n' +
    '			designated\n' +
    '			in the ‎email we would send to You.‎\n' +
    '		</li>\n' +
    '		<li>\n' +
    '			If the law requires us to provide to You particular information in writing, we may provide that ‎information\n' +
    '			instead\n' +
    '			by alerting You by email that the information is waiting for You at Your ‎secure mailbox at the web portal\n' +
    '			or as posted\n' +
    '			on our website, as designated in the email we ‎would send to You.‎\n' +
    '		</li>\n' +
    '\n' +
    '	</ol>\n' +
    '\n' +
    '\n' +
    '	<p>\n' +
    '		By agreeing to this consent form, You are agreeing to e-delivery on the terms above.\n' +
    '	</p>\n' +
    '	<h3>CONSENT:</h3>\n' +
    '	<p>\n' +
    '		The Company may utilize the services of third-party service providers to facilitate electronic delivery of\n' +
    '		Electronic\n' +
    '		documents. Accordingly, Your agreement to conduct business electronically is also contingent upon your agreement\n' +
    '		to such further terms and conditions, including any hardware and software requirements, as may be required of\n' +
    '		the\n' +
    '		Company’s various service providers. Furthermore, You expressly acknowledge and agree that any action or failure\n' +
    '		to act on the part of such service provider(s) shall not give rise to any cause of action, suit, claim for\n' +
    '		damages,\n' +
    '		or similar proceedings against the Company.\n' +
    '	</p>\n' +
    '	<p>\n' +
    '		By clicking the "{{ buttonText }}" button below, you are providing an Electronic Signature consenting to\n' +
    '		Electronic Delivery.\n' +
    '	</p>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn" ng-click="close()">Close</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/quoteResult/template/IsLocked.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a><h4>&nbsp;</h4>\n' +
    '</div>\n' +
    '<div class="modal-body" style="padding-left: 17px;" ng-if="!processError">\n' +
    '	<!-- Locked By Underwriter -->\n' +
    '	<h>This account is locked for editing, please contact {{contact}} for assistance.</h>\n' +
    '</div>\n' +
    '<div class="modal-body" style="padding-left: 17px;" ng-if="processError">\n' +
    '	<!-- Bind not available -->\n' +
    '	<h>Unable to process your quote. please contact {{contact}} for assistance.</h>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn btn-primary" ng-click="close()">close</button>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/quoteResult/template/template.html',
    '<style>\n' +
    '\n' +
    '    .applicant-wrapper {\n' +
    '        display: flex;\n' +
    '        flex-direction: column;\n' +
    '        width: 100%;\n' +
    '    }\n' +
    '\n' +
    '    .applicant-info {\n' +
    '        flex: 1 0 auto;\n' +
    '    }\n' +
    '\n' +
    '    @media screen and (min-width: 1375px) {\n' +
    '        .applicant-wrapper {\n' +
    '            flex-direction: row;\n' +
    '        }\n' +
    '    }\n' +
    '</style>\n' +
    '\n' +
    '<div class="well quote-well">\n' +
    '\n' +
    '    <div class="row-fluid" ng-show="pageState.quoteRequestError">\n' +
    '        <div class="span12">\n' +
    '            <div class="alert alert-error">\n' +
    '                An error occurred while attempting to retrieve the status for this quote.  You can <a class="resultsReloadLink" ng-click="reloadResults()">click here to attempt to retrieve your\n' +
    '                submission results again</a>, but if this error persists please contact Customer Service for assistance.\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row-fluid" ng-show="!pageState.quoteRequestError">\n' +
    '\n' +
    '        <div class="span8" ng-if="pageState.showQuote">\n' +
    '            <div class="quote-header">\n' +
    '                <h1>Your Insurance Quote</h1>\n' +
    '                <br>\n' +
    '            </div>\n' +
    '             <div ng-if="non_bindable_quote">\n' +
    '                    <div>\n' +
    '                        <h4>Quote Subject to Underwriter Review. Any changes will be communicated prior to payment or Issuance.</h4>\n' +
    '                    </div>\n' +
    '                 <br>\n' +
    '             </div>\n' +
    '            <div>\n' +
    '                <h4>Thank you for your submission. If you have any questions on your quote details please contact us for assistance at {{contactPhone}}. We look forward to the opportunity to do business with you!</h4>\n' +
    '            </div>\n' +
    '            <br>\n' +
    '            <h4 ng-if="isQuoteAvailableForBind">Please review the detailed information found in Your Documents<span class="payment-controls"> before you select Buy Now</span>.</h4>\n' +
    '            <br>\n' +
    '            <div ng-if="pageState.isTerrorismVerbiageRequired">\n' +
    '                <h4>Terrorism Coverage has been excluded from the Quote Proposal.*</h4>\n' +
    '                <br>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '                <div class="row-fluid">\n' +
    '                    <div class="product-container">\n' +
    '                        <ul ng-if="!pricingData" class="products-list">\n' +
    '                            <li class="selected" ng-repeat="quote in quoteInfo.quotes | orderBy:\'-quote.writtenpremium\'">\n' +
    '                                <label><span class="product-input"></span><span class="product-description">{{quote.quote.quoteTypeCategory}}</span><span class="premium pull-right">{{quote.quote.writtenpremium | formatMoney}}</span></label>\n' +
    '                            </li>\n' +
    '                            <li class="selected">\n' +
    '                                <label><span class="product-input"></span><span class="product-description">Taxes</span><span class="premium pull-right">{{quoteInfo.tax | formatMoney}}</span></label>\n' +
    '                            </li>\n' +
    '                        </ul>\n' +
    '\n' +
    '                        <div ng-if="pricingData" class="quotePricingDetails">\n' +
    '                            <pricing-details program="pricingProgram" pricing-data="pricingData"></pricing-details>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div class="multi-policy-alert alert-success greyDarkest" ng-if="has_multi_policy_discount">\n' +
    '                            <table class="table table-condensed">\n' +
    '                                <tbody>\n' +
    '                                <tr>\n' +
    '                                    <td class="no-top-border">This submission may qualify for a Multi-Policy Discount* in the estimated amount of:</td>\n' +
    '                                    <td class="align-right no-top-border"><strong><span class="MPDSavings">{{:: multi_policy_discount | formatMoney}}</span></strong></td>\n' +
    '                                </tr>\n' +
    '                                <tr>\n' +
    '                                    <td class="no-top-border">Estimated Quoting Premium after Multi-Policy Discount:</td>\n' +
    '                                    <td class="align-right no-top-border"><strong><span class="MPDQuotingPremium">{{::quoteInfo.totalPremium - multi_policy_discount | formatMoney }}</span></strong></td>\n' +
    '                                </tr>\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div ng-if="!pricingData" class="total-premium span12">\n' +
    '                            <div class="pull-left"><h1>Total Premium</h1></div>\n' +
    '                            <div class="pull-right"><h1 class="total-premium ng-binding">{{quoteInfo.totalPremium | formatMoney}}</h1></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <br>\n' +
    '\n' +
    '                <document-delivery-preferences master-quote-id="submissionId" is-available-for-bind="isQuoteAvailableForBind" doc-delivery-config="docDeliveryConfig" has-data="docDeliveryDataAvailable" delivery-errors="deliveryErrors" is-issued="false" button-text="button_text" insured-type="NA"></document-delivery-preferences>\n' +
    '\n' +
    '                <div class="row-fluid" ng-if="!isQuoteAvailableForBind">\n' +
    '                    <div class="span12">\n' +
    '                        <h4><b>Note:</b>&nbsp;To bind or edit a <b>non-admitted</b> quote, please contact your underwriter.</h4>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                \n' +
    '                <div ng-show="showBillingType" ng-if="isSwitchingBillTypeAllowed()">\n' +
    '                    <span style="margin-top: 5px;display: inline-block;"><h3 class="quoteResultHeading"><strong> Policy bill type preference</strong></h3></span>\n' +
    '                    <br>\n' +
    '                    <div class="row-fluid">\n' +
    '                        <div class="span12">\n' +
    '                            <h4>By default, this policy will be issued as agency bill per your agency’s set up with Markel. If needed,\n' +
    '                                you can change to direct bill by clicking on the drop down arrow, and selecting Direct.<br><br>\n' +
    '                                Note: Changing the bill type from agency bill to direct bill applies to this policy only.\n' +
    '                                You must contact your underwriter to make any changes to the bill type after the policy has been issued.\n' +
    '                            </h4>\n' +
    '                            <br>\n' +
    '                            <select class="quoteResultBillingType pull-left" ng-disabled="disableBillingType" ng-options=" type.billingType as type.label for type in billingTypes" ng-model="billingType" ng-change="changeBillType( submissionId,  paymentData.paymentPlan, billingType )"></select>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <br>\n' +
    '                <div class="row-fluid" ng-if="showPaymentPlans">\n' +
    '                    <div id="paymentPlanSelection" ng-if="paymentData.paymentOptions.billingPlans.length > 1">\n' +
    '                        <span style="margin-top: 5px;display: inline-block;"><h3 class="quoteResultHeading pull-left"><strong>Select a payment plan</strong></h3></span><br>\n' +
    '                        <div style="float:left">\n' +
    '                            <select style="margin-top: 5px;" ng-model="paymentData.paymentPlan" ng-options="pp.billingplan as pp.description for pp in paymentData.paymentOptions.billingPlans | orderBy:pp.sortorder"></select>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div ng-if="paymentData.paymentOptions.billingPlans.length === 1">\n' +
    '                        <div id="singlePaymentPlan" class="pull-left">\n' +
    '                            <h3><strong>Payment plan :</strong> {{paymentData.paymentPlanDesc}}</h3>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div ng-if="hasMultiplePaymentOptions" style="margin-left:7px;">\n' +
    '                    <div id="paymentOption" class="pull-left">\n' +
    '                        <label class="radio inline padding">\n' +
    '                            <input type="radio" class="piiSafe" ng-model="paymentData.paymentMethod" value="Pay Now">\n' +
    '                            Pay now\n' +
    '                        </label>\n' +
    '                        <label class="radio inline padding">\n' +
    '                            <input type="radio" class="piiSafe" ng-model="paymentData.paymentMethod" value="Bill Me Later">\n' +
    '                            Bill me later\n' +
    '                        </label>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div ng-show="effectiveDateValidation && isQuoteAvailableForBind && isDirect() " style="margin-left: -5px;">\n' +
    '                    <div class="alert">\n' +
    '                        <h4>The effective date is prior to today. To request to bind, please <span style="text-decoration: underline;" edit-submission application-id="{{ applicationId }}" status="{{ masterquoteStatus }}" luxon-id="{{luxonData.luxon_id}}" luxon-partition-key="{{luxonData.luxon_partition_key}}" link-text="update effective date"></span>to current or future date.</h4>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div ng-show="effectiveDateValidation && isQuoteAvailableForBind && isAnAuthenticatedUser()" style="margin-left: -5px;">\n' +
    '                    <div class="alert">\n' +
    '                        <h4>The effective date is prior to today. To request to bind, please <span style="text-decoration: underline;" edit-submission application-id="{{ applicationId }}" status="{{ masterquoteStatus }}" luxon-id="{{luxonData.luxon_id}}" luxon-partition-key="{{luxonData.luxon_partition_key}}" link-text="edit submission"></span>to current or future date or <a href ng-click="messageUW( accountId, submissionId , false )" style="text-decoration: underline;">send a message to your underwriter</a>.</h4>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="row-fluid payment-controls" ng-if="isQuoteAvailableForBind" ng-show="!effectiveDateValidation">\n' +
    '                    <br>\n' +
    '                    <br>\n' +
    '                    <br>\n' +
    '                    <br>\n' +
    '                    <div class="row-fluid" ng-if="showAgreeToTerms">\n' +
    '                        <h4>I have read and understand the terms and conditions outlined in the Quote Proposal<span ng-if="pageState.isTerrorismVerbiageRequired">&nbsp;and Policyholder Disclosure Notice of Terrorism Insurance Coverage</span>.</h4>\n' +
    '                        <br>\n' +
    '                        <h4>By clicking Buy Now I am agreeing to terms presented.</h4>\n' +
    '                        <br>\n' +
    '                    </div>\n' +
    '                    <div class="row-fluid" ng-if="showBuyButton && isRenewalBindAvailable">\n' +
    '                        <div id="quoteRightBlock">\n' +
    '                            <button id="requestToIssue" class="btn btn-large btn-success-markel pull-right" ng-click="confirmBuyNow();" ng-disabled="!isBuyNowButtonEnabled">{{:: button_text }}</button>\n' +
    '                            <p ng-show="buyNowProcessing"><img src="../assets/images/ajax-loader-grey.gif"></p>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="row-fluid" ng-if="hasLicenseError">\n' +
    '                        <div id="licenseMessage" class="alert-small span12">\n' +
    '                            Agency Services has been notified license information is needed for locations on this account. Contact us at 888-500-3344 with any questions.\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div class="row-fluid" ng-if="showBuyButton && isRenewalBindAvailable && !isBindAllowed">\n' +
    '                        <div id="actionError" class="alert-small">\n' +
    '                            You are not allowed to perform this action. Please contact your Underwriter for assistance.\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                     <div class="row-fluid well alert-info" ng-if="pageState.showAccepted">\n' +
    '                        <h1 class="ng-binding">Bind Request Received</h1>\n' +
    '                        <br>\n' +
    '                        <p>We have received your request to bind and will process shortly.  Questions? Please contact Markel at 888-500-3344</p>\n' +
    '                        <br>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="row-fluid" ng-if="pageState.isTerrorismVerbiageRequired">\n' +
    '                    <div id="quoteFinePrint">\n' +
    '                        <h5>\n' +
    '                            *Pursuant to federal legislation concerning Terrorism Coverage, you have the right to purchase Certified Acts of Terrorism\n' +
    '                            Coverage for an additional premium. Details are included in the Policyholder Disclosure Notice of Terrorism Insurance\n' +
    '                            Coverage which can be found by clicking on Terrorism Disclosure Notice in Your Documents. If you would like to purchase\n' +
    '                            Certified Acts of Terrorism Coverage, please call {{ contactPhone }} or\n' +
    '                            <a href="mailto:{{contactEmail}}?subject=Terrorism Coverage Request">click here</a>\n' +
    '                            to contact Underwriting. Please note if you select Buy Now and the Policyholder Disclosure Notice of Terrorism Insurance\n' +
    '                            Coverage is not returned to the company, an Exclusion of Certified Acts of Terrorism Endorsement will be included on your\n' +
    '                            policy.\n' +
    '                        </h5>\n' +
    '                        <h5>\n' +
    '                            *Terrorism Coverage may be mandatory in your state if you have property coverage.\n' +
    '                        </h5>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="row-fluid" ng-if="has_multi_policy_discount">\n' +
    '                    <div id="quoteFinePrint">\n' +
    '                        <h5>\n' +
    '                           *Discount eligibility is Subject Company’s Multi-Policy Discount Rules including, but not limited to, maintaining an in-force Workers’ Compensation or Business Owners policy.  Please contact your underwriter for specific rules and eligibility guidelines.\n' +
    '                        </h5>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <!-- Payment Successful -->\n' +
    '        <div class="span8 show-success" ng-if="pageState.showSuccess">\n' +
    '            <h1>Your policy is on its way!</h1>\n' +
    '            <br>\n' +
    '            <h4>Thank you for doing business with us! We look forward to servicing your insurance needs with your new policy. You will receive your policy document within the next 7 to 14 business days if by mail, earlier if electronically requested.</h4>\n' +
    '            <br>\n' +
    '            <div class="alert alert-success" ng-if="pageState.showBindPromo">\n' +
    '                <h1>Thank you!</h1>\n' +
    '                <h4>You’ve just made a $5 donation to charity. During this holiday season, we are giving back and sharing our success with those in need. For every new business binder received in mPortal between December 3 and December 31, Markel will donate $5 to be split between Mental Health America, North Shore Animal League America, and American Red Cross.</h4>\n' +
    '            </div>\n' +
    '            <h4>Policies are underwritten by Markel Insurance Company, Inc.</h4>\n' +
    '\n' +
    '            <div class="cataboom-info" ng-if="show_cataboom">         \n' +
    '                <div>\n' +
    '                    <h2 class="visually-hidden">Win!<br>with markel</h2>\n' +
    '                    <img src="../assets/images/win-with-markel.png">\n' +
    '                    <p>We like having fun and winning together &ndash; it&rsquo;s in our style. And as a thanks for binding, we&rsquo;d like to add some fun to your day!</p>\n' +
    '                    <p><strong>Bind. Play. Win.</strong></p>\n' +
    '                    <button class="btn btn-primary" id="cataboom-btn" ng-click="open_cataboom()">Click to play!</button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <!-- Payment Successful & Bind failed -->\n' +
    '        <div class="span8" ng-if="pageState.paymentSuccessBindFailed">\n' +
    '            <h2>We have received your request to bind and will process shortly</h2>\n' +
    '            <br>\n' +
    '            <h4>Please <a href ng-click="messageUW( accountId, submissionId , false )" style="text-decoration: underline;"> send a message </a> to your underwriter for assistance.</h4>\n' +
    '\n' +
    '        </div>\n' +
    '        <!-- Quote is declined -->\n' +
    '        <div class="row-fluid span8" ng-if="pageState.showDecline">\n' +
    '            <h1 class="ng-binding">Markel Quote Information</h1>\n' +
    '            <br>\n' +
    '            <h3 class="quoteResultHeading resultHeaderColor">Your insurance application has been declined</h3>\n' +
    '            <p>Thank you for choosing Markel, and for giving us the opportunity to assist you with your insurance needs. Unfortunately, we are unable to assist you with your insurance at this time.</p>\n' +
    '            <p ng-if="result.outcomeReasons.length">\n' +
    '                Your application was declined for the following reason<span ng-if="result.outcomeReasons.length > 1">s</span>:\n' +
    '                </p><ul>\n' +
    '                    <li ng-repeat="reason in result.outcomeReasons">{{:: reason }}</li>\n' +
    '                </ul>\n' +
    '            <p></p>\n' +
    '            <br>\n' +
    '            <h4 class="quoteResultHeading resultHeaderColor">Contact us</h4>\n' +
    '            <p>If you have any questions about this declination of coverage, please contact our office at {{contactPhone}}, and have the Markel submission number available.</p>\n' +
    '            <br>\n' +
    '            <p>Please note: Policies are written by one or more Markel insurance companies. Specific terms and conditions for rate and coverage may vary.</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <!-- Submission is referred -->\n' +
    '        <div class="row-fluid span8" ng-if="pageState.showReferral">\n' +
    '            <h1 class="ng-binding">Application Received</h1>\n' +
    '            <br>\n' +
    '            <h3 class="quoteResultHeading resultHeaderColor">Your insurance application has been submitted for review.</h3>\n' +
    '            <p>Thank you for choosing Markel, and for giving us the opportunity to assist you with your insurance needs. We appreciate your business and the confidence you have placed in us.</p>\n' +
    '            <div ng-if="isReferredReason">\n' +
    '                <p>\n' +
    '                    Your submission is being reviewed by Markel for the following reason<span ng-if="result.outcomeReasons.length > 1">s</span>:\n' +
    '                </p><ul>\n' +
    '                    <li ng-repeat="reason in result.outcomeReasons">{{:: reason }}</li>\n' +
    '                </ul>\n' +
    '                <p></p>\n' +
    '            </div>\n' +
    '            <br>\n' +
    '            <p>The next step is for a Markel underwriter to review your insurance application. A Markel representative will contact you shortly, and may request additional information.</p>\n' +
    '            <br>\n' +
    '            <h4 class="quoteResultHeading resultHeaderColor">Contact us</h4>\n' +
    '            <p>If you have questions about your insurance submission, or need immediate assistance, please contact our office at {{contactPhone}}, and have the Markel submission number available.</p>\n' +
    '            <br>\n' +
    '            <p>Please note: Policies are written by one or more Markel insurance companies. Specific terms and conditions for rate and coverage may vary.</p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid span8" ng-if="pageState.showPurchased">\n' +
    '            <h1 class="ng-binding">Thank you for your business</h1>\n' +
    '            <br>\n' +
    '            <h3 class="quoteResultHeading resultHeaderColor">Your application is being reviewed, and your policy will arrive shortly!</h3>\n' +
    '            <p>Thank you for choosing Markel, and for giving us the opportunity to assist you with your insurance needs. We appreciate your business and the confidence you have placed in us.</p>\n' +
    '            <br>\n' +
    '            <p>\n' +
    '                The next step is for a Markel underwriter to review your insurance application.  Once reviewed, your payment\n' +
    '                will be processed and your policy will be on its way!  Should the underwriter have any questions, or if there\n' +
    '                is any change to rates, terms and/or coverages after underwriting review, you will be contacted prior to payment\n' +
    '                for your approval to bind insurance coverage.\n' +
    '            </p>\n' +
    '            <br>\n' +
    '            <h4 class="quoteResultHeading resultHeaderColor">Contact us</h4>\n' +
    '            <p>\n' +
    '                If you have questions, or need immediate assistance, please contact our office at {{contactPhone}}, and have the\n' +
    '                Markel submission number available. Please note:  Policies are\n' +
    '                written by one or more Markel insurance companies.  Please review your policy as specific terms and conditions\n' +
    '                for rate and coverage may vary.\n' +
    '            </p>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="span3 applicant-container" ng-if="!loadingStatus" ng-show="!loadingStatus">\n' +
    '\n' +
    '            <div class="applicant-wrapper">\n' +
    '\n' +
    '                <div class="applicant-info">\n' +
    '                    \n' +
    '                    <p>Applicant</p>\n' +
    '                    <h3 class="quoteResultHeading">{{applicant}}</h3>\n' +
    '                    <p>Submission Number</p>\n' +
    '                    <h3 class="quoteResultHeading">{{submissionId}}</h3>\n' +
    '                    <p ng-if="!result.marketedProgram.isUploadBasedSubmission || ( result.marketedProgram.isUploadBasedSubmission && (documentList.error || documentList.documents.length) )">Your Documents</p>\n' +
    '                    <img src="../assets/images/ajax-loader-grey.gif" ng-show="documentList.loading">\n' +
    '                    <div class="alert alert-error" ng-if="documentList.error">\n' +
    '                        An error occurred while attempting to retrieve your list of documents.\n' +
    '                    </div>\n' +
    '                    <ul class="document-list" ng-if="!documentList.error && documentList.documents.length" ng-repeat="document in documentList.documents">\n' +
    '                        <li>\n' +
    '                            <a ng-click="getDocument(document.name, document.retrievalURL)">\n' +
    '                                <img src="../assets/images/pdf-icon.svg">{{document.name}}\n' +
    '                            </a>\n' +
    '                        </li>\n' +
    '                    </ul>\n' +
    '                    <br>\n' +
    '                    <br>\n' +
    '                    <button class="btn btn-success hideLegacy btn-additional-questions" ng-if="showAdditionalQuestionsButton" ng-click="openAdditionalQuestionsPage()">\n' +
    '                        Click here to review required items to bind\n' +
    '                    </button>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '            </div>                \n' +
    '\n' +
    '            </div>\n' +
    '            \n' +
    '        </div>\n' +
    '\n' +
    '        \n' +
    '\n' +
    '        <div ng-if="quoteInfo.isRenewal && quoteInfo.status === \'Quoted\'" class="row-fluid span3" style="margin-top: 40px">\n' +
    '            <a href ng-click="setMessageView(accountId,submissionId)"><i class="icon-arrow-right"></i> Request Change</a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/result/template/template.html',
    '<div class="section">\n' +
    '\n' +
    '	<div class="row-fluid" ng-if="!uiState.loading">\n' +
    '		<div class="span12">\n' +
    '			<h1 class="pageHead">Submission Results</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div id="quoteResultsSummary" class="row-fluid">\n' +
    '\n' +
    '		<div class="span12" ng-if="!uiState.loading && uiState.requestError">\n' +
    '			<div>\n' +
    '				<div class="alert alert-error requestError">\n' +
    '					An error occurred while attempting to retrieve the status for your submission. Please contact Customer Service for assistance.\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="span9" ng-if="!uiState.requestError">\n' +
    '			<div ng-show="uiState.loading">\n' +
    '				<h1>Retrieving data...</h1>\n' +
    '				<br>\n' +
    '				<p><img src="../assets/images/ajax-loader-grey.gif"></p>\n' +
    '			</div>\n' +
    '\n' +
    '			<div ng-if="!uiState.loading && uiState.showSummary">\n' +
    '				<table class="table" border="0" cellpadding="0" cellspacing="0">\n' +
    '					<thead>\n' +
    '						<tr>\n' +
    '							<th colspan="2">Line</th>\n' +
    '							<th>Premium</th>\n' +
    '							<th>Effective Date</th>\n' +
    '							<th>Status</th>\n' +
    '							<th ng-if="userType === \'Agent\'">Actions</th>\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '					<tbody>\n' +
    '						<tr ng-repeat="outcome in outcomes" ng-class="{ activeRow: uiState.visibleApplicationQuote === outcome.applicationId }">\n' +
    '							<td>\n' +
    '								<span product-label="outcome.marketedProgram.abbreviation" auto-width="true">{{:: outcome.marketedProgram.abbreviation}}</span>\n' +
    '							</td>\n' +
    '							<td>\n' +
    '								{{:: outcome.marketedProgram.name }}\n' +
    '							</td>\n' +
    '							<td>\n' +
    '								<span ng-if="outcome.premium">{{::outcome.premium | formatMoney }}</span>\n' +
    '								<span ng-if="!outcome.premium">N/A</span>\n' +
    '							</td>\n' +
    '							<td>{{::outcome.effectiveDate | date:\'MM/dd/yyyy\' }}</td>\n' +
    '							<td ng-if="outcomes.length === 1">\n' +
    '								<a ng-if="uiState.visibleApplicationQuote !== outcome.applicationId" ng-click="showApplicationQuote( outcome.applicationId )" ng-disabled="uiState.visibleApplicationQuote === outcome.applicationId">Hide {{::outcome.quoteStatus}}</a>\n' +
    '								<a ng-if="uiState.visibleApplicationQuote === outcome.applicationId" ng-click="hideApplicationQuote( outcome.applicationId )" ng-disabled="uiState.visibleApplicationQuote === outcome.applicationId">View {{::outcome.quoteStatus}}</a>\n' +
    '								<span ng-if="userType !== \'Agent\' && !outcome.marketedProgram.isUploadBasedSubmission && outcome.quoteStatus == \'Quote\'">\n' +
    '									| <span edit-submission application-id="{{outcome.applicationId}}" status="{{ outcome.quoteStatus }}" link-text="Edit Quote"></span>\n' +
    '								</span>\n' +
    '							</td>\n' +
    '							<td ng-if="outcomes.length !== 1">\n' +
    '								<a ng-if="uiState.visibleApplicationQuote !== outcome.applicationId" ng-click="showApplicationQuote( outcome.applicationId )" ng-disabled="uiState.visibleApplicationQuote === outcome.applicationId">View {{::outcome.quoteStatus}}</a>\n' +
    '								<a ng-if="uiState.visibleApplicationQuote === outcome.applicationId" ng-click="hideApplicationQuote( outcome.applicationId )" ng-disabled="uiState.visibleApplicationQuote === outcome.applicationId">Hide {{::outcome.quoteStatus}}</a>\n' +
    '							</td>\n' +
    '							<td ng-if="userType === \'Agent\'">\n' +
    '								<span ng-if="outcome.agentCanEdit && !outcome.marketedProgram.isUploadBasedSubmission">\n' +
    '									<span edit-submission application-id="{{ outcome.applicationId }}" status="{{ outcome.portalStatus }}" link-text="Edit" luxon-id="{{luxonData.luxon_id}}" luxon-partition-key="{{luxonData.luxon_partition_key}}"></span> |\n' +
    '								</span>\n' +
    '								<span ng-if="outcome.marketedProgram.isUploadBasedSubmission">\n' +
    '									<a ng-click="messageUW( outcome.accountId, outcome.submissionId,true )">Upload Application</a> | \n' +
    '								</span> \n' +
    '								<a ng-click="messageUW( outcome.accountId, outcome.submissionId , false )">Message UW</a>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '				</table>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="span3 contextBox" ng-if="uiState.showSummary && !uiState.loading && !uiState.requestError">\n' +
    '			<p ng-if="userType == \'Agent\' && outcomes.length > 1">This page is a summary of your submission results.  All of the results listed on the left are also available\n' +
    '				as individual summaries in your account history and via an account search on "{{accountName}}"</p>\n' +
    '			<p ng-if="userType == \'Insured\' && outcomes.length > 1">This page is a summary of your submission results.  All of the results listed on the left are also available\n' +
    '				as individual summaries on your account summary.</p>\n' +
    '			<p ng-if="outcomes.length === 1">Click on the status link to view more details about your results.</p>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div ng-if="!uiState.showSummary && accountId && !uiState.loading" class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<span class="pull-right" style="font-size:110%;"><a ng-href="#/account-summary/{{ ::accountId }}">Return to Account Summary</a></span>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div id="quoteResultsList" ng-if="!uiState.loading && !uiState.requestError">\n' +
    '		<div class="row-fluid" ng-repeat="outcome in outcomes">\n' +
    '			<div ng-if="outcomes.length === 1" ng-show="!uiState.showSummary || uiState.visibleApplicationQuote !== outcome.applicationId" ng-class="{ span9: uiState.showSummary }">\n' +
    '				<quote-result application-id="outcome.applicationId" marketed-program-id="outcome.marketedProgram.id" result="outcome.result"></quote-result>\n' +
    '			</div>\n' +
    '			<div ng-if="outcomes.length !== 1" ng-show="!uiState.showSummary || uiState.visibleApplicationQuote === outcome.applicationId" ng-class="{ span9: uiState.showSummary }">\n' +
    '				<quote-result application-id="outcome.applicationId" marketed-program-id="outcome.marketedProgram.id" result="outcome.result"></quote-result>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/resultSummary/template/template.html',
    '<div class="section">\n' +
    '\n' +
    '	<div class="row-fluid" ng-if="!uiState.loading">\n' +
    '		<div class="span12">\n' +
    '			<h1 class="pageHead">Submission Results</h1>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div id="quoteResultsSummary" class="row-fluid">\n' +
    '\n' +
    '		<div class="span12" ng-if="!uiState.loading && uiState.requestError">\n' +
    '			<div>\n' +
    '				<div class="alert alert-error requestError">\n' +
    '					An error occurred while attempting to retrieve the status for your submission. Please contact Customer Service for assistance.\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div ng-if="!uiState.requestError" ng-class="{ span9: uiState.showSummary && !uiState.full_width }">\n' +
    '			<div ng-show="uiState.loading">\n' +
    '				<h1>Retrieving data...</h1>\n' +
    '				<br>\n' +
    '				<p><img src="../assets/images/ajax-loader-grey.gif"></p>\n' +
    '			</div>\n' +
    '\n' +
    '			<div ng-if="!uiState.loading && uiState.showSummary">\n' +
    '				<table class="table" border="0" cellpadding="0" cellspacing="0">\n' +
    '					<thead>\n' +
    '						<tr>\n' +
    '							<th colspan="2">Line</th>\n' +
    '							<th>Premium</th>\n' +
    '							<th>Effective Date</th>\n' +
    '							<th>Status</th>\n' +
    '							<th ng-if="userType === \'Agent\'">Actions</th>\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '					<tbody>\n' +
    '						<tr ng-repeat="outcome in outcomes" ng-class="{ activeRow: uiState.visibleApplicationQuote === outcome.applicationId }">\n' +
    '							<td>\n' +
    '								<span product-label="outcome.marketedProgram.abbreviation" auto-width="true">{{:: outcome.marketedProgram.abbreviation}}</span>\n' +
    '							</td>\n' +
    '							<td>\n' +
    '								{{:: outcome.marketedProgram.name }}\n' +
    '							</td>\n' +
    '							<td>\n' +
    '								<span ng-if="outcome.premium">{{::outcome.premium | formatMoney }}</span>\n' +
    '								<span ng-if="!outcome.premium">N/A</span>\n' +
    '							</td>\n' +
    '							<td>{{::outcome.effectiveDate | date:\'MM/dd/yyyy\' }}</td>\n' +
    '							<td>\n' +
    '								<a ng-if="uiState.visibleApplicationQuote !== outcome.applicationId" ng-click="showApplicationQuote( outcome.applicationId )" ng-disabled="uiState.visibleApplicationQuote === outcome.applicationId">View {{::outcome.quoteStatus}}</a>\n' +
    '								<a ng-if="uiState.visibleApplicationQuote === outcome.applicationId" ng-click="hideApplicationQuote( outcome.applicationId )" ng-disabled="uiState.visibleApplicationQuote === outcome.applicationId">Hide {{::outcome.quoteStatus}}</a>\n' +
    '\n' +
    '								<span ng-if="userType !== \'Agent\' && !outcome.marketedProgram.isUploadBasedSubmission && outcome.quoteStatus == \'Quote\'">\n' +
    '									| <span edit-submission application-id="{{outcome.applicationId}}" status="{{ outcome.quoteStatus }}" link-text="Edit Quote" luxon-id="{{outcome.luxon.luxon_id}}" luxon-partition-key="{{outcome.luxon.luxon_partition_key}}"></span>\n' +
    '								</span>\n' +
    '							</td>\n' +
    '\n' +
    '							<td ng-if="userType === \'Agent\'">\n' +
    '								<span ng-if="!outcome.marketedProgram.isUploadBasedSubmission">\n' +
    '									<span edit-submission application-id="{{ outcome.applicationId }}" status="{{ outcome.portalStatus }}" link-text="Edit" luxon-id="{{outcome.luxon.luxon_id}}" luxon-partition-key="{{outcome.luxon.luxon_partition_key}}"></span> |\n' +
    '								</span>\n' +
    '								<span ng-if="outcome.marketedProgram.isUploadBasedSubmission">\n' +
    '									<a ng-click="messageUW( outcome.accountId, outcome.submissionId,true )">Upload Application</a> | \n' +
    '								</span> \n' +
    '								<a ng-click="messageUW( outcome.accountId, outcome.submissionId , false )">Message UW</a>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '				</table>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="span3 contextBox" ng-if="uiState.showSummary && !uiState.loading && !uiState.requestError && !uiState.full_width">\n' +
    '			<p ng-if="userType == \'Agent\' && outcomes.length > 1">This page is a summary of your submission results.  All of the results listed on the left are also available\n' +
    '				as individual summaries in your account history and via an account search on "{{accountName}}"</p>\n' +
    '			<p ng-if="userType == \'Insured\' && outcomes.length > 1">This page is a summary of your submission results.  All of the results listed on the left are also available\n' +
    '				as individual summaries on your account summary.</p>\n' +
    '			<p ng-if="outcomes.length === 1">Click on the status link to view more details about your results.</p>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div ng-if="!uiState.showSummary && accountId && !uiState.loading" class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<span class="pull-right" style="font-size:110%;"><a ng-href="#/account-summary/{{ ::accountId }}">Return to Account Summary</a></span>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<div id="quoteResultsList" ng-if="!uiState.loading && !uiState.requestError">\n' +
    '		<div class="row-fluid" ng-repeat="outcome in outcomes">\n' +
    '			<div ng-show="!uiState.showSummary || uiState.visibleApplicationQuote === outcome.applicationId" ng-class="{ span9: uiState.showSummary && !uiState.full_width }">\n' +
    '				<quote-result application-id="outcome.applicationId" marketed-program-id="outcome.marketedProgram.id" result="outcome.result"></quote-result>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/resume/template/template.html',
    '<div id="resumeSection" class="section">\n' +
    '    <div class="row-fluid">\n' +
    '        <div class="span12">\n' +
    '            <h3 id="resumeTitle">Resume Application</h3>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div error-message error-message-array="messages.error"></div>\n' +
    '\n' +
    '    <p>To resume working on your Markel insurance application, please enter in the mailing address zip code of the insured:</p>\n' +
    '    <form id="resumeForm" class="form form-horizontal" ng-submit="submitForm()">\n' +
    '        <div class="control-group">\n' +
    '            <label for="zip" class="control-label span2">Mailing Zip Code</label>\n' +
    '            <div class="controls">\n' +
    '                <input id="zip" type="text" class="piiSafe" ng-model="zipCode" tabindex="0">\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="formControls">\n' +
    '            <button ng-if="!zipCode" type="button" class="btn btn-success" disabled="true">Continue</button>\n' +
    '            <button ng-if="zipCode" type="submit" class="btn btn-success" loading-button="status.loading">Continue</button>\n' +
    '        </div>\n' +
    '    </form>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/resumeLater/template/template.html',
    '<div class="modal-header">\n' +
    '    <a class="close" ng-click="close()">&times;</a> <h3>Finish Later</h3>\n' +
    '</div>\n' +
    '<div id="resumeLaterModal" class="modal-body">\n' +
    '    <p>If you cannot complete and finish this application at this time, or if you want to share the application\n' +
    '    with someone else who can complete it, you can stop at any time and use the following link to resume your work:</p>\n' +
    '\n' +
    '    <p class="resumeLink">{{:: resumeLink }}</p>\n' +
    '\n' +
    '    <p>Please note that when you use the link, you will be asked for the mailing zip code of the insured prior to being\n' +
    '    allowed to resume.</p>\n' +
    '\n' +
    '    <div>\n' +
    '        <p>If you want to email the link, click an option below to send an email with this link addressed to:</p>\n' +
    '        <ul class="linkOptions">\n' +
    '            <li ng-repeat="link in links">\n' +
    '                <a ng-href="mailto:{{::link.address}}{{::mailContent}}" ng-click="close()" target="_blank">{{::link.label}}</a> <!-- target="_blank" -->\n' +
    '            </li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn" ng-click="close()">Close</button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/retrieveQuote/template/template.html',
    '<div class="row-fluid section center-container" style="margin-top:25px;">\n' +
    '	<div class="span12">\n' +
    '		<div class="sub-section box-shadow">\n' +
    '			<div class="well quote-well" style="margin: 0;" ng-if="status.showText">\n' +
    '				<h2 class="pull-left" style="line-height: 15px;">{{ status.message }}</h2>\n' +
    '				<div style="margin-top: 30px;" ng-if="quotesAvailable !== null">\n' +
    '					<p style="margin-left: 5px;" ng-if="quotesAvailable && !showQuoteLink">\n' +
    '					  A link to access your quote has been emailed to you.\n' +
    '					</p>\n' +
    '					<p style="margin-left: 5px;" ng-if="!quotesAvailable && showQuoteLink">\n' +
    '						Please try your search again, <span><a href ng-click="setNewQuoteLink()"><u>start a new quote</u></a></span> or <span><a href="https://www.markelinsurance.com/contact-us"><u>contact us</u></a></span> for assistance.\n' +
    '					</p>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '			<div error-message error-message-array="messages.error"></div>\n' +
    '			<form class="form-horizontal" style="padding:15px;" role="form" ng-submit="submitForm()">\n' +
    '				<div class="control-group">\n' +
    '					<label for="zip" class="control-label">Zip Code <span class="required-indicator">*</span></label>\n' +
    '					<div class="controls">\n' +
    '						<input id="zip" type="text" class="piiSafe" ng-model="form.zipCode" ng-disabled="!status.canEdit">\n' +
    '					</div>\n' +
    '				</div>\n' +
    '				<div class="control-group">\n' +
    '					<div class="control-group">\n' +
    '						<label for="email" class="control-label">Email Address <span class="required-indicator">*</span></label>\n' +
    '						<div class="controls">\n' +
    '							<input id="email" type="text" class="piiSafe" ng-model="form.emailId" ng-disabled="!status.canEdit">\n' +
    '						</div>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '\n' +
    '			</form>\n' +
    '\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="bottom-bar row-fluid span12">\n' +
    '        <div class="center-container span12">\n' +
    '               <div class="section-actions pull-right">\n' +
    '					<div class="pull-right next-button">\n' +
    '						<button ng-click="submitForm()" qa-btn="eligibility-continue" class="btn btn-success eligibility-continue" loading-button="status.loading">Search</button>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/search/template/template.html',
    '<div product-search-container include-location="product_search_location"></div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/submissionDetail/submissionDetail/template/template.html',
    '<div class="row-fluid policy-detail">\n' +
    '	<div class="span2">\n' +
    '		<div class="submission-info well" submission-info submission="submission"></div>\n' +
    '		<div class="actions well-action">\n' +
    '			<ul class="nav nav-list">\n' +
    '				<li class="nav-header">I want to</li>\n' +
    '				<li class="detail">\n' +
    '                    <span>\n' +
    '                        <a href ng-click="setMessageView(\'send\',{submission:submission, typeId : 1})">\n' +
    '                            <span>Send A Message</span>\n' +
    '                        </a>\n' +
    '                    </span>\n' +
    '	            </li>\n' +
    '			</ul>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="span10">\n' +
    '		<div class="documents-container" policy-documents account-id="account.accountId" submission-id="submission.submissionId"></div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/submissionDetail/submissionInfo/template/template.html',
    '<table class="table-plain">\n' +
    '	<tbody>\n' +
    '		<tr>\n' +
    '			<td>Effective Date</td>\n' +
    '			<td>{{:: submission.effectiveDate | date:"MM/dd/yyyy" }}</td>\n' +
    '		</tr>\n' +
    '		<tr>\n' +
    '			<td>Status</td>\n' +
    '			<td>{{:: submission.status }}</td>\n' +
    '		</tr>\n' +
    '		<tr>\n' +
    '			<td>Premium</td>\n' +
    '			<td>{{:: submission.premium | formatMoney }}</td>\n' +
    '		</tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/twoYearView/accountTable/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<div class="banner">\n' +
    '			<div class="row-fluid">\n' +
    '				<div class="span3">\n' +
    '					Account ID:\n' +
    '					<a ng-if="account.accountViewVersion == 1" ng-href="#/account/activity/{{:: account.accountId }}">{{:: account.accountId }}</a>\n' +
    '					<a ng-if="account.accountViewVersion != 1" ng-href="#/account-summary/{{:: account.accountId }}">{{:: account.accountId }}</a>\n' +
    '				</div>\n' +
    '				<div class="span6">\n' +
    '					Account Name/DBA:\n' +
    '					<a ng-if="account.accountViewVersion == 1" ng-href="#/account/activity/{{:: account.accountId }}">\n' +
    '						{{:: account.accountName }}<span ng-if="account.dba"> / {{:: account.dba }}</span>\n' +
    '					</a>\n' +
    '					<a ng-if="account.accountViewVersion != 1" ng-href="#/account-summary/{{:: account.accountId }}">\n' +
    '						{{:: account.accountName }}<span ng-if="account.dba"> / {{:: account.dba }}</span>\n' +
    '					</a>\n' +
    '				</div>\n' +
    '				<div class="span2"><span ng-if="account.balance !== undefined && account.balance !== null">Balance: <span class="balance">{{:: account.balance | formatMoney }}</span></span></div>\n' +
    '				<div class="pull-right">\n' +
    '					<button class="btn btn-small" ng-click="toggleDetails()">\n' +
    '						<span ng-if="!uiState.detailsHidden"><i class="icon-minus toggler"></i> Hide</span>\n' +
    '						<span ng-if="uiState.detailsHidden"><i class="icon-plus toggler"></i> View</span>\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="row-fluid" ng-show="!uiState.detailsHidden">\n' +
    '	<div class="span12">\n' +
    '		<table class="table table-striped table-condensed table-bordered details">\n' +
    '			<thead>\n' +
    '				<tr>\n' +
    '					<th class="span2">Type</th>\n' +
    '					<th class="span1">Program</th>\n' +
    '					<th class="span2">Line of Business</th>\n' +
    '					<th class="span2">Policy Number</th>\n' +
    '					<th class="span2">Submission Number</th>\n' +
    '					<th class="span1">Effective Date</th>\n' +
    '					<th class="span1">Status</th>\n' +
    '					<th class="span1">Balance</th>\n' +
    '					<th class="span1">Bill Type</th>\n' +
    '				</tr>\n' +
    '			</thead>\n' +
    '			<tbody>\n' +
    '				<tr ng-if="::(account.quotes!=undefined?true:undefined)" ng-repeat="quote in ::account.quotes" ng-hide="quote.hidden">\n' +
    '					<td>{{:: quote.policyNumber ? "Policy" : "Submission" }}</td>\n' +
    '					<td><span class="programPill">{{:: quote.program }}</span></td>\n' +
    '					<td><span class="linePill" ng-if=":: quote.lineOfBusiness">{{:: quote.lineOfBusiness }}</span></td>\n' +
    '					<td>\n' +
    '						<a ng-if="account.accountViewVersion == 1" ng-href="#/account/policy/{{:: quote.submissionId }}">\n' +
    '							<strong>{{:: quote.policyNumber }}</strong>\n' +
    '						</a>\n' +
    '						<a ng-if="account.accountViewVersion != 1" ng-href="#/account-summary/{{:: account.accountId }}/policy/{{:: quote.policyNumber }}">\n' +
    '							<strong>{{:: quote.policyNumber }}</strong>\n' +
    '						</a>\n' +
    '					</td>\n' +
    '					<td>\n' +
    '						<a ng-if="account.accountViewVersion == 1 && quote.status == \'Renewal\'" ng-href="#/renewal/{{:: quote.submissionId }}/">\n' +
    '							<strong>{{:: quote.submissionId }}</strong>\n' +
    '						</a>\n' +
    '						<a ng-if="account.accountViewVersion == 1 && quote.status !== \'Renewal\'" ng-href="#/account/issuance/{{:: account.accountId }}/na/{{:: quote.submissionId }}">\n' +
    '							<strong>{{:: quote.submissionId }}</strong>\n' +
    '						</a>\n' +
    '						<a ng-if="account.accountViewVersion != 1" ng-href="#/account-summary/{{:: account.accountId }}">\n' +
    '							<strong>{{:: quote.submissionId }}</strong>\n' +
    '						</a>\n' +
    '					</td>\n' +
    '					<td>{{:: quote.effectiveDate | date: "MM/dd/yyyy" }}</td>\n' +
    '					<td>{{:: quote.status }}</td>\n' +
    '					<td>\n' +
    '						<span ng-if="quote.balance !== undefined && quote.balance !== null">{{:: quote.balance | formatMoney }}</span>\n' +
    '						<span ng-if="quote.balance === undefined || quote.balance === null">Not Available</span>\n' +
    '					</td>\n' +
    '					<td><span ng-if=":: quote.policyNumber">{{:: quote.billType }}</span></td>\n' +
    '				</tr>\n' +
    '			</tbody>\n' +
    '		</table>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/twoYearView/twoYearView/template/dirPagination.html',
    '<ul class="pagination" ng-if="1 < pages.length || !autoHide">\n' +
    '	<li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == 1 }">\n' +
    '		<a href="" ng-click="setCurrent(1)">&laquo;</a>\n' +
    '	</li>\n' +
    '	<li ng-if="directionLinks" ng-class="{ disabled : pagination.current == 1 }">\n' +
    '		<a href="" ng-click="setCurrent(pagination.current - 1)">&lsaquo;</a>\n' +
    '	</li>\n' +
    '	<li ng-repeat="pageNumber in pages track by tracker(pageNumber, $index)" ng-class="{ active : pagination.current == pageNumber, disabled : pageNumber == \'...\' }">\n' +
    '		<a href="" ng-click="setCurrent(pageNumber)">{{ pageNumber }}</a>\n' +
    '	</li>\n' +
    '\n' +
    '	<li ng-if="directionLinks" ng-class="{ disabled : pagination.current == pagination.last }">\n' +
    '		<a href="" ng-click="setCurrent(pagination.current + 1)">&rsaquo;</a>\n' +
    '	</li>\n' +
    '	<li ng-if="boundaryLinks" ng-class="{ disabled : pagination.current == pagination.last }">\n' +
    '		<a href="" ng-click="setCurrent(pagination.last)">&raquo;</a>\n' +
    '	</li>\n' +
    '</ul>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/twoYearView/twoYearView/template/template.html',
    '<div class="row-fluid">\n' +
    '	<div class="span12">\n' +
    '		<form class="form-inline" name="twoYearFilterForm">\n' +
    '			<div class="account-filter-input" id="account-filter-name">\n' +
    '				<label>Search Name / Policy #</label>\n' +
    '				<input type="text" class="input-medium piiSafe" name="name" ng-model="filterValues.name" ng-model-options="{ debounce: 250 }">\n' +
    '			</div>\n' +
    '			<div class="account-filter-input" id="account-filter-effective-dates">\n' +
    '				<label>Effective Dates:</label>\n' +
    '				<input type="text" class="input-small piiSafe" ng-model="filterValues.effectiveDateFrom" date-picker>\n' +
    '				to\n' +
    '				<input type="text" class="input-small piiSafe" ng-model="filterValues.effectiveDateTo" date-picker>\n' +
    '			</div>\n' +
    '			<div class="account-filter-input" id="account-filter-status">\n' +
    '				<label>Status:</label>\n' +
    '				<select class="input-medium piiSafe" ng-model="filterValues.status" ng-options="status as status for status in filterOptions.status">\n' +
    '				</select>\n' +
    '			</div>\n' +
    '			<div class="account-filter-input" id="account-filter-program">\n' +
    '				<label>Program:</label>\n' +
    '				<select class="input-medium piiSafe" ng-model="filterValues.program" ng-options="program as program for program in filterOptions.program">\n' +
    '				</select>\n' +
    '			</div>\n' +
    '			<div class="account-filter-input" id="account-filter-balance">\n' +
    '				<label>Balance:</label>\n' +
    '				<select class="input-medium piiSafe" ng-model="filterValues.balance" ng-options="balance as balance for balance in filterOptions.balance">\n' +
    '				</select>\n' +
    '			</div>\n' +
    '			<div class="account-filter-input" id="account-filter-billType">\n' +
    '				<label>Bill Type:</label>\n' +
    '				<select class="input-small piiSafe" ng-model="filterValues.billType" ng-options="billType as billType for billType in filterOptions.billType">\n' +
    '				</select>\n' +
    '			</div>\n' +
    '			<div class="account-filter-input" id="account-filter-pageSize">\n' +
    '				<label>Accounts/Page:</label>\n' +
    '				<select class="input-small piiSafe" ng-model="filterValues.pageSize" ng-options="pageSize as pageSize for pageSize in filterOptions.pageSize">\n' +
    '				</select>\n' +
    '			</div>\n' +
    '		</form>\n' +
    '        <div class="account-filter-reset pull-right">\n' +
    '            <button class="btn" ng-click="refreshView()" ng-if="uiState.dataOverflow">Refresh</button>\n' +
    '            <button class="btn btn-reset-filters" ng-click="resetFilters()">Reset Filters</button>\n' +
    '        </div>\n' +
    '	</div>\n' +
    '\n' +
    '</div>\n' +
    '<div class="accounts">\n' +
    '	<div class="row message-container">\n' +
    '		<div class="span12" ng-if="uiState.maverickExcluded">\n' +
    '			<div class="alert missing-data">Note: Workers\' Compensation and Business Owner Property policies are not included in this view due to data retrieval limits.</div>\n' +
    '		</div>\n' +
    '		<div class="span12">\n' +
    '			<div loading-bar ng-if="uiState.loading"></div>\n' +
    '		</div>\n' +
    '		<div class="span12" ng-if="!uiState.loading && filteredAccounts.length === 0">\n' +
    '			<div class="alert alert-warning">We were unable to find any submissions matching your search criteria. Please revise your criteria and try again.</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '	<div class="pagination-controls pagination-controls-top">\n' +
    '		<dir-pagination-controls template-url="app/components/twoYearView/twoYearView/template/dirPagination.html"></dir-pagination-controls>\n' +
    '	</div>\n' +
    '	<div class="row-fluid" dir-paginate="account in filteredAccounts = ( accounts | twoYearViewFilter: filterValues ) | itemsPerPage: filterValues.pageSize">\n' +
    '		<div class="span12" two-year-account-table account="account"></div>\n' +
    '	</div>\n' +
    '	<div class="pagination-controls pagination-controls-bottom">\n' +
    '		<dir-pagination-controls template-url="app/components/twoYearView/twoYearView/template/dirPagination.html"></dir-pagination-controls>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/userAccountSettings/ciamAccountSettings/template/template.html',
    '<div section-toggle title="\'Markel Account Security Settings\'" open="uiState.visible"></div>\n' +
    '\n' +
    '<div class="row-fluid" ng-if="uiState.visible">\n' +
    '    <div class="span5">\n' +
    '        <div class="row-fluid saveRow">\n' +
    '            <a href="{{ domain }}/user/settings/account" target="_blank" name="ciamButton" class="btn-primary btn">\n' +
    '           	   Click Here</a>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/userAccountSettings/personalDetails/template/template.html',
    '<div section-toggle title="uiState.sectionTitle" open="uiState.visible"></div>\n' +
    '\n' +
    '<div class="row-fluid" ng-if="uiState.visible">\n' +
    '    <div class="span5">\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="firstName">First Name </label>\n' +
    '            <input maxlength="50" class="firstName piiSafe offset1 span5" name="firstName" ng-model="user.firstName" id="firstName" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="lastName">Last Name </label>\n' +
    '            <input maxlength="50" class="lastName piiSafe offset1 span5" name="lastName" ng-model="user.lastName" id="lastName" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="phone">Phone </label>\n' +
    '            <input maxlength="30" class="phone piiSafe offset1 span5" name="phone" ng-model="user.phone" id="phone" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="email">Email </label>\n' +
    '            <input maxlength="50" class="email piiSafe offset1 span5" name="email" ng-model="user.email" id="email" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="birthdate">Birthday</label>\n' +
    '            <input maxlength="100" class="birthdate piiSafe offset1 span5" name="birthdate" ng-model="user.birthdate" id="birthdate" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div ng-if="uiState.showPersonalInfo" class="row-fluid saveRow">\n' +
    '            <button type="submit" name="submitButton" class="btn-primary btn" ng-hide="submitting" ng-click="save()">Save Changes</button>\n' +
    '            <div class="processing" ng-show="submitting">Saving...</div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="span4">\n' +
    '        <span ng-if="user.type == \'Insured\'">&nbsp;</span> <!-- Required to ensure the column occupies space on the page when agent inputs are hidden -->\n' +
    '        <div class="row-fluid" ng-if="user.type == \'Agent\'">\n' +
    '            <label class="span3" for="address">Address Line 1</label>\n' +
    '            <input maxlength="100" class="address piiSafe offset1 span5" name="address" ng-model="user.address" id="address" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid" ng-if="user.type == \'Agent\'">\n' +
    '            <label class="span3" for="address2">Address Line 2</label>\n' +
    '            <input maxlength="100" class="address2 piiSafe offset1 span5" name="address2" ng-model="user.address2" id="address2" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid" ng-if="user.type == \'Agent\'">\n' +
    '            <label class="span3" for="city">City </label>\n' +
    '            <input maxlength="50" class="city piiSafe offset1 span5" name="city" ng-model="user.city" id="city" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid" ng-if="user.type == \'Agent\'">\n' +
    '            <label class="span3" for="stateOrProvince">State </label>\n' +
    '            <select name="stateOrProvince" id="stateOrProvince" ng-model="user.stateOrProvince" class="state piiSafe offset1 span5" ng-options="state.value as state.name for state in states" ng-disabled="!uiState.showPersonalInfo"></select>\n' +
    '        </div>\n' +
    '        <div class="row-fluid" ng-if="user.type == \'Agent\'">\n' +
    '            <label class="span3" for="postalCode">Postal Code </label>\n' +
    '            <input maxlength="20" class="postalCode piiSafe offset1 span5" name="postalCode" ng-model="user.postalCode" id="postalCode" size="30" type="text" ng-disabled="!uiState.showPersonalInfo">\n' +
    '        </div>\n' +
    '        <div class="row-fluid" ng-if="user.type == \'Agent\'">\n' +
    '            <label class="span3" for="agencyId">Agency ID/Name </label>\n' +
    '            <table class="table table-striped table-condensed table-bordered">\n' +
    '                    <thead>\n' +
    '                            <th>ID</th>\n' +
    '                            <th>Name</th>\n' +
    '                    </thead>\n' +
    '                    <tbody>\n' +
    '                            <tr ng-repeat="agency in userProfile.agencies">\n' +
    '                                    <td>{{:: agency.id }}</td>\n' +
    '                                    <td>{{:: agency.name }}</td>\n' +
    '                            </tr>\n' +
    '                    </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div ng-if="uiState.showPersonalInfo" class="span4" style="padding-top : 93px">\n' +
    '	    <p>Editing your email address will only update your user profile and will not update any policies associated to your account.  Please contact your <br> agent or Markel to update policy information.</p>\n' +
    '    </div>\n' +
    '    <div ng-if="!uiState.showPersonalInfo" class="span4" style="padding-top : 93px">\n' +
    '        <p>Please email <a ng-href="mailto:agencyappointment@markel.com" ng-click="close()" target="_blank">agencyappointment@markel.com</a> to request changes to your personal and address information.</p>\n' +
    '    </div>\n' +
    '    <div class="span3">\n' +
    '        <div class="alert alert-success" ng-show="messages.success">Changes saved.</div>\n' +
    '        <div error-message error-message-header="messages.errorHeader" error-message-array="messages.errors"></div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/userAccountSettings/securityResponse/template/template.html',
    '<div section-toggle title="uiState.sectionTitle" open="uiState.visible"></div>\n' +
    '\n' +
    '<div class="row-fluid" ng-if="uiState.visible">\n' +
    '    <div class="span5">\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="question">Question <span class="required-indicator">*</span></label>\n' +
    '            <select name="question" id="question" ng-model="user.question" class="securityQuestion piiSafe offset1 span8" ng-options="question.id as question.text for question in securityQuestions"></select>\n' +
    '        </div>\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="answer">Answer <span class="required-indicator">*</span></label>\n' +
    '            <input maxlength="75" class="answer piiSafe offset1 span5" name="answer" ng-model="user.answer" id="answer" size="30" type="text">\n' +
    '        </div>\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="securityPassword">Current Password <span class="required-indicator">*</span></label>\n' +
    '            <input maxlength="100" class="password tlRepress offset1 span5" name="password" ng-model="user.securityPassword" id="securityPassword" size="30" type="password">\n' +
    '        </div>\n' +
    '        <div class="row-fluid saveRow">\n' +
    '            <button type="submit" name="submitButton" class="btn-primary btn" ng-hide="submitting" ng-click="save()">Save Changes</button>\n' +
    '            <div class="processing" ng-show="submitting">Saving...</div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="span4">\n' +
    '        <div ng-if="!hasSecurityResponse">\n' +
    '            <p>You currently have no security question and response set on your account.</p>\n' +
    '            <p><strong>Please set one now.</strong></p>\n' +
    '            <p>Your security question and response will be used to confirm your identity if you ever forget your password.</p>\n' +
    '        </div>\n' +
    '        <p ng-if="hasSecurityResponse">Your security question and response will be used to confirm your identity if you ever forget your password.</p>\n' +
    '    </div>\n' +
    '    <div class="span3">\n' +
    '        <div class="alert alert-success" ng-show="messages.success">Changes saved.</div>\n' +
    '        <div error-message error-message-header="messages.errorHeader" error-message-array="messages.errors"></div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/userAccountSettings/stateNewsSubscriptions/template/template.html',
    '<div section-toggle title="\'State News Subscriptions\'" open="uiState.visible"></div>\n' +
    '\n' +
    '<div class="row-fluid" ng-if="uiState.visible">\n' +
    '    <div class="span9">\n' +
    '        <div class="control-group">\n' +
    '            <div class="controls subscribed span8">\n' +
    '\n' +
    '                <label class="checkbox control-label span2">\n' +
    '                    <input type="checkbox" name="allStates" class="piiSafe" select-all-checks source="subscriptions" key="subscribed">\n' +
    '                    <span>All States</span>\n' +
    '                </label>\n' +
    '\n' +
    '                <label class="checkbox control-label span2 state-cbox-label" ng-repeat="state in subscriptions">\n' +
    '                    <input type="checkbox" name="states" class="piiSafe" ng-model="state.subscribed">\n' +
    '                    <span>{{ state.name }}</span>\n' +
    '                </label>\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="span3">\n' +
    '        <div class="alert alert-success" ng-show="messages.success">Changes saved.</div>\n' +
    '        <div error-message error-message-header="messages.errorHeader" error-message-array="messages.errors"></div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="row-fluid saveRow" ng-if="uiState.visible">\n' +
    '    <button type="submit" name="submitButton" class="btn-primary btn" ng-hide="submitting" ng-click="save()">Save Changes</button>\n' +
    '    <div class="processing" ng-show="submitting">Saving...</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/userAccountSettings/userCredentials/template/template.html',
    '<div section-toggle title="\'Username and Password Management\'" open="uiState.visible"></div>\n' +
    '\n' +
    '<div class="row-fluid" ng-if="uiState.visible">\n' +
    '    <div class="span5">\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="userName">Current User ID <span class="required-indicator">*</span></label>\n' +
    '            <input maxlength="100" class="userName offset1 span5" name="userName" ng-model="user.userName" id="userName" size="30" type="text" readonly="readonly">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="newUserName">New User ID</label>\n' +
    '            <input maxlength="100" class="newUserName offset1 span5" name="newUserName" ng-model="user.newUserName" id="newUserName" size="30" type="text">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="currentPassword">Current Password <span class="required-indicator">*</span></label>\n' +
    '            <input maxlength="100" class="password tlRepress offset1 span5" name="password" ng-model="user.priorPassword" id="currentPassword" size="30" type="password">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="password">New Password <span class="required-indicator" ng-if="user.password || user.passwordConfirm">*</span></label>\n' +
    '            <input maxlength="100" class="password tlRepress offset1 span5" name="password" ng-model="user.password" id="password" size="30" type="password">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid">\n' +
    '            <label class="span3" for="passwordConfirm">Confirm Password <span class="required-indicator" ng-if="user.passwordConfirm || user.password">*</span></label>\n' +
    '            <input maxlength="100" class="tlRepress offset1 span5" name="passwordConfirm" ng-model="user.passwordConfirm" id="passwordConfirm" size="30" type="password">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid" ng-show="uiState.api.available">\n' +
    '            <label class="span3" for="apiKey">API Key</label>\n' +
    '            <button id="viewApiKeyBtn" type="button" class="btn btn-small" ng-click="showApiKey()" ng-hide="uiState.api.keyVisible">View</button>\n' +
    '            <span class="piiMask offset1 span5" ng-show="uiState.api.keyVisible" id="apiKey">{{::user.apiKey}}</span>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row-fluid saveRow">\n' +
    '            <button type="submit" name="submitButton" class="btn-primary btn" ng-hide="submitting" ng-click="save()">Save Changes</button>\n' +
    '            <div class="processing" ng-show="submitting">Saving...</div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="span4">\n' +
    '        <p>You may change your username and/or password.  You will need to provide your current password to perform either change.</p>\n' +
    '        <p>Your new password must have at least:</p>\n' +
    '        <ul>\n' +
    '            <li>an uppercase letter</li>\n' +
    '            <li>a lowercase letter</li>\n' +
    '            <li>a number</li>\n' +
    '            <li>8+ characters</li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '    <div class="span3">\n' +
    '        <div class="alert alert-success" ng-show="messages.success">Changes saved.</div>\n' +
    '        <div error-message error-message-header="messages.errorHeader" error-message-array="messages.errors"></div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/description-eligibility-guidelines/template/template.html',
    '<div class="row-fluid">\n' +
    '    <div class="span12">\n' +
    '        <p ng-if="hasGuidelines"><strong>The BOP eligibility guidelines are as follows:</strong></p>\n' +
    '        <div ng-repeat="guidelineSet in guidelines">\n' +
    '            <p><strong>{{:: guidelineSet.classCode }} - {{:: guidelineSet.description }}</strong></p>\n' +
    '            <div ng-repeat="categorySet in guidelineSet.categories">\n' +
    '                <p><strong>{{:: categorySet.category }}</strong></p>\n' +
    '                <p ng-if="categorySet.guidelines.length == 1">\n' +
    '                    {{:: categorySet.guidelines[ 0 ].content }}\n' +
    '                </p>\n' +
    '                <ul ng-if="categorySet.guidelines.length > 1">\n' +
    '                    <li ng-repeat="guideline in categorySet.guidelines">\n' +
    '                        {{:: guideline.content }}\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '            <br>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/document-upload/template/template.html',
    '<div class="control-group">\n' +
    '	<p ng-if="showUploadInstruction" style="font-weight:bold;">Please upload your {{ documentTypeDescriptor }}</p>\n' +
    '	<a class="btn" id="fileUploadBtn" ng-click="openFileUpload()">{{ settings.buttonLabel }}</a><br><br>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="uiState.loading">\n' +
    '	<loading-bar></loading-bar>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="!uiState.loading && uiState.error">\n' +
    '	<div class="alert alert-error">We were unable to load your {{ documentTypeDescriptor }}. Please try again.</div>\n' +
    '</div>\n' +
    '<div class="documents" ng-if="!uiState.loading && !uiState.error">\n' +
    '	<table class="table table-condensed table-bordered">\n' +
    '		<thead>\n' +
    '			<tr>\n' +
    '				<th>View File</th>\n' +
    '				<th>Date & Time</th>\n' +
    '			</tr>\n' +
    '		</thead>\n' +
    '		<tbody>\n' +
    '			<tr ng-if="retrievalError">\n' +
    '				<td colspan="2" class="alert alert-error">An error occurred while attempting to retrieve the list of {{ documentTypeDescriptor }}.</td>\n' +
    '			</tr>\n' +
    '			<tr ng-if="!visibleDocuments.length && !retrievalError">\n' +
    '				<td colspan="2">No {{ documentTypeDescriptor }} found.</td>\n' +
    '			</tr>\n' +
    '			<tr ng-repeat="doc in visibleDocuments">\n' +
    '				<td><a ng-click="getDocument(doc)">{{:: doc.name }}</a></td>\n' +
    '				<td>{{:: doc.timestamp | date: "MM/dd/yyyy hh:mm a" }}</td>\n' +
    '			</tr>\n' +
    '\n' +
    '		</tbody>\n' +
    '	</table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/document-upload/template/uploadDocumentModal.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>{{ modalTitle }}</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<form class="form" method="post" action="{{ formAction }}" ng-submit="onSubmit()" file-upload file-upload-on-success="onUploadSuccess" file-upload-on-error="onUploadFailure">\n' +
    '			<input type="hidden" name="submissionId" value="{{ submissionId }}">\n' +
    '			<input type="hidden" name="effectiveDate" value="{{ effectiveDate }}">\n' +
    '			<input type="hidden" name="documentType" value="{{ documentType }}">\n' +
    '			<input type="hidden" name="profileToken" value="{{ profileToken }}">\n' +
    '			<div class="alert alert-error" ng-if="uiState.errors.length">\n' +
    '				<p ng-repeat="error in uiState.errors">{{ error }}</p>\n' +
    '			</div>\n' +
    '			<div class="alert alert-error" ng-if="uiState.sizeError">\n' +
    '				<p>Document cannot be blank.</p>\n' +
    '			</div>\n' +
    '			<div class="alert alert-error" ng-if="!uiState.validFileType">\n' +
    '				<p>The document must be a valid file type</p>\n' +
    '			</div>\n' +
    '			<div class="alert alert-error" ng-if="uiState.uploadFailed">\n' +
    '				<p>An unexpected error has occurred. Please try again.</p>\n' +
    '			</div>\n' +
    '			<div ng-if="!uiState.completed">\n' +
    '				<div id="dropzone" name="dropzone" class="droplocation">{{ dropText }}</div>\n' +
    '				<div align="center">OR</div>\n' +
    '				<br>\n' +
    '				<div class="control-group">\n' +
    '					<div class="controls" data-ng-repeat="document in documents">\n' +
    '						<input type="file" name="uploadDocument" style="margin-bottom: 0px;">\n' +
    '						<button type="submit" class="btn btn-primary" ng-disabled="uiState.submitting">\n' +
    '							<span ng-if="!uiState.submitting">Upload</span>\n' +
    '							<span ng-if="uiState.submitting">Uploading...</span>\n' +
    '						</button>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div class="alert alert-success" ng-if="uiState.completed">\n' +
    '				<p>Your document has been uploaded successfully.</p>\n' +
    '			</div>\n' +
    '		</form>\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<a class="btn close-button" ng-click="close()">Close</a>\n' +
    '	<div class="modal-footer-content pull-right"></div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/field-group-table/template/template.html',
    '<div ng-class="options.data.tableDiv.class">\n' +
    '    <table ng-class="options.data.table.class">\n' +
    '        <thead ng-if="options.data.table.headerRows.length">\n' +
    '            <tr ng-repeat="headerRow in options.data.table.headerRows" ng-class="headerRow.class">\n' +
    '                <th ng-if="header.text" ng-repeat="header in headerRow.headers" colspan="{{:: header.colspan ? header.colspan : 1}}" rowspan="{{:: header.rowspan ? header.rowspan : 1}}" ng-class="header.class">\n' +
    '                    {{:: header.text }}\n' +
    '                </th>\n' +
    '                <th ng-if="!header.text" ng-repeat="header in headerRow.headers">{{:: header}}</th>\n' +
    '            </tr>\n' +
    '        </thead>\n' +
    '        <thead ng-if="options.data.table.headerRows1.length">\n' +
    '        <tr ng-repeat="headerRow in options.data.table.headerRows1" ng-class="headerRow.class">\n' +
    '            <th ng-if="header.text" ng-repeat="header in headerRow.headers" colspan="{{:: header.colspan ? header.colspan : 1}}" rowspan="{{:: header.rowspan ? header.rowspan : 1}}" ng-class="header.class">\n' +
    '                {{:: header.text }}\n' +
    '                </th>\n' +
    '            <th ng-if="!header.text" ng-repeat="header in headerRow.headers">{{:: header}}</th>\n' +
    '            </tr>\n' +
    '        </thead>\n' +
    '        <tbody>\n' +
    '            <tr ng-repeat="rowGroup in options.fieldGroup" ng-class="rowGroup.data.row.class">\n' +
    '                <td ng-repeat="cellItem in rowGroup.fieldGroup" colspan="{{:: cellItem.data.cell.colspan ? cellItem.data.cell.colspan : 1}}" rowspan="{{:: cellItem.data.cell.rowspan ? cellItem.data.cell.rowspan : 1}}" ng-class="cellItem.data.cell.class">\n' +
    '                    <div ng-if="cellItem.fieldGroup">\n' +
    '                        <formly-form model="model" fields="cellItem.fieldGroup"></formly-form>\n' +
    '                    </div>\n' +
    '                    <div ng-if="!cellItem.fieldGroup">\n' +
    '                        <formly-form model="model" fields="[cellItem]"></formly-form>\n' +
    '                    </div>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '        </tbody>\n' +
    '        <tfoot ng-if="options.data.table.footerRows.length">\n' +
    '            <tr ng-repeat="footerRow in options.data.table.footerRows" ng-class="footerRow.class">\n' +
    '                <td ng-if="footer.text" ng-repeat="footer in footerRow.footers" colspan="{{:: footer.colspan ? footer.colspan : 1}}" rowspan="{{:: footer.rowspan ? footer.rowspan : 1}}" ng-class="footer.class">\n' +
    '                    {{:: footer.text }}\n' +
    '                </td>\n' +
    '                <td ng-if="!footer.text" ng-repeat="footer in footerRow.footers">{{:: footer}}</td>\n' +
    '            </tr>\n' +
    '        </tfoot>\n' +
    '    </table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/host-shows-events/template/template.html',
    '<div>\n' +
    '    <formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<style>\n' +
    '\n' +
    '    .EventInfoStartDate input { width: 70px !important; text-align: left !important;}\n' +
    '    .EventInfoEndDate input { width: 70px !important; text-align: left !important;}\n' +
    '    .EventInfoEstimatedDate select { min-width: 50px !important; max-width: 65px !important; text-align: left !important;}\n' +
    '    .EventInfoName input { text-align: left !important; width: 243px !important;}\n' +
    '    .EventInfoShowAssociation select{ min-width: 50px !important; max-width: 190px !important; text-align: left !important;}\n' +
    '    .EventInfoCompetitionNumber input { width: 100px !important; text-align: left !important;}\n' +
    '    .EventInfoShowSafety select{ min-width: 50px !important; max-width: 95px !important; text-align: left !important;}\n' +
    '\n' +
    '    .EIErrorStartDate { margin-top:5px; width: 79px !important; }\n' +
    '    .EventInfoRowErrorStartDate {padding: 0px !important; width: 79px !important; }\n' +
    '    .EIErrorEndDate { margin-top:5px; width: 79px !important; }\n' +
    '    .EventInfoRowErrorEndDate { padding: 0px !important; width: 79px !important; }\n' +
    '    .EIErrorEstimatedDate { margin-top:5px; width: 64px !important; }\n' +
    '    .EventInfoRowErrorEstimatedDate { padding: 0px !important; width: 64px !important; }\n' +
    '    .EIErrorName { margin-top:5px; width: 252px !important; }\n' +
    '    .EventInfoRowErrorName { padding: 0px !important; width: 252px !important; }\n' +
    '    .EIErrorShowAssociation { margin-top:5px; width: 190px !important; }\n' +
    '    .EventInfoRowErrorShowAssociation { padding: 0px !important; width: 190px !important; }\n' +
    '    .EIErrorCompetitionNumber { margin-top:5px; }\n' +
    '    .EventInfoRowErrorCompetitionNumber { padding: 0px !important; }\n' +
    '    .EIErrorShowSafety { margin-top:5px; width: 94px !important; }\n' +
    '    .EventInfoRowErrorShowSafety { padding: 0px !important; width: 94px !important; }\n' +
    '\n' +
    '    .table th, .table td { text-align: left !important; padding: 8px !important; }\n' +
    '    .specialty-application-section .btn-danger { background-color: #d9534f !important; border-color: #d9534f !important; }\n' +
    '    .input-append .add-on { margin-left: 0px !important;}\n' +
    '    .field-help {\n' +
    '        margin-top: 4px !important;\n' +
    '        margin-left: 4px !important;\n' +
    '    }</style>\n' +
    '\n' +
    '<table class="table table-bordered" id="hostShowsEventsTable">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th style="width: 110px">Start date <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 110px">End date <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 67px;">Estimated date? <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 154px;">Name of event <span style="color:red;">*</span></th>\n' +
    '        <th>Event sanctioned by <span style="color:red;">*</span></th>\n' +
    '        <th>Competition number </th>\n' +
    '        <th>Safety procedures? <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 86px;">Add event</th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="section in enumerations">\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[4] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[5] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[6] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '            <button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/key-characteristics/template/template.html',
    '<div>\n' +
    '    <formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<style>\n' +
    '    .KeyCharacteristicsDataPoint input { width: 70px !important; }\n' +
    '    .KCError { width: 100px !important; margin-top:5px; }\n' +
    '    .keyCharacteristicsRowError { width: 95% !important; padding-bottom: 5px; padding-left: 3px; padding-right: 0px; }\n' +
    '    .field-help {\n' +
    '        margin-top: 4px !important;\n' +
    '        margin-left: 12px;\n' +
    '    }</style>\n' +
    '\n' +
    '<table class="table table-bordered" id="keyCharacteristicsTable">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th>Zip code <span style="color:red;">*</span></th>\n' +
    '        <th>Building age <br> (in years) <span style="color:red;">*</span></th>\n' +
    '        <th>Years of <br> ownership <span style="color:red;">*</span></th>\n' +
    '        <th>Licensed <br> capacity <span style="color:red;">*</span></th>\n' +
    '        <th>Average daily <br> attendance <span style="color:red;">*</span></th>\n' +
    '        <th>No. of infants<span style="color:red;">*</span></th>\n' +
    '        <th>No. of child <br> transport vehicles<span style="color:red;">*</span></th>\n' +
    '        <th>Add location</th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="section in enumerations">\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[4] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[5] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[6] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '            <button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/summary-of-equines/template/template.html',
    '<div>\n' +
    '    <formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<style>\n' +
    '\n' +
    '    .AnimalInfoDataPointName input{ text-align: left !important; width: 195px !important; }\n' +
    '    .AnimalInfoDataPointBirthYear input{ text-align: left !important; width: 40px !important; }\n' +
    '    .AnimalInfoDataPointGender select{text-align: left !important; min-width: 50px !important; max-width: 75px !important; }\n' +
    '    .AnimalInfoDataPointBreed select{ text-align: left !important; min-width: 50px !important; max-width: 111px !important;  }\n' +
    '    .AnimalInfoDataPointUse select{ text-align: left !important; min-width: 50px !important; max-width: 82px !important;  }\n' +
    '    .AnimalInfoDataPointOwnership select{ text-align: left !important; min-width: 50px !important; max-width: 82px !important; }\n' +
    '    .AnimalInfoDataPointPercentage input { text-align: left !important; width: 60px !important;  }\n' +
    '\n' +
    '    .AIErrorName { width: 204px !important; margin-top:5px; }\n' +
    '    .AnimalInfoRowErrorName { width: 204px !important; padding: 0px !important; }\n' +
    '    .AIErrorBirthYear { width: 49px !important; margin-top:5px; }\n' +
    '    .AnimalInfoRowErrorBirthYear { width: 49px !important; padding: 0px !important; }\n' +
    '    .AIErrorGender { width: 74px !important; margin-top:5px; }\n' +
    '    .AnimalInfoRowErrorGender { width: 74px !important; padding: 0px !important; }\n' +
    '    .AIErrorBreed { width: 110px !important; margin-top:5px; }\n' +
    '    .AnimalInfoRowErrorBreed { width: 110px !important; padding: 0px !important; }\n' +
    '    .AIErrorUse { width: 81px !important; margin-top:5px; }\n' +
    '    .AnimalInfoRowErrorUse { width: 81px !important; padding: 0px !important; }\n' +
    '    .AIErrorOwnership { width: 80px !important; margin-top:5px; }\n' +
    '    .AnimalInfoRowErrorOwnership { width: 80px !important; padding: 0px !important; }\n' +
    '    .AIErrorPercentage { width: 70px !important; margin-top:5px; }\n' +
    '    .AnimalInfoRowErrorPercentage { width: 70px !important; padding: 0px !important; }\n' +
    '\n' +
    '    .table th, .table td { text-align: left !important; padding: 8px !important; }\n' +
    '    .field-help {\n' +
    '        margin-top: 4px !important;\n' +
    '        margin-left: 4px !important;\n' +
    '    }</style>\n' +
    '\n' +
    '<table class="table table-bordered" id="summaryOfEquinesTable">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th style="width: 206px;">Equine name <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 51px;">Birth<br>year <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 77px;">Gender <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 110px;">Breed <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 83px;">Use <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 80px;">Ownership <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 71px;">% Owned<span style="color:red;">*</span></th>\n' +
    '        <th style="width: 87px;">Add equine</th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="section in enumerations">\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[4] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[5] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[6] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '            <button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/pricingDetails/animalMortality/template/template.html',
    '<table class="table">\n' +
    '    <tbody ng-repeat="animalRow in pricingData.animalTable.rows">\n' +
    '        <tr class="enumerationHeaderRow">\n' +
    '            <th>\n' +
    '                {{:: animalRow.enum }})\n' +
    '                {{:: animalRow.Name }}\n' +
    '                <span ng-if="animalRow.AnimalRegNo">({{:: animalRow.AnimalRegNo }})</span>: {{:: animalRow.Use }}\n' +
    '                <br>\n' +
    '                {{:: animalRow.Breed }} {{:: animalRow.Gender }}, born {{:: animalRow.YOB }}\n' +
    '            </th>\n' +
    '            <th ng-if="$index == 0" class="amount">Value/Limit</th>\n' +
    '            <th ng-if="$index == 0" class="amount">Deductible</th>\n' +
    '            <th ng-if="$index == 0" class="amount">Premium</th>\n' +
    '            <th ng-if="$index > 0" colspan="3">&nbsp;</th>\n' +
    '        </tr>\n' +
    '        <tr ng-repeat="coverageRow in animalRow.coverageTable.rows">\n' +
    '            <td class="enumerationCoverageStartingCell">\n' +
    '                {{:: coverageRow.coverage }}\n' +
    '            </td>\n' +
    '            <td class="amount">\n' +
    '                <span ng-if="coverageRow.limit != null">{{:: coverageRow.limit | formatMoney }}</span>\n' +
    '                <span ng-if="coverageRow.limit == null">N/A</span>\n' +
    '            </td>\n' +
    '            <td class="amount">\n' +
    '                {{:: coverageRow.deductible | formatMoney }}\n' +
    '            </td>\n' +
    '            <td class="amount">\n' +
    '                {{:: coverageRow.writtenrpap | formatMoney }}\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        <tr class="enumerationTotalRow">\n' +
    '            <td colspan="3" class="totalLabel">TOTAL ANIMAL PREMIUM</td>\n' +
    '            <td class="totalAmount">{{:: animalRow.coverageTable.total | formatMoney }}</td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '\n' +
    '    <tbody>\n' +
    '        <tr class="totalPremiumRow">\n' +
    '            <td colspan="3" class="totalLabel"><h4>TOTAL PREMIUM</h4></td>\n' +
    '            <td class="totalAmount">{{:: pricingData.animalTable.total | formatMoney }}</td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '\n' +
    '    <tbody>\n' +
    '        <tr class="taxesAndFeedsHeaderRow">\n' +
    '            <td colspan="3" class="totalLabel"><h4>ADDITIONAL TAXES & FEES</h4></td>\n' +
    '            <td class="amount">&nbsp;<span ng-if="!pricingData.taxesAndFeesTable.rows.length">$0.00</span></td>\n' +
    '        </tr>\n' +
    '        <tr ng-repeat="taxRow in pricingData.taxesAndFeesTable.rows" ng-class="{ finalTaxRow: $last }">\n' +
    '            <td colspan="3" class="horizontalLabel">{{:: taxRow.displayname }}</td>\n' +
    '            <td class="amount">{{:: taxRow.writtenexcise | formatMoney }}</td>\n' +
    '        </tr>\n' +
    '        <tr class="finalTotalRow">\n' +
    '            <td colspan="3" class="totalLabel"><h1>TOTAL</h1></td>\n' +
    '            <td class="amount"><h1>{{:: pricingData.finalTotal | formatMoney }}</h1></td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/questionShellSections/agentDateSelections/template/licensedInfoModal.html',
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3>Licensed Agent</h3>\n' +
    '</div>\n' +
    '<div class="modal-scroll row-fluid">\n' +
    '	<div class="modal-body">\n' +
    '		<table class="table table-condensed table-bordered">\n' +
    '			<tr>\n' +
    '				<th>Agent</th>\n' +
    '				<th>State</th>\n' +
    '				<th>Start Date</th>\n' +
    '				<th>End Date</th>\n' +
    '				<th>Status</th>\n' +
    '			</tr>\n' +
    '			<tr ng-repeat="agent in result | orderBy: \'state\'">\n' +
    '				<td>{{ agent.name }}</td>\n' +
    '				<td>{{ agent.state }}</td>\n' +
    '				<td>{{ agent.effDate | date : "MM/dd/yyyy" }}</td>\n' +
    '				<td>{{ agent.expDate | date : "MM/dd/yyyy" }}</td>\n' +
    '				<td ng-if="agent.status">Active</td>\n' +
    '				<td ng-if="!agent.status">Not Active</td>\n' +
    '			</tr>\n' +
    '		</table>\n' +
    '\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '	<button class="btn" ng-click="close()">Cancel</button>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/questionShellSections/agentDateSelections/template/template.html',
    '<div class="control-group" id="agentInfo">\n' +
    '	<div ng-if="uiState.invalidForm" class="alert alert-info">Please enter valid date.</div>\n' +
    '	<div ng-if="section.error" class="alert alert-info">Program is not available for the selected effective date.</div>\n' +
    '	<div class="row-fluid">\n' +
    '		<div class="span12">\n' +
    '			<table class="table table-condensed table-bordered" id="">\n' +
    '				<tr>\n' +
    '					<th class="span3">Programs</th>\n' +
    '					<th class="span3">Effective Date</th>\n' +
    '					<th class="span3">Servicing Agent</th>\n' +
    '					<th ng-if="uiState.showLicensedAgentDetails" class="span3">Licensed Agent</th>\n' +
    '				</tr>\n' +
    '				<tr ng-repeat="program in store.programs">\n' +
    '					<td class="controls field-position">{{ program.marketedProgramName }}</td>\n' +
    '					<td>\n' +
    '						<input type="text" class="piiSafe" ng-model="program.effectiveDate" value="{{" program.effectivedate }} date-picker date-picker-on-select="loadPrograms" date-picker-min-date="0" date-picker-defaultdate="+0">\n' +
    '					</td>\n' +
    '					<td>\n' +
    '						<select name="serviceAgent" class="piiSafe" ng-model="program.serviceAgent" ng-options="agent.name for agent in form.serviceAgents | orderBy:\'name\'">\n' +
    '						</select>\n' +
    '					</td>\n' +
    '					<td ng-if="uiState.showLicensedAgentDetails && program.licensedAgentRequired && uiState.hasLicenseData">\n' +
    '						<select name="licensedAgent" class="piiSafe" ng-model="program.licensedAgent" ng-options="license.name for license in form.licenseAgents | orderBy:\'name\' | deDuplicate:\'name\'">\n' +
    '						</select><br>\n' +
    '						<a ng-click="viewLicensedInfo(program.licensedAgent)" ng-hide="!program.licensedAgentRequired">View Licensed Information</a>\n' +
    '					</td>\n' +
    '					<td ng-if="uiState.showLicensedAgentDetails && !program.licensedAgentRequired"> NA</td>\n' +
    '					<td ng-if="uiState.showLicensedAgentDetails && program.licensedAgentRequired &&!uiState.hasLicenseData">No licenses available for state</td>\n' +
    '				</tr>\n' +
    '			</table>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/section/container/template/template.html',
    '\n' +
    '<div class="row-fluid center-container specialty-application-section section-{{ layout }}">\n' +
    '	<div class="content-wrapper span12">\n' +
    '		<div class="row-fluid">\n' +
    '			<div ng-if="additionalInfoErrors.length" class="alert alert-error" id="additional-info-error">\n' +
    '				Please complete all the following required questions: <br>\n' +
    '				<ul>\n' +
    '					<li ng-repeat="field in additionalInfoErrors">{{ field.templateOptions.label }}</li>\n' +
    '				</ul>\n' +
    '			</div>\n' +
    '			<div class="section_window" ng-style="{ width: width + 20 }">\n' +
    '				<div class="section_container" ng-style="{ \'margin-left\' :  ( (0 - width - 43)  * current_section_index ) + ( 10 * current_section_index ) }">\n' +
    '					<div ng-repeat="section in tree.sections | orderBy: [\'orderNumber\']" class="section-detail box-shadow" ng-style="{ width: width - 25 }" ng-if="section.depth == 1 && section.visible">\n' +
    '						<div model="form.model" section="section" layout="layout" specialty-application-section-detail></div>						\n' +
    '					</div>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="bottom-bar row-fluid span12">\n' +
    '	<div class="section-actions center-container span12">\n' +
    '\n' +
    '		<div class="section-navigation row-fluid" ng-style="{ width: width - 25 }">\n' +
    '			<div class="span12">\n' +
    '				<div class="pull-left previous-button" ng-if="!show_all">\n' +
    '					<button class="btn btn-success btn-application-submit" ng-if="sectionGraph[ tree.selectedNode.sectionId ].previous" ng-disabled="areRequestsPending()" ng-click="navigate($event,{section:sectionGraph[ tree.selectedNode.sectionId ].previous,navigation:\'previous\'} )">\n' +
    '							<span class="glyphicon glyphicon-chevron-left"></span> <span class="btn_txt">PREVIOUS</span>\n' +
    '					</button>\n' +
    '				</div>\n' +
    '				<div class="pull-right next-button">\n' +
    '					<div>\n' +
    '						<button class="btn btn-success btn-application-next" ng-if="sectionGraph[ tree.selectedNode.sectionId ].next && !show_all" ng-disabled="areRequestsPending()" ng-click="navigate($event,{section:sectionGraph[ tree.selectedNode.sectionId ].next,navigation: \'next\'} )">\n' +
    '								<span class="btn_txt">NEXT</span> <span class="glyphicon glyphicon-chevron-right"></span>\n' +
    '						</button>\n' +
    '					</div>\n' +
    '					<button class="btn btn-success hideLegacy btn-application-submit" ng-if="isOnLastSection() && !preBindEdit && !show_all" ng-disabled="areRequestsPending() || !allSectionsAreVisitedAndComplete()" ng-click="finalizeApplication()">\n' +
    '							Submit Application\n' +
    '					</button>\n' +
    '					<button class="btn btn-success hideLegacy btn-additional-questions" ng-if="isOnLastSection() && show_all && preBindEdit && showAdditionalQuestionsButton" ng-disabled="areRequestsPending()" ng-click="finalizeAdditionalQuestions()">\n' +
    '						Submit Additional Questions\n' +
    '					</button>\n' +
    '\n' +
    '					<button class="btn btn-success hideLegacy btn-additional-questions" ng-if="isOnLastSection() && show_all && preBindEdit && !showAdditionalQuestionsButton" ng-disabled="areRequestsPending()" ng-click="saveAdditionalQuestions()">\n' +
    '						Save and Go Back\n' +
    '					</button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '			<div class="alert-message hideLegacy" ng-if="isOnLastSection() && (areRequestsPending() || !allSectionsAreVisitedAndComplete())">\n' +
    '					<span ng-if="!preBindEdit">Please review each application section prior to Submitting</span>\n' +
    '					<span ng-if="preBindEdit">Please review each question section prior to submitting</span>\n' +
    '			</div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/section/detail/template/template.html',
    '<span ng-if="section.type !=\'inline\' && section.visible">\n' +
    '	<span class="sub-section-accordion section-{{ section.depth }}" ng-click="section_open($event,section)">\n' +
    '		<h3 ng-if="section.type == \'tree\' && section.enumeration == null" ng-class="{ \'section-incomplete\': section.visited && !section.valid , \'section-open\': section.open }">\n' +
    '			<span class="glyphicon" ng-class="{ \'glyphicon-chevron-down\': section.open , \'glyphicon-chevron-right\' : !section.open }" ng-show="section.loaded && section.depth > 1"></span>	\n' +
    '			{{ section.enumeration }} {{ section.title }} 		\n' +
    '		\n' +
    '			<span ng-if="section.loading">\n' +
    '				<img src="/assets/images/ajax-loader-grey.gif">\n' +
    '			</span>\n' +
    '		</h3>\n' +
    '	</span>\n' +
    '\n' +
    '	<span class="sub-section-accordion section-{{ section.depth }}" ng-click="section_open($event,section)">\n' +
    '		<h3 ng-if="section.type == \'tree\' && section.enumeration != null" ng-class="{ \'section-incomplete\': section.visited && !section.valid , \'section-open\': section.open }">\n' +
    '			<span class="glyphicon" ng-class="{ \'glyphicon-chevron-down\': section.open , \'glyphicon-chevron-right\' : !section.open }" ng-show="section.loaded"></span>	\n' +
    '			{{ section.enumeration }} : {{ section.title.split(\')\')[1] }} <!--  {{ section.sectionId }}  -->\n' +
    '		\n' +
    '			<span ng-if="section.loading">\n' +
    '				<img src="/assets/images/ajax-loader-grey.gif">\n' +
    '			</span>\n' +
    '\n' +
    '			<button ng-if="section.deletable && !show_all" title="Delete {{ section.enumeration }}" class="btn btn-danger pull-right btn_action btn_enumeration_delete" ng-click="delete_section($event,section)" ng-show="section.loaded && section.parentNode.sections.length > 1">\n' +
    '\n' +
    '					<span class="glyphicon glyphicon-trash"> </span>\n' +
    '\n' +
    '			</button>\n' +
    '		</h3>\n' +
    '	</span>\n' +
    '\n' +
    '\n' +
    '	<div class="sub-section-parent sub-section-content" ng-class="{ \'sub-section-open\': section.open }">\n' +
    '		<div class="row-fluid" ng-if="section.enumeration == \'Building\' && !show_all" building-table model="model" fields="section.fields" section="section"></div>\n' +
    '\n' +
    '		<formly-form ng-if="section.enumeration != \'Building\'" class="form form-horizontal" model="model" fields="section.fields"></formly-form>\n' +
    '\n' +
    '		<div ng-if="section.sections.length && sub_section.type != \'inline\'" ng-repeat="sub_section in section.sections | orderBy: [\'orderNumber\']" class="child-section row-fluid">\n' +
    '			<div specialty-application-section-detail model="model" layout="layout" section="sub_section"></div>\n' +
    '		</div>\n' +
    '	</div>\n' +
    '\n' +
    '	<button class="btn btn-primary pull-right btn_action btn_enumeration_add" ng-if="add_enumeration && !show_all" ng-click="add_section($event,section)" ng-show="section.loaded && section.open ">\n' +
    '\n' +
    '				<span class="glyphicon glyphicon-plus"> </span> {{:: enumeration_title }}\n' +
    '		</button>\n' +
    '</span>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/submit/review/template/template.html',
    '<div class="section center-container">\n' +
    '\n' +
    '    <div id="applicationReview" ng-if="uiState.stage != \'waitToEdit\'" class="row-fluid reviewContainer">\n' +
    '        <div class="span12">\n' +
    '            <div ng-if="uiState.stage == \'start\'">\n' +
    '                <div class="alert alert-info">Please confirm the information below is accurate and select "Continue"</div>\n' +
    '\n' +
    '                <h3 class="applicationHeader">Your Application</h3>\n' +
    '\n' +
    '                <table>\n' +
    '                    <tbody>\n' +
    '                    <tr ng-if="insured.name">\n' +
    '                        <td width="50%"><p class="reviewLabel">Insured Name:</p></td>\n' +
    '                        <td>{{:: insured.name }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr ng-if="insured.dba">\n' +
    '                        <td><p class="reviewLabel">Doing Business As:</p></td>\n' +
    '                        <td>{{:: insured.dba }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr ng-if="insured.email">\n' +
    '                        <td><p class="reviewLabel">Email Address:</p></td>\n' +
    '                        <td>{{:: insured.email }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr ng-if="insured.phone">\n' +
    '                        <td><p class="reviewLabel">Phone Number:</p></td>\n' +
    '                        <td>{{:: insured.phone }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr ng-if="insured.address1">\n' +
    '                        <td><p class="reviewLabel">Address:</p></td>\n' +
    '                        <td>{{:: insured.address1 }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr ng-if="insured.city">\n' +
    '                        <td><p class="reviewLabel">City:</p></td>\n' +
    '                        <td>{{:: insured.city }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr ng-if="insured.state">\n' +
    '                        <td><p class="reviewLabel">State:</p></td>\n' +
    '                        <td>{{:: insured.state }}</td>\n' +
    '                    </tr>\n' +
    '                    <tr ng-if="insured.zip">\n' +
    '                        <td><p class="reviewLabel">Zip Code:</p></td>\n' +
    '                        <td>{{:: insured.zip }}</td>\n' +
    '                    </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '\n' +
    '\n' +
    '                <div ng-if="programs.bop" specialty-application-review-bop></div>\n' +
    '                <div ng-if="programs.wcbop" specialty-application-review-wc></div>\n' +
    '                <div ng-if="programs.miscerrorsomissions" specialty-application-review-misc-eo></div>\n' +
    '\n' +
    '                \n' +
    '            <div class="bottom-bar row-fluid span12">\n' +
    '                <div class="center-container span12">\n' +
    '                   <div class="section-actions pull-right">\n' +
    '                            <div class="pull-right next-button">\n' +
    '                            <button class="btn editButton" style="margin-right:15px;" ng-click="editSubmission()">Edit</button>\n' +
    '                            <button class="btn btn-success eligibility-continue" ng-click="continueSubmission()">Continue</button>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '            <div id="tmplConfirmation" ng-if="uiState.stage == \'continue\'">\n' +
    '                <div class="progress-label">We are processing your application...</div>\n' +
    '                <div id="progressbar"></div>\n' +
    '                <div class="row-fluid marketing other-pages">\n' +
    '                    <div class="span3">&nbsp;</div>\n' +
    '                    <div class="span6">\n' +
    '                        <h1 id="message" class="{{uiState.currentMessage.class}}">{{uiState.currentMessage.text}}</h1>\n' +
    '                    </div>\n' +
    '                    <div class="span3">&nbsp;</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div ng-if="uiState.stage == \'retryingSubmit\'">\n' +
    '                <div class="well quote-well">\n' +
    '                    <h1>Retrying submit...</h1>\n' +
    '                    <p><img src="/assets/images/ajax-loader-grey.gif"></p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div ng-if="uiState.stage == \'showError\'">\n' +
    '                <div class="reviewErrors alert alert-error">\n' +
    '                    <div ng-if="uiState.errorType == \'submitError\' || uiState.errorType == \'autoOutcomeError\'">\n' +
    '                        <p>\n' +
    '                            An error occurred while processing your submission.\n' +
    '                        </p>\n' +
    '                        <p>\n' +
    '                            To retry the submission of your application, please <a ng-click="retrySubmit()">click here</a>.\n' +
    '                        </p>\n' +
    '                        <p>\n' +
    '                            If the error occurs more than once, please contact Customer Service for assistance.\n' +
    '                        </p>\n' +
    '                    </div>\n' +
    '                    <div ng-if="uiState.errorType == \'resultError\'">\n' +
    '                        <p>\n' +
    '                            Your application was submitted, but an error occurred while retrieving the results of the submission.\n' +
    '                        </p>\n' +
    '                        <p>\n' +
    '                            Please <a ng-click="retryResults()">click here</a> to try again.\n' +
    '                        </p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="uiState.stage == \'waitToEdit\'" class="row-fluid">\n' +
    '        <div class="span12">\n' +
    '            <div>\n' +
    '                <div class="well quote-well">\n' +
    '                    <h1>Retrieving data...</h1>\n' +
    '                    <p><img src="/assets/images/ajax-loader-grey.gif"></p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/building-optional-endorsements/loss-payable-details/template/template.html',
    '<div>\n' +
    '    <p><b>Loss Payable</b></p>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-bordered" ng-repeat="section in enumerations">\n' +
    '    <tr>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td></td>\n' +
    '    </tr>\n' +
    '    <tr>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[4] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[5] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[6] ]"></formly-form>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/building-optional-endorsements/mortgage-holder-details/template/template.html',
    '<div>\n' +
    '	<p><b>Mortgage Holder</b></p>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-bordered" ng-repeat="section in enumerations">\n' +
    '	<tr>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[4] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[5] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[6] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td></td>\n' +
    '	</tr>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/inland-marine/blanket-item-limit/template/template.html',
    '<div class="hidden">\n' +
    '    <formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<style>\n' +
    '    .scheduleNumber input{ text-align: left !important; width: 45px !important; }\n' +
    '    .scheduleLimit input{ text-align: left !important; width: 71px !important; }\n' +
    '    .scheduleDescription textarea{ text-align: left !important; width: 525px !important; height: 25px}\n' +
    '\n' +
    '    .scheduleNumberError { width: 65px !important; padding: 0px !important; }\n' +
    '    .scheduleLimitErr { width: 80px !important; margin-top:5px; }\n' +
    '    .scheduleLimitError { width: 80px !important; padding: 0px !important; }\n' +
    '    .scheduleDescError { width: 534px !important; margin-top:5px; }\n' +
    '    .scheduleDescriptionError { width: 534px !important; padding: 0px !important; }\n' +
    '\n' +
    '</style>\n' +
    '\n' +
    '<table class="table table-bordered" id="blanketItemTable">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th style="width: 206px;">Item <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 51px;">Item limit <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 77px;">Item description <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 87px;">Add / Delete</th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="section in enumerations">\n' +
    '        <td style="width: 11%;">\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td style="width: 14%;">\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td style="width: 14%;">\n' +
    '            <button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '            <button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/inland-marine/fine-item-limit/template/template.html',
    '<div class="hidden">\n' +
    '    <formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<style>\n' +
    '    .facLimit input{ text-align: left !important; width: 71px !important; }\n' +
    '    .facDescription textarea{ text-align: left !important; width: 460px !important; height: 25px}\n' +
    '\n' +
    '    .facLimitErr { width: 81px !important; margin-top:5px; }\n' +
    '    .facLimitError { width: 81px !important; padding: 0px !important; }\n' +
    '    .facDescError { width: 620px !important; margin-top:5px; }\n' +
    '    .facDescriptionError { width: 470px !important; padding: 0px !important; }\n' +
    '\n' +
    '</style>\n' +
    '\n' +
    '<table class="table table-bordered" id="fineArtsTable">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th style="width: 51px;">Item<span style="color:red;">*</span></th>\n' +
    '        <th style="width: 51px;">Item limit <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 77px;">Item description <span style="color:red;">*</span></th>\n' +
    '        <th style="width: 87px;">Add / Delete</th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="section in enumerations track by $index">\n' +
    '        <td ng-if="section" style="width: 28%;">\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td ng-if="section" style="width: 28%;">\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td ng-if="section">\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td ng-if="section" style="width: 38%;">\n' +
    '            <button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '            <button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/optional-endorsements/additional-information-location/template/template.html',
    '<div>\n' +
    '	<p><b>{{ ::header }}</b></p>\n' +
    '</div>\n' +
    '<table id="{{::locationControlId}}" class="table table-bordered" ng-repeat="section in enumerations">\n' +
    '	<tr>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[4] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[5] ]"></formly-form>\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/optional-endorsements/item-description-limit/template/template.html',
    '<div class="hidden">\n' +
    '    <formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-bordered">\n' +
    '    <thead>\n' +
    '        <tr>\n' +
    '            <th width="43.5%">Description <span style="color:red;">*</span></th>\n' +
    '            <th width="43.5%">Limit <span style="color:red;">*</span></th>\n' +
    '            <th width="13%">Actions</th>\n' +
    '        </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '        <tr ng-repeat="section in enumerations">\n' +
    '            <td>\n' +
    '                <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '                <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '            </td>\n' +
    '            <td>\n' +
    '                <button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '                <button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/optional-endorsements/owner-officer-information/template/template.html',
    '<div>\n' +
    '    <formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-bordered">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th>First Name <span style="color:red;">*</span></th>\n' +
    '        <th>Last Name <span style="color:red;">*</span></th>\n' +
    '        <th>Title <span style="color:red;">*</span></th>\n' +
    '        <th>% Ownership <span style="color:red;">*</span></th>\n' +
    '        <th>Class <span style="color:red;">*</span></th>\n' +
    '        <th>Payroll <span style="color:red;">*</span></th>\n' +
    '        <th>Included <span style="color:red;">*</span></th>\n' +
    '        <th>Actions</th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tbody>\n' +
    '    <tr ng-repeat="section in enumerations">\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[4] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[5] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[6] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '            <button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/optional-endorsements/photography-make-model/template/template.html',
    '<div>\n' +
    '    <p>\n' +
    '        <b>Photography</b>\n' +
    '    </p>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-bordered">\n' +
    '    <thead>\n' +
    '    <tr>\n' +
    '        <th>Description </th>\n' +
    '        <th>Make* </th>\n' +
    '        <th>Model Number* </th>\n' +
    '        <th>Serial Number*</th>\n' +
    '    </tr>\n' +
    '    </thead>\n' +
    '    <tr ng-repeat="section in enumerations">\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[1] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[2] ]"></formly-form>\n' +
    '        </td>\n' +
    '        <td>\n' +
    '            <formly-form model="model" fields="[ section[3] ]"></formly-form>\n' +
    '        </td>\n' +
    '    </tr>\n' +
    '</table>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/optional-endorsements/waiver-of-transfer/template/template.html',
    '<div class="hidden">\n' +
    '	<formly-form id="{{::quantityControlId}}" class="form form-horizontal" model="model" fields="[ quantityControl ]"></formly-form>\n' +
    '</div>\n' +
    '\n' +
    '<table class="table table-bordered" style="width: 57%; margin-right: 43%">\n' +
    '	<thead>\n' +
    '	<tr>\n' +
    '		<th width="24%">Name of Person or Organization <span style="color:red;">*</span></th>\n' +
    '		<th width="8%">Actions</th>\n' +
    '	</tr>\n' +
    '	</thead>\n' +
    '	<tbody>\n' +
    '	<tr ng-repeat="section in enumerations">\n' +
    '		<td>\n' +
    '			<formly-form model="model" fields="[ section[0] ]"></formly-form>\n' +
    '		</td>\n' +
    '		<td>\n' +
    '			<button class="btn btn-success" ng-click="changeQuantity(1)" ng-show="$last"><i class="icon-plus icon-white"></i></button>\n' +
    '			<button ng-if="newLength != 1" class="btn btn-danger" ng-click="changeQuantityOfSections(section, -1)"><i class="icon-trash icon-white"></i></button>\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/policy-information/building-table/template/template.html',
    '<div class="control-group building-detail">\n' +
    '    <div class="validationErrors hidden"></div>\n' +
    '    <input type="hidden" value="0" class="buildingID piiSafe">\n' +
    '    <div class="vr_wait_window hidden">\n' +
    '          <table style="width:100%;height:100%;">\n' +
    '            <tr>\n' +
    '                <td style="vertical-align: middle;"><span class="glyphicon glyphicon-cog spin"></span></td>\n' +
    '                <td class="vr_message">Please wait calling Verisk ProMetrix Property Prefill Database.</td>\n' +
    '                <td style="vertical-align: middle;text-align:right;"><span class="glyphicon glyphicon-cog spin"></span></td>\n' +
    '            </tr>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '    <div class="vr_wait_background alert-info hidden"></div>\n' +
    '    \n' +
    '    <table class="table table-bordered building-detail-table">\n' +
    '        <tr>\n' +
    '            <td style="padding:0px;">\n' +
    '                 <table class="table table-condensed vr_table" style="margin-bottom:0px;">\n' +
    '                  \n' +
    '                    <tr class="bopClassificationHeader">\n' +
    '                        <th><span class="banner-sub">Step 1</span><br> Risk Information</th>\n' +
    '                        <th><span class="banner-sub">Step 2</span><br> Building Characteristics</th>\n' +
    '                        <th><span class="banner-sub">Step 3</span><br> Coverages / Limits</th>\n' +
    '                    </tr>\n' +
    '                  \n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1 applicant_type">\n' +
    '                           <formly-form model="model" ng-if="building_fields[\'Applicant Type\']" fields="[building_fields[\'Applicant Type\']]"></formly-form>\n' +
    '                        </td>\n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                            <div class="row-fluid">\n' +
    '                            	<div class="control-group span6">\n' +
    '                               		<formly-form model="model" ng-if="building_fields[\'Construction Type\']" fields="[building_fields[\'Construction Type\']]"></formly-form>\n' +
    '                            	</div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3">\n' +
    '                             <div class="control-group span12 class_code">\n' +
    '                                <formly-form model="model" ng-if="building_fields[\'Class Code Description ID\']" fields="[building_fields[\'Class Code Description ID\']]"></formly-form>\n' +
    '                                <a class="bopBuildingEligibilityPopover hidden" href="#">Eligibility Guidelines</a>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        \n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1 building_location">\n' +
    '                             <div class="control-group span12 class_code">\n' +
    '                                <formly-form model="model" ng-if="building_fields[\'Building Location\']" fields="[building_fields[\'Building Location\']]"></formly-form>\n' +
    '                             </div>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                            <div class="row-fluid span12">\n' +
    '                                <div class="control-group span6" vr-changed="false">\n' +
    '                                    <formly-form model="model" ng-if="building_fields[\'Year Built\']" fields="[building_fields[\'Year Built\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                                <div class="control-group span6">\n' +
    '                                    <formly-form model="model" ng-if="building_fields[\'Stories\']" fields="[building_fields[\'Stories\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3">\n' +
    '                        \n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1 building_description">\n' +
    '                            <div class="row-fluid span12">\n' +
    '                                 <div class="control-group">\n' +
    '                                   	<formly-form model="model" ng-if="building_fields[\'Building Description\']" fields="[building_fields[\'Building Description\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        \n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                            <div class="row-fluid span12">\n' +
    '                                <div class="control-group span6" vr-changed="false">\n' +
    '                                    <formly-form model="model" ng-if="building_fields[\'Building Square Footage\']" fields="[building_fields[\'Building Square Footage\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                                <div class="control-group span3">\n' +
    '                                    <formly-form model="model" ng-if="building_fields[\'Occupied Percent\']" fields="[building_fields[\'Occupied Percent\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3">\n' +
    '                            <div class="row-fluid span12">\n' +
    '                                <div class="control-group span12">\n' +
    '                                    <formly-form model="model" ng-if="building_fields[\'BPP Limit\']" fields="[building_fields[\'BPP Limit\']]"></formly-form>\n' +
    '                                        <div style="width: 200px" ng-if="building_fields[\'BPP Limit Warning\']">\n' +
    '                                            <formly-form model="model" ng-if="building_fields[\'BPP Limit Warning\']" fields="[building_fields[\'BPP Limit Warning\']]"></formly-form>\n' +
    '                                        </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1">\n' +
    '                            \n' +
    '                        </td>\n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                            <div class="row-fluid span12">\n' +
    '                                <div class="control-group span6 annual-gross-sales" ng-if="building_fields[\'Annual Gross Sales\']">\n' +
    '                                    <div class="control-group span6" vr-changed="false">\n' +
    '                                        <formly-form model="model" fields="[building_fields[\'Annual Gross Sales\']]"></formly-form>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                                <div class="control-group span6 annual-payroll" ng-if="building_fields[\'Annual Payroll\']">\n' +
    '                                    <div class="control-group span6" vr-changed="false">\n' +
    '                                        <formly-form model="model" fields="[building_fields[\'Annual Payroll\']]"></formly-form>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3">\n' +
    '\n' +
    '                        </td>\n' +
    '                     </tr>\n' +
    '                     <tr>\n' +
    '                        <td class="building_col_1 business_name">\n' +
    '                            <div class="control-group span12 class_code">\n' +
    '                                <formly-form model="model" ng-if="building_fields[\'Business Name\']" fields="[building_fields[\'Business Name\']]"></formly-form>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                      \n' +
    '                        <td class="vr_middle_col building_col_2">     \n' +
    '                            <div class="control-group span12">\n' +
    '                                <formly-form model="model" ng-if="building_fields[\'Single Occupancy\']" fields="[building_fields[\'Single Occupancy\']]"></formly-form>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3">\n' +
    '                            <div class="row-fluid span12">\n' +
    '                                <div class="control-group span12" vr-changed="false">\n' +
    '                                     <formly-form model="model" ng-if="building_fields[\'Replacement Cost\']" fields="[building_fields[\'Replacement Cost\']]"></formly-form>   \n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1">\n' +
    '                         	<div class="row-fluid span12" ng-if="building_fields[\'Rental Property\']">\n' +
    '                                <div class="control-group row-fluid span6">\n' +
    '                                    <formly-form model="model" fields="[building_fields[\'Rental Property\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                               <div class="row-fluid span12">\n' +
    '                                <div class="control-group span6" vr-changed="false">\n' +
    '                                    <formly-form model="model" ng-if="building_fields[\'Hydrant Distance\']" fields="[building_fields[\'Hydrant Distance\']]"></formly-form>\n' +
    '                                </div>\n' +
    '\n' +
    '                                <div class="control-group span6" vr-changed="false">\n' +
    '                                     <formly-form model="model" ng-if="building_fields[\'Fire Station Distance\']" fields="[building_fields[\'Fire Station Distance\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '\n' +
    '                         <td class="building_col_3">\n' +
    '                             <div class="row-fluid">\n' +
    '                                 <div class="control-group span12 building-replacement-cost">\n' +
    '                                     <input type="hidden" name="insuranceToValueBuildingReplacementCost" class="insuranceToValueBuildingReplacementCost vr_input piiSafe" value="0">\n' +
    '                                     <formly-form model="model" ng-if="building_fields[\'getReplacementCost\']" fields="[building_fields[\'getReplacementCost\']]"></formly-form>\n' +
    '                                     <formly-form model="model" ng-if="building_fields[\'Building Value\']" fields="[building_fields[\'Building Value\']]"></formly-form>\n' +
    '                                         <div style="width: 200px" ng-if="building_fields[\'Building Value Warning\']">\n' +
    '                                            <formly-form model="model" ng-if="building_fields[\'Building Value Warning\']" fields="[building_fields[\'Building Value Warning\']]"></formly-form>\n' +
    '                                         </div>\n' +
    '                                 </div>\n' +
    '                             </div>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1">\n' +
    '                            \n' +
    '                        </td>\n' +
    '                        \n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                                <div class="row-fluid span12">\n' +
    '                                    <div class="control-group span6">\n' +
    '                                       <formly-form model="model" ng-if="building_fields[\'Fire Alarm\']" fields="[building_fields[\'Fire Alarm\']]"></formly-form>\n' +
    '                                    </div>\n' +
    '                                    \n' +
    '                                    <div class="control-group span6" ng-if="building_fields[\'Fire Protection Area\']">\n' +
    '                                        <div class="control-group span6">\n' +
    '                                        	<formly-form model="model" ng-if="building_fields[\'Fire Protection Area\']" fields="[building_fields[\'Fire Protection Area\']]"></formly-form>\n' +
    '                                            <div ng-class="datapointStyles[\'Fire Protection Area Label\']">Subject to Validation</div>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3">\n' +
    '                        	<div class="row-fluid" ng-if="building_fields[\'Mine Subsidence Waiver\']">\n' +
    '                        		<formly-form model="model" fields="[building_fields[\'Mine Subsidence Waiver\']]"></formly-form>\n' +
    '                        	</div>              \n' +
    '                        </td>                                        \n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1"></td>\n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                             <div class="row-fluid span12">\n' +
    '                                <div class="control-group span6" vr-changed="false">\n' +
    '                                   	<formly-form model="model" ng-if="building_fields[\'Sprinklered\']" fields="[building_fields[\'Sprinklered\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                                <div class="control-group span6" id="bopBuildingCondominiumControlGroup" vr-changed="false">\n' +
    '                                    <formly-form model="model" ng-if="building_fields[\'Condo Unit Owner\']" fields="[building_fields[\'Condo Unit Owner\']]"></formly-form>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3">\n' +
    '                        	 <div class="row-fluid" ng-if="building_fields[\'Mine Subsidence Limit\']">\n' +
    '                        		<formly-form model="model" fields="[building_fields[\'Mine Subsidence Limit\']]"></formly-form>\n' +
    '                        	</div>  \n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                    <tr>\n' +
    '                        <td class="building_col_1">&nbsp;</td>\n' +
    '                        <td class="vr_middle_col building_col_2">\n' +
    '                            <div ng-if="building_fields[\'Renovations\']">\n' +
    '                                <div class="row-fluid control-group span6" vr-changed="false">\n' +
    '                                     <formly-form model="model" ng-if="building_fields[\'Renovations\']" fields="[building_fields[\'Renovations\']]"></formly-form>  \n' +
    '                                </div>\n' +
    '\n' +
    '                                <div class="span12" ng-if="building_fields[\'Renovations\'].defaultValue == \'Yes\'">\n' +
    '                                    <table class="table table-bordered">\n' +
    '                                        <tr>\n' +
    '                                            <th colspan="3">\n' +
    '                                               {{:: building_fields[\'Renovation Type Label\'].templateOptions.label }}\n' +
    '                                            </th>\n' +
    '                                        </tr>\n' +
    '                                        <tr>\n' +
    '                                            <td colspan="2" width="100px" ng-if="building_fields[\'Roof Renovation\']">\n' +
    '                                               <formly-form model="model" fields="[building_fields[\'Roof Renovation\']]"></formly-form>\n' +
    '                                            </td>\n' +
    '                                            <td ng-if="building_fields[\'Roof Years\']">\n' +
    '                                               {{:: building_fields[\'Roof Years\'].templateOptions.label }} <formly-form model="model" fields="[building_fields[\'Roof Years\']]"></formly-form>\n' +
    '                                            </td>\n' +
    '                                        </tr>\n' +
    '                                        <tr>\n' +
    '                                            <td colspan="2" width="100px" ng-if="building_fields[\'Electrical Renovation\']">\n' +
    '                                                <formly-form model="model" fields="[building_fields[\'Electrical Renovation\']]"></formly-form>\n' +
    '                                            </td>\n' +
    '                                            <td ng-if="building_fields[\'Electrical Years\']">\n' +
    '                                               {{:: building_fields[\'Electrical Years\'].templateOptions.label }}  <formly-form model="model" fields="[building_fields[\'Electrical Years\']]"></formly-form>\n' +
    '                                            </td>\n' +
    '                                        </tr>\n' +
    '                                        <tr>\n' +
    '                                            <td colspan="2" width="100px" ng-if="building_fields[\'Heating Renovation\']">\n' +
    '                                                <formly-form model="model" fields="[building_fields[\'Heating Renovation\']]"></formly-form>\n' +
    '                                            </td>\n' +
    '                                            <td ng-if="building_fields[\'Heating Years\']">\n' +
    '                                               {{:: building_fields[\'Heating Years\'].templateOptions.label }}  <formly-form model="model" fields="[building_fields[\'Heating Years\']]"></formly-form>\n' +
    '                                            </td>\n' +
    '                                        </tr>\n' +
    '                                        <tr>\n' +
    '                                            <td colspan="2" width="100px" ng-if="building_fields[\'Plumbing Renovation\']">\n' +
    '                                                <formly-form model="model" fields="[building_fields[\'Plumbing Renovation\']]"></formly-form>\n' +
    '                                            </td>\n' +
    '                                            <td ng-if="building_fields[\'Plumbing Years\']">\n' +
    '                                                 <div class="row-fluid span12">\n' +
    '                                                 	{{:: building_fields[\'Plumbing Years\'].templateOptions.label }} \n' +
    '                                                 	<formly-form model="model" fields="[building_fields[\'Plumbing Years\']]"></formly-form>\n' +
    '                                                 </div>\n' +
    '                                            </td>\n' +
    '                                        </tr>\n' +
    '                                    </table>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td class="building_col_3" style="vertical-align:bottom;">\n' +
    '                            <div class="row-fluid" ng-if="building_fields[\'Additional Living Expense\']">\n' +
    '                        		<formly-form model="model" fields="[building_fields[\'Additional Living Expense\']]"></formly-form>\n' +
    '                        	</div>  \n' +
    '\n' +
    '                        	<div class="row-fluid" ng-if="building_fields[\'Additional Expense Coverage\']">\n' +
    '                        		<formly-form model="model" fields="[building_fields[\'Additional Expense Coverage\']]"></formly-form>\n' +
    '                        	</div>  \n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                </table>\n' +
    '\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '    </table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/widget/policy-information/coverage-table/template/template.html',
    '  <div id="coverage-package" class="control-group">\n' +
    '    <table class="table table-condensed table-bordered">\n' +
    '        <thead>\n' +
    '            <tr class="packageHeader">\n' +
    '                <th nowrap="nowrap" class="span3">\n' +
    '                    <a class="btn pull-right" ng-click="show_detail($event)">Show</a>\n' +
    '                    Coverage <span class="required-indicator">*</span><br>\n' +
    '                    <br>\n' +
    '                </th>\n' +
    '                <th nowrap="nowrap" class="span3 package-background" ng-repeat="package in packages" ng-if="package.value != \'\'">\n' +
    '                    <label class="radio pull-right">\n' +
    '                        <input type="radio" class="piiSafe" name="coverage-option" ng-checked="package.selected" ng-click="set_option($event,package)"><span>&nbsp;{{ package.name }}</span>\n' +
    '                    </label>\n' +
    '                </th>\n' +
    '            </tr>\n' +
    '        </thead>\n' +
    '        <tbody>\n' +
    '            <tr>\n' +
    '                <td colspan="{{:: packages.length}}" ng-show="!coverages_loaded && show_coverages">\n' +
    '                    <p><img src="/assets/images/ajax-loader-grey.gif"></p>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '            <tr class="detail package-background" ng-show="show_coverages && coverages_loaded" ng-repeat="coverage in coverages">\n' +
    '                <td class="coverage span3">\n' +
    '                   {{:: coverage.coverageTitle }}\n' +
    '                </td>\n' +
    '                <td class="span3" ng-class="\'coverage-detail-\' + $index" ng-repeat="detail in coverage.details">\n' +
    '                    <div ng-class="{ \'highlightDifference\' : !coverage.packagesIdentical }" ng-bind-html="detail.text | trustHtml"></div>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/questions/config/widgets/verisk/modal-loss-cost-template.html',
    '<style>\n' +
    '    .verisk-loss-cost-modal {\n' +
    '        min-width: 600px;\n' +
    '        min-height: 45%;\n' +
    '        height: auto;\n' +
    '        border-radius: 2px;\n' +
    '    }\n' +
    '\n' +
    '    .verisk-loss-costl.modal.fade.in {\n' +
    '        top: 35%;\n' +
    '    }\n' +
    '\n' +
    '    .modal-header p {\n' +
    '        margin-bottom: 0;\n' +
    '    }\n' +
    '\n' +
    '    .loss-cost {\n' +
    '        display: flex;\n' +
    '        flex-direction: row;\n' +
    '        margin: 0.75rem 0.75rem 0.75rem;\n' +
    '        background-color: rgba(0, 0, 0, .04);\n' +
    '        padding: 0.5rem;\n' +
    '    }\n' +
    '\n' +
    '    .location-verisk-loss-cost p {\n' +
    '        margin-bottom: .5rem;\n' +
    '    }\n' +
    '\n' +
    '    .verisk-loss-cost > div:first-of-type .control-group {\n' +
    '        padding-bottom: .25rem;\n' +
    '    }\n' +
    '\n' +
    '    .loss-cost-occupant-list > span {\n' +
    '        display: inline-block;\n' +
    '        padding: 0 0 0 .5rem;\n' +
    '        position: relative;\n' +
    '        /* border-right: solid 1px #424242; */\n' +
    '    }\n' +
    '\n' +
    '    .loss-cost-occupant-list > span:after {\n' +
    '        content: ", ";\n' +
    '        position: absolute;\n' +
    '        right: -4px;\n' +
    '    }\n' +
    '\n' +
    '    .loss-cost-occupant-list > span:last-of-type:after {\n' +
    '        content: "";\n' +
    '    }\n' +
    '\n' +
    '    .verisk-actions {\n' +
    '        display: flex;\n' +
    '        flex-direction: row;\n' +
    '        justify-content: space-around;\n' +
    '    }\n' +
    '</style>\n' +
    '\n' +
    '<div class="modal-header">\n' +
    '	<h3><strong>We found Occupant(s)!</strong></h3>\n' +
    '    <p>Please select an option and click &ldquo;Continue&rdquo;.</p>\n' +
    '    <p>If you leave the submission prior to selecting an option, this information will not be available in the future.</p>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<div class="loss-cost-test">\n' +
    '\n' +
    '            <div class="verisk-loss-cost" ng-repeat="lc in lossCostData.LossCosts" ng-show="lc.LineNumber != 10 ">\n' +
    '                 <div>\n' +
    '                     <div class="control-group">\n' +
    '                         <label class="radio">\n' +
    '                            <input type="radio" class="piiSafe" name="lc-{{$index}}" ng-model="$parent.SelectedObj" ng-checked="set_option(lc)" ng-changed="set_option(lc)" ng-value="lc">\n' +
    '                            <span>&nbsp;{{ lc.Name }}</span>\n' +
    '                        </label>\n' +
    '                     </div>\n' +
    '                 </div>\n' +
    '            </div>\n' +
    '         </div>\n' +
    '\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <div class="verisk-actions">\n' +
    '        <button class="btn btn-primary" ng-click="AddLostCostData(lc)">Continue</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/questions/config/widgets/verisk/modal-template.html',
    '<style>\n' +
    '    .verisk-risk-modal {\n' +
    '        min-width: 600px;\n' +
    '        min-height: 45%;\n' +
    '        height: auto;\n' +
    '        border-radius: 2px;\n' +
    '    }\n' +
    '\n' +
    '    .verisk-risk-modal.modal.fade.in {\n' +
    '        top: 35%;\n' +
    '    }\n' +
    '\n' +
    '    .modal-header p {\n' +
    '        margin-bottom: 0;\n' +
    '    }\n' +
    '\n' +
    '    .location-risk {\n' +
    '        display: flex;\n' +
    '        flex-direction: row;\n' +
    '        margin: 0.75rem 0.75rem 0.75rem;\n' +
    '        background-color: rgba(0, 0, 0, .04);\n' +
    '        padding: 0.5rem;\n' +
    '    }\n' +
    '\n' +
    '    .location-risk p {\n' +
    '        margin-bottom: .5rem;\n' +
    '    }\n' +
    '\n' +
    '    .location-risk > div:first-of-type .control-group {\n' +
    '        width: 50px;\n' +
    '    }\n' +
    '\n' +
    '    .risk-occupant-list > span {\n' +
    '        display: inline-block;\n' +
    '        padding: 0 0 0 .5rem;\n' +
    '        position: relative;\n' +
    '        /* border-right: solid 1px #424242; */\n' +
    '    }\n' +
    '\n' +
    '    .risk-occupant-list > span:after {\n' +
    '        content: ", ";\n' +
    '        position: absolute;\n' +
    '        right: -4px;\n' +
    '    }\n' +
    '\n' +
    '    .risk-occupant-list > span:last-of-type:after {\n' +
    '        content: "";\n' +
    '    }\n' +
    '\n' +
    '    .verisk-actions {\n' +
    '        display: flex;\n' +
    '        flex-direction: row;\n' +
    '        justify-content: space-around;\n' +
    '    }    \n' +
    '</style>\n' +
    '\n' +
    '<div class="modal-header">\n' +
    '	<a class="close" ng-click="close()">&times;</a>\n' +
    '	<h3><strong>We found a location!</strong></h3>\n' +
    '    <p>Please select if applicable and click &ldquo;Add selected structures&rdquo;. If your structure isn&rsquo;t listed, review the address entered, and add a structure manually.</p>\n' +
    '</div>\n' +
    '<div class="modal-scroll">\n' +
    '	<div class="modal-body">\n' +
    '		<div class="verisk-test">\n' +
    '         \n' +
    '            <div class="location-risk" ng-repeat="risk in riskData.Risks">\n' +
    '                 <div>\n' +
    '                     <div class="control-group">\n' +
    '                         <label class="checkbox inline">\n' +
    '                             <input type="checkbox" name="{{ risk.RiskId }}" class="risk-checkbox" ng-change="multiCheckbox.change()" ng-model="multiCheckbox.checked[$index]">\n' +
    '                             <span ng-show="false">{{ risk.RiskId }}</span>\n' +
    '                         </label>\n' +
    '                     </div>\n' +
    '                 </div>\n' +
    '                 <div>\n' +
    '                     <h3>{{ risk.BuildingDescription }}</h3>\n' +
    '                     <p><strong>Includes the following occupants:</strong></p>\n' +
    '                     <p class="risk-occupant-list">\n' +
    '                         <span ng-repeat="occupant in risk.Occupants">\n' +
    '                             {{occupant.Name}}\n' +
    '                         </span>\n' +
    '                     </p>\n' +
    '                 </div>\n' +
    '            </div>\n' +
    '         </div>\n' +
    '\n' +
    '	</div>\n' +
    '</div>\n' +
    '<div class="modal-footer">\n' +
    '    <div class="verisk-actions">\n' +
    '        <button class="btn btn-primary" ng-click="getData()">Add selected structure(s) +</button>\n' +
    '        <button class="btn" ng-click="close()">Cancel and add structure manually</button>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/questions/config/widgets/verisk/template-loss-cost.html',
    '<span style="display: none;">Open Verisk Loss Cost Modal</span>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/questions/config/widgets/verisk/template.html',
    '<style>\n' +
    '    .overlay-verisk-modal {\n' +
    '        position: absolute;\n' +
    '        top: 0;\n' +
    '        right: 0;\n' +
    '        bottom: 0;\n' +
    '        left: 0;\n' +
    '        background-color: rgba(0,0,0,.4);\n' +
    '        display: flex;\n' +
    '        justify-content: center;\n' +
    '        align-items: center;\n' +
    '    }\n' +
    '\n' +
    '    .overlay-verisk-modal-message {\n' +
    '        background-color: #fff;\n' +
    '        width: auto;\n' +
    '        height: auto;\n' +
    '        padding: 2rem;\n' +
    '    }\n' +
    '</style>\n' +
    '\n' +
    '<div ng-show="overlayShow" class="overlay-verisk-modal">\n' +
    '    <div class="overlay-verisk-modal-message">Please wait while we look up location details</div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/submit/programDetails/bop/template/template.html',
    '<h3 class="programHeader">Business Owners Policy</h3>\n' +
    '\n' +
    '<table width="100%">\n' +
    '    <tbody>\n' +
    '        <tr ng-repeat="policyDatapoint in policyDatapoints">\n' +
    '            <td width="40%"><p class="reviewLabel">{{:: policyDatapoint.reportLabel }}:</p></td>\n' +
    '            <td>{{:: policyDatapoint.value }}</td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '    <tbody>\n' +
    '        <tr>\n' +
    '            <td colspan="2">\n' +
    '                <h4>Building Location(s)</h4>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '    <tbody class="enumerationBlock" ng-repeat="building in buildings">\n' +
    '        <tr class="titleRow">\n' +
    '            <td colspan="2">\n' +
    '                <p class="reviewLabel indent">BUILDING {{ ($index+1) }}</p>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        <tr>\n' +
    '            <td><p class="reviewLabel indent">Description:</p><p></p></td>\n' +
    '            <td>{{:: building.description }}</td>\n' +
    '        </tr>\n' +
    '        <tr>\n' +
    '            <td><p class="reviewLabel indent">Class Code/Description:</p></td>\n' +
    '            <td>{{:: building.classCode }}</td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '    <tbody>\n' +
    '        <tr>\n' +
    '            <td colspan="2">\n' +
    '                <h4>Optional Endorsements Selected</h4>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '    <tbody>\n' +
    '        <tr ng-repeat="oe in optionalEndorsements">\n' +
    '            <td colspan="2">\n' +
    '                <p class="reviewLabel indent">{{ oe }}</p>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/submit/programDetails/miscErrorsOmissions/template/template.html',
    '<h3 class="programHeader">Misc Errors & Omissions</h3>\n' +
    '\n' +
    '<table width="100%">\n' +
    '    <tbody>\n' +
    '        <tr ng-repeat="policyDatapoint in policyDatapoints">\n' +
    '            <td width="40%"><p class="reviewLabel">{{:: policyDatapoint.reportLabel }}:</p></td>\n' +
    '            <td>{{:: policyDatapoint.value }}</td>\n' +
    '        </tr>\n' +
    '    </tbody>\n' +
    '</table>');
}]);
})();

(function(module) {
try {
  module = angular.module('mPortal.config.templates');
} catch (e) {
  module = angular.module('mPortal.config.templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/components/specialtyApplication/submit/programDetails/wc/template/template.html',
    '<h3 class="programHeader">Workers\' Compensation</h3>\n' +
    '\n' +
    '<table width="100%">\n' +
    '	<tbody>\n' +
    '	<tr>\n' +
    '		<td width="40%"><p class="reviewLabel">Effective Date:</p></td>\n' +
    '		<td>{{:: effectiveDate }}</td>\n' +
    '	</tr>\n' +
    '	</tbody>\n' +
    '	<tbody>\n' +
    '	<tr>\n' +
    '		<td colspan="2">\n' +
    '			<h4>Location(s)</h4>\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '	</tbody>\n' +
    '	<tbody class="enumerationBlock" ng-repeat="location in locations">\n' +
    '	<tr class="titleRow">\n' +
    '		<td colspan="2">\n' +
    '			<p class="reviewLabel indent">LOCATION {{ ($index+1) }}</p>\n' +
    '		</td>\n' +
    '	</tr>\n' +
    '	<tr>\n' +
    '		<td><p class="reviewLabel indent">Address:</p><p></p></td>\n' +
    '		<td>{{:: location.address }}, {{:: location.city }}, {{:: location.state }} {{:: location.zip }}</td>\n' +
    '	</tr>\n' +
    '	<tr ng-repeat="data in location.classCodeDetails">\n' +
    '		<td><p class="reviewLabel indent">Class Code Description:</p></td>\n' +
    '		<td>{{:: data.classCode }}</td>\n' +
    '		<td><p class="reviewLabel indent">Payroll:</p></td>\n' +
    '		<td>${{:: data.payroll }}</td>\n' +
    '	</tr>\n' +
    '	</tbody>\n' +
    '</table>');
}]);
})();
 });
